import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import ReactLoading from 'react-loading'

import Menu from '../../components/Menu'
import api from '../../services/api'

import {ImCompass2} from 'react-icons/im'
import {GoLocation, GoStop} from 'react-icons/go'
import {FaFileCsv, FaFilePdf} from 'react-icons/fa'

//
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {Impressao} from '../../services/pdImage'
import {CSVLink} from 'react-csv'
//

import calculateDistance from '../../services/calculateDistance'

import {GoogleMap, useLoadScript, Marker, InfoWindow, Polyline} from '@react-google-maps/api'
import mapStyle from '../../mapstyle'
const libraries = ['places']
const mapContainerStyle = {
    width: '100%',
    height: '600px'
}
const optionsMap = {
    //styles: mapStyle
}

function Maps() {
    let {conta} = useParams()

    const [intervaloList, setIntervaloList] = useState([])
    const [leitorFilter, setLeitorFilter] = useState('*')
    const [dataFimFilter, setDataFimFilter] = useState(dates(1, '+'))
    const [intervaloFilter, setIntervaloFilter] = useState('*')
    const [tagTableList, setTagTableList] = useState([])
    const [showList, setShowList] = useState(false)
    const [loading, setLoading] = useState(false)
    const [statusFilter, setStatusFilter] = useState('Todos')
    const [infC1List, setInfC1List] = useState([])
    const [infC1, setInfC1] = useState('Todos')

    const [showMap, setShowMap] = useState(false)
    const [markList, setMarkList] = useState([])
    const [selectedMark, setSelectedMark] = useState(null)
    const [selectedMark2, setSelectedMark2] = useState(null)

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_API_G_KEY,
        libraries,
    })
    let ref

    useEffect(() => {
        getLeitor()
    }, [leitorFilter])

    useEffect(() => {
        getItems()
    }, [dataFimFilter, leitorFilter, intervaloFilter])

    if(loadError) return (
        <div>
            Error loading maps
        </div>
    )
    if(!isLoaded) return (
        <></>
    )

    function dates(days, direction) {
        let dt   = new Date()
        if(direction == '+') {
            dt = new Date(dt.getTime() + (days * 24 * 60 * 60 * 1000))
        }
        else if(direction == '-') {
            dt = new Date(dt.getTime() - (days * 24 * 60 * 60 * 1000))
        }
        return (dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2))
    }

    function getItems() {
        setLoading(true)
        api.get(`/registro/${conta}/*/*/*/${dataFimFilter}/*/*/*`
        ).then(
            response => {
                let regList = response.data.filter((item) => {
                    if(item.modo == 'p' || item.modo == 'l') return item
                })
                let regIds = regList.map((item) => {
                    return item._id
                })
                api.get(`/registro_tag_date/${conta}/*/${dataFimFilter}`).then(
                    response2 => {
                        let lastRead = {}
                        let lastReadByTag = {}
                        let tagList = []

                        let regItensList = response2.data.filter((item) => {
                            if(regIds.includes(item.id_registro) && item.latitude && item.latitude != '') {
                                return item
                            }
                        })

                        regItensList.map(item => {
                            if(regList[regIds.indexOf(item.id_registro)].id_leitor){
                                if(!(lastRead[regList[regIds.indexOf(item.id_registro)].id_leitor._id])) {
                                    lastRead[regList[regIds.indexOf(item.id_registro)].id_leitor._id] = [item.tag, item.dt_leitura]
                                }else {
                                    if(new Date(item.dt_leitura) > new Date(lastRead[regList[regIds.indexOf(item.id_registro)].id_leitor._id][1])) {
                                        lastRead[regList[regIds.indexOf(item.id_registro)].id_leitor._id] = [item.tag, item.dt_leitura]
                                    }
                                }
    
                                if(!(lastReadByTag[item.tag])) {
                                    lastReadByTag[item.tag] = [item.dt_leitura]
                                }else {
                                    if(new Date(item.dt_leitura) > new Date(lastReadByTag[item.tag][0])) {
                                        lastReadByTag[item.tag] = [item.dt_leitura]
                                    }
                                }
                            }
                        })
                        
                        let listOfItens
                        api.get(`/item/${conta}/*/*/*/*/*/*/*/*/*/*/*/*/*`).then(
                            response3 => {
                                listOfItens = response3.data
                                if(!infC1List.length) {
                                    let infDistinct = []
                                    listOfItens.map(item => {
                                        if(item.inf_compl_1 != '' && infDistinct.indexOf(item.inf_compl_1) == -1) {
                                            infDistinct.push(item.inf_compl_1)
                                        }
                                    })
                                    setInfC1List(infDistinct.sort())
                                }
                            }
                        ).then(
                            result => {
                                let itensWorked = listOfItens.map((item, index) => {
                                    let itemToWork = item
                                    if(
                                        !((item.latitude && item.latitude != '') &&
                                        (item.longitude && item.longitude != ''))
                                    ) {
                                        let lat_lng = item.inf_compl_5.split(';')
                                        itemToWork['latitude'] = Number(lat_lng[0])
                                        item['longitude'] = Number(lat_lng[1])
                                    }
                                    if(!(item.raio && item.raio != '')) {
                                        itemToWork['raio'] = 25
                                    }
                                    itemToWork['actualPos'] = []
                                    itemToWork['dt_leitura'] = ''
                                    itemToWork['regInfo'] = {}
                                    itemToWork['status'] = {
                                        read: 'Sem leitura',
                                        period: '',
                                        last: false
                                    }
        
                                    regItensList.map((itemR) => {
                                        if(item.tag == itemR.tag) {
                                            let itemFiltered = itemR
                
                                            itemFiltered['regInfo'] = regList[regIds.indexOf(itemR.id_registro)]
                
                                            if(itemR.regInfo.id_leitor && (leitorFilter == '*' || (itemFiltered.regInfo.id_leitor.nm_leitor).toLowerCase().includes(leitorFilter.toLowerCase())) && ((intervaloFilter == '*' || intervaloFilter == itemFiltered.regInfo.id_leitor.periodo))) {
                
                                                itemFiltered['status'] = {}
                                                let info = ""
                                                //if(itemR.excedente == 1) {info = "Excedente"}
                                                if(itemR.auditado == 1) {
                                                    info = "Auditado"
                                                }
                                                if((itemR.encontrado == 1 && itemR.excedente == 0) || itemR.excedente == 1) {
                                                    info = "Encontrado"
                                                }
                                                if(itemR.encontrado == 0 && itemR.excedente == 0) {
                                                    info = "Ausente"
                                                }
                                                if(itemR.incorporado == 1) {
                                                    info = 'Incorporado'
                                                }
                                                itemFiltered.status['inRadius'] = (calculateDistance(
                                                    {latitude: itemToWork.latitude ,longitude: itemToWork.longitude},
                                                    {latitude: itemFiltered.latitude ,longitude: itemFiltered.longitude }
                                                ) < itemToWork.raio)
                                                itemFiltered.status['read'] = info
                                                itemFiltered.status['period'] = ''
                                                itemFiltered.status['last'] = false
                                                
                                                itemToWork.actualPos = [itemFiltered.latitude, itemFiltered.longitude]
                                                itemToWork.regInfo = itemFiltered.regInfo
                                                itemToWork.dt_leitura = itemFiltered.dt_leitura
                                                itemToWork.status = itemFiltered.status
                                            }
                                        }
                                    })
        
                                    return itemToWork
                                })
                                setTagTableList(itensWorked.filter(item => {
                                    if (!(Number.isNaN(item.latitude))) { return item}
                                }).map((item => {
                                    let finalItem = item
                                    if(
                                        (item.regInfo.id_leitor && lastRead[item.regInfo.id_leitor._id][0] == item.tag)
                                        && (item.regInfo.id_leitor && lastRead[item.regInfo.id_leitor._id][1] == item.dt_leitura)
                                    ) {
                                        finalItem.status.last = true
                                    }
        
                                    if(lastReadByTag[item.tag] && lastReadByTag[item.tag][0] == item.dt_leitura) {
                                        if(finalItem.status.read != 'Sem leitura') {
                                            if(Number(item.regInfo.encontrado) + Number(item.regInfo.excedente) >= 1) {
                                                let date1 = new Date(item.dt_leitura)
                                                let date2 = new Date()
                                                let diffTime = Math.abs(date2 - date1)
                                                let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) 
                                                finalItem.status.period = diffDays
                                                if((finalItem.status.period > finalItem.regInfo.id_leitor.periodo * 2)) {
                                                    finalItem.status.read = 'Ausente'
                                                }
                                                if(!(finalItem.regInfo.id_leitor.periodo || finalItem.regInfo.id_leitor.periodo == 0 || finalItem.regInfo.id_leitor.periodo == null || finalItem.regInfo.id_leitor.periodo == '')) {
                                                    finalItem.status.read = 'Sem leitura'
                                                }
                                            }else{
                                                finalItem.status.period = -1
                                            }
                                        }else{
                                            finalItem.status.period = -1
                                        }
                                    }
        
                                    return finalItem
                                })).reverse())
                                setLoading(false)
                            }
                        )

                    }
                )
            }
        )
    }

    function getLeitor() {
        api.get(`/leitor/${conta}/*/*/*/1`).then(
            response => {
                let periodoLista = []
                response.data.filter(item => {
                    if((leitorFilter == '*' || item.nm_leitor.toLowerCase().includes(leitorFilter.toLowerCase())) && (item.periodo) && (item.periodo > 0)) {return item}
                }).map(item => {
                    if(!periodoLista.includes(item.periodo)) {
                        periodoLista.push(item.periodo)
                    }
                })
                setIntervaloList(periodoLista)
            }
        )
    }

    function colorGet(item) {
        if(item.status.read == 'Sem leitura') {
            return '#D64D4D'
        }else if(item.status.inRadius) {
            let stat = item.status.read
            let color = ''
            if(stat == "Excedente") {
                color = '#A33BBF'
            }else if(stat == "Auditado") {
                color = '#08B08C'
            }else if(stat == "Encontrado") {
                color = '#4EA90B'
            }else if(stat == "Ausente") {
                color = '#B40C0C'
            }else if(stat == "Incorporado") {
                color = '#26C6DA'
            }   
            return color
        }else {
            return '#E3AE1B'
        }
    }
    
    function colorReaderGet(days, period) {
        let color = ''
        if(days == -1) {
            color = '#D64D4D'
        }else{
            if(days >= 2 * period) {
                color = '#B40C0C'
            }else if ((period > 0) && (days >= period)) {
                color = 'gray'
            }else if(period == 0 || period == null || ((period > 0) && (days < period))) {
                color = '#4EA90B'
            }
        }

        return color
    }

    function markersLoader(itensList) {
        window.scroll(0, 0)
        let preList = itensList.filter((item) => {
            if(item.longitude) {
                return item
            }
        })
        if(preList.length == 0) {
            window.alert('Itens não possuem localização')
            
        }else {
            setMarkList(preList)
            setShowMap(true)
        }
    }

    function markersUnloader() {
        setMarkList([])
        setShowMap(false)
        setSelectedMark(null)
    }

    function textReadReturn(period) {
        let textRead = ''
        if (period == 0 || period == null || period == '') {
            textRead = 'Não há periodo'
        }else {
            textRead = `Periodo de ${period} dias`
        }

        return textRead
    }
    
    function textStatusReturn(days) {
        let textStatus = ''
        if(days == -1) {
            textStatus = 'Leitura ausente'
        }else{
            if(days == 0) {
                textStatus = `Lido hoje`
            }else if(days == 1){
                textStatus = `Lido há ${days} dia`
            }else{
                textStatus = `Lido há ${days} dias`
            }
        }

        return textStatus
    }
    
    function colorStatus(item) {
        if(item.status.read == 'Excedente') {
            return '/contelurb-Excedente.png'
        }else {
            if(item.status.read == 'Sem leitura') {
                return '/contelurb-Sem-Leitura.png'
            }else if(item.status.read == 'Ausente') {
                return '/contelurb-Ausente.png'
            }else if(!(item.status.inRadius)){
                return '/contelurb-Fora.png'
            }else{
                if ((item.regInfo.id_leitor.periodo > 0) && (item.status.period >= item.regInfo.id_leitor.periodo)) {
                    return '/contelurb-Aguardo.png'
                }else if(item.regInfo.id_leitor.periodo == 0 || item.regInfo.id_leitor.periodo == null || ((item.regInfo.id_leitor.periodo > 0) && (item.status.period < item.regInfo.id_leitor.periodo))) {
                    return '/contelurb-Okay.png'
                }
            }
        }
    }

    function setorFilterReturn(item, filter) {
        if(filter == 'Todos' || item.inf_compl_1 == filter) {
            return true
        }else {
            return false
        }
    }

    function statusFilterReturn(item, filter) {
        let coord1
        let coord2
        switch (filter) {
            case 'Todos':
                return true
                break;
            case 'Ausente':
                if(item.status.read == 'Ausente'){
                    return true
                }else{
                    return false
                }
                break;
            case 'Okay':
                coord1 = {latitude: item.latitude, longitude: item.longitude}
                coord2 = {latitude: Number(item.actualPos[0]), longitude: Number(item.actualPos[1])}
                if((item.status.read == 'Encontrado') && (calculateDistance(coord1, coord2) < item.raio) && (!item.regInfo.id_leitor.periodo || item.regInfo.id_leitor.periodo == '' || item.status.period < item.regInfo.id_leitor.periodo)) {
                    return true
                }else{
                    return false
                }
                break;
            case 'Fora':
                coord1 = {latitude: item.latitude, longitude: item.longitude}
                coord2 = {latitude: Number(item.actualPos[0]), longitude: Number(item.actualPos[1])}
                if(calculateDistance(coord1, coord2) > item.raio) {
                    return true
                }else{
                    return false
                }
                break;
            case 'OutTime':
                coord1 = {latitude: item.latitude, longitude: item.longitude}
                coord2 = {latitude: Number(item.actualPos[0]), longitude: Number(item.actualPos[1])}
                if((item.status.read == 'Encontrado') && (calculateDistance(coord1, coord2) < item.raio) && (item.status.period > item.regInfo.id_leitor.periodo)) {
                    return true
                }else{
                    return false
                }
                break;
            case 'NoReader':
                if(item.status.read == 'Sem leitura') {
                    return true
                }else {
                    return false
                }
                break
            default:
                return false
                break;
        }
    }

    //
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    const visualizarImpressao = async () => {
        let pdfFilter = await tagTableList.filter(item => {
            if(
                statusFilterReturn(item, statusFilter) &&
                setorFilterReturn(item, infC1)
            ){return item}
        }).map((item, index) => {
            return [
                index + 1,
                item.id_categoria.descricao + '\n' + item.tag,
                (item.inf_compl_1 ? item.inf_compl_1 : 'N / A') +
                '\n' + (item.inf_compl_2 ? item.inf_compl_2 : '') +
                '\n' + (item.inf_compl_3 ? item.inf_compl_3 : '') +
                '\n' + (item.inf_compl_4 ? item.inf_compl_4 : '') +
                '\n' + (item.inf_compl_5 ? item.inf_compl_5 : ''),
                (item.id_nivel_loc_1 ? item.id_nivel_loc_1.descricao : 'N / A') +
                '\n' + (item.id_nivel_loc_2 ? item.id_nivel_loc_2.descricao : '') +
                '\n' + (item.id_nivel_loc_3 ? item.id_nivel_loc_3.descricao : '') +
                '\n' + (item.id_nivel_loc_4 ? item.id_nivel_loc_4.descricao : ''),
                !(item.latitude == undefined && item.latitude == undefined) ? ('latitude: ' + item.latitude + '\nlongitude: ' + item.longitude) : '',
                item.status.read + (item.regInfo.id_leitor ? `\nleitor: ${item.regInfo.id_leitor.nm_leitor}` : '') +
                `\n${(item.status.read != 'Sem leitura' ? textStatusReturn(item.status.period) : '')}` +
                `\n${(item.status.read != 'Sem leitura' ? textReadReturn(item.regInfo.id_leitor.periodo) : '')}` +
                '\n' + (item.status.last ? `Ultima leitura do leitor: ${item.regInfo.id_leitor.nm_leitor}` : ''),
                !item.status.inRadius && item.actualPos[0] && item.actualPos[1]? `Sim\nCoordenada atual:\n${item.actualPos[0]}\n${item.actualPos[1]}` : 'Não'
            ]
        })

        let linkeres = {}

        const classeImpressao = await new Impressao({
            hLogo: '',
            PDFOrientation: 'portrait',
            watermarker: ' ',
            hTitle: "Itens",
            hItems: [
                "#",
                "CATEGORIA",
                "INFORMAÇÃO",
                "LOCALIZAÇÃO",
                "LOCALIZAÇÃO DE LEITURA",
                "STATUS",
                "FORA DE POSIÇÃO"
            ],
            images: linkeres,
            bItems: pdfFilter
        });
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }

    function csv() {
        let head = [
            "tagvinc",
            "codExterno",
            "ativo",
            "tag",
            "categoria",
            "ean",
            "infComplementar1",
            "infComplementar2",
            "infComplementar3",
            "infComplementar4",
            "infComplementar5",
            "parceiro",
            "nivel1",
            "nivel2",
            "nivel3",
            "nivel4",
            "observacao"
        ]

        let body = tagTableList.filter(item => {
            if (
                statusFilterReturn(item, statusFilter) &&
                setorFilterReturn(item, infC1)
            ) {return item}
        }).map((item, index) => {
            return [
                item.id_categoria.descricao,
                item.tag,
                (item.inf_compl_1 ? item.inf_compl_1 : ''),
                (item.inf_compl_2 ? item.inf_compl_2 : ''),
                (item.inf_compl_3 ? item.inf_compl_3 : ''),
                (item.inf_compl_4 ? item.inf_compl_4 : ''),
                (item.inf_compl_5 ? item.inf_compl_5 : ''),
                (item.id_nivel_loc_1 ? item.id_nivel_loc_1.descricao : ''),
                (item.id_nivel_loc_2 ? item.id_nivel_loc_2.descricao : ''),
                (item.id_nivel_loc_3 ? item.id_nivel_loc_3.descricao : ''),
                (item.id_nivel_loc_4 ? item.id_nivel_loc_4.descricao : ''),
                !(item.latitude == undefined && item.latitude == undefined) ? (item.latitude + ';' + item.longitude) : '',
                item.status.read,
                (item.status.read != 'Sem leitura' ? (textStatusReturn(item.status.period)) : ''),
                (item.status.read != 'Sem leitura' ? (textReadReturn(item.regInfo.id_leitor.periodo)) : ''),
                (item.status.last ? `Ultima leitura do leitor: ${item.regInfo.id_leitor.nm_leitor}` : ''),
                !item.status.inRadius ? 'Não' : `Sim`,
                !item.status.inRadius && item.actualPos[0] && item.actualPos[1] ? `${item.actualPos[0]};${item.actualPos[1]}` : '',
                item.regInfo && item.regInfo.id_leitor ? item.regInfo.id_leitor.nm_leitor : '',
            ]
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        if(body2.length){
            return [...body2]
        }else{
            return [['']]
        }
    }

    return (
        <>
            <div  style={{marginLeft: '30px'}}>
                {showMap ? (
                    <div className='extra'>
                        <div
                            className='extracontainer'
                            style={{
                                marginLeft: 'calc(50% - 40%)',
                                minHeight: 'auto',
                                height: '660px',
                                width: '80%'
                            }}
                        >
                            <div className="title" style={{background: 'green'}}>
                                <h1>Mapa - Contelurb</h1>
                                <div>
                                    <button onClick={e=> {markersUnloader()}}>FECHAR</button>
                                </div>
                            </div>
                            <div style={{marginTop: '20px'}}>
                                <GoogleMap
                                    className='mapStyles'
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={18}
                                    center={{lat: Number.parseFloat(markList[0].latitude), lng: Number.parseFloat(markList[0].longitude)}}
                                >
                                    {markList.map((item, index) => {
                                        if(item.longitude) {
                                            return (
                                                <Marker
                                                    key = {index}
                                                    position={{
                                                        lat: Number.parseFloat(item.latitude),
                                                        lng: Number.parseFloat(item.longitude)
                                                    }}
                                                    icon={{
                                                        url: colorStatus(item),
                                                        scale: 5
                                                    }}
                                                    onClick= {e => {setSelectedMark(item)}}
                                                />
                                            )
                                        }
                                    })}

                                    {selectedMark && (
                                        <InfoWindow
                                            position={{
                                                lat: Number.parseFloat(selectedMark.latitude),
                                                lng: Number.parseFloat(selectedMark.longitude)
                                            }}
                                            onCloseClick={e => {setSelectedMark(null)}}
                                        >
                                            <div style={{textAlign: 'center'}}>
                                                <h2>{selectedMark.id_categoria.descricao}</h2>
                                                <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.inf_compl_4}</p>
                                                <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.tag}</p>
                                                <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.latitude} {selectedMark.longitude}</p>
                                                <div style={{padding: '10px', background: colorGet(selectedMark), width: 'auto', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{(!(selectedMark.status.inRadius) && selectedMark.actualPos.length ? 'Fora de posição': selectedMark.status.read)}</div>
                                                {!(selectedMark.status.inRadius) && selectedMark.actualPos.length ? <div>{`Coordenda atual ${selectedMark.actualPos[0]} ${selectedMark.actualPos[1]}`}</div> : ''}
                                                <div>{selectedMark.dt_leitura}</div>
                                                {selectedMark.status.period != '' && selectedMark.regInfo.id_leitor ?
                                                <div
                                                    style={{padding: '10px', background: colorReaderGet(selectedMark.status.period, selectedMark.regInfo.id_leitor.periodo), width: 'auto', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}
                                                >{textStatusReturn(selectedMark.status.period)}</div>:''}
                                                {selectedMark.regInfo.id_leitor ? <div style={{textAlign:'center'}}>{textReadReturn(selectedMark.regInfo.id_leitor.periodo)}</div>: ''}
                                                {selectedMark.status.last ?   
                                                <div
                                                    style={{
                                                        padding: '10px', background: '#f7a22b', width: 'auto', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'
                                                    }}
                                                ><GoStop/> Ultima Leitura</div>: ''}
                                                {selectedMark.status.last ?<div style={{textAlign: 'center'}}>{`Leitor: ${selectedMark.regInfo.id_leitor.nm_leitor}`}</div>:''}
                                            </div>
                                        </InfoWindow>
                                    )}
                                </GoogleMap>
                            </div>
                        </div>
                    </div>
                ) : ''}
                <div className="title" style={{background:'green'}}>
                    <h1>Mapa - Contelurb</h1>
                </div>
                <div className="filters">
                    <div className="filter">
                        <label>Tipo de Leitura</label>
                        <select
                            value={leitorFilter}
                            onChange={e => {setLeitorFilter(e.target.value)}}
                        >
                            <option value='*'>Todos</option>
                            <option value='coleta'>Coleta</option>
                            <option value='higienização'>Higienização</option>
                            <option value='manutenção'>Manutenção</option>
                        </select>
                    </div>
                    <div className="filter">
                        <label>Data</label>
                        <div className="date">
                            <input
                                className='dinput'
                                type='date'
                                value={dataFimFilter}
                                onChange={e => {setDataFimFilter(e.target.value)}}
                            />
                        </div>
                    </div>
                    <div className="filter">
                        <label>Periodo</label>
                        <select
                            value={intervaloFilter}
                            onChange={e => {setIntervaloFilter(e.target.value)}}
                        >
                            <option value='*'>Todos</option>
                            {intervaloList.map((item, index) => (
                                <option key={index} value={item}>{`${item} dias`}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label>Setor</label>
                        <select
                            value={infC1}
                            onChange={e => {setInfC1(e.target.value)}}
                        >
                            <option value='Todos'>Todos</option>
                            {infC1List.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label>Status</label>
                        <select
                            value={statusFilter}
                            onChange={e => {setStatusFilter(e.target.value)}}
                        >
                            <option value='Todos'>Todos</option>
                            <option value='Okay'>Leitura Realizada</option>
                            <option value='Ausente'>Ausente</option>
                            <option value='Fora'>Fora de Posição</option>
                            <option value='OutTime'>Aguardando Leitura</option>
                            <option value='NoReader'>Sem leitura</option>
                        </select>
                    </div>
                </div>
                {!showMap && !loading?<div style={{marginTop: '20px'}}>
                    <GoogleMap
                        className='mapStyles'
                        mapContainerStyle={mapContainerStyle}
                        zoom={18}
                        center={{lat: Number.parseFloat(tagTableList[0] ? tagTableList[0].latitude: 0), lng: Number.parseFloat(tagTableList[0] ? tagTableList[0].longitude : 0)}}
                    >
                        {tagTableList.filter(item =>{
                            if(
                                statusFilterReturn(item, statusFilter) &&
                                setorFilterReturn(item, infC1)
                            ){return item}
                        }).map((item, index) => {
                            if(item.longitude) {
                                return (
                                    <Marker
                                        key = {index}
                                        position={{
                                            lat: Number.parseFloat(item.latitude),
                                            lng: Number.parseFloat(item.longitude)
                                        }}
                                        icon={{
                                            url: colorStatus(item),
                                            scale: 5
                                        }}
                                        onClick= {e => {
                                            setSelectedMark2(item)
                                        }}
                                    />
                                )
                            }
                        })}

                        {selectedMark2 && (
                            <InfoWindow
                                position={{
                                    lat: Number.parseFloat(selectedMark2.latitude),
                                    lng: Number.parseFloat(selectedMark2.longitude)
                                }}
                                onCloseClick={e => {setSelectedMark2(null)}}
                            >
                                <div style={{textAlign: 'center'}}>
                                    {tagTableList.filter((point) => {
                                        if((calculateDistance(
                                            {latitude: selectedMark2.latitude, longitude: selectedMark2.longitude},
                                            {latitude: point.latitude, longitude: point.longitude}
                                        ) < 10) && (selectedMark2._id != point._id) && (statusFilterReturn(point, statusFilter) && setorFilterReturn(point, infC1))) {return point}
                                    }).length ? <p style={{fontWeight:'bold', fontSize: 15}}>Leituras sobrepostas</p> : ''}
                                    {tagTableList.filter((point) => {
                                        if((calculateDistance(
                                            {latitude: selectedMark2.latitude, longitude: selectedMark2.longitude},
                                            {latitude: point.latitude, longitude: point.longitude}
                                        ) < 10) && selectedMark2._id != point._id) {return point}
                                    }).map((point, index) => (
                                        <div
                                            key= {index}
                                            className='maps-others'
                                            onClick={e => {setSelectedMark2(point)}}
                                        >{point.tag}</div>
                                    ))}
                                    <h2>{selectedMark2.id_categoria.descricao}</h2>
                                    <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark2.inf_compl_4}</p>
                                    <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark2.tag}</p>
                                    <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark2.latitude} {selectedMark2.longitude}</p>
                                    <div style={{padding: '10px', background: colorGet(selectedMark2), width: 'auto', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{!(selectedMark2.status.inRadius) && selectedMark2.actualPos.length ? 'Fora de posição' : selectedMark2.status.read}</div>
                                    {!(selectedMark2.status.inRadius) && selectedMark2.actualPos.length? <div>{`Coordenda atual ${selectedMark2.actualPos[0]} ${selectedMark2.actualPos[1]}`}</div> : ''}
                                    <div>{selectedMark2.dt_leitura}</div>
                                    {selectedMark2.status.period != '' && selectedMark2.regInfo.id_leitor ?
                                    <div
                                        style={{padding: '10px', background: colorReaderGet(selectedMark2.status.period, selectedMark2.regInfo.id_leitor.periodo), width: 'auto', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}
                                    >{textStatusReturn(selectedMark2.status.period)}</div>:''}
                                    {selectedMark2.regInfo.id_leitor ? <div style={{textAlign:'center'}}>{textReadReturn(selectedMark2.regInfo.id_leitor.periodo)}</div> : ''}
                                    {selectedMark2.status.last ?   
                                    <div
                                        style={{
                                            padding: '10px', background: '#f7a22b', width: 'auto', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'
                                        }}
                                    ><GoStop/> Ultima Leitura</div>: ''}
                                    {selectedMark2.status.last ?<div style={{textAlign: 'center'}}>{`Leitor: ${selectedMark2.regInfo.id_leitor.nm_leitor}`}</div>:''}
                                </div>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                </div>: 
                <div style={{display: 'flex', justifyContent: 'center', margin: '240px 0'}}>
                    <ReactLoading type={'bars'} color={'green'} height={150} width={150}/>
                </div>
                }
                <div className="export">
                    <button onClick={e => {setShowList(!showList)}}>{showList ? 'Ocultar Lista' : 'Mostrar Lista'}</button>
                </div>
                {showList ? <div className="export">
                    <button onClick={visualizarImpressao}><FaFilePdf className="dIcons"/></button>
                    <CSVLink className="csv" data={csv()} separator={';'}>
                        <button><FaFileCsv className="dIcons"/></button>
                    </CSVLink>
                </div> : ''}
                {showList ? <div className="list">
                    <table>
                        <thead>
                            <tr id="header">
                                <th>#</th>
                                <th>ITEM</th>
                                <th>LOCAL</th>
                                <th>STATUS DE LEITURA</th>
                                <th>STATUS DO ITEM</th>
                                <th>ULTIMA LEITURA</th>
                                <th><ImCompass2 className="table_icons"/></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tagTableList.filter(item => {
                                if(statusFilterReturn(item, statusFilter) && setorFilterReturn(item, infC1)){return item}
                            }).map((item, index) => (
                                <tr key={index}>
                                    <th>{index + 1}</th>
                                    <th>
                                        <div>{item.id_categoria.descricao}</div>
                                        <div>{item.tag}</div>
                                    </th>
                                    <th>
                                        <div>{item.id_item && item.id_item.id_nivel_loc_1 ? item.id_item.id_nivel_loc_1.descricao : 'N / A'}</div>
                                        {item.id_item && item.id_item.id_nivel_loc_1 ? <div>{item.id_item.id_nivel_loc_2 ? item.id_item.id_nivel_loc_2.descricao : 'N / A'}</div> : ''}
                                        {item.id_item && item.id_item.id_nivel_loc_2 ? <div>{item.id_item.id_nivel_loc_3 ? item.id_item.id_nivel_loc_3.descricao : 'N / A'}</div> : ''}
                                        {item.id_item && item.id_item.id_nivel_loc_3 ? <div>{item.id_item.id_nivel_loc_4 ? item.id_item.id_nivel_loc_4.descricao : 'N / A'}</div> : ''}
                                    </th>
                                    <th>
                                        <div style={{padding: '10px', background: colorGet(item), width: '120px', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{(!(item.status.inRadius) && item.status.read != 'Sem leitura' ? 'Fora de posição': item.status.read)}</div>
                                        <div>{item.dt_leitura}</div>
                                    </th>
                                    {item.status.period != '' ? <th>
                                        <div
                                            style={{
                                                padding: '10px',
                                                background: colorReaderGet(item.status.period, item.regInfo.id_leitor.periodo),
                                                width: 'auto',
                                                textAlign: 'center',
                                                borderRadius: '10px',
                                                color: '#F8F8F8',
                                                fontWeight: 'bold'
                                            }}
                                        >{textStatusReturn(item.status.period)}</div>
                                        <div style={{textAlign:'center'}}>{textReadReturn(item.regInfo.id_leitor.periodo)}</div>
                                    </th> : <th></th>}
                                    {item.status.last ?
                                        <th>        
                                            <div
                                                style={{
                                                    padding: '10px',
                                                    background: '#f7a22b',
                                                    width: 'auto',
                                                    textAlign: 'center',
                                                    borderRadius: '10px',
                                                    color: '#F8F8F8',
                                                    fontWeight: 'bold'
                                                }}
                                            ><GoStop/> Ultima Leitura</div>
                                            <div style={{textAlign: 'center'}}>{`Leitor: ${item.regInfo.id_leitor.nm_leitor}`}</div>
                                        </th>
                                    : <th></th>}
                                    <th>{item.longitude ? <GoLocation className="table_icons show_map" onClick={e => {markersLoader([item])}}/> : ''}</th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> : ''}
            </div>
        </>
    )
}

export default Maps