import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { t } from "i18next";

import { Icon } from "@iconify/react";

import secureLocalStorage from "react-secure-storage";

import CustomModal from "../../../components/ui/CustomModal";

import { Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material";

// ----------------------------------------------------------------

export default function SettingsButton(){
    const userData = secureLocalStorage.getItem('userDetails')

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const [confirmModal, setConfirmModal] = useState(false)

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    
    const handleLogout = () => {
        sessionStorage.clear()
        secureLocalStorage.clear()
        if(sessionStorage.getItem('idConta') === 'kRTYf3Drw'){
            window.location.href = '/PBC/login'
        }else{
            window.location.href = '/login'
        }
    };

    return(
        <>
            <IconButton onClick={handleClick} >
                <Icon icon={'mingcute:settings-3-line'}/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.10))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                <MenuItem>
                    <Stack>
                        <Typography variant="h6">{userData?.nome}</Typography>
                        <Typography variant="subtitle">{userData?.login}</Typography>
                        <Typography>{userData?.perfil === 'admin' ? 'Admin' : 'Usuário'}</Typography>
                    </Stack>
                </MenuItem>
                <Divider/>
                <MenuItem sx={{display: userData?.perfil !== 'admin' ? 'none' : ''}} onClick={e=>{navigate('/account')}}>
                    <ListItemIcon>
                        <Icon icon={'solar:settings-outline'}/>
                    </ListItemIcon>
                    <ListItemText>{t('menu.account')}</ListItemText>
                </MenuItem>
                <MenuItem sx={{display: userData?.perfil !== 'admin' ? 'none' : ''}} onClick={e=>{navigate('/activity')}}>
                    <ListItemIcon>
                        <Icon icon={'fluent-mdl2:date-time'}/>
                    </ListItemIcon>
                    <ListItemText>{t('menu.history')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={e=>{navigate('/terms-and-conditions')}}>
                    <ListItemIcon>
                        <Icon icon={'mage:file-3'}/>
                    </ListItemIcon>
                    <ListItemText>{t('terms.termsAndConditions.title')}</ListItemText>
                </MenuItem>
                <MenuItem sx={{display: userData?.perfil !== 'admin' ? 'none' : ''}} onClick={e=>{navigate('/account/guides')}}>
                    <ListItemIcon>
                        <Icon icon={'material-symbols:help-outline'}/>
                    </ListItemIcon>
                    <ListItemText>{t('menu.help')}</ListItemText>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={e=>{setConfirmModal(!confirmModal)}}>
                    <ListItemIcon>
                        <Icon icon={'material-symbols:logout-rounded'}/>
                    </ListItemIcon>
                    <ListItemText>{t('menu.exit')}</ListItemText>
                </MenuItem>
            </Menu>

            {confirmModal ? 
                <CustomModal 
                    onConfirm={handleLogout}
                    onDeny={e=>{setConfirmModal(false)}}
                >
                    <Box sx={{p:2}}>
                        <Typography color='error' sx={{p:3}}>
                            <Icon icon='ph:warning-fill' width="64"/>
                        </Typography>
                        <Typography variant="h1" sx={{mb:1}}>{t('messages.attention')}</Typography>    
                        <Typography variant="subtitle">{t('messages.logout.confirm_logout')}</Typography>  
                    </Box>
                </CustomModal>
            : ''}
        </>
    )
}