export default function ListItemButton(theme) {
  return {
      MuiListItemButton: {
          styleOverrides: {
              root: {
                '& .MuiListItemText-primary': {
                    fontSize: '0.85rem'
                },
                '&:hover': {
                    backgroundColor: 'transparent',
                    '& .MuiListItemIcon-root': {
                        color: theme.palette.primary.contrastText,
                    }
                },
                borderRadius: '8px'
              }
          }
      }
  };
}
