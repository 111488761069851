import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';

import Stepper from '@mui/material/Stepper';

import Step from '@mui/material/Step';

import StepLabel from '@mui/material/StepLabel';

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import { Icon } from '@iconify/react/dist/iconify.js';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import secureLocalStorage from 'react-secure-storage';

import { Backdrop, Box, Button, Grid, IconButton, Paper, Typography } from '@mui/material';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Icon icon="uis:check" className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};


export default function Transport({ children }) {
    const navigate = useNavigate()

    const location = useLocation().pathname.replace('/transport/', '');

    const steps = ['qrcode', 'upload-foto', 'confirm-data']
  
    const [activeStepper, setActiveStepper] = useState(Math.max(0, steps.indexOf(location)));

    const [cameraPermission, setCameraPermission] = useState("");

    const [locationPermission, setLocationPermission] = useState("");

    const [showHelper, setShowHelper] = useState(false)

    useEffect(()=>{
        if(!secureLocalStorage.getItem('transport-user')){
            navigate('/transport/login')
        }
    },[])

    useEffect(() => {
      // Verifica a permissão de localização
      navigator.geolocation.getCurrentPosition(
        (position) => {
            setLocationPermission(position.coords.latitude && position.coords.longitude);
        },
        (error) => {
            // Erro ao obter a localização
            console.error(error);
        }
      )
    }, []);

    useEffect(() => {
      // Verifica a permissão de câmera
      navigator.permissions.query({ name: 'camera' })
        .then(permissionStatus => {
          const hasPermission = permissionStatus.state === "granted"
          setCameraPermission(hasPermission);
          permissionStatus.onchange = () => {
            const hasPermission = permissionStatus.state === "granted"
            setCameraPermission(hasPermission);
          };
        })
        .catch(err => console.error('Erro ao verificar permissão da câmera:', err));
    }, [])

    return (
        <>
          <Stack alignItems="center" sx={{ padding: 3, height: '100vh' }} spacing={6}>

          <Stepper 
              alternativeLabel 
              activeStep={activeStepper} 
              connector={<QontoConnector />}
              sx={{
                  width: {xs: '100%', md: '50%'}
              }}
          >

              <Step key={0}>
                  <StepLabel StepIconComponent={QontoStepIcon}>Scanear QRCode</StepLabel>
              </Step>

              <Step key={1}>
                  <StepLabel StepIconComponent={QontoStepIcon}>Adicionar foto</StepLabel>
              </Step>

              <Step key={2}>
                  <StepLabel StepIconComponent={QontoStepIcon}>Enviar Dados</StepLabel>
              </Step>

          </Stepper>

          <Outlet/>

          </Stack>

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={!locationPermission || !cameraPermission}
          >
            <Box
              as={Paper}
              sx={{
                width: {xs: '80%', md:'30%'},
                p: 5,
                borderRadius: 5
              }}
            >
              <Typography variant="h1">Permissões</Typography>
              <Typography variant="subtitle">Permita acesso para continuar o cadastro.</Typography>
              <Grid container spacing={2} sx={{pt: 1}}>
                <Grid item container spacing={1} alignItems="center">
                  <Grid item><Icon icon="solar:camera-broken"/></Grid>
                  <Grid item md><Typography>Camera</Typography></Grid>
                  <Grid item><Button variant="contained" color={cameraPermission ? "success" : "warning"}><Icon icon={cameraPermission ? "uim:check" : "pajamas:warning-solid"}/></Button></Grid>
                </Grid>
                <Grid item container spacing={1} alignItems="center">
                  <Grid item><Icon icon="tabler:map-pin"/></Grid>
                  <Grid item md><Typography>Localização</Typography></Grid>
                  <Grid item><Button variant="contained" color={locationPermission ? "success" : "warning"}><Icon icon={locationPermission ? "uim:check" : "pajamas:warning-solid"}/></Button></Grid>
                </Grid>
              </Grid>
              <Stack direction="row" alignItems="center" spacing={1} sx={{mt: 3, color:"info.main"}} onClick={e=>{setShowHelper(!showHelper)}}>
                <Icon icon="tabler:help-circle"/>
                <Typography variant="subtitle" color="info.main">Como permitir acesso à câmera e local?</Typography>
              </Stack>
            </Box>
          </Backdrop>

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showHelper}
          >
            <Stack
              as={Paper}
              sx={{
                width: {xs: '80%', md:'40%'},
                p: 5,
                borderRadius: 5
              }}
              spacing={3}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h1">Como gerenciar as permissões no Navegador</Typography>
                <IconButton onClick={e=>{setShowHelper(!showHelper)}}><Icon icon="iconamoon:close-bold"/></IconButton>
              </Stack>
              <Typography>Para permitir o acesso à câmera e à localização no Google Chrome, siga os passos abaixo:</Typography>
              <Typography><b>No computador:</b> Abra o Chrome e clique no ícone de três pontos no canto superior direito. Em seguida, selecione "Configurações" e vá para "Privacidade e segurança". Clique em "Configurações do site" e, em "Permissões", escolha "Câmera" e "Local".</Typography>
              <Typography><b>No Android:</b> Abra o Chrome, toque no ícone de três pontos ao lado da barra de endereço, depois em "Configurações" e em "Configurações do site". Selecione "Câmera" ou "Local" e ative ou desative o acesso conforme necessário. Caso o site que deseja acessar esteja listado como "Bloqueado", toque nele e altere a permissão para "Permitir".</Typography>
              <Typography>Outra opção é selecionar "Perguntar antes de acessar (recomendado)" em "Câmera". Assim, sempre que um site solicitar acesso à sua câmera, você receberá uma notificação para permitir ou bloquear o acesso.</Typography>
            </Stack>
          </Backdrop>
      </>
    );
}
