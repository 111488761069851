import React, {useState, useEffect} from 'react'
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Button } from 'react-bootstrap';
import api from '../../services/api'

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    marginBottom: 10,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  tableRow: { margin: 'auto', flexDirection: 'row', width: '100%'},
  tableCell: { width: '100%', margin: 'auto', fontSize: 12, padding: 5, borderStyle: 'solid', borderWidth: 1 },
});

function PDFExporter({ data }) {

  const [activities, setActivities] = useState([])
  const [userList, setUserList] = useState([])
  const [filterBy, setFilterbyUser] = useState('*')
  const [catList, setListbyCategory] = useState([])

  useEffect(()=>{
    async function getUserList() {
        api.get(`/usuario/${sessionStorage.getItem('idConta')}/*/*/*/*`, {})
        .then(response => {                
            setUserList(response.data)
        })
    }
    getUserList()
  },[])

  function getUsername(id){
      let username = userList.filter(item => item._id == id)
      return username[0]
  }
    
    return (
    <PDFViewer width="100%" className='vh-100'>
        <Document>
        <Page size="A4" style={styles.page} className="table">
            <Text style={styles.header}>Histórico de Atividades</Text>
            <View style={styles.table}>
            <View style={styles.tableRow}>
                <Text style={styles.tableCell}>Data</Text>
                <Text style={styles.tableCell}>Ação</Text>
                <Text style={styles.tableCell}>Responsável</Text>
            </View>
            {data.map((a) => (
                <View style={styles.tableRow} key={a.id}>
                <Text style={styles.tableCell}>{new Date(a.dt_acao).toLocaleDateString('pt-BR', {day: '2-digit', month: 'short', year: 'numeric'})} {new Date(a.dt_acao).getHours()}:{new Date(a.dt_acao).getMinutes()}</Text>
                <Text style={styles.tableCell}>{a.acao}</Text>
                <Text style={styles.tableCell}>{getUsername(a.valor.id_usuario)?.nome}</Text>
                </View>
            ))}
            </View>
        </Page>
        </Document>
    </PDFViewer>
    );
}

export default PDFExporter;
