import React, { useEffect, useRef, useState } from 'react';

import SuccessAlert from "../../assets/notifications-sounds/success.mp3";

const Notification = ({ type }) => {
    const audioPlayer = useRef(null);

    const [notificationSound, setNotificationSound] = useState(SuccessAlert)

    function handleNotification() {
        if(type == 'success'){
            setNotificationSound(SuccessAlert)
        }
        audioPlayer.current.play();
    }

    useEffect(() => {
        handleNotification();
    }, []);

  return (
    <>
        <audio ref={audioPlayer} src={notificationSound} />
    </>
  );
};

export default Notification;
