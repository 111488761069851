import { Container } from 'react-bootstrap';
import ReactLoading from 'react-loading'

// ----------------------------------------------------------------

export default function LoadingPage(){
    return(
        <>
            <Container fluid className="vh-100 d-flex justify-content-center align-items-center position-absolute top-0 start-0 bg-dark bg-opacity-25">
                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
            </Container>
        </>
    )
}