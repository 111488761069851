import { useState } from "react";

import { t } from "i18next";

import { Icon } from "@iconify/react";

import CustomModal from "../../components/ui/CustomModal";

import { Backdrop, Box, Button, CircularProgress, Grid, InputAdornment, TextField, Typography } from "@mui/material";

import api from "../../services/api";

import Alert from "../../components/ui/Alert";

// ----------------------------------------------------------------

export default function CleanBase({email, haveAuthenticator}){
    const [loading, setLoading] = useState(false)

    const [confirmModal, setConfirmModal] = useState(false) // mostrar o modal pedindo confirmação

    const [authModal, setAuthModal] = useState(false) // mostrar o modal pedindo o código

    const [authCode, setAuthCode] = useState()

    const [message, setMessage] = useState() 

    const [requestEmail, setRequestEmail] = useState()

    function handleConfirm(){
        setConfirmModal(false)
        if(haveAuthenticator){
            setAuthModal(!authModal)
        }else{
            setLoading(true)

            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post(`/create2fa/${sessionStorage.getItem('idConta')}?emailSend=${email}`, option
            ).then(
                response => {
                    setLoading(false)
                    setRequestEmail({
                        "icon": "flat-color-icons:ok",
                        "title": t('messages.sucess_email'),
                        "message": `
                            <>
                                <Typography>${t('settings.clean_base.send_email_1')}</Typography>
                                <Typography>${email}</Typography>
                                <Typography>${t('settings.clean_base.send_email_2')}</Typography>
                            </>
                        `
                    })
                },
                error=>{
                    setLoading(false)
                    if(error.response.status === 401){
                        setAuthModal(true)
                    }else{
                        setMessage({
                            "type": "error",
                            "title": t('messages.errors.error'),
                            "message": t('messages.errors.500')
                        })
                    }
                }
            )
        }
    }

    function handleAuth(){
        setLoading(true)

        let option = {
            headers: { 'TOKENKEY': [authCode] }
        }
        api.delete(`conta/${sessionStorage.getItem('idConta')}`, option
        ).then(
            response => {
                setLoading(false)
                setAuthModal(false)
                setMessage({
                    "type": "success",
                    "title": t('messages.success'),
                    "message": t('settings.clean_base.success_message')
                })
            },
            error => {
                setLoading(false)

                if(error.response.status === 401){
                    setAuthModal(false)
                    setMessage({
                        "type": "error",
                        "title": t('messages.errors.error'),
                        "message": t('settings.clean_base.code_invalid')
                    })
                }else{
                    setAuthModal(false)
                    setMessage({
                        "type": "error",
                        "title": t('messages.errors.error'),
                        "message": t('messages.errors.500')
                    })
                }
            }
        )
    }

    return (
        <>
            <Button variant="outlined" color="dark" onClick={e=>{setConfirmModal(!confirmModal)}} startIcon={<Icon icon='mi:delete' />}>{t('settings.clean_base.clean_base')}</Button>

            {confirmModal ? 
                <CustomModal 
                    title={t('settings.clean_base.clean_base')}
                    onConfirm={e=>{handleConfirm()}}
                    onDeny={e=>{setConfirmModal(false)}}
                >
                    <Typography variant="h1" sx={{mb:2}}>{t('messages.attention')}</Typography>    
                    <Typography variant="subtitle">{t('settings.clean_base.description')}</Typography>  
                    <Typography color="error" variant="subtitle" paragraph sx={{my:1}}>{t('settings.clean_base.warning')}</Typography>
                    <Typography variant="bold">{t('messages.confirm.ask_confirm')}</Typography>    
                </CustomModal>
            : ''}

            {authModal ? 
                <CustomModal 
                    title={t('settings.clean_base.clean_base')}
                    onClose={e=>{setAuthModal(false)}}
                    onSend={e=>{handleAuth()}}
                    onDeny={e=>{setAuthModal(false)}}
                >
                    <Grid container justifyContent="center" sx={{p:3}}>
                        <Box
                            component="img"
                            sx={{
                                height: 120,
                                width: 120
                            }}
                            alt="logomarca Google authenticator" 
                            src={"/assets/img/google-authenticator.svg"}
                        />
                    </Grid>
                    <Typography variant="h1" sx={{mb:2}}>{t('settings.clean_base.authenticator')}</Typography>    
                    <Typography variant="subtitle">{t('settings.clean_base.code_request')}</Typography>   
                    <TextField 
                        size="small" 
                        value={authCode}
                        onChange={e=>{setAuthCode(e.target.value)}}
                        fullWidth 
                        sx={{my:2}} 
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon icon='mingcute:lock-fill' />
                              </InputAdornment>
                            ),
                        }}
                    />
                    <Button>{t('settings.clean_base.help')}</Button>   
                </CustomModal>
            : ''}

            { message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{setMessage(null)}}
                />
            : ''}

            { requestEmail ? 
                <CustomModal
                    icon={requestEmail.icon}
                    title={requestEmail.title}
                    onDeny={e=>{requestEmail(null)}}
                    onConfirm={e=>{setAuthModal(true)}}
                >
                    <Typography variant="subtitle">{t('settings.clean_base.send_email_1')}</Typography>
                    <Typography variant="subtitle" color="primary">{email}</Typography>
                    <Typography variant="subtitle"> {t('settings.clean_base.send_email_2')}</Typography>
                </CustomModal>
            : ''}

            <Backdrop  
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}