import React, {useState, useEffect} from 'react'
import ReactLoading from 'react-loading'

import Swal from 'sweetalert2'

import {FaFileCsv, FaFilePdf, } from 'react-icons/fa'
import {FiTrash2, FiFilter, FiChevronsLeft, FiChevronsRight, FiRefreshCcw, FiArrowLeft, FiPlus, FiSave, FiX, FiUpload, FiMap} from 'react-icons/fi'

import api from '../../services/api'
import Menu from '../../components/Menu'

import ExportCSV from '../../components/exportCSV'
import ExportPDF from '../../components/exportPDF'

import './style.css'
import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, FloatingLabel, CloseButton } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

function Monitoring() {
    const { t } = useTranslation();

    const [items, setItems] = useState([])
    const [dataInicio, setDataInicio] = useState(dates(30, '-'))
    const [dataFim, setDataFim] = useState(dates(1, '+'))
    const [Identificador, setIdentificador] = useState('')
    const [itemsLoaded, setItemsLoaded] = useState(true)
    const [change, setChange] = useState(0)

    const headersCSV = [
        "COD_EXTERNO",
        "CATEGORIA",
        "TAG",
        "NIVEL_1",
        "NIVEL_2",
        "NIVEL_3",
        "NIVEL_4",
        "REGISTRO"
    ]

    useEffect(() => {
        setItemsLoaded(false)
        api.get(`/registro_valor/${sessionStorage.getItem('idConta')}/*/*/*/${Identificador == '' ? '*' : Identificador}/${dataInicio}/${dataFim}`, {}
        ).then(response => {setItems(response.data)}).finally(
            () => {setItemsLoaded(true)}
        )
    }, [])

    function dates(days, direction) {
        let dt   = new Date();
        if(direction == '+') {
            dt = new Date(dt.getTime() + (days * 24 * 60 * 60 * 1000))
        }
        else if(direction == '-') {
            dt = new Date(dt.getTime() - (days * 24 * 60 * 60 * 1000))
        }
        return (dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2))
    }

    function formatDate(data) {
        let dt = new Date(data)
        let dt_reg = ("0" + dt.getDate()).slice(-2) + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + dt.getFullYear()
        dt_reg += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
        return <th><div>{dt_reg.slice(0,10)}</div><div>{dt_reg.slice(11,19)}</div></th>
    }

    function csv() {
        let body = items.map((item) => {
            return [
                item.id_externo ? item.id_externo : '',
                item.id_modulo.categoria ? item.id_modulo.categoria.descricao : '',
                item.tag ? item.tag : '',
                item.id_modulo.id_nivel_loc_1 ? item.id_modulo.id_nivel_loc_1.descricao : '',
                item.id_modulo.id_nivel_loc_2 ? item.id_modulo.id_nivel_loc_2.descricao : '',
                item.id_modulo.id_nivel_loc_3 ? item.id_modulo.id_nivel_loc_3.descricao : '',
                item.id_modulo.id_nivel_loc_4 ? item.id_modulo.id_nivel_loc_4.descricao : '',
                item.lido_registro ? item.lido_registro : '',
            ]
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }
    //

    function changeFunction(key) {
        switch (key) {
            case 0:
                return (
                    <div >
                        <Container fluid>
                            <Row className="my-3 flex-wrap">
                                <Col><h3>{t('common.monitoring')}</h3></Col>
                                <Col className="container-btn">
                                    <Button onClick={e=> {setChange(6)}}><FaFilePdf className="me-2"/>{t('actions.export')} PDF</Button>
                                    <Button onClick={e=> {setChange(5)}}><FaFileCsv className="me-2"/>{t('actions.export')} CSV</Button>
                                </Col>
                            </Row>
                            <Col className="p-3 bg-white rounded border">
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t('common.data')}</Form.Label>
                                        <div className="date">
                                            <Form.Control
                                                type="date"
                                                value={dataInicio}
                                                onChange={e => {setDataInicio(e.target.value)}}
                                            />
                                            <Form.Control
                                                type="date"
                                                value={dataFim}
                                                onChange={e => {setDataFim(e.target.value)}}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>{t('common.identify')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={Identificador}
                                            onChange={e=> {setIdentificador(e.target.value)}}
                                        />
                                    </Form.Group>
                                </Row>
                                {!itemsLoaded ?
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                    </div>
                                :
                                    <div className="my-3">
                                        <Table responsive hover>
                                            <thead className="text-uppercase">
                                                <tr id="header">
                                                    <th>#</th>
                                                    <th>{t('common.items')}</th>
                                                    <th>{t('common.data')}</th>
                                                    <th>{t('common.value')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {items.map((item, index) => (
                                                    <tr key={index}>
                                                        <th>{index + 1}</th>
                                                        <th>
                                                            <div>{item.tag}</div>
                                                        </th>
                                                        {item.dt_registro ? formatDate(item.dt_registro) : <th></th>}
                                                        <th style={{fontWeight: 'bold', color: '#2488f7'}}>{item.valor}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>  
                                }
                            </Col>
                        </Container>
                    </div>
                )
                break
            case 5:
                return (
                    <div >
                        <Container fluid>
                            <Row>
                                <Col>
                                    <h2>{t('common.monitoring')}</h2>
                                    <h5 style={{marginTop: '-12px'}}>{t('actions.generate')} CSV</h5>
                                </Col>
                                <Col className="container-btn">
                                    <Button variant="light" className="text-uppercase" onClick={e => {setChange(0)}}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                </Col>
                            </Row>
                            <ExportCSV headers={headersCSV} listItens={csv()} modulo={'monitoramento'}/>
                        </Container>
                    </div>
                )
                break
            case 6:
                return (
                    <div >   
                        <Container fluid>
                            <Row>
                                <Col>
                                    <h2>{t('common.monitoring')}</h2>
                                    <h5 style={{marginTop: '-12px'}}>{t('actions.generate')} PDF</h5>
                                </Col>
                                <Col className="container-btn">
                                    <Button variant="light" className="text-uppercase" onClick={e => {setChange(0)}}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                </Col>
                            </Row>
                            <ExportPDF headers={headersCSV} listItens={csv()} modulo={'monitoramento'}/>
                        </Container>              
                    </div>
                )
                break
            default:
                return (
                    <div></div>
                )
                break;
        }
    }
    
    return (
        <div    >
            <div id="background">
                {changeFunction(change)}
            </div>
        </div>
    )
}

export default Monitoring