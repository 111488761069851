import PropTypes from 'prop-types';

import { useMemo } from 'react';

import { CssBaseline } from '@mui/material';

import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

import palette from './design/palette';

import typography from './design/typography';

import CustomMui from './custom-mui/index';

import shadows from './design/shadows';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette,
      shadows: shadows(),
      typography
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = CustomMui(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
