import { useEffect, useState } from "react"

import { useParams } from "react-router-dom"

import { Button, FormControl, InputLabel, ListItemText, MenuItem, Select, Stack, Typography } from "@mui/material"

import { t } from "i18next"

import api from "../../../services/api"

import { Icon } from "@iconify/react/dist/iconify.js"

//------------------------------------------------------

export default function InventoryModel({saveModel, onSave, onSelectModel}){
    const params = useParams()

    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState()

    const [models, setModels] = useState([])

    const [contaConfigId, setContaConfigId] = useState(null);

    const [selectedModel, setSelectedModel] = useState()

    useEffect(()=>{
        getModels()
    },[])

    useEffect(()=>{
        if(saveModel?._id){
            handleSave(saveModel)
        }
    },[saveModel])

    function getModels() {
        setLoading(true)
        api.get(`/conta_config/${sessionStorage.getItem('idConta')}`).then(
            response => {
                let data = response.data[0] ? response.data[0] : []
                setContaConfigId(data._id)
                const modellist = Array.isArray(data.inventario) ? data.inventario : [data.inventario];
                const filteredModels = modellist.filter(m=> m?.readers)
                setModels(filteredModels)
                if(params){
                    handleChange(filteredModels.find(item => item._id === params.id_modelo))
                }
                setLoading(false)
            }
        )
    }

    function handleSave(newReg){
        if(!contaConfigId){
            return
        }
        setLoading(true)
        const inventario = models
        inventario.push(newReg)
        const reg = [{
            "_id": contaConfigId,
            "id_conta": sessionStorage.getItem('idConta'),
            "inventario": inventario
        }]
        
        api.post(`/conta_config`, reg).then(
            response=> { 
                onSave()
                setMessage({
                    "type": "success",
                    "message": t('messages.success_save'),
                    "title": t('messages.success')
                })
            },
            error =>{
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    function handleChange(model){
        setSelectedModel(model)
        onSelectModel(model)
    }

    function handleDelete(){
        setLoading(true)
        const inventario = models.filter((inv) => inv._id !== selectedModel._id)
        setModels(inventario);
        const reg = [{
            "_id": contaConfigId,
            "id_conta": sessionStorage.getItem('idConta'),
            "inventario": inventario
        }]
        
        api.post(`/conta_config`, reg).then(
            response=> { 
                onSave()
                setMessage({
                    "type": "success",
                    "message": t('messages.success_save'),
                    "title": t('messages.success')
                })
            },
            error =>{
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    return (
        <>
            
            <Stack direction="row" justifyContent="end" spacing={3}>
                {selectedModel && (
                    <Button variant="outlined" color="dark" onClick={e=>{handleDelete()}} sx={{textWrap: 'nowrap'}}>
                        {t('actions.delete')} {t('settings.model')}
                    </Button>
                )}
                <FormControl sx={{minWidth: '35ch'}}>
                    <InputLabel id="model-select-label">{t('settings.model')}</InputLabel>
                    <Select labelId="model-select-label" label={t('settings.model')} value={selectedModel} onChange={e=>{handleChange(e.target.value)}} sx={{ maxHeight: 64}}>
                        <MenuItem key={9} value="" disabled>--</MenuItem>
                        {models.map((model, index)=> (
                            <MenuItem key={model._id} value={model}>
                                <ListItemText>{t('settings.model')} {model?._id}</ListItemText>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    <Icon icon="material-symbols-light:barcode-reader-outline" width="24"/> 
                                    { model?.multiple ? 'Vários' : model?.readers ? model?.readers[0]?.nm_leitor : ''}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        </>
    )
}