import { redirect, useNavigate } from 'react-router-dom';

import secureLocalStorage from 'react-secure-storage';

import { Icon } from '@iconify/react';

import { Box, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';

import linkList from './linkList'

import { t } from 'i18next';

import CustomModal from '../../../components/ui/CustomModal';

import { useState } from 'react';

// ----------------------------------------------------------------

export default function NavItems({openNav, onCloseNav}){
    const navigate = useNavigate();

    const [confirmModal, setConfirmModal] = useState(false)

    const handleLogout = () => {
        sessionStorage.clear()
        secureLocalStorage.clear()
        if(sessionStorage.getItem('idConta') === 'kRTYf3Drw'){
            window.location.href = '/PBC/login'
        }else{
            window.location.href = '/login'
        }
    };

    return (
        <>
            <Box sx={{p:1}}>
                <List>
                    <Stack direction="row" justifyContent="space-between" sx={{px: 2, display: openNav ? '' : 'none' }}>
                        <Typography variant="menu">MENU</Typography> 
                        <IconButton onClick={e=>{onCloseNav(false)}}>
                            <Icon icon="ic:round-keyboard-double-arrow-left" width="14"/>
                        </IconButton>
                    </Stack>
                    {linkList.map((item) => (
                        <>
                            {item?.label ? 
                                <Typography sx={{p: 2}} variant="menu">{item.label}</Typography> 
                            : ''}
                            {item.show ? 
                                <ListItemButton  
                                    onClick={e=>{navigate(item.path)}}
                                >
                                    <ListItemIcon sx={{mr: openNav ? 2 : 0 , minWidth: 'auto'}}>
                                        <Icon icon={item.icon} width={21}/>
                                    </ListItemIcon>
                                    <ListItemText sx={{display: openNav ? '' : 'none' }}>{t(`menu.${item.title}`)}</ListItemText>
                                </ListItemButton>
                            : ''}
                        </>
                    ))}
                    <ListItemButton onClick={e=>{setConfirmModal(!confirmModal)}}>
                        <ListItemIcon sx={{mr: openNav ? 2 : 0 , minWidth: 'auto'}}>
                            <Icon icon='material-symbols:mode-off-on' width={21}/>
                        </ListItemIcon>
                        <ListItemText sx={{display: openNav ? '' : 'none' }}>{t('actions.logout')}</ListItemText>
                    </ListItemButton>
                </List>
            </Box>

            {confirmModal ? 
                <CustomModal 
                    onConfirm={handleLogout}
                    onDeny={e=>{setConfirmModal(false)}}
                >
                    <Box sx={{p:2}}>
                        <Typography color='error' sx={{p:3}}>
                            <Icon icon='ph:warning-fill' width="64"/>
                        </Typography>
                        <Typography variant="h1" sx={{mb:1}}>{t('messages.attention')}</Typography>    
                        <Typography variant="subtitle">{t('messages.logout.confirm_logout')}</Typography>  
                    </Box>
                </CustomModal>
            : ''}
        </>
    )
}