import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";

class ItemBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    
      series: [{
        data: props.values
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: props.labels,
        }
      },
    
    
    };
  }



  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="bar" width="100%" height='350'/>
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default ItemBarChart;