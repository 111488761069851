import { AppBar, Box, Grid, Grid2, Typography } from '@mui/material';

import MenuMobile from './items/MenuMobile.js';

import SettingsButton from './items/SettingsButton.js';

import LanguageSwitch from './items/LanguageSwitch.js';

import logo from "../../assets/logo/inovaone/inovaone-lg.png"

import logoPBC from "../../assets/logo/pbc/pbc_logo.png"

import secureLocalStorage from 'react-secure-storage';

// ----------------------------------------------------------------

export default function Header(){
    return(
        <>
            <AppBar position="fixed" color="inherit" sx={{p: 1, px: 2, boxShadow: 2, border: 0 }}>
                <Grid2
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center" 
                    spacing={1}
                >
                    <Grid2 item>
                        <Box
                            component="img"
                            sx={{
                                height: 28,
                                width: 'auto'
                            }}
                            alt="Logo da aplicação"
                            src={sessionStorage.idConta === 'kRTYf3Drw' ? logoPBC : logo}
                        />
                    </Grid2>
                    <Grid2
                        item 
                        xs
                        container
                        justifyContent="flex-end"
                    >
                        <Grid2 
                            item
                            sx={{
                                display: {md: 'none'}
                            }}
                        >
                            <MenuMobile/>
                        </Grid2>
                        <Grid2 
                            item
                            sx={{
                                display: {xs: 'none', md: 'flex'}
                            }}
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="subtitle">{secureLocalStorage.getItem('userDetails')?.nome}</Typography>
                        </Grid2>
                        <Grid2 item>
                            <LanguageSwitch/>
                        </Grid2>
                        <Grid2 item>
                            <SettingsButton/>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </AppBar>
        </>
    )
}