import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import secureLocalStorage from 'react-secure-storage';

import QrCodeScan from "../../components/QrcodeScan/QrcodeScan"

import { Alert, Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';

import { Icon } from '@iconify/react/dist/iconify.js';

import api from "../../services/api"

import Loading from '../../components/loading';

import { green } from '@mui/material/colors';

//---------------------------------------------------------

export default function ScanQRCode(){
    const params = useParams()

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [data, setData] = useState();

    const [tag, setTag] = useState();

    const [error, setError] = useState()

    useEffect(()=>{
        if(params){
            const {id_conta, tag} = params
            if(!id_conta || !tag){
                setError("Item não encontrado. Tente novamente ou entre em contato com o suporte.")
                return
            }
            getInventory(id_conta.replace(/[${}]/g, ''), tag.replace(/[${}]/g, ''))
        }
    }, [])
    
    function handleReadCode(readed){
        const removeLink = readed.replace("https://www.inovacode.app.br/transport/qrcode/", "")
        const dataobj = removeLink.split('/')
        if(!dataobj[0] || !dataobj[1]){
            setError("Item não encontrado. Tente novamente ou entre em contato com o suporte.")
            return
        }
        getInventory(dataobj[0].replace(/[${}]/g, ''), dataobj[1].replace(/[${}]/g, ''))
    }

    function getInventory(_id, tag){
        setLoading(true)
        api.get(`/registroPage/${_id}/*/inventario/*/*/*/*/*/*/0?tags=${tag}`)
        .then(
            response=>{
                if(response.data.length){
                    const inventario = response.data[0]
                    if(!inventario){
                        setError("Item não encontrado. Tente novamente ou entre em contato com o suporte.")
                        setTag('Err: ' + _id + ', ' + tag)
                    }else{
                        getItem(_id, tag, inventario)
                    }
                }
            },
            error=>{
                setError("Tag não encontrada. Tente novamente ou entre em contato com o suporte.")
                setTag('Err: ' + _id + ', ' + tag)
                setLoading(false)
            }
        ).finally(()=>{
            setLoading(false)
        })
    }

    function getItem(_id, tag, inventario){
        setLoading(true)
        api.get(`/itensPage/${_id}/*/*/${tag}/*/*/*/*/*/*/*/*/*/*/*/0?limit=10`)
        .then(
            response=>{
                if(response.data.length){
                    const item = response.data[0]
                    if(!item){
                        setError("Item não encontrado. Tente novamente ou entre em contato com o suporte.")
                    }else{
                        setData({...inventario, email: item.inf_compl_1})
                        setLoading(false)
                    }
                }
                setLoading(false)
            },
            error=>{
                setError("Tag não encontrada. Tente novamente ou entre em contato com o suporte.")
                setLoading(false)
            }
        )
    }

    function handleContinue(){
        const preValues = secureLocalStorage.getItem('transport-user')
        preValues.inventory = data
        secureLocalStorage.setItem('transport-user', preValues)
        navigate('/transport/upload-foto')
    }

    return(
        <>
                <Stack spacing={5} sx={{minWidth: {xs: '100%', md: '30vw'}, height: '100%'}}>
                    <Box>
                        <Typography variant="h1">Qr code Scan</Typography>
                        <Typography variant="subtitle">Aponte a camera e escaneie o código QR.</Typography>
                    </Box>

                    <Stack
                        justifyContent="center"
                        alignItems="center"
                    >
                        {!data ? 
                            <Box 
                                sx={{
                                    width: {xs: '90vw', md: '400px'},
                                    height: 'auto'
                                }}>
                                <QrCodeScan onChange={e=>{handleReadCode(e)}}/>
                            </Box>
                        : ''}
                    </Stack>

                    {data && (
                        <>
                            <Stack justifyContent="center" alignItems="center" sx={{padding: 2}}>
                                <IconButton sx={{border: 5, borderColor: green[100], backgroundColor: 'green', color: 'white', marginBlock: 5}}>
                                    <Icon icon="uis:check" width="32"/>
                                </IconButton>
                                <Typography variant="h6" sx={{fontWeight: 700}}>Lido com sucesso</Typography>
                                <Typography variant="subtitle" textAlign="center" sx={{width: '50%'}}>Clique no botão abaixo para continuar o registro.</Typography>
                            </Stack>
                            <Button
                                variant="contained"
                                color="dark"
                                onClick={e=>{handleContinue()}}
                                endIcon={<Icon icon="ic:round-double-arrow" width={12}/>}
                                fullWidth
                            >
                                Continuar
                            </Button>
                        </>
                    )}

                    {error ? 
                        <div>
                            <Alert severity="error">
                                {error}
                            </Alert>
                            <Typography variant="subtitle" textAlign="end" sx={{fontSize: '0.7em'}}>{tag}</Typography>
                        </div>
                    : ''}
                </Stack>

            <Loading show={loading}/>
        </>
    )
}