import React, {useState, useEffect} from 'react'
import ReactLoading from 'react-loading'

import Swal from 'sweetalert2'

import {GoLocation} from 'react-icons/go'
import {FaFileCsv, FaFilePdf} from 'react-icons/fa'

//
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {Impressao} from '../../services/PDFBalance'
//import {Impressao} from '../../services/PDFContructor'
import {CSVLink} from 'react-csv'
//

import api from '../../services/api'
import Menu from '../../components/Menu'

import './style.css'
import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, FloatingLabel, CloseButton } from 'react-bootstrap'
//Number(teste.replace(/[^0-9,]+/g, '').replace(',','.'))

import { useTranslation } from 'react-i18next'

function BalanceSheet() {
    const { t } = useTranslation();

    const [items, setItems] = useState([])
    const [itemsLoaded, setItemsLoaded] = useState(false)
    const [orientation, setOritentation] = useState('portrait')
    const [title, setTitle] = useState('')
    const [image, setImage] = useState(undefined)

    const fileTypes = ['image/jpeg']

    useEffect(() => {
        setItemsLoaded(false)
        api.get(`/itensPage/${sessionStorage.getItem('idConta')}/*/*/*/*/*/*/*/*/*/*/*/*/*/0`, {}
        ).then(
            response => {setItems(response.data)}
        ).finally(
            () => {setItemsLoaded(true)}
        )
    }, [])

    //
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    const visualizarImpressao = async () => {
        let categorias = []
        let pdfFilter = await items.filter((item, index) => {if(!item.total_reg) {return item}}).map((item, index) => {
            if(item.id_categoria.descricao && !categorias.includes(item.id_categoria.descricao)) {
                categorias.push(item.id_categoria.descricao)
            }
            return [
                index + 1,
                item.id_categoria ? item.id_categoria.descricao : '',
                item.inf_compl_1,
                item.inf_compl_2,
                item.inf_compl_3,
                item.inf_compl_4,
                item.inf_compl_5,
            ]
        })

        let linkeres = {}

        await items.filter((item, index) => {if(!items.total_reg) {return item}}).map((item, index) => {
            if(item.foto && item.foto != '' && item.foto != '/static/media/sem_foto.b83884cf.png' && item.foto != 'assets/imgs/sem_foto.png')
            linkeres[`images_${index}`] = `http://3.138.64.4:8000/${item.foto}`
        })

        const classeImpressao = await new Impressao(
            {
            hLogo: '',
            PDFOrientation: orientation,
            hTitle: title,
            bCategories: categorias,
            hItems: [
                "#",
                "CATEGORIA",
                "SALDO ANTERIOR",
                "ENTRADAS",
                "SAÍDAS",
                "VALOR LÍQUIDO"
            ],
            images: linkeres,
            bItems: pdfFilter
        });
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }
    //

    function listGet() {
        let distinctValues = {}
        api.get().then(
            response => {}
        )
    }

    return (
        <>
            <Row>
                <Col>
                    <h3>{t('menu.balance-sheet')}</h3>
                </Col>
                <Col className="container-btn">
                    <Button variant="custom-primary" onClick={visualizarImpressao}><FaFilePdf className="me-2"/>{t('actions.export')} PDF</Button>
                </Col>
            </Row>
            <Row className="filters">
                <Form.Group as={Col}>
                    <Form.Label>{t('export.orientation')}</Form.Label>
                    <Form.Select
                        value={orientation}
                        onChange={e => {setOritentation(e.target.value)}}
                    >
                        <option value={'portrait'}>{t('export.portrait')}</option>
                        <option value={'landscape'}>{t('export.landscape')}</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{t('export.title')}</Form.Label>
                    <Form.Control
                        value={title}
                        onChange={e => {setTitle(e.target.value)}}
                    />
                </Form.Group>
            </Row>
            {itemsLoaded ? 
                <div className="list">
                    <Table responsive hover>
                        <thead>
                            <tr id="header">
                                <th>#</th>
                                <th>CATEGORIA</th>
                                <th>SALDO ANTERIOR</th>
                                <th>ENTRADAS</th>
                                <th>SAÍDAS</th>
                                <th>VALOR LÍQUIDO</th>
                            </tr>
                        </thead>
                        <tbody>    
                            {items.slice(0, items.length - 1).map((item, index) => (
                                <tr key={index}>
                                    <th>{index + 1}</th>
                                    <th>
                                        <div>{item.id_categoria ? item.id_categoria.descricao : ''}</div>
                                        <div>{item.inf_compl_1 != '' ? item.inf_compl_1 : ''}</div>
                                    </th>
                                    <th>{item.inf_compl_2}</th>
                                    <th>{item.inf_compl_3}</th>
                                    <th>{item.inf_compl_4}</th>
                                    <th>{item.inf_compl_5}</th>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            : 
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                </div>
            }
        </>
    )
}

export default BalanceSheet