import React, {PureComponent ,Component} from 'react';
import {
	ResponsiveContainer,
	PieChart,
	Pie,
	Cell,
	Sector,
	BarChart,
	XAxis,
	YAxis,
	Legend,
	Tooltip,
	CartesianGrid,
	Bar
} from 'recharts';
import { Dropdown, Col } from 'react-bootstrap'

const renderActiveShape = (props) => {
	const RADIAN = Math.PI / 180;
	const {
		cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
		fill, payload, percent, value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontWeight={'bold'}>{payload.name}</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
				cursor={'pointer'}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Qtd: ${value}`}</text>
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
				{`(${(percent * 100).toFixed(2)}%)`}
			</text>
		</g>
	);
};

export default class Example extends PureComponent {
	constructor() {
        super();
    }
	
	state = {
		activeIndex: -1,
		clickedIndex: -1,
		selected: 'Categorias',
		infoIndex: -1,
		infoState: []
	};

	infoState = []

	returnData = async (dti, dts) => {
		if(this.state.clickedIndex > -1) {
			let lista = []
			dti.filter((dt) => dt.status == dts[this.state.clickedIndex].name).map((it) => {
				let index = lista.findIndex((item) => item.name == it[this.state.selected])
				if(index > -1) {
					lista[index].value += 1
				}else {
					lista.push({name: it[this.state.selected], value: 1})
				}
			})
			await this.setState({
				infoState: lista
			})
		}
	}

	onPieEnter = (data, index) => {
		this.setState({
			activeIndex: index,
		});
	};

	onPieOut = (data, index) => {
		this.setState({
			activeIndex: -1,
		})
	}

	onPieClick = (data, index) => {
		this.setState({
			clickedIndex: this.state.clickedIndex == index ? -1 : index,
			infoIndex: -1,
			infoState: this.state.clickedIndex == index ? [] : this.state.infoState
		});
	}

	changeSelected = (selected) => {
		if(this.state.selected != selected) {
			this.setState({
				selected: selected,
				infoIndex: -1,
				infoState: []
			});
		}
	}

	barFocused = (data, index) => {
		this.setState({
			infoIndex: index
		})
	}
	
	barNotFocused = (data, index) => {
		this.setState({
			infoIndex: -1
		});
	}

	focusedVerify = (state) => {
		if (state.isTooltipActive) {
			this.setState({
				infoIndex: state.activeTooltipIndex
			});
		} else {
			this.setState({
				infoIndex: -1
			});
		}
	}
	
	focusedVerifyOut = () => {
		this.setState({
			infoIndex: -1
		})
	}

	CustomTooltip = ({ payload, label, active }) => {
		if (active) {
		  return (
			<div style={{
				backgroundColor: 'white',
				border: '2px solid transparent',
				borderRadius:'5px',
				backgroundColor: '#2488f7',
				boxShadow: '2px 2px 5px #3B3C40'
			}}>
			  	<p
					className="content"
					style={{
						margin:'10px',
						fontWeight: 'bold',
						color:'#FFFFFF'
					}}
				>
				  	{`Qtd. de "${payload[0].payload.name}": ${payload[0].payload.value}`}
			  </p>
			</div>
		  );
		}
		return null;
	}

	/*<ResponsiveContainer width="100%" height={300}>
		<BarChart
		data={data}
		margin={{top: 15, right: 30, left: 20, bottom: 5}}
		style={{color: '#2488f7', textAlign: 'center'}}
		>
			<CartesianGrid
				strokeDasharray='3 3'
				stroke='#000000'
			/>
			<Tooltip
				labelStyle={{color: 'black', fontWeight: 'bold'}}
				itemStyle={{color: '#2488f7', fontWeight: 'bold'}}
				/>
			<YAxis
				dataKey='value'
				stroke='#000000'
				domain={[0]}
			/>
			<XAxis
				dataKey='date'
				stroke='#000000'
				/>
			<Legend />
			<Bar dataKey="value" fill="#2488f7" name="Quantidade">
				{
					data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} cursor={'pointer'} />)
				}

			</Bar>
		</BarChart>
	</ResponsiveContainer>*/

	render() {
		const {data, colors, dataItens} = this.props

		return (
			<>
				<div className="d-flex align-items-center">
					<h6>Dados: </h6>
					<Dropdown >
						<Dropdown.Toggle 
							className="px-2 mx-2 border"
							style={{background:'#fff', color:'#7d7d7d'}}>
							<span className="pe-4 h6">{this.state.selected}</span>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={async e=>{
								await this.changeSelected('Categorias')
								await this.returnData(dataItens, data)
							}}>Categorias</Dropdown.Item>
							<Dropdown.Item onClick={async e=>{
								await this.changeSelected('Niveis 1')
								await this.returnData(dataItens, data)
							}}>Niveis 1</Dropdown.Item>
							<Dropdown.Item onClick={async e=>{
								await this.changeSelected('Niveis 2')
								await this.returnData(dataItens, data)
							}}>Niveis 2</Dropdown.Item>
							<Dropdown.Item onClick={async e=>{
								await this.changeSelected('Niveis 3')
								await this.returnData(dataItens, data)
							}}>Niveis 3</Dropdown.Item>
							<Dropdown.Item onClick={async e=>{
								await this.changeSelected('Niveis 4')
								await this.returnData(dataItens, data)
							}}>Niveis 4</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div
					style={{
						backgroundColor: 'transparent',
						width: '100%',
						height: '300px',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<ResponsiveContainer width={'100%'} height={300}>
						<PieChart width={'100%'} height={300}>
							<Pie
								data={[{ value: 12 }]}
								dataKey="value"
								innerRadius={60}
								outerRadius={80}
								fill="#f5f5f5"
								cornerRadius={10}
							/>
							<Pie
								activeIndex={this.state.activeIndex != -1 ? this.state.activeIndex:this.state.clickedIndex}
								activeShape={renderActiveShape}
								data={data}
								innerRadius={60}
								outerRadius={80}
								fill="#8884d8"
								paddingAngle={5}
								dataKey="value"
								cornerRadius={10}
								onMouseEnter={this.onPieEnter}
								onMouseOut={this.onPieOut}
								onClick={async (e,i) => {
									await this.onPieClick(e,i)
									await this.returnData(dataItens, data)
								}}
							>
								{
									data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index]} cursor={'pointer'} />)
								}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
					
					{this.state.clickedIndex > -1 ?
						<div
							style={{
								width: '100%',
								heigth: '300px',
								textAlign: 'center'
							}}
						>
							<p style={{fontWeight: 'bold'}}>{this.state.selected} de {data[this.state.clickedIndex].name}</p>
							<ResponsiveContainer>
								<BarChart
									height={200}
									data={this.state.infoState}
									onMouseMove={this.focusedVerify}
									onMouseOut={this.focusedVerifyOut}
								>
									<Tooltip content={<this.CustomTooltip/>}/>
									<Bar 
										dataKey="value"
										fill={'#2488f7'}
									>
										{
											this.state.infoState.map((entry, index) => (
												<Cell
													key={`cell-${index}`}
													cursor="pointer"
													fill={(this.state.infoIndex == -1) || (index === this.state.infoIndex) ? 'rgba(140,11,59,1)' : 'rgba(140,11,59,0.2)'}
													radius={5}
												/>
											))
										}
									</Bar>
								</BarChart>
							</ResponsiveContainer>
						</div>
					:
						<div
							className='text-secondary'
							style={{
								width: '100%',
								heigth: '300px',
								textAlign: 'center',
								margin: 'auto',
								fontWeight: 'bold'
							}}
						>
							Selecione um Status
						</div>
					}
				</div>
			</>
		);
	}
}