import { t } from "i18next";

import { Button, Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

//----------------------------------------------------------------

export default function UsersList({userList, onEdit, onCreate}){

    function handleEdit(index){
        onEdit(index)
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <div>
                    <Typography variant="h2">{t('settings.config_users')}</Typography>
                    <Typography variant="subtitle">{t('settings.config_users_subtitle')}</Typography>
                </div>
                <Button size="small" variant="contained" startIcon={<Icon icon="heroicons:plus-16-solid" />} onClick={e=>{onCreate(true)}}>Novo Usuário</Button>
            </Stack>

            <TableContainer component={Paper} sx={{mt:3}}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>{t('settings.data.name')}</TableCell>
                            <TableCell>{t('settings.profile')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {userList.map((row, index) => (
                        <TableRow
                            hover
                            key={row._id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            onClick={e=>{handleEdit(index)}}
                        >
                            <TableCell component="th">{row._id}</TableCell>
                            <TableCell component="th" scope="row">{row.nome}</TableCell>
                            <TableCell component="th" scope="row">
                                <Chip label={row.perfil.includes('usu') ? t('common.user') : row.perfil.includes('admin') ? 'ADM ': row.perfil} color={row.perfil.includes('admin') ? 'secondary': 'default'}/>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Button onClick={e=>{handleEdit(index)}}>{t('actions.edit')}</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}