import {GoogleMap, useLoadScript, Marker, InfoWindow} from '@react-google-maps/api'

const libraries = ['places']

const optionsMap = {
    //styles: mapStyle
}

export default function Maps({latitude, longitude, width, height}){
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_API_G_KEY,
        libraries,
    })

    const mapContainerStyle = {
        width: width || '100%',
        height: height || '600px'
    }

    return (
        <>
            <GoogleMap
                className='mapStyles'
                mapContainerStyle={mapContainerStyle}
                zoom={18}
                center={{lat: Number.parseFloat(latitude), lng: Number.parseFloat(longitude)}}
            >
                <Marker
                    position={{
                        lat: Number.parseFloat(latitude),
                        lng: Number.parseFloat(longitude)
                    }}
                    icon={{
                        url: '/iconMap.png',
                        scaledsize: new window.google.maps.Size(32, 30)
                    }}
                />
            </GoogleMap>
        </>
    )
}