import { useState, useEffect } from 'react';

import api from '../services/api'; 

import secureLocalStorage from 'react-secure-storage';

const useToken = () => {
    const [token, setToken] = useState(null);

    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(null);

    useEffect(() => {
        async function getToken() {
            const id_conta = sessionStorage.getItem('idConta')
            const headerToken = { headers: { 'Authentication': id_conta } };
        
            try {
                const response = await api.get(`/getToken/${id_conta}`, headerToken);
                const token = response.data.token
                setToken(token)
            } catch (error) {
                setError(error)
                throw error; 
            } finally {
                setLoading(false);
            }
        }

        getToken();
    }, []);

    return { token, loading, error };
};

export default useToken;
