export class Impressao {

  constructor(generator) {
      this.headerTitle = generator.hTitle
      this.headerItems = generator.hItems
      this.dadosParaImpressao = generator.bItems;
  }  

  async PreparaDocumento() {
    const corpoDocumento = this.CriaCorpoDocumento();
    const documento = this.GerarDocumento(corpoDocumento);
    return documento;
  }

  CriaCorpoDocumento() {
    const header = this.headerItems.map(item =>{
        return { text: item, bold: true, fontSize: 9, margin: [0, 0, 0, 0] }
    })
    const body = this.dadosParaImpressao.map((prod) => {
        let arr = prod.map(item => {
            return { text: item, fontSize: 8 }
        })
      return arr
    });

    const lineHeader = [
      {
        text:
          '__________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
        alignment: 'center',
        fontSize: 5,
        colSpan: this.headerItems.length,
      },
      {},
      {},
    ];

    let content = [header, lineHeader];
    content = [...content, ...body];
    return content;
  }

  GerarDocumento(corpoDocumento) {
      let widthsItems = this.headerItems.map(() => {
          return "auto"
      })
      let headtitle = this.headerTitle
      const documento = {
        pageSize: 'A4',
        pageMargins: [14, 53, 14, 48],
        header: function () {
          return {
            margin: [14, 12, 14, 0],
            layout: 'noBorders',
            table: {
              widths: ['*'],
              body: [                             
                [
                  { text: headtitle, style: 'reportName' }
                ]              
              ],
            },
          };
        },
        content: [
          {
            layout: 'noBorders',
            table: {              
              headerRows: 1,
              widths: widthsItems,

              body: corpoDocumento
            }
          },
        ],
    footer(currentPage, pageCount) {
          return {
            layout: 'noBorders',
            margin: [14, 0, 14, 22],
            table: {
              widths: ['auto'],
              body: [
                [
                  {
                    text:
                      '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                    alignment: 'center',
                    fontSize: 5,
                  },
                ],
                [
                  [
                    {
                      text: `Página ${currentPage.toString()} de ${pageCount}`,
                      fontSize: 7,
                      alignment: 'right',
                      /* horizontal, vertical */
                      margin: [3, 0],
                    },
                    {
                      text: 'Inovacode',
                      fontSize: 7,
                      alignment: 'center',
                    },
                  ],
                ],
              ],
            },
          };
        },
    styles: {
      reportName: {
        fontSize: 9,
        bold: true,
        alignment: 'center',
        margin: [0, 4, 0, 0],
      },
      tester: {
        background:  "linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('./assets/menu_background.png'),background-repeat: no-repeat"
      }
    },

  };
    return documento;
  }
}