import React, { PureComponent } from 'react';

import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
  fill, payload, percent, value }) => {
  const radius = innerRadius + 50;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <>
      <text x={x} y={y - 10} textAnchor={textAnchor} fill="#333">{`${payload.name}: ${value}`}</text>
      <text x={x} y={y} fill="#999" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`(${(percent * 100).toFixed(0)}%)`}
      </text>
    </>
  );
};

export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/pie-chart-with-customized-active-shape-y93si';

  state = {
    activeIndex: 0,
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { data, propsColors, width = 600, height = 250} = this.props

    const colors = propsColors

    return (
        <>
          <ResponsiveContainer width={width} height={height}>
              <PieChart width={width} height={height}>
              <Pie
                  data={data}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={75}
                  outerRadius={80}
                  paddingAngle={5}
                  fill="#8884d8"
                  dataKey="value"
                  cy="90%"
                  label={renderCustomizedLabel}
              >
                  {
                      data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index]} cursor={'pointer'} />)
                  }
              </Pie>
              </PieChart>
          </ResponsiveContainer>
        </>
      
    );
  }
}
