import { Icon } from "@iconify/react/dist/iconify.js";

import { Button, Grid, Stack, Typography } from "@mui/material";

import { cyan, green, grey, lightBlue, purple, red, yellow } from '@mui/material/colors';

import CategoryBarChart from "./CategoryBarChart";

import StatusPieChart from "./StatusPieChart";

import StatusHalfPieChart from "./StatusHalfPieChart";

import { useState } from "react";

//----------------------------------------------------------------

export default function Dashboard({ totalItens, statusList, IDRegistro, dashItensFound }){
    const color = [ red[400], green[400], purple[400], yellow[600], cyan[400], lightBlue[600] ]  

    const [showItens, setShowItens] = useState(false)

    return(
        <>
            <Grid 
                container
                direction="row"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item md>
                    <StatusPieChart data={statusList} colors={color}/>
                </Grid>
                <Grid item md>
                    <StatusHalfPieChart data={dashItensFound} IDRegistro={IDRegistro} colors={[color[1], color[0]]}/>
                </Grid>
            </Grid>
        </>
    )
}