export default function List(theme) {
    return {
        MuiListItemText: {
            styleOverrides: {
              primary: {
                fontSize: '1rem', // Tamanho da fonte do texto primário
              },
              secondary: {
                fontSize: '1rem', // Tamanho da fonte do texto secundário
              },
            },
        }
    
    }
}
