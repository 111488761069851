module.exports = function getBase64Image(url,width=0, height=0) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = width > 0 ? width : img.width;
            canvas.height = height > 0 ? height : img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            const dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL)
        }
        img.src = url
    })
}