import React, { PureComponent } from 'react';

import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { green, grey, red } from '@mui/material/colors';

//------------------------------------------------------------------------------

const fakeDATA = [
  {
    "name": "",
    "uv": 0,
    "pv": 0
  },
  {
    "name": "",
    "uv": 0,
    "pv": 0
  },
  {
    "name": "",
    "uv": 0,
    "pv": 0
  },
  {
    "name": "",
    "uv": 0,
    "pv": 0
  }
]

const fakeSeries = ['uv', 'pv']

const colorDefault = {
  "uv": red[400],
  "pv": "rgba(102,187,106,0.5)"
}


export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/p/sandbox/simple-bar-chart-72d7y5';

  render() {
    const { width = "100%", height = 400, propsColors, propsData, series = fakeSeries} = this.props

    const data = propsData || fakeDATA

    const colors = propsColors || colorDefault

    return (
      <ResponsiveContainer width={width} height={height}>
        <BarChart
          width={width}
          height={height-100}
          data={data}
          barSize={20}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {!propsData && (
            <text
                  x='55%'
                  y='45%'
                  style={{ fontSize: 14, fontWeight: 'bold', fill: grey[400] }}
                  width={200}
                  scaleToFit={true}
                  textAnchor='middle'
                  verticalAnchor='middle'
              >
                Nenhum dado encontrado
            </text>
          )}
          <Legend />
          {series.map((status, i)=>(
            <Bar dataKey={status} fill={colors[status]} activeBar={<Rectangle fill="pink" stroke="blue" />} radius={[6, 6, 0, 0]}/>
          ))}      
        </BarChart>
      </ResponsiveContainer>
    );
  }
}