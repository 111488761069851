import { Box } from "@mui/material";

//-----------------------------------------

export default function ScanOverlay() {
    return (
        <>
            <Box sx={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, zIndex: 1, padding: 6}}>
                <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 13.4694V6C1 3.23858 3.23858 1 6 1H13.5979" stroke="white" stroke-linecap="round"/>
                    <path d="M13.5979 95H6C3.23858 95 1 92.7614 1 90L1 82.5306" stroke="white" stroke-linecap="round"/>
                    <path d="M95 82.5306V90C95 92.7614 92.7614 95 90 95H82.4021" stroke="white" stroke-linecap="round"/>
                    <path d="M82.4021 1L89 1C92.3137 1 95 3.68629 95 7V13.4694" stroke="white" stroke-linecap="round"/>
                    <path d="M1 47.5H95" stroke="white" stroke-opacity="0.2" stroke-linecap="round"/>
                </svg>
            </Box>
        </>
    );
}
