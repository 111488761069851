import React from "react";

import ReactApexChart from "react-apexcharts";

class ItemsBarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    
      series: [{
        name: 'Total:',
        data: props.values
      }],
      options: {
        chart: {
          height: 350,
          type: 'bar',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const { seriesIndex, dataPointIndex } = config;
              const value = config.w.config.series[seriesIndex].data[dataPointIndex];
              const label = config.w.config.xaxis.categories[dataPointIndex];
              props.onDataPointSelect({ label, value });
            }
          }
        },
        colors: ["#008EFF"],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: props.labels,
          labels: {
            style: {
              fontSize: '12px',
            },
            hideOverlappingLabels: false,
            rotateAlways: false
          }
        }
      },
    };
  }



  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="bar" width="100%" height='350' />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default ItemsBarChart;