import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'

import english from './locales/en/en-us.json';
import portuguese from './locales/pt/pt-br.json';
import spanish from './locales/es/es.json';

i18n.use(initReactI18next).init({
        lng: sessionStorage.getItem('language') ? sessionStorage.getItem('language') : navigator.language.includes('en') ? 'en' : navigator.language.includes('es') ? 'es' : 'pt-BR',
        fallbackLng: 'pt-BR',  
        interpolation:{
            escapeValue: false
        },   
        resources:{
            'pt-BR': portuguese,
            'en': english,
            'es': spanish
        }
})

export default i18n