export class Impressao {
  constructor(generator) {
      this.headerLogo = generator.hLogo ? generator.hLogo : ''
      this.PDFOrientation = generator.PDFOrientation ? generator.PDFOrientation : 'portrait'
      this.headerTitle = generator.hTitle
      this.headerItems = generator.hItems
      this.categories = generator.bCategories
      this.imagesLinks = generator.images ? generator.images : {}
      this.dadosParaImpressao = generator.bItems;
  }  

  getLine() {
    let line =''
    for(let i=0; i<(this.PDFOrientation == 'portrait' ? 250 : 360); i++) {
      line += '_'
    }
    return line
  }

  async PreparaDocumento() {
    let line = this.getLine()
    const corpoDocumento = this.CriaCorpoDocumento();
    const documento = this.GerarDocumento(corpoDocumento, line);
    return documento;
  }
  
  CriaCorpoDocumento() {
    const header = this.headerItems.map(item =>{
        return { text: item, bold: true, fontSize: 9, margin: [0, 0, 0, 0] }
    })
    let body = []
    let totSAGeral = 0
    let totEGeral = 0
    let totSGeral = 0
    let totVLGeral = 0
    this.categories.map((cat, indexc) => {
      let totSA = 0
      let totE = 0
      let totS = 0
      let totVL = 0
      this.dadosParaImpressao.filter((filt) => {if(filt[1] == cat){return filt}}).map((prod, indexo) => {
        if(indexo == 0) {
          let categoria = prod.filter((filtCampo, indexCampo) => {if(indexCampo != 1){return filtCampo}}).map((item, index) => {
            if(index == 1){
              return { text: cat, fontSize: 8, bold: true }
            }else {
              return { text: '', fontSize: 8 }
            }
          })
          body.push(categoria)
        }
        let arr = prod.filter((filtCampo, indexCampo) => {if(indexCampo != 1){return filtCampo}}).map((item, index) => {
          if(index == 2) {
              totSA += Number(item.replace(',','.').replace(' ',''))
          }else if(index == 3) {
            totE += Number(item.replace(',','.').replace(' ',''))
          }else if(index == 4) {
            totS += Number(item.replace(',','.').replace(' ',''))
          }else if(index == 5) {
            totVL += Number(item.replace(',','.').replace(' ',''))
          }
          if(this.headerItems[index] == 'IMAGEM'){
            if(item && item != '' && item != '/static/media/sem_foto.b83884cf.png' && item != 'assets/imgs/sem_foto.png') {
              return {image: `images_${indexo}`, height: 35, width: 50}
            }else{
              return { text: 'Sem foto', fontSize: 8 }
            }
          }else {
            return { text: item, fontSize: 8 }
          }
        })
        body.push(arr)
        if(indexo == this.dadosParaImpressao.filter((filt) => {if(filt[1] == cat){return filt}}).length - 1) {
          let totall = prod.filter((filtCampo, indexCampo) => {if(indexCampo != 1){return filtCampo}}).map((item, index) => {
            if(index == 1){
              return { text: 'Total', fontSize: 8, bold: true}
            }else if(index == 2) {
              return { text: Number.isInteger(totSA) ? totSA : totSA.toFixed(2).toString().replace('.',','), fontSize: 8, bold: true }
            }else if(index == 3) {
              return { text: Number.isInteger(totE) ? totE : totE.toFixed(2).toString().replace('.',','), fontSize: 8, bold: true }
            }else if(index == 4) {
              return { text: Number.isInteger(totS) ? totS : totS.toFixed(2).toString().replace('.',','), fontSize: 8, bold: true }
            }else if(index == 5) {
              return { text: Number.isInteger(totVL) ? totVL : totVL.toFixed(2).toString().replace('.',','), fontSize: 8, bold: true}
            }else {
              return { text: '', fontSize: 8 }
            }
          })
          let white = prod.filter((filtCampo, indexCampo) => {if(indexCampo != 1){return filtCampo}}).map((item, index) => {
            return { text: '', fontSize: 8 }
          })

          body.push(white)
          body.push(totall)
          body.push(white)
          body.push(white)
        }
    })
      totSAGeral += totSA
      totEGeral += totE
      totSGeral += totS
      totVLGeral += totVL
    })
    body.push([
      { text: '', fontSize: 8, bold: true},
      { text: 'Total Geral', fontSize: 8, bold: true},
      { text: Number.isInteger(totSAGeral) ? totSAGeral : totSAGeral.toFixed(2).toString().replace('.',','), fontSize: 8, bold: true},
      { text: Number.isInteger(totEGeral) ? totEGeral : totEGeral.toFixed(2).toString().replace('.',','), fontSize: 8, bold: true},
      { text: Number.isInteger(totSGeral) ? totSGeral : totSGeral.toFixed(2).toString().replace('.',','), fontSize: 8, bold: true},
      { text: Number.isInteger(totVLGeral) ? totVLGeral : totVLGeral.toFixed(2).toString().replace('.',','), fontSize: 8, bold: true}
    ])


    const lineHeader = [
      {
        text: this.getLine(),
        alignment: 'center',
        fontSize: 5,
        colSpan: this.headerItems.length,
      },
      {},
      {},
    ];

    let content = [header, lineHeader];
    content = [...content, ...body];
    return content;
  }

  GerarDocumento(corpoDocumento, line) {
      let widthsItems = this.headerItems.map(() => {
          return "auto"
      })
      let headtitle = this.headerTitle
      let logo = this.headerLogo
      const documento = {
      pageSize: 'A4',
      pageOrientation: this.PDFOrientation,
      pageMargins: [14, 53, 14, 48],
      watermark: {text: 'Inovacode', opacity: 0.1, angle: 330},
      header: function () {
        return {
            margin: [14, 12, 14, 0],
            layout: 'noBorders',
            table: {
              widths: (logo == '' ? ['*'] : ['*', '*']),
              body: [                             
                [ 
                  //{image: 'snow', height: 35, width: 50},
                  { text: headtitle, style: 'reportName' }
                ]              
              ],
            },
          };
      },
      content: [
        {
              layout: 'noBorders',
              table: {              
                headerRows: 1,
                widths: widthsItems,

                body: corpoDocumento
              }
            },
      ],
      images: this.imagesLinks,//{ snow: 'http://3.138.64.4:8000/awKHXejox_324062021135257.jpeg' },
    
    footer(currentPage, pageCount) {
      return {
        layout: 'noBorders',
        margin: [14, 0, 14, 22],
        table: {
          widths: ['auto'],
          body: [
            [
              {
                text: line,
                alignment: 'center',
                fontSize: 5,
              },
            ],
            [
              [
                {
                  text: `Página ${currentPage.toString()} de ${pageCount}`,
                  fontSize: 7,
                  alignment: 'right',
                  /* horizontal, vertical */
                  margin: [3, 0],
                },
                {
                  text: 'Inovacode',
                  fontSize: 7,
                  alignment: 'center',
                },
              ],
            ],
          ],
        },
      };
    },
    styles: {
      reportName: {
        fontSize: 9,
        bold: true,
        alignment: 'center',
        margin: [0, 4, 0, 0],
      }
    },

  };
    return documento;
  }
}