import { useEffect, useState } from 'react';

import { t } from 'i18next';

import api from '../../services/api';

import { Icon } from '@iconify/react';

import { TabContext, TabList, TabPanel } from '@mui/lab';

import { Backdrop, Box, CircularProgress, Container, Stack, Tab, Typography } from '@mui/material';

import Reader from './ConfigReader';

import ConfigItems from './ConfigItems';

import ConfigApi from './ConfigAPI';

import ConfigUsers from './ConfigUsers/index.js';

import CleanBase from './CleanBase.js';

import Account from './AccountDetails';

import Guides from './Guides.js';

import ConfigMenu from './ConfigMenu.js';

import Menu from '../../components/Menu.js';

import { useParams } from 'react-router-dom';

//----------------------------------------------------------------

export default function Settings() {
    const { page } = useParams()

    const [userData, setUserData] = useState()

    const [config, setConfig] = useState()

    const [value, setValue] = useState(page || 'account');

    const [loading, setLoading] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
      
    useEffect(()=>{
        getAccountDetails()
        getAccountConfig() 
    },[])

    function getAccountDetails() {
        setLoading(true)
        api.get(`/conta/${sessionStorage.getItem('idConta')}/*/*/*`, {}
        ).then(response => {
            if(response.data[0]){
                const data = response.data[0];
                setUserData(data)
                setLoading(false)
            }
        })
    }

    function getAccountConfig() {
        setLoading(true)
        api.get(`/conta_config/${sessionStorage.getItem('idConta')}`
        ).then(response => {
            if(response.data[0]){
                setConfig(response.data[0])
                setLoading(false)
            }
        })
    }

  return (
    <>
        <Container id="background" sx={{my: 5}}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography variant="h1">{t('menu.settings')}</Typography>
                <CleanBase email={userData?.email} haveAuthenticator={config?.secretKey}/>
            </Stack>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} sx={{height: '64px'}}>
                        <Tab icon={<Icon icon='iconamoon:profile'/>} iconPosition="start" label={t('settings.settings')} value="account" />
                        <Tab icon={<Icon icon='mdi:user-card-details-outline'/>} iconPosition="start" label={t('settings.user')} value="user" />
                        <Tab icon={<Icon icon='material-symbols:barcode-reader-outline'/>} iconPosition="start" label={t('settings.config_readers')} value="config_readers" />
                        <Tab icon={<Icon icon='carbon:tag-edit'/>} iconPosition="start" label={t('settings.config_items')} value="item" />
                        <Tab icon={<Icon icon='solar:settings-outline'/>} iconPosition="start" label={t('settings.navbar.title')} value="menu" />
                        <Tab icon={<Icon icon='mingcute:link-fill'/>} iconPosition="start" label='API' value="API" />
                        <Tab icon={<Icon icon='fluent:book-search-24-regular'/>} iconPosition="start" label={t('menu.guides')} value="guides" />
                    </TabList>
                    </Box>
                    <TabPanel value="account">
                        { userData ? <Account userDataSet={userData}/> : ''}
                    </TabPanel>
                    <TabPanel value="user">
                        <ConfigUsers config={config}/>
                    </TabPanel>
                    <TabPanel value="config_readers">
                        <Reader userData={config}/>
                    </TabPanel>
                    <TabPanel value="item">
                        <ConfigItems userData={config}/>
                    </TabPanel>
                    <TabPanel value="API">
                        <ConfigApi config={config}/>
                    </TabPanel>
                    <TabPanel value="menu">
                        <ConfigMenu/>
                    </TabPanel>
                    <TabPanel value="guides">
                        <Guides/>
                    </TabPanel>
                </TabContext>
            </Box>
        </Container>
        
        <Backdrop  
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}