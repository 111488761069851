import { useEffect, useState } from "react"

import { FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import BarChart from "../../../../components/ReCharts/BarChart";

import { cyan, lightBlue, purple } from "@mui/material/colors";

import api from "../../../../services/api";

//--------------------------------------------------------

const fakeDATA = [
  {
      "name": "",
      "Hoje": 0,
      "Total": 0
    }
]

export default function ItemsByDays(){
    const [loading, setLoading] = useState(false)

    const [local, setLocal] = useState('id_nivel_loc1')

    const [nivelList, setNivelList] = useState(fakeDATA)

    const [data, setData] = useState()

    useEffect(()=>{
        function getData(){
            setLoading(true)
            const id_conta = sessionStorage.getItem('idConta')
            api.get(`/qtdeItens_nivel/${id_conta}/*?agroup=${local}`).then(
                response=>{
                  const res = response.data[0]
                  if(res?.ItemsMov.length){
                    formatData(response.data[0])
                  }
                }
            ).finally(
              setLoading(false)
            )
        }

        getData()
    },[local])

    function formatData(dataList) {
      const formatedData = {}
    
      const ItemsTotal = dataList?.ItemsTotal

      ItemsTotal.map((item)=>{
        if(item[local]){
          const nivel = item[local]
          formatedData[nivel?._id] = {
            "name": nivel?.descricao,
            "Em Estoque": item.totalItems,
            "Novos": findById(dataList.ItemsMov, nivel?._id)
          }
        }
      })

      setData(Object.values(formatedData));
    }    

    function findById(data, _id) {
      for (let item of data) {
          if (item[local] && item[local]?._id === _id) {
              return item.totalItems;
          }
      }
      return 0;
  }

    return (
        <>
            <Stack 
                component={Paper} 
                spacing={3} 
                sx={{
                    p: 3, 
                    borderRadius: 2,
                    height: '100%'
                }}
            >
              <Grid container direction="row" justifyContent="space-between" spacing={2}>
                  <Grid item md>
                      <Stack direction="row" alignItems="center" spacing={2}>
                          <IconButton size="small" sx={{borderRadius: 1, backgroundColor: cyan[50], color: cyan[600]}}>
                              <Icon icon="solar:box-broken"/>
                          </IconButton>
                          <Stack direction="row" spacing={1}>
                              <Typography variant="h4">Itens movimentados</Typography>
                          </Stack>
                      </Stack>
                  </Grid>
                  <Grid item md={3}>
                      <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Local</InputLabel>
                          <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              size="small"
                              label="Local"
                              sx={{borderRadius: 100}}
                              value={local}
                              onChange={e=>{setLocal(e.target.value)}}
                          >
                              <MenuItem value={'id_nivel_loc1'}>Nivel 1</MenuItem>
                              <MenuItem value={'id_nivel_loc2'}>Nivel 2</MenuItem>
                              <MenuItem value={'id_nivel_loc3'}>Nivel 3</MenuItem>
                              <MenuItem value={'id_nivel_loc4'}>Nivel 4</MenuItem>
                          </Select>
                      </FormControl>
                  </Grid>
              </Grid>

              <Stack justifyContent="center" alignItems="end" height="100%">
                <BarChart width="100%" height={350} propsData={data} series={['Em Estoque', 'Novos']} propsColors={{'Em Estoque':'#7599ff', 'Novos':'#64c8ff'}}/>

              </Stack>

            </Stack>
        </>
    )
}