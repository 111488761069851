import { useState, useEffect } from "react";

// componentes
import Menu from "../../components/Menu";

import api from "../../services/api";

import LoadingPage from "../../components/loading/LoadingPage"

import ItemsPieChart from './Charts/ItemsPieChart';

import Cards from "./Charts/Cards"

import ItemsBarChart from "./Charts/ItemsBarChart";

import DashItems from "./Charts/DashItems";

// estilos
import { Col, Container, Form, Row } from "react-bootstrap";

// ----------------------------------------------------------------

export default function ComumDashboard(){
    const [loading, setLoading] = useState(true)

    const [itensLength, setItensLength] = useState(0)

    const [inventarios, setInventarios] = useState(0)

    const [groupBy, setGroupBy] = useState('categories')

    const [data, setData] = useState([])

    const [values, setValues] = useState([])

    const [count, setCount] = useState([])

    const [dashLoaded, setDashLoaded] = useState(true)

    const [dashDataList, setDashDataList] = useState([])

    const [dashTotalQtd, setDashTotalQtd] = useState(0)

    const [dashTotalValue, setDashTotalValue] = useState('')

    const [dataFilter, setDataFilter] = useState('Categorias')

    const [labels, setLabels] = useState([])
    
    const [totalValue, setTotalValue] = useState()

    useEffect(() => {
        async function getDashValues() {
          setLoading(true);
          try {
            const response = await api.get(`/item/${sessionStorage.getItem('idConta')}/*/*/*/*/*/*/*/*/*/*/*/*/*`);
            let dashData = [];
            let dashValue = 0;
            response.data.pop();
            
            await response.data.map((item) => {
              dashValue += item.valor;
              let status = '';
              if (item.ativo == 0) { status = 'Inativo'; }
              if (item.ativo == 1) { status = 'Ativo'; }
              if (item.ativo == 2) { status = 'Danificado'; }
              if (item.ativo == 3) { status = 'Obsoleto'; }
              dashData.push({
                'Categorias': item.id_categoria ? item.id_categoria.descricao : 'N/A',
                'Valor': item.valor,
                'Status': status,
                'Niveis 1': item.id_nivel_loc_1 ? item.id_nivel_loc_1.descricao : 'N/A',
                'Niveis 2': item.id_nivel_loc_2 ? item.id_nivel_loc_2.descricao : 'N/A',
                'Niveis 3': item.id_nivel_loc_3 ? item.id_nivel_loc_3.descricao : 'N/A',
                'Niveis 4': item.id_nivel_loc_4 ? item.id_nivel_loc_4.descricao : 'N/A',
                'Parceiros': item.id_parceiro ? item.id_parceiro.nome : 'N/A'
              });
            });
      
            setItensLength(response.data.length);
            setDashTotalQtd(response.data.length);
            setDashTotalValue(dashValue);
            setDashDataList(dashData.filter((item) => { return item; }));
      
            // Esperar pela conclusão de getDataItens
            await getDataItens(dashData.filter((item) => { return item; }));
            setLoading(false);
          } catch (error) {
            console.error('Ocorreu um erro:', error);
            setLoading(false);
          }
        }
      
        getDashValues();
      }, []);
      

    useEffect(()=>{
        async function getInventories(){
            setLoading(true)
            api.get(`/registroPage/${sessionStorage.getItem('idConta')}/*/inventario/*/*/*/*/*/*/*`, {}
            ).then(response => {
                setInventarios(response.data[response.data.length-1].total_reg)
                setLoading(false)
            })
        }
        getInventories()
    }, [])

    async function getDataItens(dti) {
        setLoading(true)
        // Gráfico de valores (R$)
        let listCountValues = []
        let listCountLabel = []
        let values = []
        let valueTotal = 0

        // Gráficos de contagem
        let count = []
        let labelsCount = []

        let lista = []
        await dti.map((it) => {
            let index = lista.findIndex((item) => item.name == it[dataFilter])
            if(index > -1) {
                lista[index].value += 1
                listCountValues[index].value += it.Valor
            }else {
                lista.push({name: it[dataFilter], value: 1})
                listCountValues.push({name: it[dataFilter], value: it.Valor})
            }
        })
        lista.forEach((it)=>{
            labelsCount.push(it.name)
            count.push(it.value)
        })
        listCountValues.forEach((it) => {
            if(it.value > 0){
                listCountLabel.push(it.name)
                values.push(parseFloat(it.value.toFixed(2)))
                valueTotal += parseFloat(it.value.toFixed(2))
            }
        })
        // Gráfico de contagem 
        setLabels(labelsCount)
        setCount(count)

        // Gráfico de valores (R$)
        setData(listCountLabel)
        setValues(values)
        setTotalValue(valueTotal.toLocaleString('pt-BR', { style: 'decimal' }))
        setLoading(false)
    }

    useEffect(()=>{
        getDataItens(dashDataList)
    }, [dataFilter])


    return (
        <>
            <Container className="p-3" fluid>
                <h3>Dashboard</h3>
                <Container fluid>
                    <Row className="my-3">
                        <Col className="p-0">
                            <Cards total={itensLength.toLocaleString('pt-BR', { style: 'decimal' })} label={'Itens cadastrados'}/>
                        </Col>
                        <Col>
                            <Cards total={`${totalValue}`} label={'Valor'}/>
                        </Col>
                        <Col>
                            <Cards total={inventarios.toLocaleString('pt-BR', { style: 'decimal' })} label={'Inventários'}/>
                        </Col>
                    </Row>
                    <Row className="mt-4 align-items-start">
                        <Col sm={3} md={3} lg={2}>
                            <Form.Label className="h5">Categorizar por:</Form.Label>
                            <Form.Select onChange={e=>{setDataFilter(e.target.value)}}>
                                <option value="Categorias">Categorias</option>
                                <option value="Niveis 1">Niveis 1</option>
                                <option value="Niveis 2">Niveis 2</option>
                                <option value="Niveis 3">Niveis 3</option>
                                <option value="Niveis 4">Niveis 4</option>
                                <option value="Parceiros">Parceiros</option>
                                <option value="Status">Status</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="my-3 flex-xl-nowrap">
                        <Col md={12} lg={7} className="bg-white shadow rounded p-3 m-1 me-2">
                            <h5>Quantidade</h5>
                            {loading || dashTotalValue === '' ? <LoadingPage/> : <ItemsPieChart data={labels} values={count}/>}
                        </Col>
                        <Col className="bg-white shadow rounded p-3 m-1">
                            <Row>
                                <Col><h5>Valor</h5></Col>
                                <Col className="text-end fw-bold">{totalValue !== 0 ? <>Total: <span className="text-primary fw-normal">R$ {totalValue}</span></> : 'Não há valores relacionado aos itens' }</Col>
                            </Row>
                            {loading || dashTotalValue === '' ? <LoadingPage/> : <ItemsBarChart data={data} values={values}/>}
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}