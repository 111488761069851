import ListItemButton from "./ListItemButton";

import Card from "./Card"

import Button from "./Button";

import Input from "./Input";

import Table from "./Table";

import List from "./List";
import Chip from "./Chip";
import Paper from "./Paper";

// ----------------------------------------------------------------------

export default function CustomMui(theme) {
  return Object.assign(
    ListItemButton(theme),
    Card(theme),
    Button(theme),
    Input(theme),
    Table(theme),
    List(theme),
    Chip(theme),
    Paper(theme)
  );
}
