import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import api from '../services/api'

import Swal from 'sweetalert2'

import {
    AiFillHome,
    AiOutlineTag,
    AiOutlinePoweroff,
    AiOutlinePrinter,
    AiOutlineUnorderedList,
    AiOutlineEdit
} from 'react-icons/ai'
import {
    BsCollectionFill,
    BsFileText,
    BsFillPersonFill,
    BsFillGearFill,
    BsFiletypeDoc
} from 'react-icons/bs'
import {FaAddressBook, FaMapMarkedAlt} from 'react-icons/fa'
import {FiRadio, FiSettings} from 'react-icons/fi'
import {GoLocation, GoGraph, GoDashboard} from 'react-icons/go'
import {IoMdList} from 'react-icons/io'
import {RiFilePaper2Fill} from 'react-icons/ri'
import {BiCheck} from 'react-icons/bi'
import { HiOutlineDocument, HiOutlineDocumentText } from "react-icons/hi"; 
import { AiOutlineFileDone } from "react-icons/ai"; 
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdOutlineDashboard } from "react-icons/md"; 
import { IoIosHelpCircleOutline } from "react-icons/io"; 
import { CgNotes } from "react-icons/cg"; 

import { useTranslation } from 'react-i18next'

import logo from "../assets/logo-sm-2.png";
import PBC from "../assets/pbc_logo.png";

import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
    NavDropdown,
    Form,
    Dropdown,
    Offcanvas,
    Button
    } from 'react-bootstrap'
import traffic_api from '../services/traffic_api'
import secureLocalStorage from 'react-secure-storage'

import { Icon } from '@iconify/react/dist/iconify.js'

function Menu() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const [language, setLanguage] = useState('');
    const [user, setUser] = useState(sessionStorage.getItem('username'))
    const [subMenu, setSubMenu] = useState(0)
    const history = useNavigate()
    const [showMenu, setShowMenu] = useState(sessionStorage.getItem('show-menu') ? sessionStorage.getItem('show-menu') : true)
    const [customMenu, setCustomMenu] = useState()

    useEffect(() => {
        if(!sessionStorage.getItem('idConta')) {
            history('/login')
        }
        if (secureLocalStorage.getItem('ks:u')) {
            const disconnectDate = new Date(secureLocalStorage.getItem('ks:u')?.disconnect);
            const currentDate = new Date();
            if (disconnectDate.getTime() <= currentDate.getTime()) {
                secureLocalStorage.clear();
                sessionStorage.clear();
                history('/login');
            }
        }
    }, [])

    useEffect(() => {
        if(sessionStorage.getItem('show-menu') !== showMenu) {
            sessionStorage.setItem('show-menu', showMenu)
            window.location.reload()
        }
    }, [showMenu])

    useEffect(() =>{
        let lista 
        lista = sessionStorage.getItem('menu') ? JSON.parse(sessionStorage.getItem('menu'))[0].custom : null
        setCustomMenu(lista)
    }, [])

    useEffect(() => {
        if(!user){
          api.get(`/usuario/${sessionStorage.getItem('idConta')}/${sessionStorage.getItem('idUser')}/*/*/*`, {}
            ).then(response => {
                setUser(response.data[0] ? response.data[0].nome : '')
            })   
        }
    }, [])

    useEffect(() => {
        if(!sessionStorage.getItem('menu')){
          api.get(`/usuario/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
            ).then(response => {
                if(response.data[0].modulos_acesso?.length && response.data[0].modulos_acesso[0] !== null) {
                    let customMenu = JSON.stringify([{custom: Object.keys(response.data[0].modulos_acesso[0]).filter(item => item != '_id')}])
                    sessionStorage.setItem('menu', customMenu)
                    let lista = customMenu ? JSON.parse(customMenu)[0].custom : null
                    setCustomMenu(lista)
                }
            })   
        }
    }, [])

    useEffect(() => {
        if(language){
            sessionStorage.setItem('language', language)
        }
    }, [language])

    function redirect(url) {
        const pages = [
            '/logs',
            '/collections/collects',
            '/collections/inventories',
            '/monitoring',
            '/items',
            '/partners',
            '/readers',
            '/categories',
            '/locations',
            '/types',
            '/print',
            '/account',
            '/login',
            '/bi',
            '/maps',
            '/balance-sheet',
            '/print-order',
            '/print-adress',
            '/collections/extern-inventories',
            '/dashboard',
            '/terms-and-conditions',
            '/home', 
            '/account/guides',
            '/activity',
            '/collections/registers'
        ]

        if(url == 12) {
            Swal.fire({
                title: "Atenção !",
                text: "Deseja realmente sair da conta ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Sim",
                cancelButtonText: "Não"
            }).then(
                result => {
                    if(result.isConfirmed) {
                        Swal.fire({
                            title: "Sucesso !",
                            text: 'Até uma próxima vez',
                            icon: 'success',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        }).then(
                            result => {
                                if(sessionStorage.idConta === 'kRTYf3Drw'){
                                    console.log('TRUE')
                                    sessionStorage.clear()
                                    localStorage.clear()
                                    history('/PBC/login')
                                }else{
                                    sessionStorage.clear()
                                    localStorage.clear()
                                    history(pages[url])
                                }
                            }
                        )
                    }
                }
            )
        }else{            
            if(!pages[url]){
                return
            }
            const traffic = [{
                user:{
                    id: sessionStorage.getItem('idConta'),
                },
                visitedPage: pages[url]
            }]
            traffic_api.post('/page-traffic/register/InovaOne', traffic)
            history(pages[url])
        }
    }

    return (
        <>
            <Navbar expand="md" className="shadow-sm bg-white" sticky="top">
                <Container fluid>
                    <Navbar.Brand>
                        {sessionStorage.idConta === 'kRTYf3Drw' ? 
                            <img
                                alt=""
                                src={PBC}
                            />
                        :
                            <>
                                <img
                                    alt=""
                                    src={logo}
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-top"
                                    />{' '}
                                Inova<b>One</b>
                            </>
                        }
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-menu"/>
                    <Navbar.Offcanvas
                        id="responsive-menu"
                        aria-labelledby="responsive-menu"
                        
                        style={{width:'90%'}}
                        placement="start"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="responsive-menu">
                                
                                <img
                                    alt=""
                                    src={logo}
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-top"
                                    />{' '}
                                Inova<b>One</b>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="ms-auto">
                                <NavDropdown 
                                    className="d-none d-md-block"
                                    align="end" 
                                    title={i18n.language.includes('pt') ? <span className="fi fi-br me-2"></span> : i18n.language.includes('en') ? <span className="fi fi-us me-2"></span> : <span className="fi fi-es me-2"></span>}>
                                        <NavDropdown.Item onClick={e => {setLanguage('pt-BR'); i18n.changeLanguage('pt-BR')}}><span className="fi fi-br me-2"></span>{t('languages.portuguese')}{i18n.language.includes("pt-BR") ? <BiCheck className='ms-2'/> : ''}</NavDropdown.Item>
                                        <NavDropdown.Item onClick={e => {setLanguage('en'); i18n.changeLanguage("en")}}><span className="fi fi-us me-2"></span>{t('languages.english')}{i18n.language.includes("en") ? <BiCheck className='ms-2'/> : ''}</NavDropdown.Item>
                                        <NavDropdown.Item onClick={e => {setLanguage('es'); i18n.changeLanguage("es")}}><span className="fi fi-es me-2"></span>{t('languages.spanish')}{i18n.language.includes("es")? <BiCheck className='ms-2'/> : ''}</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown
                                    className="d-none d-md-block"
                                    title={user != '' ? <span className="px-2">{user}</span> : ''} 
                                    align="end"
                                    >
                                    {sessionStorage.getItem('perfil') == 'admin' ? 
                                        <>
                                            <NavDropdown.Item className="d-flex align-items-center" onClick={e => (redirect(11))}>
                                                <FiSettings width="20px"></FiSettings>
                                                <span className="ps-2">{t('menu.account')}</span>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item className="d-flex align-items-center" onClick={e => (redirect(23))}>
                                                <AiOutlineFileDone width="20px"/>
                                                <span className="ps-2">{t('activity_log.history')}</span>
                                            </NavDropdown.Item>
                                            <NavDropdown.Divider />
                                        </>
                                    : ''}
                                    <NavDropdown.Item className="d-flex align-items-center" onClick={e => (redirect(20))}>
                                        <CgNotes width="20px"/>
                                        <span className="ps-2">{t('terms.termsAndConditions.title')}</span>
                                    </NavDropdown.Item>
                                    {sessionStorage.getItem('perfil') == 'admin' ?
                                        <>
                                            <NavDropdown.Item className="d-flex align-items-center" onClick={e => (redirect(22))}>
                                                <IoIosHelpCircleOutline width="20px"/>
                                                <span className="ps-2">Ajuda</span>
                                            </NavDropdown.Item>
                                        </>
                                    : ''}
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item className="d-flex align-items-center" onClick={e => (redirect(12))}>
                                        <AiOutlinePoweroff width="20px"/>
                                        <span className="ps-2">{t('menu.exit')}</span>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                            <Nav className="justify-content-end flex-grow-1 d-md-none text-secondary">
                                <Nav.Link href="/home"><AiFillHome className="icons"/>Home</Nav.Link>
                                <Nav.Link href="/inventories"><BsFileText className="icons"/>{t('menu.inventories')}</Nav.Link>
                                <Nav.Link href="/collections/extern-inventories"><BsFileText className="icons"/> {t('menu.inventories_extern')}</Nav.Link>
                                <Nav.Link href="/collections/collects"><BsCollectionFill className="icons"/> {t('menu.collections')}</Nav.Link>
                                <Nav.Link href="/items"><AiOutlineTag className="icons"/> {t('menu.items')}</Nav.Link>
                                <Nav.Link href="/categories"><AiOutlineUnorderedList className="icons"/> {t('menu.categories')}</Nav.Link>
                                <Nav.Link href="/locations"><GoLocation className="icons"/> {t('menu.locate')}</Nav.Link>
                                <Nav.Link href="/partners"><BsFillPersonFill className="icons"/> {t('menu.partners')}</Nav.Link>
                                <Nav.Link href="/print"><AiOutlinePrinter className="icons"/> {t('menu.print')}</Nav.Link>
                                <Nav.Link href="/print-order"><AiOutlinePrinter className="icons"/> {t('menu.print-order')}</Nav.Link>
                                <Nav.Link href="/readers"><FiRadio className="icons"/> {t('menu.readers')}</Nav.Link>
                                <Nav.Link href="/print-adress"><AiOutlinePrinter className="icons"/> {t('menu.printers')}</Nav.Link>
                                <Nav.Link href="/types"><IoMdList className="icons"/> {t('menu.register')}</Nav.Link>
                                <Nav.Link href="/balance-sheet"><RiFilePaper2Fill className="icons"/> {t('menu.balance-sheet')}</Nav.Link>
                                <Nav.Link href="/maps"><FaMapMarkedAlt className="icons"/> {t('menu.maps')}</Nav.Link>
                                <Nav.Link href="/monitoring"><BsFileText className="icons"/> {t('menu.monitoring')}</Nav.Link>
                                <Nav.Link href="/logs"><FaAddressBook className="icons"/> {t('menu.logs')}</Nav.Link>
                                <Nav.Link href="/bi"><GoGraph className="icons"/> {t('menu.bi')}</Nav.Link>
                                {(sessionStorage.getItem('idConta') == 'tU65GZUf1') ? <Nav.Link href="/dashboard"><GoDashboard className="icons"/> Dashboard </Nav.Link> : ''}
                                {(sessionStorage.getItem('perfil') == 'admin') ? <Nav.Link href="/account"><BsFillGearFill className="icons"/> {t('menu.account')}</Nav.Link> : ''}
                                <Nav.Link onClick={e => (redirect("12"))}><AiOutlinePoweroff className="icons"/> {t('menu.exit')}</Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            {showMenu && showMenu !== 'false' ?
                <Col className="menu text-secondary d-none d-md-block">
                    <ul>
                        <span className='select-info'>
                            <p>MENU</p>
                            <div className='d-flex'>
                                <span className='btn h5' onClick={e=>{setShowMenu(false)}}><MdKeyboardDoubleArrowLeft /></span>
                            </div>
                        </span>
                        {customMenu ?
                            <>
                                <li  onClick={e => (redirect(e.target.value))} value="21"><AiFillHome className="icons"/>Home</li>
                                <li className={customMenu.find(item=> item == 'bi') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="13"><MdOutlineDashboard className="icons"/> {t('menu.bi')}</li>
                                <li className={customMenu.find(item=> item == 'inventarios') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="2"><BsFileText className="icons"/>{t('menu.inventories')}</li>
                                <li className={customMenu.find(item=> item == 'inventarios_externo') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="18"><BsFileText className="icons"/> {t('menu.inventories_extern')}</li>
                                <li className={customMenu.find(item=> item == 'registro_rotas') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="24"><BsFileText className="icons"/> {t('common.registers')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="24"><BsFileText className="icons"/>{t('common.registers')}</li>
                                <li className={customMenu.find(item=> item == 'coletas') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="1"><BsCollectionFill className="icons"/> {t('menu.collections')}</li>
                                <li className={customMenu.find(item=> item == 'itens') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="4"><AiOutlineTag className="icons"/> {t('menu.items')}</li>
                                <li className={customMenu.find(item=> item == 'categorias') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="7"><AiOutlineUnorderedList className="icons"/> {t('menu.categories')}</li>
                                <li className={customMenu.find(item=> item == 'localizacoes') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="8"><GoLocation className="icons"/> {t('menu.locate')}</li>
                                <li className={customMenu.find(item=> item == 'parceiros') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="5"><BsFillPersonFill className="icons"/> {t('menu.partners')}</li>
                                <li className={customMenu.find(item=> item == 'impressoes') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="10"><AiOutlinePrinter className="icons"/> {t('menu.print')}</li>
                                <li className={customMenu.find(item=> item == 'ordem_impressao') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="16"><AiOutlinePrinter className="icons"/> {t('menu.print-order')}</li>
                                <li className={customMenu.find(item=> item == 'leitores') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="6"><FiRadio className="icons"/> {t('menu.readers')}</li>
                                <li className={customMenu.find(item=> item == 'impressoras') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="17"><AiOutlinePrinter className="icons"/> {t('menu.printers')}</li>
                                <li className={customMenu.find(item=> item == 'tipos_registros') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="9"><IoMdList className="icons"/> {t('menu.register')}</li>
                                <li className={customMenu.find(item=> item == 'balancete') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="15"><RiFilePaper2Fill className="icons"/> {t('menu.balance-sheet')}</li>
                                <li className={customMenu.find(item=> item == 'mapas') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="14"><FaMapMarkedAlt className="icons"/> {t('menu.maps')}</li>
                                <li className={customMenu.find(item=> item == 'monitoramento') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="3"><BsFileText className="icons"/> {t('menu.monitoring')}</li>
                                <li className={customMenu.find(item=> item == 'logs') ? "" : "visually-hidden"} onClick={e => (redirect(e.target.value))} value="0"><FaAddressBook className="icons"/> {t('menu.logs')}</li>
                                { subMenu == 0 && sessionStorage.getItem('idConta') == '_Y12sHQ61'? <li onClick={e => (redirect(e.target.value))} value="19"><GoDashboard className="icons"/> Dashboard </li>: ''}
                                <li onClick={e => (redirect(e.target.value))} value="23"><HiOutlineDocumentText className="icons"/> {t('activity_log.history')}</li>
                                {(sessionStorage.getItem('perfil') == 'admin') ? <li onClick={e => (redirect(e.target.value))} value="11"><BsFillGearFill className="icons"/> {t('menu.account')}</li> : ''}
                                <li onClick={e => (redirect(e.target.value))} value="12"><AiOutlinePoweroff className="icons"/> {t('menu.exit')}</li>
                            </>
                        : 
                            <>
                                <li onClick={e => (redirect(e.target.value))} value="21"><AiFillHome className="icons"/>Home</li>
                                <li onClick={e => (redirect(e.target.value))} value="2"><BsFileText className="icons"/>{t('menu.inventories')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="18"><BsFileText className="icons"/> {t('menu.inventories_extern')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="24"><BsFileText className="icons"/>{t('common.registers')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="1"><BsCollectionFill className="icons"/> {t('menu.collections')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="4"><AiOutlineTag className="icons"/> {t('menu.items')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="7"><AiOutlineUnorderedList className="icons"/> {t('menu.categories')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="8"><GoLocation className="icons"/> {t('menu.locate')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="5"><BsFillPersonFill className="icons"/> {t('menu.partners')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="10"><AiOutlinePrinter className="icons"/> {t('menu.print')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="16"><AiOutlinePrinter className="icons"/> {t('menu.print-order')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="6"><FiRadio className="icons"/> {t('menu.readers')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="17"><AiOutlinePrinter className="icons"/> {t('menu.printers')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="9"><IoMdList className="icons"/> {t('menu.register')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="15"><RiFilePaper2Fill className="icons"/> {t('menu.balance-sheet')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="14"><FaMapMarkedAlt className="icons"/> {t('menu.maps')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="3"><BsFileText className="icons"/> {t('menu.monitoring')}</li>
                                <li onClick={e => (redirect(e.target.value))} value="0"><FaAddressBook className="icons"/> {t('menu.logs')}</li>
                                {/*<li onClick={e => (redirect(e.target.value))} value="13"><GoGraph className="icons"/> {t('menu.bi')}</li>*/}
                                { subMenu == 0 && sessionStorage.getItem('idConta') == '_Y12sHQ61'? <li onClick={e => (redirect(e.target.value))} value="19"><GoDashboard className="icons"/> Dashboard </li>: ''}
                                <li onClick={e => (redirect(e.target.value))} value="23"><HiOutlineDocumentText className="icons"/> {t('activity_log.history')}</li>
                                {(sessionStorage.getItem('perfil') == 'admin') ? <li onClick={e => (redirect(e.target.value))} value="11"><BsFillGearFill className="icons"/> {t('menu.account')}</li> : ''}
                                <li onClick={e => (redirect(e.target.value))} value="12"><AiOutlinePoweroff className="icons"/> {t('menu.exit')}</li>
                            </>
                        }
                    </ul>
                </Col>
            :
                <div className="mt-4 py-5 position-fixed top-0 left-0 d-flex flex-column">
                    <span onClick={e => (redirect(21))} className="mt-3 text-secondary p-2"><AiFillHome/></span>
                    <span onClick={e=>{setShowMenu(true)}} className=" mt-3 text-white p-2" style={{borderRadius: '0 8px 8px 0', backgroundColor: '#6189f6'}}><MdKeyboardDoubleArrowRight/></span>
                </div>
            }
        </>
    )
}

export default Menu