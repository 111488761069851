import { useEffect, useState } from "react";

import {useNavigate } from 'react-router-dom'

import { Box, Button, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, Stack, Switch, TextField, Typography } from "@mui/material";

import api from "../../../../services/api";

import secureLocalStorage from "react-secure-storage";

import { t } from "i18next";
import { Icon } from "@iconify/react/dist/iconify.js";

//----------------------------------------------------------------

const modeList = [
    {
        name: 'Ean'
    },
    {
       name: 'Localização', 
    },
    {
        name: 'Categoria'
    },
    {
        name: 'Localização | Categoria'
    },
    {
        name: 'Todos os itens'
    }
]

// ----------------------------------------------------------------

export default function FormTagPicking({onSave, onCreateModel, config}){
    const history = useNavigate()

    const [loading, setLoading] = useState()

    const [filter, setFilter] = useState(config?.filter || "no_filter")

    const [nivel1, setNivel1] = useState(config?.nivel_1 || '')

    const [nivel2, setNivel2] = useState(config?.nivel_2 || '')

    const [nivel3, setNivel3] = useState(config?.nivel_3 || '')

    const [nivel4, setNivel4] = useState(config?.nivel_4 || '')

    const [nivel1List, setNivel1List] = useState([])

    const [nivel2List, setNivel2List] = useState([])

    const [nivel3List, setNivel3List] = useState([])

    const [nivel4List, setNivel4List] = useState([])

    const [categoria, setCategoria] = useState(config?.categoria || '')

    const [categoriaList, setCategoriaList] = useState([])

    const [marca, setMarca] = useState(config?.marca || '')

    const [marcaList, setMarcaList] = useState([])

    const [model, setModel] = useState(config?.modelo || '')

    const [modelList, setModelList] = useState([])

    const [ean, setEan] = useState(config?.ean || '')
    
    const [tagCount, setTagCount] = useState(config?.quant_esperada || '')

    useEffect(() => {
        nivel1Get()
    }, [])

    useEffect(() => {
        nivel2Get(nivel1)
    }, [nivel1])

    useEffect(() => {
        setNivel3()
        setNivel4()
        nivel3Get(nivel2)
    }, [nivel2])
    
    useEffect(() => {
        nivel4Get(nivel3)
    }, [nivel3])

    useEffect(() => {
        getCategorias()
    }, [])

    useEffect(() => {
        if(categoria){
            getMarcas()
        }
    }, [categoria])

    function nivel1Get() {
        setLoading(true)
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {
            setNivel1List(response.data)
            if(nivel1){
                setNivel1(response.data.find(item=>item._id === nivel1._id))
            }
        })
    }

    function nivel2Get(nivel1) {
        setLoading(true)
        if(nivel1) {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1 != '' ? nivel1._id : '*'}/*/*/*/*`, {}
            ).then(
                response => {
                    setNivel2List(response.data)
                    if(nivel2){
                        setNivel2(response.data.find(item=>item._id === nivel2._id))
                    }
                }, err => {
                    setLoading(false)
                }
            )
        }else {
            setNivel2List([])
        }
    }

    function nivel3Get(nivel2) {
        if(nivel2) {
            setLoading(true)
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2 != '' ? nivel2._id : '*'}/*/*/*/*`, {}
            ).then(
                response => {
                    setNivel3List(response.data)
                    if(nivel3){
                        setNivel3(response.data.find(item=>item._id === nivel3._id))
                    }
                }, err => {
                    setLoading(false)
                }
            )
        }else {
            setNivel3List([])
        }
    }

    function nivel4Get(nivel3) {
        if(nivel3) {
            setLoading(true)
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel3 != '' ? nivel3._id : '*'}/*/*/*/*`, {}
            ).then(
                response => {
                    setNivel4List(response.data)
                    if(nivel4){
                        setNivel4(response.data.find(item=>item._id === nivel4._id))
                    }
                }, err => {
                    setLoading(false)
                }
            )
        }else {
            setNivel4List([])
        }
    }

    function handleMarca(selectedMarca){
        setMarca(selectedMarca._id)
        setModel()
        setModelList(selectedMarca.modelo || [])
    }

    function getCategorias() {
        setLoading(true)
        api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(
            response => {
                setCategoriaList(response.data)
                setLoading(false)
            }, err => {
                setLoading(false)
            }
        )
    }

    function getMarcas(){
        setLoading(true)
        api.get(`/infs_compl/${sessionStorage.getItem('idConta')}?id_categoria=${categoria}&marca=&modelo=`).then(
            response => {
                setMarcaList(response.data)
                if(marca){
                    const findMarca = response.data.find(item=>item._id === marca)
                    setMarca(findMarca?._id || '')
                }
            }, err => {
                setLoading(false)
            }
        )
    }

    function handleFilter(selectOption){
        setFilter(selectOption)
    }

    function handleStart(action){

        let startConfig = {
            "quant_esperada": tagCount
        }

        switch (filter) {
            case 'Ean':
                startConfig["tag"] = ean
                break
            case 'Localização | Categoria':
                startConfig["categoria"] = categoria
                startConfig["marca"] = marca
                startConfig["modelo"] = model
                startConfig["nivel_1"] = nivel1
                startConfig["nivel_2"] = nivel2
                startConfig["nivel_3"] = nivel3
                startConfig["nivel_4"] = nivel4
                break;
            case 'Localização':
                startConfig["nivel_1"] = nivel1
                startConfig["nivel_2"] = nivel2
                startConfig["nivel_3"] = nivel3
                startConfig["nivel_4"] = nivel4
                break;
            case 'Categoria':
                startConfig["categoria"] = categoria
                startConfig["marca"] = marca
                startConfig["modelo"] = model
                break;
            case 'Todos os itens':
                break;
            default:
                startConfig["no_filter"] = true
                break;
        }

        for (let key in startConfig) {
            if (!startConfig[key]) {
                delete startConfig[key];
            }
        }

        if(action === 'saveModel'){
            onCreateModel({filter: filter, ...startConfig})
        }
        
        if(action === 'startInventory'){
            onSave(startConfig)
        }
    }
    
    return (
        <>                
            <Grid item md>
                <FormGroup>
                    <Typography>Filtragem</Typography>
                    <Select size="small" value={filter} onChange={e=>{handleFilter(e.target.value)}}>
                        <MenuItem value="no_filter">--</MenuItem>
                        {modeList.map((option)=>(
                            <MenuItem value={option.name}>{option.name}</MenuItem>
                        ))}
                    </Select>
                </FormGroup>
            </Grid>
                {filter ? 
                    <Box>
                        <Grid container spacing={2}>
                            {filter === 'Categoria' || filter === 'Localização | Categoria' ? 
                                <>
                                    <Grid item md={4}>
                                        <FormGroup>
                                            <Typography>Categoria</Typography>
                                            <Select size="small" value={categoria} onChange={e=>{setCategoria(e.target.value)}}>
                                                <MenuItem value=""></MenuItem>
                                                {categoriaList.map((item, index)=>(
                                                    <MenuItem key={index} value={item._id}>{item.descricao}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormGroup>
                                            <Typography>Marca</Typography>
                                            <Select size="small" value={marca} onChange={e=>{handleMarca(e.target.value)}}>
                                                <MenuItem value=""></MenuItem>
                                                {marcaList.map((item, index)=>(
                                                    <MenuItem key={index} value={item._id}>{item.marca}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormGroup>
                                            <Typography>Modelo</Typography>
                                            <Select size="small" value={model} onChange={e=>{setModel(e.target.value)}}>
                                                <MenuItem value=""></MenuItem>
                                                {modelList.map((item, index)=>(
                                                    <MenuItem key={index} value={item._id}>{item.descricao}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </Grid>
                                </>
                            : ''} 
                            {filter === 'Localização' || filter === 'Localização | Categoria' ? 
                                <>
                                    <Grid item md={3}>
                                        <FormGroup>
                                            <Typography>Nivel 1</Typography>
                                            <Select size="small" value={nivel1} onChange={e=>{setNivel1(e.target.value)}}>
                                                <MenuItem value=""></MenuItem>
                                                {nivel1List.map((item, index)=>(
                                                    <MenuItem key={index} value={item}>{item.descricao}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3}>
                                        <FormGroup>
                                            <Typography>Nivel 2</Typography>
                                            <Select size="small"value={nivel2} onChange={e=>{setNivel2(e.target.value)}}>
                                                <MenuItem value=""></MenuItem>
                                                {nivel2List.map((item, index)=>(
                                                    <MenuItem key={index} value={item}>{item.descricao}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3}>
                                        <FormGroup>
                                            <Typography>Nivel 3</Typography>
                                            <Select size="small"value={nivel3} onChange={e=>{setNivel3(e.target.value)}}>
                                                <MenuItem value=""></MenuItem>
                                                {nivel3List.map((item, index)=>(
                                                    <MenuItem key={index} value={item}>{item.descricao}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3}>
                                        <FormGroup>
                                            <Typography>Nivel 4</Typography>
                                            <Select size="small"value={nivel4} onChange={e=>{setNivel4(e.target.value)}}>
                                                <MenuItem value=""></MenuItem>
                                                {nivel4List.map((item, index)=>(
                                                    <MenuItem key={index} value={item}>{item.descricao}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormGroup>
                                    </Grid>
                                </>
                        : ''} 
                        {filter === 'Ean'? 
                            <>
                                <Grid item md>
                                    <FormGroup>
                                        <Typography>Ean</Typography>
                                        <TextField size="small" value={ean} onChange={e=>{setEan(e.target.value)}}/>
                                    </FormGroup>
                                </Grid>
                            </>
                        : ''} 
                    </Grid>
                    </Box>
                : ""}
            <Box>
                <FormGroup>
                    <Typography>Quantidade Esperada</Typography>
                    <TextField size="small" type="number" placeholder="(opcional)" value={tagCount} onChange={e=>{setTagCount(parseInt(e.target.value))}}/>
                    <FormHelperText>Receba um alerta ao atingir a quantidade esperada</FormHelperText>
                </FormGroup>
            </Box>

            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button 
                    variant="outlined" 
                    color="dark" 
                    onClick={e=>{handleStart('saveModel')}}
                    startIcon={<Icon icon="mingcute:save-2-line"/>}
                >
                    {t('actions.create')} {t('settings.model')}
                </Button>

                <Button 
                    variant="contained" 
                    onClick={e=>{handleStart('startInventory')}}
                >
                    Iniciar
                </Button>
            </Stack>
        </>
    )
}