import React, {useState, useEffect} from 'react'
import {useNavigate } from 'react-router-dom'

// componentes
import api from '../../../services/api'
import LoadingPage from '../../../components/loading/LoadingPage';

// estilos
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './../style.css'
import logoInova from '../../../assets/pbc_logo_bg.png';
import logo from '../../../assets/logo.webp';

//packages
import { useTranslation } from 'react-i18next'
import emailjs from 'emailjs-com'
import Swal from 'sweetalert2'

// ----------------------------------------------------------------

export default function Login(){
    const history = useNavigate()
    const { t } = useTranslation();
    //
    const [loading, setLoading] = useState(false)
    const [recoverLoading, setRecoverLoading] = useState(false)    
    const [userLogin, setUserLogin] = useState("")
    const [passLogin, setPassLogin] = useState("")
    
    useEffect(() => {
        setLoading(true)
        if(sessionStorage.getItem('idConta')){
            history('/home')
        }
        setLoading(false)
    },[])

    function Auth(e){
        e.preventDefault()
        if(userLogin !== "" && passLogin !=="") {
            setLoading(true)
            const user = {
                login: userLogin,
                senha: passLogin,
                tipo: "BO"
            }
            api.post(`/login`, user).then(
                response => {
                    let User = response.data
                        sessionStorage.setItem('idConta', User.id_conta)
                            sessionStorage.setItem('idUser', User._id)
                            User.perfil === '' ? sessionStorage.setItem('perfil', 'admin') : sessionStorage.setItem('perfil', User.perfil)
                            if(User.nome){
                                sessionStorage.setItem('username', User.nome)
                            }
                            if(User.niveis_acesso.length > 0){
                                if(User.niveis_acesso[0].niveis1[0].id_nivel_loc1.length > 1){
                                    sessionStorage.setItem('nivel1', User.niveis_acesso[0].niveis1[0].id_nivel_loc1)
                                }
                                if(User.niveis_acesso[0].niveis2[0].id_nivel_loc2.length > 1){
                                    sessionStorage.setItem('nivel2', User.niveis_acesso[0].niveis2[0].id_nivel_loc2)
                                }
                                if(User.niveis_acesso[0].niveis3[0].id_nivel_loc3.length > 1){
                                    sessionStorage.setItem('nivel3', User.niveis_acesso[0].niveis3[0].id_nivel_loc3)
                                }
                                if(User.niveis_acesso[0].niveis4[0].id_nivel_loc4.length > 1){
                                    sessionStorage.setItem('nivel4', User.niveis_acesso[0].niveis4[0].id_nivel_loc4)
                                }
                            }
                            if(User.modulos_acesso?.length && User.modulos_acesso[0] !== null) {
                                sessionStorage.setItem('menu', JSON.stringify([{custom: Object.keys(User.modulos_acesso[0]).filter(item => item !== '_id')}]))
                            }
                            if(User.perfil_personalizavel.length > 0){
                                if(User.perfil_personalizavel[0].permissoes[0].itens){
                                    sessionStorage.setItem('filterView', User.perfil_personalizavel[0].permissoes[0].itens.visualizar)
                                    sessionStorage.setItem('filterCreate', User.perfil_personalizavel[0].permissoes[0].itens.criar)
                                    sessionStorage.setItem('filterEdit', User.perfil_personalizavel[0].permissoes[0].itens.editar)
                                    sessionStorage.setItem('filterExclude', User.perfil_personalizavel[0].permissoes[0].itens.excluir)
                                }
                            }
                    Swal.fire({
                        title: `${t('login.messages.sign_in.success')}`,
                        text: `${t('login.messages.sign_in.welcome')} ${User.nome}`,
                        icon: 'success',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    }).then(
                        result => {                                                
                            setLoading(false)
                            history('/home')
                        },
                        result => {                                                
                            Swal.fire({
                                title: "Oops... !",
                                text: `${t('login.messages.sign_up.errors.error')}`,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })  
                        }
                    )
                },
                response => {
                    Swal.fire({
                        title: "Oops... !",
                        text: `${t('login.messages.sign_in.login_error')}`,
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                    setLoading(false)
                }
            )
        }else {
            Swal.fire({
                title: "Oops... !",
                text: `${t('login.messages.sign_in.missing')}`,
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })  
        }
    }

    function RecoverFunc() {
        Swal.fire({
            title: `${t('login.messages.password_recover.title')}`,
            html: `<p style="text-align:left;">${t('login.messages.password_recover.text')}</p>`,
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: `${t('login.messages.password_recover.send')}`,
            cancelButtonText: `${t('login.messages.password_recover.close')}`,
            preConfirm: (logRecover) => {
                if(logRecover !== '') {
                    setRecoverLoading(true)
                    api.get(`usuario/*/*/${logRecover}/*/*`).then(
                        response => {
                            if(response.data[0] && response.data[0].id_conta) {
                                api.get(`/conta/${response.data[0].id_conta}/*/*/*`).then(
                                    res => {
                                        const info = {
                                            userLogin: response.data[0].login,
                                            passLogin: response.data[0].senha,
                                            mainEmail: res.data[0].email
                                        }
                                        emailjs.send(process.env.REACT_APP_SERVICE_ID , process.env.REACT_APP_TEMPLATE_ID, info, process.env.REACT_APP_USER_ID)
                                        .then(
                                            result => {
                                                Swal.fire({
                                                    title: `${t('login.messages.password_recover.success')}`,
                                                    text: `${t('login.messages.password_recover.success_text')} ${res.data[0].email}`,
                                                    icon: 'success',
                                                    showConfirmButton: true
                                                })
                                                setRecoverLoading(false)
                                            },
                                            error => {
                                                Swal.fire({
                                                    title: "Oops... !",
                                                    html: `${t('login.messages.password_recover.error')}<br/>${res.data[0].email}`,
                                                    icon: 'error',
                                                    showConfirmButton: false,
                                                    timerProgressBar: true,
                                                    timer: '2500'
                                                })
                                                setRecoverLoading(false)
                                            }
                                        )
                                    }
                                )
                            }else {
                                Swal.fire({
                                    title: "Oops... !",
                                    text: `${t('login.messages.password_recover.error_catch')}`,
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                setRecoverLoading(false)
                            }
                        },
                        response => {
                            Swal.fire({
                                title: "Oops... !",
                                text: `${t('login.messages.password_recover.error_catch')}`,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            setRecoverLoading(false)
                        }
                    )
                }else {
                    Swal.fire({
                        title: "Oops... !",
                        text: `${t('login.messages.password_recover.error_missing')}`,
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                    setRecoverLoading(false)
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
        
    }

    return (
        <>
            {loading || recoverLoading ?
                <LoadingPage/>
            : ''}
            <Container fluid>
                <Row className="vh-100">
                    <Col sm={7} className="d-flex justify-content-center align-items-center flex-column">
                        <Col sm={12} md={5}>
                            <Form onSubmit={e => {Auth(e)}}>
                                <Row className="justify-content-center"><img src={logoInova} alt="Logo InovaOne" style={{width:'190px'}}/></Row>
                                <h3 className='py-3'>{t('login.sign_in')}</h3>
                                <Form.Group className="mb-3 text-secondary" controlId="formGroupEmail">
                                    <Form.Label>{t('login.placeholder.login')}</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        value={userLogin}
                                        onChange={e => setUserLogin(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 text-secondary" controlId="formGroupPassword">
                                    <Form.Label>{t('login.placeholder.password')}</Form.Label>
                                    <Form.Control 
                                        type="password"
                                        value={passLogin}
                                        onChange={e => setPassLogin(e.target.value)}
                                    />
                                </Form.Group>
                                <div className="d-grid gap-2 py-3">
                                    <Button variant="custom-primary" type='submit' value={'ACESSAR'}>
                                        {t('login.buttons.login')}
                                    </Button>
                                    <div 
                                        onClick={e=> {RecoverFunc()}} 
                                        className="text-end" 
                                        style={{color: '#4362E7', cursor: 'pointer'}}>
                                        {t('login.forgot_password')}
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    </Col>   
                    <Col className="background register">
                        <h2 className="line">{t('login.welcome')}</h2>
                        <h5 className="pt-5 pb-3">{t('login.register_text')}</h5>
                        <Button variant="outline-light rounded-pill px-3" onClick={e=>history('/PBC/register')}>
                            {t('login.buttons.create_account')}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}