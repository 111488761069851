import { useEffect, useState } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { Divider, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

import { Icon } from "@iconify/react";

import { grey } from "@mui/material/colors";

import secureLocalStorage from "react-secure-storage";

import linkList from "../../menu/nav-items/linkList";

import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------

export default function MenuMobile() {

    const { t } = useTranslation();

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        sessionStorage.clear()
        secureLocalStorage.clear()
        if(sessionStorage.getItem('idConta') === 'kRTYf3Drw'){
            window.location.href = '/PBC/login'
        }else{
            window.location.href = '/login'
        }
    };

    return (
        <>
            <IconButton onClick={handleClick} >
                <Icon icon={'mynaui:menu'} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ height: '80vh' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflowY: 'scroll',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.10))',
                        width: 200,
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                {linkList.map((link, index) =>(
                    <MenuItem onClick={e => { navigate(link.path) }}>
                        <ListItemIcon>
                            <Icon icon={link.icon}/>
                        </ListItemIcon>
                        <ListItemText>{t(`menu.${link.title}`)}</ListItemText>
                    </MenuItem>
                ))}
                <MenuItem onClick={e => {handleLogout()}}>
                    <ListItemIcon>
                        <Icon icon={'mingcute:exit-line'}/>
                    </ListItemIcon>
                    <ListItemText>{t('menu.exit')}</ListItemText>
                </MenuItem>
            </Menu>
        </>
    )
}