import React from "react";

import ReactApexChart from "react-apexcharts";

class ItemsHalfPieChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: props.value,
        options: {
          chart: {
            type: 'donut',
          },
          labels:props.labels,
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 90,
              offsetY: 10
            }
          },
          grid: {
            padding: {
              bottom: -80
            }
          },
          colors: ['#008eff', '#D5D5D5'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      
      
      };
    }

  

    render() {
      return (
          <div className="donut w-100">
            <div className="w-100">
            <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
            </div>
            </div>
        );
    }
}

export default ItemsHalfPieChart;