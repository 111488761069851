import React, { PureComponent } from 'react';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { cyan, grey, red } from '@mui/material/colors';

//------------------------------------------------------------

const fakerData = [
    { name: '', serie_1: 0, serie_2: 0},
    { name: '', serie_1: 0, serie_2: 0},
    { name: '', serie_1: 0, serie_2: 0},
    { name: '', serie_1: 0, serie_2: 0},
    { name: '', serie_1: 0, serie_2: 0},
];
  

export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/p/sandbox/line-chart-width-xaxis-padding-8v7952';
  
  render() {
    const { propsData, propsColors, width = 600, height = 350, series = ['serie_1', 'serie_2'], dashedIndex = []} = this.props

    const data = propsData || fakerData

    const colors = [...propsColors, cyan[400], red[200]]

    return (
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
            <CartesianGrid strokeDasharray="3 3" vertical={false}/>
            <XAxis axisLine={false} stroke={grey[400]} dataKey="name" tickMargin={16} />
            <YAxis axisLine={false} stroke={grey[400]} tickLine={false} />
            <Tooltip />
            {!propsData && (
              <text
                    x='50%'
                    y='50%'
                    style={{ fontSize: 14, fontWeight: 'bold', fill: grey[400] }}
                    width={200}
                    scaleToFit={true}
                    textAnchor='middle'
                    verticalAnchor='middle'
                >
                  Nenhum dado encontrado
              </text>
            )}
            <Legend iconType="circle" iconSize={8}/>
            {series.map((item, i)=>(
              <Line type="monotone" dataKey={item} stroke={colors[i]} strokeDasharray={dashedIndex.includes(i) ? "3 4 5 2" : ""}
/>
            ))}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
