import React, {PureComponent ,Component} from 'react';
import {
    Container,
    Row,
    Col,
    Dropdown
    } from 'react-bootstrap'

import {
	ResponsiveContainer,
	Cell,
	BarChart,
	XAxis,
	YAxis,
	Legend,
	Tooltip,
	CartesianGrid,
	Bar
} from 'recharts';

import i18next from 'i18next'

const colorList = [
	'237,27,36',
	'239,66,36',
	'243,101,35',
	'249,161,27',
	'254,242,0',
	'155,203,59',
	'39,179,83',
	'15,176,161',
	'55,86,166', 
	'75,60,153',
	'146,39,143',
	'147,42,111'
]

export default class Example extends PureComponent {
	constructor() {
        super();
    }
	
	state = {
		first: true,
		selected: 'Categorias',
		infoIndex: -1,
		infoState: [],
		infoIndex2: -1,
		infoState2: []
	};

	loadFirst = async (dti) => {
		if(this.state.first) {
			this.returnData(dti)
			this.setState({first: false})
		}
	}

	returnData = async (dti) => {
			let lista = []
			let lista2 = []
			await dti.map((it) => {
				let index = lista.findIndex((item) => item.name == it[this.state.selected])
				if(index > -1) {
					lista[index].value += 1
					lista2[index].value += it.Valor
				}else {
					lista.push({name: it[this.state.selected], value: 1})
					lista2.push({name: it[this.state.selected], value: it.Valor})
				}
			})
			await this.setState({
				infoState: lista,
				infoState2: lista2
			})
	}

	changeSelected = (selected) => {
		if(this.state.selected != selected) {
			this.setState({
				selected: selected,
				infoIndex: -1,
				infoState: [],
				infoIndex2: -1,
				infoState2: []
			});
		}
	}

	barFocused = (data, index) => {
		this.setState({
			infoIndex: index
		})
	}
	
	barFocused2 = (data, index) => {
		this.setState({
			infoIndex2: index
		})
	}
	
	barNotFocused = (data, index) => {
		this.setState({
			infoIndex: -1
		});
	}
	
	barNotFocused2 = (data, index) => {
		this.setState({
			infoIndex2: -1
		});
	}

	focusedVerify = (state) => {
		if (state.isTooltipActive) {
			this.setState({
				infoIndex: state.activeTooltipIndex
			});
		} else {
			this.setState({
				infoIndex: -1
			});
		}
	}
	
	focusedVerify2 = (state) => {
		if (state.isTooltipActive) {
			this.setState({
				infoIndex2: state.activeTooltipIndex
			});
		} else {
			this.setState({
				infoIndex2: -1
			});
		}
	}
	
	focusedVerifyOut = () => {
		this.setState({
			infoIndex: -1
		})
	}
	
	focusedVerifyOut2 = () => {
		this.setState({
			infoIndex2: -1
		})
	}

	CustomTooltip = ({ payload, label, active }) => {
		if (active) {
		  return (
			<div style={{
				backgroundColor: 'white',
				border: '2px solid transparent',
				borderRadius:'5px',
				backgroundColor: '#2488f7',
				boxShadow: '2px 2px 5px #3B3C40'
			}}>
			  	<p
					className="content"
					style={{
						margin:'10px',
						fontWeight: 'bold',
						color:'#FFFFFF'
					}}
				>
				  	{`Qtd. de "${payload[0].payload.name}": ${payload[0].payload.value}`}
			  </p>
			</div>
		  );
		}
		return null;
	}

	CustomTooltip2 = ({ payload, label, active }) => {
		if (active) {
		  return (
			<div style={{
				backgroundColor: 'white',
				border: '2px solid transparent',
				borderRadius:'5px',
				backgroundColor: '#2488f7',
				boxShadow: '2px 2px 5px #3B3C40'
			}}>
			  	<p
					className="content"
					style={{
						margin:'10px',
						fontWeight: 'bold',
						color:'#FFFFFF'
					}}
				>
				  	{`Soma dos valores de "${payload[0].payload.name}": ${payload[0].payload.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
			  </p>
			</div>
		  );
		}
		return null;
	}

	/*<ResponsiveContainer width="100%" height={300}>
		<BarChart
		data={data}
		margin={{top: 15, right: 30, left: 20, bottom: 5}}
		style={{color: '#2488f7', textAlign: 'center'}}
		>
			<CartesianGrid
				strokeDasharray='3 3'
				stroke='#000000'
			/>
			<Tooltip
				labelStyle={{color: 'black', fontWeight: 'bold'}}
				itemStyle={{color: '#2488f7', fontWeight: 'bold'}}
				/>
			<YAxis
				dataKey='value'
				stroke='#000000'
				domain={[0]}
			/>
			<XAxis
				dataKey='date'
				stroke='#000000'
				/>
			<Legend />
			<Bar dataKey="value" fill="#2488f7" name="Quantidade">
				{
					data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} cursor={'pointer'} />)
				}

			</Bar>
		</BarChart>
	</ResponsiveContainer>*/

	render() {
		const {dataItens, totQtd, totVal} = this.props

		this.loadFirst(dataItens)

		return (
			<div>
				{dataItens.length > 0 ?
					<Container fluid className="mt-3">
						<Row className="dash">
							<Col className="border bg-white rounded pt-2 m-1" style={{height:'400px'}}>
								<div className="d-flex flex-row align-items-baseline justify-content-between ">
									<h5>{i18next.t('items.quantity_itens')}</h5>
									<Dropdown >
										<Dropdown.Toggle 
											className="px-2 ms-2 border"
											style={{background:'#fff', color:'#7d7d7d'}}>
											<span className="pe-4">{this.state.selected}</span>
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={async e=>{
													await this.changeSelected('Categorias')
													await this.returnData(dataItens)
											}}>{i18next.t('common.categories')}</Dropdown.Item>
											<Dropdown.Item onClick={async e=>{
												await this.changeSelected('Status')
												await this.returnData(dataItens)
											}}>Status</Dropdown.Item>
											<Dropdown.Item onClick={async e=>{
												await this.changeSelected('Niveis 1')
												await this.returnData(dataItens)
											}}>{i18next.t('common.level')} 1</Dropdown.Item>
											<Dropdown.Item onClick={async e=>{
												await this.changeSelected('Niveis 2')
												await this.returnData(dataItens)
											}}>{i18next.t('common.level')} 2</Dropdown.Item>
											<Dropdown.Item onClick={async e=>{
												await this.changeSelected('Niveis 3')
												await this.returnData(dataItens)
											}}>{i18next.t('common.level')} 3</Dropdown.Item>
											<Dropdown.Item onClick={async e=>{
												await this.changeSelected('Niveis 4')
												await this.returnData(dataItens)
											}}>{i18next.t('common.level')} 4</Dropdown.Item>
											<Dropdown.Item onClick={async e=>{
												await this.changeSelected('Parceiros')
												await this.returnData(dataItens)
											}}>{i18next.t('common.partners')}</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
								
								<div style={{height:'300px'}}>
									<ResponsiveContainer>
										<BarChart
											width={700}
											height={250}
											data={this.state.infoState}
											onMouseMove={this.focusedVerify}
											onMouseOut={this.focusedVerifyOut}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="name" />
											<YAxis />
											<Tooltip content={<this.CustomTooltip/>}/>
											<Bar 
												dataKey="value"
												fill="#8c0b3b"
											>
												{
													this.state.infoState.map((entry, index) => (
														<Cell
															key={`cell-${index}`}
															cursor="pointer"
															fill={(this.state.infoIndex == -1) || (index === this.state.infoIndex) ? 'rgba(36,136,247,1)' : 'rgba(36,136,247,0.2)'}
															radius={5}
														/>
													))
												}
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</div>
								<Row>
									<h6 className="ms-auto m-2 p-1 w-auto" style={{fontFamil:'16px'}}>Total: <span style={{fontSize:'18px', fontFamily:'Poppins', color:'#2488f7'}}>{totQtd} itens</span></h6>
								</Row>
							</Col>
							<Col className="border bg-white rounded pb-5 pt-2 m-1" style={{height:'400px'}}>
								<h6>{i18next.t('items.total_itens')}: <span style={{color:'#2488f7'}}>{this.state.selected}</span></h6>
								<div style={{height:'300px'}}>
									<ResponsiveContainer>
										<BarChart
											height={200}
											data={this.state.infoState2}
											onMouseMove={this.focusedVerify2}
											onMouseOut={this.focusedVerifyOut2}
										>
											<Tooltip content={<this.CustomTooltip2/>}/>
											<Bar 
												dataKey="value"
												fill={'#2488f7'}
											>
												{
													this.state.infoState2.map((entry, index) => (
														<Cell
															key={`cell-${index}`}
															cursor="pointer"
															fill={(this.state.infoIndex2 == -1) || (index === this.state.infoIndex2) ? 'rgba(36,136,247,1)' : 'rgba(36,136,247,0.2)'}
															radius={5}
														/>
													))
												}
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</div>
								<Row>
									<h6 className="ms-auto m-2 p-1 w-auto" style={{fontFamil:'16px'}}>Total: <span style={{fontSize:'18px', fontFamily:'Poppins', color:'#2488f7'}}>{totVal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} itens</span></h6>
								</Row>
							</Col>
						</Row>
					</Container>
				:
					<div
						style={{
							width: '100%',
							textAlign: 'center',
							margin: '20px',
							fontSize: '20px',
							fontWeight: 'bold'
						}}
					>
						{i18next.t('items.no_data')}
					</div>
				}
			</div>
		);
	}
}