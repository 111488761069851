import { useEffect, useState } from 'react';

import { Icon } from '@iconify/react/dist/iconify.js';

import { Stack, Typography } from '@mui/material';

import HalfPieChart from '../../../components/ReCharts/HalfPieChart';

//----------------------------------------------------------------

export default function StatusHalfPieChart({colors, data}){
    return (
        <Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Icon icon="fluent:box-search-16-regular"/>
                <Typography variant="h6">Taxa de Ausentes x Encontrados</Typography>
            </Stack>
            <Stack>
                <HalfPieChart data={data} propsColors={colors}/>
            </Stack>
        </Stack>
    )
}