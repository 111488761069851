export class Impressao {

  constructor(generator) {
    this.watermarker = generator.watermarker ? generator.watermarker : 'Inovacode'
    this.headerLogo = generator.hLogo ? generator.hLogo : ''
    this.PDFOrientation = generator.PDFOrientation ? generator.PDFOrientation : 'portrait'
    this.headerTitle = generator.hTitle
    this.headerDescription = generator.hDescription
    this.headerItems = generator.hItems
    this.imagesLinks = generator.images ? generator.images : {}
    this.imagesCompl = generator.imagesCompl ? generator.imagesCompl : 'false'
    this.dadosParaImpressao = generator.bItems;
    this.footerSignatures = generator.fSignatures
    if(this.headerLogo != '') {
      this.imagesLinks["header_logo"] = this.headerLogo
    }
  }

  getLine() {
    let line =''
    for(let i=0; i<=(this.PDFOrientation == 'portrait' ? 250 : 360); i++) {
      line += '_'
    }
    return line
  }

  async PreparaDocumento() {
    let line = this.getLine()
    const corpoDocumento = await this.CriaCorpoDocumento();
    const documento = this.GerarDocumento(corpoDocumento, line);
    return documento;
  }

  async CriaCorpoDocumento() {
    const header = await this.headerItems.map(item =>{
        return { text: item, bold: true, fontSize: 9, margin: [0, 0, 0, 0] }
    })
    let body = []
    await this.dadosParaImpressao.map(async (prod, indexo) => {
        let arr = await prod.map((column, indexc) => {
          return column.map((itemC, index) => {
            if(itemC && itemC.toString().includes(process.env.REACT_APP_API_URL)){
              return {image: this.imagesLinks[`images_${indexo}`], height: 35, width: 50}
            }else {
              return { text: itemC, fontSize: 8 }
            }
          })
        })
        body.push(arr)
        let result = []
        Object.keys(this.imagesLinks).filter((link) => (link.includes(`images_compl_${indexo}_`))).map((ftcpl) => {
          if(result.length == 0 || result[result.length - 1].length == this.headerItems.length){
            result[result.length] = [{image: this.imagesLinks[ftcpl], height: 35, width: 50}]
          }else{
            result[result.length - 1] = [...result[result.length - 1],{image: this.imagesLinks[ftcpl], height: 35, width: 50}]
          }
        })
        if(result.length > 0 && this.imagesCompl == 'true') {
          if(result[result.length - 1].length < this.headerItems.length) {
            result[result.length - 1] = this.headerItems.map((d,i) => {
              if(result[result.length - 1][i]) {
                return result[result.length - 1][i]
              }else{
                return { text: '', fontSize: 8 }
              }
            })
          }
          result.map((linha) => {
            body.push(linha)
          })
        }
    });

    const lineHeader = [
      {
        text: this.getLine(),
        alignment: 'center',
        fontSize: 5,
        colSpan: this.headerItems.length,
      }
    ];

    let content = [header, lineHeader];
    content = [...content, ...body];
    return content;
  }

  async GerarDocumento(corpoDocumento, line) {
      let widthsItems = this.headerItems.map(() => {
          return "auto"
      })
      let headtitle = this.headerTitle
      let headdescription = this.headerDescription
      let logo = this.headerLogo
      let marginPage = [14, 36, 14, 48]
      marginPage[1] = (logo == '' && headdescription == '') ? 36 : 100
      marginPage[3] = this.footerSignatures.length ? 100 : 48
      let fSignaturesList = this.footerSignatures
      const documento = await {
      pageSize: 'A4',
      pageOrientation: this.PDFOrientation,
      pageMargins: marginPage, // change by desc
      //watermark: {text: this.watermarker, opacity: 0.1, angle: 330},
      header: function () {
        let bH = []
        if(logo != '') {
          bH.push(
            //{image: 'header_logo', height: 80, width: 80}
            {image: logo, height: 80, width: 80}
          )
        }
        let hT = []
        if(headtitle != '') {
          hT.push({ text: headtitle, style: 'reportName' })
        }
        if(headdescription != '') {
          hT.push({ text: headdescription, style: 'description',})
        }
        if(hT) {bH.push(hT)}
        return {
            margin: [14, 12, 14, 0],
            layout: 'noBorders',
            table: {
              widths: (logo == '' ? ['*'] : [80, '*']),
              body: [bH],
            },
          };
      },
      content: [
        {
          layout: 'noBorders',
          table: {              
            headerRows: 1,
            widths: widthsItems,

            body: corpoDocumento
          }
        },
      ],
      //images: {},//this.imagesLinks,
      footer(currentPage, pageCount) {
        let listFooter = [
          {
            layout: 'noBorders',
            margin: [14, 0, 14, 22],
            table: {
              widths: ['auto'],
              body: [
                [
                  {
                    text: line,
                    alignment: 'center',
                    fontSize: 5,
                  },
                ],
                [
                  [
                    {
                      text: `Página ${currentPage.toString()} de ${pageCount}`,
                      fontSize: 7,
                      alignment: 'right',
                      /* horizontal, vertical */
                      margin: [3, 0],
                    },
                    {
                      text: '',//'Inovacode',
                      fontSize: 7,
                      alignment: 'center',
                    },
                  ],
                ],
              ],
            },
          }
        ]
        if(fSignaturesList.length) {
          listFooter.push(
            {
              layout: 'noBorders',
              margin: [14, 0, 14, 22],
              table: {
                widths: fSignaturesList.map(() => ('*')),
                body : [fSignaturesList.map((item, index) => {
                  let linesignatue = item
                  let linename = ''
                  for(let i = 0;i < linesignatue.length; i++) {
                    linename += '__'
                  }
                  return ([
                    [
                      {text: linename, alignment: 'center', fontSize: 7},
                      {text: item, alignment: 'center', fontSize: 7}
                    ]
                  ])
                })],
              },
            }
          )
        }
        return listFooter
      },
    styles: {
      reportName: {
        fontSize: 9,
        bold: true,
        alignment: 'center',
        margin: [0, 4, 0, 0],
      },
      description: {
        fontSize: 9,
        bold: false,
        alignment: 'justify',
        margin: [0, 4, 0, 0],
      }
    },

  };
    return documento;
  }
}