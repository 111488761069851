import React, {useState, useEffect} from 'react'
import {useNavigate, useParams } from 'react-router-dom'

// componentes
import api from '../../services/api'
import LoadingPage from '../../components/loading/LoadingPage';

// estilos
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './style.css'
import logoInova from '../../assets/logoinova.png';
import logo from '../../assets/logo.webp';

//packages
import { useTranslation } from 'react-i18next'
import emailjs from 'emailjs-com'
import Swal from 'sweetalert2'

import secureLocalStorage from 'react-secure-storage';
import dayjs from 'dayjs';


// ----------------------------------------------------------------

export default function Login(){
    const {token, id_conta} = useParams()
    const history = useNavigate()
    const { t } = useTranslation();
    //
    const [loading, setLoading] = useState(false)
    const [recoverLoading, setRecoverLoading] = useState(false)    
    const [userLogin, setUserLogin] = useState("")
    const [passLogin, setPassLogin] = useState("")
    
    useEffect(() => {        
        if(token){
            tokenAuthentication()
        }
    },[])

    useEffect(() => {
        setLoading(true)
        if(sessionStorage.getItem('idConta')){
            history('/home')
        }
        setLoading(false)
    },[])

    function Auth(e){
        e.preventDefault()
        if(userLogin !== "" && passLogin !=="") {
            setLoading(true)
            const user = {
                login: userLogin,
                senha: passLogin,
                tipo: "BO"
            }
            api.post(`/login`, user).then(
                response => {
                    let User = response.data

                    // Store basic user information
                    sessionStorage.setItem('idConta', User.id_conta)
                    sessionStorage.setItem('idUser', User._id)
                    User.perfil === '' ? sessionStorage.setItem('perfil', 'admin') : sessionStorage.setItem('perfil', User.perfil)
                    if(User.nome){
                        sessionStorage.setItem('username', User.nome)
                    }

                    // Seta pra disconectar automaticamente para daqui um dia
                    let disconnect = dayjs().add(1, 'day').format()
                    if (User.id_conta === "Mrz2ue7F2") { // Tratativa de desconect da CBA
                        if(dayjs().get('hour') < 18){
                            disconnect = dayjs().set('hour', 18).set('minute', 0).set('second', 0).set('millisecond', 0).format()
                        }else{
                            disconnect = dayjs().add(1, 'day').set('hour', 18).set('minute', 0).set('second', 0).set('millisecond', 0).format()
                        }
                    }
                    sessionStorage.setItem('disconnect', disconnect) 

                    // Store access levels if available
                    if(User.niveis_acesso.length > 0){
                        if(User?.niveis_acesso[0]?.niveis1[0]?.id_nivel_loc1?.length){
                            sessionStorage.setItem('nivel1', User.niveis_acesso[0].niveis1[0].id_nivel_loc1)
                        }
                        if(User?.niveis_acesso[0]?.niveis2[0]?.id_nivel_loc2?.length){
                            sessionStorage.setItem('nivel2', User.niveis_acesso[0].niveis2[0].id_nivel_loc2)
                        }
                        if(User?.niveis_acesso[0]?.niveis3[0]?.id_nivel_loc3?.length){
                            sessionStorage.setItem('nivel3', User.niveis_acesso[0].niveis3[0].id_nivel_loc3)
                        }
                        if(User?.niveis_acesso[0]?.niveis4[0]?.id_nivel_loc4?.length){
                            sessionStorage.setItem('nivel4', User.niveis_acesso[0].niveis4[0].id_nivel_loc4)
                        }
                    }

                    // Store custom navbar if available
                    if(User.modulos_acesso?.length && User.modulos_acesso[0] !== null) {
                        sessionStorage.setItem('menu', JSON.stringify([{custom: Object.keys(User.modulos_acesso[0]).filter(item => item !== '_id')}]))
                    }
                    
                    // Store personalized profile permissions if available
                    if(User.perfil_personalizavel.length > 0){
                        if(User.perfil_personalizavel[0].permissoes[0].itens){
                            sessionStorage.setItem('filterView', User.perfil_personalizavel[0].permissoes[0].itens.visualizar)
                            sessionStorage.setItem('filterCreate', User.perfil_personalizavel[0].permissoes[0].itens.criar)
                            sessionStorage.setItem('filterEdit', User.perfil_personalizavel[0].permissoes[0].itens.editar)
                            sessionStorage.setItem('filterExclude', User.perfil_personalizavel[0].permissoes[0].itens.excluir)
                        }
                        if(User.perfil_personalizavel[0].permissoes[1]){
                            const permissions = User.perfil_personalizavel[0].permissoes[1].itens
                            if(permissions.criar === false){
                                sessionStorage.setItem('cantCreate', true)
                            }
                            if(permissions.editar === false){
                                sessionStorage.setItem('cantEdit', true)
                            }
                            if(permissions.excluir === false){
                                sessionStorage.setItem('cantDelete', true)
                            }
                        }
                    }
                    
                    secureLocalStorage.setItem('userDetails', User) 

                    api.get(`/usuario/${User.id_conta}/*/*/*/*`)
                    .then(
                        response=>{
                            // Store custom navbar if available
                            const res_data = response.data[0]
                            
                            if(res_data?.modulos_acesso?.length && res_data.modulos_acesso[0] !== null){
                                console.log(res_data?.modulos_acesso[0])
                                const custom_menu = res_data.modulos_acesso[0]
                                secureLocalStorage.setItem('custom-menu', custom_menu)
                            }
                            
                            Swal.fire({
                                title: `${t('login.messages.sign_in.success')}`,
                                text: `${t('login.messages.sign_in.welcome')} ${User.nome}`,
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            }).then(
                                result => {                                                
                                    window.location.href = '/home'; 
                                }
                            )
                        },
                        error=>{
                            Swal.fire({
                                title: "Oops... !",
                                text: `${t('login.messages.sign_up.errors.error')}`,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })  
                        }
                    ).finally(()=>{
                        setLoading(false)
                    })
                },
                error => {
                    Swal.fire({
                        title: error.response.status === 403 ? 'Acesso negado': "Oops... !" ,
                        text: error.response.status === 403 ? 'Entre em contato com o suporte': `${t('login.messages.sign_in.login_error')}` ,
                        icon: 'error',
                        showCancelButton: error.response.status === 403 ? true : false,
                        showConfirmButton: error.response.status === 403 ? true : false,
                        confirmButtonColor: "#2BA352",
                        confirmButtonText: 'Abrir chat com suporte.'
                    }).then((result) => {
                        if (result?.isConfirmed) {
                            window.location.href = "https://wa.me/5511942316933";
                        }
                    });  
                    setLoading(false)
                }
            )
        }else {
            Swal.fire({
                title: "Oops... !",
                text: `${t('login.messages.sign_in.missing')}`,
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })  
        }
    }

    function tokenAuthentication(e){
        setLoading(true)
        api.post(`/login/authcba`, null, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'id_conta': id_conta

            }
        }).then(
            response => {
                let User = response.data

                // Store basic user information
                sessionStorage.setItem('idConta', User.id_conta)
                sessionStorage.setItem('idUser', User._id)
                User.perfil === '' ? sessionStorage.setItem('perfil', 'admin') : sessionStorage.setItem('perfil', User.perfil)

                // Set disconnection date (2 days from now)
                const disconnect = new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000))
                secureLocalStorage.setItem('ks:u', {disconnect: disconnect}) // armazena informações criptografadas

                // Store username if available
                if(User.nome){
                    sessionStorage.setItem('username', User.nome)
                }

                // Store access levels if available
                if(User.niveis_acesso.length > 0){
                    if(User.niveis_acesso[0].niveis1[0].id_nivel_loc1.length > 1){
                        sessionStorage.setItem('nivel1', User.niveis_acesso[0].niveis1[0].id_nivel_loc1)
                    }
                    if(User.niveis_acesso[0].niveis2[0].id_nivel_loc2.length > 1){
                        sessionStorage.setItem('nivel2', User.niveis_acesso[0].niveis2[0].id_nivel_loc2)
                    }
                    if(User.niveis_acesso[0].niveis3[0].id_nivel_loc3.length > 1){
                        sessionStorage.setItem('nivel3', User.niveis_acesso[0].niveis3[0].id_nivel_loc3)
                    }
                    if(User.niveis_acesso[0].niveis4[0].id_nivel_loc4.length > 1){
                        sessionStorage.setItem('nivel4', User.niveis_acesso[0].niveis4[0].id_nivel_loc4)
                    }
                }

                // Store custom navbar if available
                if(User.modulos_acesso?.length && User.modulos_acesso[0] !== null) {
                    sessionStorage.setItem('menu', JSON.stringify([{custom: Object.keys(User.modulos_acesso[0]).filter(item => item !== '_id')}]))
                }
                
                // Store personalized profile permissions if available
                if(User.perfil_personalizavel.length > 0){
                    if(User.perfil_personalizavel[0].permissoes[0].itens){
                        sessionStorage.setItem('filterView', User.perfil_personalizavel[0].permissoes[0].itens.visualizar)
                        sessionStorage.setItem('filterCreate', User.perfil_personalizavel[0].permissoes[0].itens.criar)
                        sessionStorage.setItem('filterEdit', User.perfil_personalizavel[0].permissoes[0].itens.editar)
                        sessionStorage.setItem('filterExclude', User.perfil_personalizavel[0].permissoes[0].itens.excluir)
                    }
                }
                
                Swal.fire({
                    title: `${t('login.messages.sign_in.success')}`,
                    text: `${t('login.messages.sign_in.welcome')} ${User.nome}`,
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                }).then(
                    result => {                                                
                        setLoading(false)
                        history('/home')
                    },
                    result => {                                                
                        Swal.fire({
                            title: "Oops... !",
                            text: `${t('login.messages.sign_up.errors.error')}`,
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })  
                    }
                )
            },
            error => {
                Swal.fire({
                    title: error.response.status === 403 ? 'Acesso negado': "Oops... !" ,
                    text: error.response.status === 403 ? 'Entre em contato com o suporte': `${t('login.messages.sign_in.login_error')}` ,
                    icon: 'error',
                    showCancelButton: error.response.status === 403 ? true : false,
                    showConfirmButton: error.response.status === 403 ? true : false,
                    confirmButtonColor: "#2BA352",
                    confirmButtonText: 'Abrir chat com suporte.'
                }).then((result) => {
                    if (result?.isConfirmed) {
                        window.location.href = "https://wa.me/5511942316933";
                    }
                });  
                setLoading(false)
            }
        )
    }

    function RecoverFunc() {
        Swal.fire({
            title: `${t('login.messages.password_recover.title')}`,
            html: `<p style="text-align:left;">${t('login.messages.password_recover.text')}</p>`,
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: `${t('login.messages.password_recover.send')}`,
            cancelButtonText: `${t('login.messages.password_recover.close')}`,
            preConfirm: (logRecover) => {
                if(logRecover !== '') {
                    setRecoverLoading(true)
                    api.get(`usuario/*/*/${logRecover}/*/*`).then(
                        response => {
                            if(response.data[0] && response.data[0].id_conta) {
                                api.get(`/conta/${response.data[0].id_conta}/*/*/*`).then(
                                    res => {
                                        const info = {
                                            userLogin: response.data[0].login,
                                            passLogin: response.data[0].senha,
                                            mainEmail: res.data[0].email
                                        }
                                        emailjs.send(process.env.REACT_APP_SERVICE_ID , process.env.REACT_APP_TEMPLATE_ID, info, process.env.REACT_APP_USER_ID)
                                        .then(
                                            result => {
                                                Swal.fire({
                                                    title: `${t('login.messages.password_recover.success')}`,
                                                    text: `${t('login.messages.password_recover.success_text')} ${res.data[0].email}`,
                                                    icon: 'success',
                                                    showConfirmButton: true
                                                })
                                                setRecoverLoading(false)
                                            },
                                            error => {
                                                Swal.fire({
                                                    title: "Oops... !",
                                                    html: `${t('login.messages.password_recover.error')}<br/>${res.data[0].email}`,
                                                    icon: 'error',
                                                    showConfirmButton: false,
                                                    timerProgressBar: true,
                                                    timer: '2500'
                                                })
                                                setRecoverLoading(false)
                                            }
                                        )
                                    }
                                )
                            }else {
                                Swal.fire({
                                    title: "Oops... !",
                                    text: `${t('login.messages.password_recover.error_catch')}`,
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                setRecoverLoading(false)
                            }
                        },
                        response => {
                            Swal.fire({
                                title: "Oops... !",
                                text: `${t('login.messages.password_recover.error_catch')}`,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            setRecoverLoading(false)
                        }
                    )
                }else {
                    Swal.fire({
                        title: "Oops... !",
                        text: `${t('login.messages.password_recover.error_missing')}`,
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                    setRecoverLoading(false)
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
        
    }

    return (
        <>
            {loading || recoverLoading ?
                <LoadingPage/>
            : ''}
            <Container fluid>
                <Row className="vh-100">
                    <Col sm={7} className="d-flex justify-content-center align-items-center flex-column">
                        <Col sm={12} md={5}>
                            <Form onSubmit={e => {Auth(e)}}>
                                <Row className="justify-content-center"><img src={logoInova} alt="Logo InovaOne" style={{width:'190px'}}/></Row>
                                <h3 className='py-3'>{t('login.sign_in')}</h3>
                                <Form.Group className="mb-3 text-secondary" controlId="formGroupEmail">
                                    <Form.Label>{t('login.placeholder.login')}</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        value={userLogin}
                                        onChange={e => setUserLogin(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 text-secondary" controlId="formGroupPassword">
                                    <Form.Label>{t('login.placeholder.password')}</Form.Label>
                                    <Form.Control 
                                        type="password"
                                        value={passLogin}
                                        onChange={e => setPassLogin(e.target.value)}
                                    />
                                </Form.Group>
                                <div className="d-grid gap-2 py-3">
                                    <Button variant="custom-primary" type='submit' value={'ACESSAR'}>
                                        {t('login.buttons.login')}
                                    </Button>
                                    <div 
                                        onClick={e=> {RecoverFunc()}} 
                                        className="text-end" 
                                        style={{color: '#4362E7', cursor: 'pointer'}}>
                                        {t('login.forgot_password')}
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    </Col>   
                    <Col className="background register">
                        <h2 className="line">{t('login.welcome')}</h2>
                        <h5 className="pt-5 pb-3">{t('login.register_text')}</h5>
                        <Button variant="outline-light rounded-pill px-3" onClick={e=>history('/register')}>
                            {t('login.buttons.create_account')}
                        </Button>
                        <div className="position-absolute top-0 end-0 p-3" >
                            <img alt="Logo marca da empresa InovaCode em branco" src={logo} style={{width:'160px', height:'30px'}}></img>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}