import { Icon } from "@iconify/react/dist/iconify.js";

import { Button, Grid, Stack, Typography } from "@mui/material";

import { cyan, green, grey, lightBlue, purple, red, yellow } from '@mui/material/colors';

import CategoryBarChart from "./CategoryBarChart";

import StatusPieChart from "./StatusPieChart";

import StatusHalfPieChart from "./StatusHalfPieChart";

import { useState } from "react";

//----------------------------------------------------------------

export default function Dashboard({ totalItens, statusList, IDRegistro, dashItensFound }){
    const color = [ red[400], green[400], purple[400], yellow[600], cyan[400], lightBlue[600] ]  

    const [showItens, setShowItens] = useState(false)

    return(
        <>
            <Grid 
                container
                direction="row"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item md>
                    <StatusPieChart data={statusList} colors={color}/>
                </Grid>
                <Grid item md>
                    <StatusHalfPieChart data={dashItensFound} IDRegistro={IDRegistro} colors={[color[1], color[0]]}/>
                </Grid>
                <Grid item md={6}>
                    {totalItens < 2000 || showItens ? 
                        <CategoryBarChart data={statusList} IDRegistro={IDRegistro} colors={color} totalItens={totalItens}/>
                    : 
                        <>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Icon icon="solar:chart-square-broken" />
                                <Typography variant="h6">Gráfico de Barras</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" sx={{backgroundColor: grey[200], color: grey[600], width: 450, my: 2, padding: 2, borderRadius: 5}} spacing={2}>
                                <Icon icon="flowbite:info-circle-solid" width={36}/>
                                <Stack justifyContent="center" alignItems="start" spacing={2}>
                                    <Typography>A quantidade de itens é muito grande para gerar esse gráfico e pode demorar um pouco. Deseja carregar?</Typography>
                                    <Button variant="outlined" color="dark" size="small" onClick={e=>{setShowItens(true)}}>Carregar</Button>
                                </Stack>
                            </Stack>
                        </>
                        
                    }
                </Grid>
                
                
            </Grid>
        </>
    )
}