import { useEffect, useState } from "react";

import { Box, Grid, MenuItem, Stack, Typography,Select, Backdrop, CircularProgress } from "@mui/material";

import ItemsPieChart from "../graphs/ItemsPieChart";
import useWebSocket from "react-use-websocket";
import ItemsHalfPieChart from "../graphs/ItemsHalfPieChart";

//----------------------------------------------------------------

export default function WidgetStatistics({items, partner, details}){
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState()

    const [type, setType] = useState('id_categoria')

    const [clickedCell, setClickedCell] = useState()

    useEffect(() => {
        setLoading(true)
        formatData()
    }, [type])

    useEffect(() => {
        if(data){
            setLoading(false)
        }
    },[data])

    function formatData(){ 
        const labels = {}
        const value = {}
        items.forEach((item)=>{
            const key = item[type]?._id
            if(key){
                labels[key] = item[type]?.descricao
                value[key] = (value[key] || 0) + 1
            }
        })

        const result = {
            'labels': Object.values(labels),
            'valores': Object.values(value)
        }
        
        setData(result ?? [])
    }

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                spacing={1}
            >
                <Grid item xs> 
                    <Box sx={{p: 3, borderRadius: 5}} className="bg-white shadow-sm">
                        <Stack direction="row" justifyContent="space-between" sx={{mb: 3}}>
                            <Typography variant="h5">Dashboard</Typography>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={type}
                                onChange={e=>{setType(e.target.value)}}
                                sx={{borderRadius: 4}}
                            >
                                <MenuItem value={'id_categoria'}>Categoria</MenuItem>
                                <MenuItem value={'id_nivel_loc_1'}>Nivel 1</MenuItem>
                                <MenuItem value={'id_nivel_loc_2'}>Nivel 2</MenuItem>
                                <MenuItem value={'id_nivel_loc_3'}>Nivel 3</MenuItem>
                                <MenuItem value={'id_nivel_loc_4'}>Nivel 4</MenuItem>
                            </Select>
                        </Stack>
                        <Grid container>
                            <Grid item xs>
                                {!loading && data?.labels ? 
                                    <ItemsPieChart labels={data.labels} values={data.valores} onDataPointSelect={e=>{setClickedCell(e)}} 
                                    />
                                : ''}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}