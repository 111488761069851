export default function MuiTable(theme) {
  return {
      MuiTableHead: {
          styleOverrides: {
              root: {
                  '& .MuiTableCell-head': {
                      fontSize: '0.9rem',
                      textTransform: 'uppercase',
                      color: theme.palette.grey[500],
                      backgroundColor: theme.palette.grey[200]
                  },
              },
          },
      },
  };
}
