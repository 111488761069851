
import { useEffect, useState } from "react"

import secureLocalStorage from "react-secure-storage"

import { t } from "i18next"

import api from "../../services/api"

import Alert from "../../components/ui/Alert"

import { Backdrop, Button, CircularProgress, FormGroup, FormLabel, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"

//----------------------------------------------------------------

export default function Reader({userData}){
    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState(false)

    const [data, setData] = useState([])

    const [model, setModel] = useState('')

    const [serial, setSerial] = useState('')

    const [RSSI, setRSSI] = useState('')

    const [qtdFantasma, setQtdFantasma] = useState('')

    const [potencia, setPotencia] = useState('')

    const [session, setSession] = useState('')

    const [state, setState] = useState('')

    const [printer, setPrinter] = useState('')

    useEffect(()=>{
        getModel()
    },[])

    function getModel(){
        setData(userData)
        setModel(userData?.leitor_modelo)
        setSerial(userData?.leitor_serial)
        setRSSI(userData?.limite_rssi ?? 0)
        setQtdFantasma(userData?.quantidade_fantasma ? userData?.quantidade_fantasma : 0)
        setPotencia(userData?.leitor_potencia)
        setSession(userData?.leitor_secao)
        setState(userData?.leitor_estado)
        setPrinter(userData?.impressora_serial)
    }

    function handleSave(){
        const update = data
        update.id_conta = sessionStorage.getItem('idConta');
        update.leitor_modelo = model
        update.leitor_serial = serial
        update.limite_rssi = RSSI
        update.quantidade_fantasma = qtdFantasma
        update.leitor_potencia = potencia
        update.leitor_secao = session
        update.leitor_estado = state
        update.impressora_serial = printer

        let option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        setLoading(true)

        api.post('/conta_config', [update], option
        ).then(
            response => {
                setLoading(false)
                setMessage({
                    "type": "success",
                    "message": t('messages.success_save'),
                    "title": t('messages.success')
                })
            },
            error => {
                setLoading(false)
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        )
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Typography variant="h2">{t('settings.config_readers')}</Typography>

            <Typography variant="subtitle">{t('settings.config_readers_subtitle')}</Typography>

            <Grid container spacing={3} xs={12} md={10} sx={{mt:1}}>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{t('settings.model')}</FormLabel>
                        <Select size="small" value={model} onChange={e=>{setModel(e.target.value)}} fullWidth>
                            <MenuItem value=""></MenuItem>
                            <MenuItem value="blu">Bluebird</MenuItem>
                            <MenuItem value="dot">Dot 900</MenuItem>
                            <MenuItem value="301">i300</MenuItem>
                            <MenuItem value="bu1">Clock B1</MenuItem>
                            <MenuItem value="tsl">Tsl 1128</MenuItem>
                            <MenuItem value="imp">Impinj</MenuItem>
                            <MenuItem value="inn">InovaCode</MenuItem>
                            <MenuItem value="mdl">Midleware</MenuItem>
                            <MenuItem value="nfc">NFC</MenuItem>  
                            <MenuItem value="ebc">eBeacon</MenuItem> 
                            <MenuItem value="ter">Terminal</MenuItem> 
                            <MenuItem value="csv">CSV</MenuItem>
                        </Select>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>Serial</FormLabel>
                        <TextField size="small" value={serial} onChange={e=>{setSerial(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{t('settings.rssi_limit')}</FormLabel>
                        <TextField type="number" size="small" value={RSSI} onChange={e=>{setRSSI(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{t('settings.ghost_count')}</FormLabel>
                        <TextField type="number" size="small" value={qtdFantasma} onChange={e=>{setQtdFantasma(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{t('settings.potency')}</FormLabel>
                        <Select size="small" value={potencia} onChange={e=>{setPotencia(e.target.value)}} fullWidth>
                            <MenuItem value=""></MenuItem>
                            <MenuItem value="5">{t('settings.low')}</MenuItem>
                            <MenuItem value="20">{t('settings.medium')}</MenuItem>
                            <MenuItem value="30">{t('settings.high')}</MenuItem>
                        </Select>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{t('settings.section')}</FormLabel>
                        <Select size="small" value={session} onChange={e=>{setSession(e.target.value)}} fullWidth>
                            <MenuItem value=""></MenuItem>
                            <MenuItem value="0">{t('settings.section')} 0</MenuItem>
                            <MenuItem value="1">{t('settings.section')} 1</MenuItem>
                            <MenuItem value="2">{t('settings.section')} 2</MenuItem>
                            <MenuItem value="3">{t('settings.section')} 3</MenuItem>
                        </Select>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormGroup>
                        <FormLabel>{t('settings.state')}</FormLabel>
                        <Select size="small" value={state} onChange={e=>{setState(e.target.value)}} fullWidth>
                            <MenuItem value="">Sem conversão</MenuItem>
                            <MenuItem value="0">A</MenuItem>
                            <MenuItem value="1">A~B</MenuItem>
                            <MenuItem value="2">B</MenuItem>
                        </Select>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormGroup>
                        <FormLabel>{t('common.printer')}</FormLabel>
                        <TextField size="small" value={printer} onChange={e=>{setPrinter(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="error" onClick={e=>{handleSave()}} sx={{borderRadius: 5}}>{t('actions.save')}</Button>
                </Grid>
            </Grid>

            {message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{setMessage(e)}}
                />
            : ''}
        </>
    )
}