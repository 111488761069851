import React, { PureComponent } from 'react';

import { PieChart, Pie, Sector, ResponsiveContainer, Cell, Legend } from 'recharts';

import { cyan, green, lightBlue, purple, red, yellow } from '@mui/material/colors';

import { Stack } from '@mui/material';

import { Icon } from '@iconify/react/dist/iconify.js';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Total: ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/pie-chart-with-customized-active-shape-y93si';

  state = {
    activeIndex: 0,
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  onPieOut = (data, index) => {
		this.setState({
			activeIndex: -1,
		})
	}

  render() {
	const { data, propsColors, width = 600, height = 280} = this.props

	const colors = propsColors || [ red[400], green[400], purple[400], yellow[600], cyan[400], lightBlue[600] ]

    return (
      <Stack direction="row">
          <Stack sx={{marginTop: 3}}>
              {[...data]
                .map((status, i) => (
                  <Stack 
                      key={i}
                      direction="row" 
                      alignItems="center" 
                      spacing={2} 
                      sx={{ cursor: 'pointer', flexWrap: "nowrap" }}
                      onClick={() => { this.setState({ activeIndex: i }) }}
                  >
                      <Icon icon="pepicons-pop:square-filled" color={colors[i]} />
                      {status.name}:{status.value}
                  </Stack>
                )
              )}
          </Stack>        
        <ResponsiveContainer width={width} height={height}>
          <PieChart width={width} height={"80%"}>
            <Pie
              activeIndex={this.state.activeIndex}
              activeShape={renderActiveShape}
              data={data}
              innerRadius={height / 4}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={this.onPieEnter}
              onMouseOut={this.onPieOut}
            >
              {
                data.map((entry, index) => 
                  <Cell key={`cell-${index}`} fill={colors[index]} cursor={'pointer'} />
                )
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Stack> 
    );
  }
}
