export class Impressao {

  constructor(generator) {
    this.watermarker = generator.watermarker ? generator.watermarker : 'Inovacode'
    this.headerLogo = generator.hLogo ? generator.hLogo : ''
    this.PDFOrientation = generator.PDFOrientation ? generator.PDFOrientation : 'portrait'
    this.headerTitle = generator.hTitle
    this.headerItems = generator.hItems
    this.imagesLinks = generator.images ? generator.images : {}
    this.dadosParaImpressao = generator.bItems;
  }  

  getLine() {
    let line =''
    for(let i=0; i<=(this.PDFOrientation == 'portrait' ? 250 : 360); i++) {
      line += '_'
    }
    return line
  }

  async PreparaDocumento() {
    let line = this.getLine()
    const corpoDocumento = this.CriaCorpoDocumento();
    const documento = this.GerarDocumento(corpoDocumento, line);
    return documento;
  }

  CriaCorpoDocumento() {
    const header = this.headerItems.map(item =>{
        return { text: item, bold: true, fontSize: 9, margin: [0, 0, 0, 0] }
    })
    const body = this.dadosParaImpressao.map((prod, indexo) => {
        let arr = prod.map((item, index) => {
            if(this.headerItems[index] == 'IMAGEM'){
              if(item && item != '' && item != '/static/media/sem_foto.b83884cf.png' && item != 'assets/imgs/sem_foto.png') {
                return {image: `images_${indexo}`, height: 35, width: 50}
              }else{
                return { text: 'Sem foto', fontSize: 8 }
              }
            }else {
              return { text: item, fontSize: 8 }
            }
        })
      return arr
    });

    const lineHeader = [
      {
        text: this.getLine(),
        alignment: 'center',
        fontSize: 5,
        colSpan: this.headerItems.length,
      },
      {},
      {},
    ];

    let content = [header, lineHeader];
    content = [...content, ...body];
    return content;
  }

  GerarDocumento(corpoDocumento, line) {
      let widthsItems = this.headerItems.map(() => {
          return "auto"
      })
      let headtitle = this.headerTitle
      let logo = this.headerLogo
      const documento = {
      pageSize: 'A4',
      pageOrientation: this.PDFOrientation,
      pageMargins: [14, 53, 14, 48],
      watermark: {text: this.watermarker, opacity: 0.1, angle: 330},
      header: function () {
        return {
            margin: [14, 12, 14, 0],
            layout: 'noBorders',
            table: {
              widths: (logo == '' ? ['*'] : ['*', '*']),
              body: [                             
                [ 
                  //{image: 'snow', height: 35, width: 50},
                  { text: headtitle, style: 'reportName' }
                ]              
              ],
            },
          };
      },
      content: [
        {
              layout: 'noBorders',
              table: {              
                headerRows: 1,
                widths: widthsItems,

                body: corpoDocumento
              }
            },
      ],
      images: this.imagesLinks,//{ snow: 'http://3.138.64.4:8000/awKHXejox_324062021135257.jpeg' },
    
    footer(currentPage, pageCount) {
      return {
        layout: 'noBorders',
        margin: [14, 0, 14, 22],
        table: {
          widths: ['auto'],
          body: [
            [
              {
                text: line,
                alignment: 'center',
                fontSize: 5,
              },
            ],
            [
              [
                {
                  text: `Página ${currentPage.toString()} de ${pageCount}`,
                  fontSize: 7,
                  alignment: 'right',
                  /* horizontal, vertical */
                  margin: [3, 0],
                },
                {
                  text: 'Inovacode',
                  fontSize: 7,
                  alignment: 'center',
                },
              ],
            ],
          ],
        },
      };
    },
    styles: {
      reportName: {
        fontSize: 9,
        bold: true,
        alignment: 'center',
        margin: [0, 4, 0, 0],
      }
    },

  };
    return documento;
  }
}