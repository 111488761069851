import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

  constructor(props) {
    super(props);
    this.state = {    
      series: props.values,
      options: {
        chart: {
          type: 'donut',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true
            },
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const selectedDataPoint = {
                seriesIndex: config.seriesIndex,
                dataPointIndex: config.dataPointIndex,
                label: this.state.options.labels[config.dataPointIndex],
                value: this.state.series[config.dataPointIndex]
              };
              props.onDataPointSelect(selectedDataPoint);
            }
          }
        },
        
        labels: props.labels,

        theme: {
          monochrome: {
              enabled: true,
              color: '#008EFF',
              shadeTo: 'light',
              shadeIntensity: 0.30
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          },
        },
        legend: {
          position: 'right'
        },
        responsive: [{
          breakpoint: 1441,
          options: {
            legend: {
              show: true,
              floating: false,
              position: 'bottom',
              width: '100%',
            }
          },
        }]
      },
      tooltip: {
        shared: false,
        intersect: true
      }
      
    
    };
  }

  render() {

    return (
      <div className="donut w-100">
        <div className="w-100">
          <Chart options={this.state.options} series={this.state.series} type="donut" width="100%" height='200' />
        </div>
      </div>
    );
  }
}

export default Donut;