import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

  constructor(props) {
    super(props);
    this.state = {
    
      series: props.values,
      options: {
        chart: {
          width: '100%',
          type: 'donut',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true
            },
          }
        },
        labels: props.data,
        
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          },
        },
        legend: {
          show: true,
          floating: false,
          position: 'right',
          horizontalAlign: 'rigth',
          width: 250,

        },
        responsive: [{
          breakpoint: 1441,
          options: {
            legend: {
              show: true,
              floating: false,
              position: 'bottom',
              width: '100%',
            }
          },
        }]
      },
      
    
    };
  }

  render() {

    return (
      <div className="donut p-3 w-100">
        <div className="w-100">
          <Chart options={this.state.options} series={this.state.series} type="donut" width="100%" height='400' />
        </div>
      </div>
    );
  }
}

export default Donut;