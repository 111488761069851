import React, {useState, useEffect} from 'react'
import ReactLoading from 'react-loading'
import {FaFileCsv, FaFilePdf} from 'react-icons/fa'

import api from '../../services/api'
import Menu from '../../components/Menu'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {Impressao} from '../../services/pdImage'
import {CSVLink} from 'react-csv'
import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, CloseButton } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function Logs(params) {
    const { t } = useTranslation();
    const [logs, setLogs] = useState([])
    const [categoriaList ,setCategoriaList] = useState([])
    const [categoryFilter ,setCategoryFilter] = useState('*')
    const [tagFilter ,setTagFilter] = useState('')
    const [dataInicio, setDataInicio] = useState(dates(31, '-'))
    const [dataFim, setDataFim] = useState(dates(1, '+'))
    const [loading, setLoading] = useState(false)
    const [logFilter, setLogFilter] = useState('Todas')

    const fotoNone = ['', '/static/media/sem_foto.b83884cf.png', 'assets/imgs/sem_foto.png']

    useEffect(() => {
        getLogs()
    }, [dataInicio, dataFim])

    useEffect(() => {
        categoriaGet()
    }, [])

    function getLogs() {
        setLoading(true)
        api.get(`/registro/${sessionStorage.getItem('idConta')}/*/*/${dataInicio}/${dataFim}/*/*/*`).then(
            response => {
                let regList = response.data.filter((item) => {
                    if(item.modo == 'l') {
                        return item
                    }
                }).map((item) => {
                    return item._id
                })
                api.get(`/registro_tag_date/${sessionStorage.getItem('idConta')}/${dataInicio}/${dataFim}`).then(
                    response2 => {
                        let logs = response2.data.filter((item) => {
                            let rssi = ['-00', '-10', '-20', '-30']
                            if(rssi.includes(item.latitude) && regList.includes(item.id_registro)) {
                                return item
                            }
                        })
                        logs = logs.reverse()
                        setLogs(logs)
                        setLoading(false)
                    }
                )
            }
        )
    }

    function categoriaGet() {
        api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setCategoriaList(response.data)})
    }

    function dates(days, direction) {
        let dt   = new Date()
        if(direction == '+') {
            dt = new Date(dt.getTime() + (days * 24 * 60 * 60 * 1000))
        }
        else if(direction == '-') {
            dt = new Date(dt.getTime() - (days * 24 * 60 * 60 * 1000))
        }
        return (dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2))
    }

    function colorGet(stat) {
        let color = ''
        if(stat == "Timeout") {
            color = '#A33BBF'
        }else if(stat == "Login") {
            color = '#4EA90B'
        }else if(stat == "Logout") {
            color = '#B40C0C'
        }else if(stat == "Heart Beat") {
            color = '#26C6DA'
        }
        return color
    }

    function formatDatecsv(data) {
        let dt = new Date(data)
        let dt_reg = ("0" + dt.getDate()).slice(-2) + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + dt.getFullYear()
        dt_reg += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
        return dt_reg
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs
    const visualizarImpressao = async () => {
        let pdfFilter = await logs.filter(item => {
            if(
                (categoryFilter == '*' || item.descricao == categoryFilter) &&
                (tagFilter == '' || item.tag.search(tagFilter) > -1)
            ) {
                return item
            }
        }).map((item, index) => {
            let info = ""
            let dtstatus = ['', null]
            if(item.latitude == '-10') {
                info = 'Login'
            }else if(item.latitude == '-20') {
                info = 'Logout'
            }else if(item.latitude == '-30') {
                info = 'Timeout'
            }else if(item.latitude == '-00') {
                info = 'Heart Beat'
            }
            if(logFilter == 'Todas' || logFilter == info){
                return [
                    index + 1,
                    item.descricao + '\n' + item.tag,
                    info,
                    !dtstatus.includes(item.dt_leitura) ? formatDatecsv(item.dt_leitura): '',
                    item.foto
                ]
            }
        })

        let linkeres = {}

        await logs.map((item, index) => {
            if(item.foto && !fotoNone.includes(item.foto))
            linkeres[`images_${index}`] = `http://3.138.64.4:8000/${item.foto}`
        })

        const classeImpressao = await new Impressao(
            {
            hTitle: "Itens Coletas",
            hItems: [
                "#",
                "TAG",
                "LOG",
                "DATA",
                "IMAGEM"
            ],
            images: linkeres,
            bItems: pdfFilter.filter((item) => {
                if(item){
                    return item
                }
            })
        });
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }

    function csv() {
        let head = [
            "CATEGORIA",
            "TAG",
            "LOG",
            "DATA"
        ]
        let body = logs.filter(item => {
            if(
                (categoryFilter == '*' || item.descricao == categoryFilter) &&
                (tagFilter == '' || item.tag.search(tagFilter) > -1)
            ) {
                return item
            }
        }).map((item, index) => {
            let info = ""
            let dtstatus = ['', null]
            if(item.latitude == '-10') {
                info = 'Login'
            }else if(item.latitude == '-20') {
                info = 'Logout'
            }else if(item.latitude == '-30') {
                info = 'Timeout'
            }else if(item.latitude == '-00') {
                info = 'Heart Beat'
            }
            if(logFilter == 'Todas' || logFilter == info){
                return [
                    item.descricao,
                    item.tag,
                    info,
                    !dtstatus.includes(item.dt_leitura) ? formatDatecsv(item.dt_leitura): '',
                ]
            }
        })
        let body2 = body.filter((item) => {
            if(item){
                return item
            }
        })
        if(body2.length){
            return [...body2]
        }else{
            return [['']]
        }
    }

    return (
        <div    >
            <div id="background">
                <div >
                    <Container fluid>
                        <Row>
                            <Col><h3>Logs</h3></Col>
                            <Col className="container-btn">
                                <Button variant="custom-primary" onClick={e => {
                                    visualizarImpressao()}
                                }><FaFilePdf className="me-2"/>{t('actions.export')} PDF</Button>
                                <CSVLink className="csv" data={csv()} separator={';'} filename={'coletas.csv'}>
                                    <Button variant="custom-primary"><FaFileCsv className="me-2"/> {t('actions.export')} CSV</Button>
                                </CSVLink>
                            </Col>
                        </Row>
                        <div className='bg-white rounded border p-3'>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{t('common.categories')}</Form.Label>
                                    <Form.Select
                                        value={categoryFilter}
                                        onChange={e=>{setCategoryFilter(e.target.value)}}
                                    >
                                        <option value={'*'}>Todas</option>
                                        <option value={'SEM DESCRICAO'}>SEM DESCRICAO</option>
                                        {categoriaList.map((item, index) => (
                                            <option key={index} value={item.descricao}>{item.descricao}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>TAG</Form.Label>
                                    <Form.Control
                                        value={tagFilter}
                                        onChange={e => {setTagFilter(e.target.value)}}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{t('common.date')}</Form.Label>
                                    <div className="date">
                                        <Form.Control 
                                            type="date"
                                            value={dataInicio}
                                            onChange={e => {setDataInicio(e.target.value)}}
                                        />
                                        <Form.Control
                                            type="date"
                                            value={dataFim}
                                            onChange={e => {setDataFim(e.target.value)}}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Log</Form.Label>
                                    <Form.Select
                                        value={logFilter}
                                        onChange={e=>{setLogFilter(e.target.value)}}
                                    >
                                        <option value={'Todas'}>TODAS</option>
                                        <option value={'Login'}>Login</option>
                                        <option value={'Logout'}>Logout</option>
                                        <option value={'Timeout'}>Timeout</option>
                                        <option value={'Heart Beat'}>Heart Beat</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <div className="list">
                                {loading ?
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                    </div>
                                :
                                    <Table>
                                        <thead>
                                            <tr id="header">
                                                <th>#</th>
                                                <th>CATEGORIA</th>
                                                <th>TAG</th>
                                                <th>LOG</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {logs.filter(item => {
                                                if(
                                                    (categoryFilter == '*' || item.descricao == categoryFilter) &&
                                                    (tagFilter == '' || item.tag.search(tagFilter) > -1)
                                                ) {
                                                    return item
                                                }
                                            }).map((item, index) => {
                                                let dtstatus = ['', null]
                                                let info = ''
                                                if(item.latitude == '-10') {
                                                    info = 'Login'
                                                }else if(item.latitude == '-20') {
                                                    info = 'Logout'
                                                }else if(item.latitude == '-30') {
                                                    info = 'Timeout'
                                                }else if(item.latitude == '-00') {
                                                    info = 'Heart Beat'
                                                }
                                                if(logFilter == 'Todas' || logFilter == info) {
                                                    return (
                                                        <tr key={index}>
                                                            <th>{index + 1}</th>
                                                            <th>{item.descricao}</th>
                                                            <th>{item.tag}</th>
                                                            <th>
                                                                <div style={{padding: '10px', background: colorGet(info), width: '120px', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{info}</div>
                                                                <div>{!dtstatus.includes(item.dt_leitura) ? formatDatecsv(item.dt_leitura): ''}</div>
                                                            </th>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </Table>
                                }   
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    )
}