import { useEffect, useState } from "react";

import { t } from "i18next";

import Loading from "../../components/ui/Loading";

import api from "../../services/api";

import { Box, Button, Paper, Stack, styled, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js"

import { DataGrid } from "@mui/x-data-grid";

import { ptBR } from '@mui/x-data-grid/locales';

import { useNavigate } from "react-router-dom";
import Alert from "../../components/ui/Alert";

// ----------------------------------------------------------   

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: '100%',
});

const columns = [
    { 
        field: 'id', 
        headerName: 'ID', 
        width: 90
    },
    {
      field: 'categoria',
      headerName: 'Categoria',
      width: 200
    },
    {
        field: 'ean',
        headerName: 'EAN',
        width: 200   
    },
    {
        field: 'quantidade',
        headerName: 'Quantidade', 
        editable: true,
        width: 200  
    },
    {
        field: 'quantidade_lida',
        headerName: 'Quantidade lida', 
        editable: true,
        width: 200  
    },
    {
        field: 'quantidade_auditada',
        headerName: 'Quantidade auditada', 
        editable: true,
        width: 200
    },
    {
        field: 'observacao',
        headerName: 'Observacao',
        width: 300, 
        editable: true  
    },
    {
        field: 'isValid',
        headerName: 'Categoria cadastrada',
        width: 300,
        renderCell: (params) => (<Icon color={params.row.isValid ? "#4caf50" : "#f44336"} icon={params.row.isValid ? "pajamas:check-xs": "pajamas:close"}/>)  
    }
];

export default function OrderImport(){
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [categorias, setCategorias] = useState([])

    const [upcomingCategorias, setUpcomingCategorias] = useState([])

    const [orders, setOrders] = useState([])

    const [message, setMessage] = useState()

    useEffect(()=>{
        function getcategorias() {
            setLoading(true)
            api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/1`, {})
            .then(
                response => {
                    setCategorias(response.data.slice(0, -1))
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        }

        getcategorias()
    }, [])

    async function handleUpload(file){
        var XMLParser = await require('react-xml-parser');
        const xml = new XMLParser().parseFromString(await file.text());
        const newOrders = orders.length ? orders : []
        const upCategorias = upcomingCategorias || []// Categorias não cadastradas

        for (var i = 0; i < xml.getElementsByTagName('xProd').length; i++) {
            const xean = xml.getElementsByTagName('cEAN')[i].value
            const xdesc = xml.getElementsByTagName('xProd')[i].value
            const getCategoria = categorias.find(c => c.descricao === xdesc || c.ean === xean)
            const order = {
                id: i,
                id_conta: sessionStorage.getItem('idConta'),
                id_categoria: getCategoria?._id || getID(),
                categoria: getCategoria?.descricao || xdesc,
                ean: getCategoria?.ean || xean,
                quantidade: 0,
                quantidade_lida: 0,
                quantidade_auditada: 0, 
                observacao: "",
                isValid: getCategoria ? true : false
            }
            newOrders.push(order)
            if(!getCategoria){
                upCategorias.push({
                    _id: order.id_categoria,
                    descricao: order.categoria,
                    ean: order.ean,
                    id_conta: order.id_conta
                })
            }
        }

        setCategorias(upCategorias)
        setOrders(newOrders)
    }

    function getID(){	
        return 'xxxxxxxx-yxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })
    }

    function randomIdentificador(){
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var id = '';
        for (var i = 0; i < 6; i++) {
            var randomIndex = Math.floor(Math.random() * characters.length);
            id += characters[randomIndex];
        }
        return id;
    }

    function handleSave(){
        if(upcomingCategorias.length){
            importCategorias()
        }else{
            save()
        }
    }

    function save(){
        setLoading(true)
        let option = {headers: { 'Content-Type': ['application/json']}}

        const registro = [{
            "id_conta": sessionStorage.getItem('idConta'),
            "id_usuario": sessionStorage.getItem('idUsuario'),
            "modo": "p",
            "identificador": randomIdentificador(),
            "qtd_ausente": 0,
            "qtd_encontrado": 0,
            "qtd_auditado": 0,
            "qtd_excedente": 0,
            "qtd_incorporado": 0,
            "qtd_outro_ambiente": 0,
            "status": 1
        }]
        api.post(`/registro`, registro, option)
        .then(
            response => {
                const id_registro = response?.data[0]?._id
                const newOrder = orders.map((order, index) => ({
                    ...order,
                    id_registro: id_registro
                }));
                api.post(`/registro_pedido`, newOrder, option)
                .then(
                    response => {
                        setMessage({
                            "type": "success",
                            "title": t('messages.success'),
                            "message": t('settings.clean_base.success_message')
                        })
                        setLoading(false)
                    },
                    error=>{
                        setMessage({
                            "type": "error",
                            "title": t('messages.errors.error'),
                            "message": t('messages.errors.500')
                        })
                    }
                ).finally(
                    () => {
                        setLoading(false)
                    }
                )
            }, 
            error => {

            }
        )  
    }

    function importCategorias(){
        setLoading(true)
        let option = {headers: { 'Content-Type': ['application/json']}}
        api.post(`/itensDef/${sessionStorage.getItem('idConta')}`, upcomingCategorias, option)
        .then(
            response => {
                save()
            },
            error=>{
                setMessage({
                    "type": "error",
                    "title": t('messages.errors.error'),
                    "message": t('messages.errors.500')
                })
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )  
        
    }

    return (
        <>
            <Stack spacing={3}>
                
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h1">Importar arquivo</Typography>
                    <Stack spacing={1} direction="row">
                        <Button variant="outlined" color="dark" onClick={e=> {navigate('/orders')}}>{t('actions.exit')}</Button>
                        <Button variant="contained" onClick={e=> {handleSave()}}>{t('actions.save')}</Button>
                    </Stack>
                </Stack>

                <Stack spacing={3} as={Paper} sx={{borderRadius: 3, p: 3}}>
                    <Box
                        component="label"
                        role={undefined}
                        variant="standard"
                        tabIndex={-1}
                        onDrop={e=>{handleUpload(e.target.files[0])}}
                        sx={{
                            p: 3,
                            height: '100%',
                            border: 1,
                            borderStyle: "dashed",
                            borderColor: "grey.400",
                            color: "grey.500",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <Icon icon="duo-icons:upload-file" width="32"/>
                        <Typography textAlign="center" sx={{mt: 2}}>{t('items.upload_file')} XML</Typography>
                        <VisuallyHiddenInput type="file" accept=".xml" onChange={e=>{handleUpload(e.target.files[0])}}/>
                    </Box>

                    <DataGrid
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={orders}
                        columns={columns}
                        editMode="row"
                        hideFooterPagination
                        disableRowSelectionOnClick={true}
                        sx={{
                            border: 0
                        }}
                        processRowUpdate={(updatedRow, originalRow) => {
                            setOrders(prevRows => {
                                const newRows = [...prevRows];
                                newRows[originalRow.id] = updatedRow;
                                return newRows;
                            });
                        }}                        
                        getRowHeight={({ id, densityFactor }) => {
                            if (id % 2 === 0) {
                              return 50 * densityFactor;
                            }
                  
                            return null;
                        }}
                        slotProps={{
                            columnsManagement: {
                                toggleAllMode: 'filteredOnly', 
                            },
                        }}
                    />
                </Stack>
            </Stack>

            <Loading show={loading}/>

            {message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{
                        setMessage(e)
                    }}
                />
            : ''}            
        </>
    )
}