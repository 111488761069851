import React, { useEffect, useState } from 'react';

import { QrReader } from 'react-qr-reader';

import { Typography } from '@mui/material';

import ScanOverlay from './ScanOverlay';

//-----------------------------------------------------------

const Qrcodescan = ({onChange}) => {
  const [data, setData] = useState('No result');

  function handleChange(qrdata){
    setData(qrdata)
    onChange(qrdata)
  }

  return (
    <>
      <QrReader
        onResult={(result, error) => {
          if (!!result) {
            handleChange(result?.text);
          }
        }}
        constraints={{ facingMode: 'environment' }}
        videoContainerStyle={{ 
          width: '100%', 
          height: '100%', 
          backgroundColor: 'rgba(0, 0, 0, 0.3)', 
          borderRadius: '24px' 
        }}
        ViewFinder={()=>{
            return (
                <ScanOverlay/>
            )
        }}
      />

      <Typography sx={{textAlign: 'center', p: 3}}>Scaneando ...</Typography>
    </>
  );
};

export default Qrcodescan;