import React, {useState, useEffect} from 'react'
import ReactLoading from 'react-loading'
import Menu from '../../components/Menu'
import api from '../../services/api'
import axios from 'axios'

import Swal from 'sweetalert2'

import {MdContentCopy} from 'react-icons/md'
import {ImWhatsapp} from 'react-icons/im'
import {HiOutlineMail} from 'react-icons/hi'
import {FiTrash2, FiFilter, FiSave, FiArrowLeft, FiSearch} from 'react-icons/fi'

import './style.css'
import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, FloatingLabel, CloseButton } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

function ExportInventories() {
    const { t } = useTranslation();

    const [partnerSearch, setPartnerSearch] = useState('*')
    const [dataInicio, setDataInicio] = useState(dates(7, '-'))//(dates(31, '-'))
    const [dataFim, setDataFim] = useState(dates(1, '+'))
    const [pageNumber, setPageNumber] = useState(0)
    const [invList, setInvList] = useState([])
    const [invLoading, setInvLoading] = useState(false)
    const [modoApi, setModoApi] = useState("one")
    const [enderecoApi, setEnderecoApi] = useState("")
    const [partnerList, setPartnerList] = useState([])
    const [partnerSelected, setPartnerSelected] = useState('')
    const [nv1List, setNv1List] = useState([])
    const [nv2List, setNv2List] = useState([])
    const [nv3List, setNv3List] = useState([])
    const [nv4List, setNv4List] = useState([])
    const [nv1Selected, setNv1Selected] = useState('')
    const [nv2Selected, setNv2Selected] = useState('')
    const [nv3Selected, setNv3Selected] = useState('')
    const [nv4Selected, setNv4Selected] = useState('')
    const [itensLoaded, setItensLoaded] = useState([])
    const [itensLoading, setItensLoading] = useState(false)
    const [windowLoading, setWindowLoading] = useState(false)
    const [changeValue, setChangeValue] = useState(0)

    const [responsibleView, setResponsibleView] = useState('')
    const [emailView, setEmailView] = useState('')
    const [numberView, setNumberView] = useState('')

    const [invInfo, setInvInfo] = useState([])
    const [invItems, setInvItems] = useState([])
    const [invLoadingConf, setInvLoadingConf] = useState(false)

    useEffect(()=>{
        getInvExtList()
    }, [pageNumber])
    
    useEffect(async ()=>{
        //start config, niveis e parceiros
        await getContaConfig(true)
    }, [])

    useEffect(async () => {
        await getPartners()
        await getLocations()
    }, [enderecoApi])


    function copyToClipBoard(identificador) {
        let link = `${window.location.href}/${identificador}`
        navigator.clipboard.writeText(link).then(
            () => {
                Swal.fire({
                    title: "Sucesso !",
                    text: 'Link salvo na Área de transferência',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            },
            () => {
                Swal.fire({
                    title: "Ops... !",
                    text: 'Houve um erro ao enviar link para Área de transferência',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        )
    }
    function sendWhatsapp(identificador, partner) {
        if(partner.telefone && partner.telefone != '') {
            let link = `${window.location.href}/${identificador}`
            let textMsg = `*Inventário Externo*\nOlá *${partner.nome_responsavel}*, tudo bem?\nFoi solicitado que você realize um inventário externo.\nSegue o link em anexo\n${link}`
            let reg = [{
                numero: partner.telefone,
                mensagem: textMsg
            }]
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
            api.post(`envioAlerta`, reg,option).then(
                response => {
                    Swal.fire({
                        title: "Sucesso !",
                        text: `Link enviado para o Whatsapp: ${partner.telefone}`,
                        icon: 'success',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                },
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao enviar link por Whatsapp',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                },
            )
        }
    }
    function sendEmail(identificador, partner, alert = true) {
        return new Promise(
            (resolve, reject) => {
                if(partner.email && partner.email != '') {
                    let link = `${window.location.href}/${identificador}`
                    let textMsg = ''
                    textMsg += `<p>Olá <b>${partner.nome_responsavel}</b>, tudo bem?</p>`
                    textMsg += `<p>Foi solicitado que você realize um inventário externo.</p>`
                    textMsg += `<p>Sugerimos que seja realizado através de um celular, para facilitar na hora de tirar fotos.</p>`
                    if(enderecoApi.toLowerCase().includes('https://api.inovacode.app.br/impa')){
                        textMsg += `<p>Em caso de equipamentos eletrônicos, solicitamos que a foto contenha as informações do item, tais como marca, modelo, numero de serie entre outros.</p>`
                        textMsg += `<p>No campo observações, solicitamos que descreva brevemente o estado em que o bem se encontra.</p>`
                    }
                    textMsg += `<p>Para acessá-lo <a href="${link}"><b>clique aqui</b></a></p>`
                    textMsg += `<p>Caso tenha algum problema de acesso pelo botão acima, segue o link abaixo.</p>`
                    textMsg += `<p>${link}</p>`
                    //if(window.sessionStorage.getItem('idConta') == 'rrRSGwq6m'){
                    if(enderecoApi.toLowerCase().includes('https://api.inovacode.app.br/impa')){
                        textMsg += `<p><b>Prazo de entrega:</b> 15 dias</p>`
                        textMsg += `<p>Quaisquer dúvidas, entrar em contato com: 2529-5298 / 2529-5090 / 2529-5299 ou e-mail: patrim@impa.br</p>`
                    }
                    let reg = {
                        "email": partner.email,
                        "titulo": "Inventário Externo",
                        "html": textMsg
                    }
                    //api.post(`/emailCustom/${partner.email}/Inventário Externo`, [{html: textMsg}]).then(
                    axios.post(`${modoApi == 'one' ? process.env.REACT_APP_API_URL : enderecoApi}/emailCustom`, reg).then(
                        response => {
                            if(alert) {
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: `Link enviado para o e-mail: ${partner.email}`,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                        },
                        response => {
                            Swal.fire({
                                title: "Ops... !",
                                text: `Houve um erro ao enviar link por e-mail: ${partner.email}`,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }
                    ).finally(()=>{resolve()})
                }
            }
        )
    }

    //local-okay
    async function getContaConfig(init = false) {
        await api.get(`/conta_config/${sessionStorage.getItem('idConta')}`).then(
            async response => {
                await setModoApi(response.data[0].api_modo)
                await setEnderecoApi(response.data[0].api_endereco)
            },
            response => {
                Swal.fire({
                    title: "Ops... !",
                    text: 'Houve um erro ao carregar informações da conta',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        )
    }

    function orderBy(data) {
        let dataSorted = data
        dataSorted = dataSorted.sort((a,b) => (a.nome > b.nome) ? 1 : -1)
        return dataSorted
    }

    //local-okay
    function getPartners() {
        if(modoApi == 'one') {
            api.get(`/parceiro/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(
                async response => {
                    let listagem = await orderBy(response.data)
                    setPartnerList(listagem)
                },
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao carregar lista de parceiros',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }else if(modoApi == 'local') {
            axios.get(`${enderecoApi}/parceiro/${sessionStorage.getItem('idConta')}`).then(
                async response => {
                    let listagem = await orderBy(response.data)
                    setPartnerList(listagem)
                },
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao carregar lista de parceiros',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    //local-okay
    function getLocations() {
        if(modoApi == 'one') {
            api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(
                response => {
                    setNv1List(response.data)
                },
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao carregar Nivel de localização 1',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
                response => {setNv2List(response.data)},
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao carregar Nivel de localização 2',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
                response => {setNv3List(response.data)},
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao carregar Nivel de localização 3',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
                response => {setNv4List(response.data)},
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao carregar Nivel de localização 4',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }else if(modoApi == 'local'){
            axios.get(`${enderecoApi}/nivel_loc1/${sessionStorage.getItem('idConta')}`).then(
                response => {
                    setNv1List(response.data)
                },
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao carregar Nivel de localização 1',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
            axios.get(`${enderecoApi}/nivel_loc2/${sessionStorage.getItem('idConta')}/1`).then(
                response => {setNv2List(response.data)},
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao carregar Nivel de localização 2',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
            axios.get(`${enderecoApi}/nivel_loc3/${sessionStorage.getItem('idConta')}/*`).then(
                response => {setNv3List(response.data)},
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao carregar Nivel de localização 3',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
            axios.get(`${enderecoApi}/nivel_loc4/${sessionStorage.getItem('idConta')}/*`).then(
                response => {setNv4List(response.data)},
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao carregar Nivel de localização 4',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    function locationsChoice(nv, value) {
        switch (nv) {
            case '1':
                setNv1Selected(value)
                setNv2Selected('')
                setNv3Selected('')
                setNv4Selected('')
                break
            case '2':
                setNv2Selected(value)
                setNv3Selected('')
                setNv4Selected('')
                break
            case '3':
                setNv3Selected(value)
                setNv4Selected('')
                break
            case '4':
                setNv4Selected(value)
                break
            default:
                break
        }
    }

    //local-okay
    function loadingItens() {
        if(partnerSelected == '') {
            Swal.fire({
                title: "Ops... !",
                text: 'Selecione um responsável para realizar o inventário',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }else if(nv1Selected == '') {
            Swal.fire({
                title: "Ops... !",
                text: 'Selecione ao menos um nivel de localização',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }else{
            Swal.fire({
                title: "Atenção !",
                html: 'Tem certeza que deseja carregar os itens deste responsável nesta localização?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Sim",
                cancelButtonText: "Não"
            }).then(
                async result => {
                    if(result.isConfirmed) {
                        setItensLoading(true)
                        if(modoApi == 'one') {
                            await api.get(`/item/${sessionStorage.getItem('idConta')}/*/*/*/*/*/*/*/*/*/${nv1Selected == '' ? '*' : nv1Selected}/${nv2Selected == '' ? '*' : nv2Selected}/${nv3Selected == '' ? '*' : nv3Selected}/${nv4Selected == '' ? '*' : nv4Selected}`).then(
                                async response => {
                                    //await setItensLoaded(response.data.filter((item) => (item.id_parceiro && item.id_parceiro._id == partnerSelected)))
                                    await setItensLoaded(response.data.filter((item) => ((partnerSelected == '*' || (item.id_parceiro && item.id_parceiro._id == partnerSelected)) && (item.id_parceiro != '' && item.id_parceiro != '0'))))
                                },
                                response => {}
                            ).finally(()=>{setItensLoading(false)})
                        }else if(modoApi == 'local') {
                            //await axios.get(`/item/${sessionStorage.getItem('idConta')}/*/*/*/*/*/*/*/*/*/${nv1Selected == '' ? '*' : nv1Selected}/${nv2Selected == '' ? '*' : nv2Selected}/${nv3Selected == '' ? '*' : nv3Selected}/${nv4Selected == '' ? '*' : nv4Selected}`).then(
                            await axios.get(`${enderecoApi}/itens/${sessionStorage.getItem('idConta')}/0/0/${partnerSelected}/${nv1Selected == '' ? '0' : nv1Selected}/${nv2Selected == '' ? '0' : nv2Selected}/${nv3Selected == '' ? '0' : nv3Selected}/${nv4Selected == '' ? '0' : nv4Selected}?filterP=true`).then(
                                async response => {
                                    await setItensLoaded(response.data.filter((item) => ((partnerSelected == '*' || partnerSelected == item.id_parceiro) && (item.id_parceiro != '' && item.id_parceiro != '0'))))
                                },
                                response => {}
                            ).finally(()=>{setItensLoading(false)})
                        }
                    }
                }
            )
        }
    }

    function getInvExtList() {
        setInvLoading(true)
        //api.get(`/registroPage/${sessionStorage.getItem('idConta')}/*/inventario/${dataInicio}/${dataFim}/*/${parceiro}/*/${pageNumber * 30}`).then(
        api.get(`/registroPage/${sessionStorage.getItem('idConta')}/*/externo/${dataInicio}/${dataFim}/${partnerSearch}/*/*/${pageNumber * 30}`).then(
            response => {
                setInvList(response.data)
            },
            response => {}
        ).finally(()=>{setInvLoading(false)})
    }

    function clear() {
        setChangeValue(0)
        setPartnerSelected('')
        setNv1Selected('')
        setNv2Selected('')
        setNv3Selected('')
        setNv4Selected('')
        setItensLoaded([])
        setItensLoading(false)
        //setPageNumber(0)
        getInvExtList()
        setInvItems([])
        setInvInfo([])
    }

    async function saveInventory() {
        if(partnerSelected != '' && nv1Selected != '') {
            await loadingItens()
            if(itensLoaded.length == 0) {
                Swal.fire({
                    title: "Ops... !",
                    text: 'Não há itens para ser inventáriado',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }else {
                Swal.fire({
                    title: "Atenção !",
                    html: 'Tem certeza que deseja gerar um inventário deste responsável nesta localização?',
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sim",
                    cancelButtonText: "Não"
                }).then(
                    result => {
                        if(result.isConfirmed) {
                            let dt = new Date()
                            let dt_reg = ("0" + dt.getDate()).slice(-2) + "" + ("0" + (dt.getMonth() + 1)).slice(-2) + "" + dt.getFullYear()
                            dt_reg += "" + ("0" + dt.getHours()).slice(-2) + "" + ("0" +dt.getMinutes()).slice(-2) + "" + ("0" +dt.getSeconds()).slice(-2)
                            let partnerInfo = partnerList.find((partner) => (partner._id == partnerSelected))
                            let nv1Info = nv1List.find((nv) => (nv._id == nv1Selected))
                            let nv2Info = nv2List.find((nv) => (nv._id == nv2Selected))
                            let nv3Info = nv3List.find((nv) => (nv._id == nv3Selected))
                            let nv4Info = nv4List.find((nv) => (nv._id == nv4Selected))
                            let reg = [{
                                'id_conta': sessionStorage.getItem('idConta'),
                                'modo': 'ext',
                                'identificador': `${partnerSelected}_${dt_reg}`,
                                'dt_registro': dt,
                                'quantidade': itensLoaded.length.toString(),
                                'encontrado': '0',
                                'status': '0',
                                'dt_alteração': dt,
                                'observacao': '',
                                'id_parceiro': partnerInfo._id,
                                'externo': {
                                    'nome_responsavel': partnerInfo.nome,
                                    'telefone': partnerInfo.telefone,
                                    'email': partnerInfo.email,
                                    'id_nivel_loc_3': nv3Selected
                                },
                                'nivel_loc_1': nv1Info ? nv1Info.descricao : '',
                                'nivel_loc_2': nv2Info ? nv2Info.descricao : '',
                                'nivel_loc_3': nv3Info ? nv3Info.descricao : '',
                                'nivel_loc_4': nv4Info ? nv4Info.descricao : '',
                            }]

                            let option = {
                                headers: { 'Content-Type': ['application/json'] }
                            }

                            api.post(`/registro?tr=icd3vatgr@23!`, reg, option).then(
                                async response => {
                                    let formatedItens = itensLoaded.map((item) => {
                                        let itemReg = {
                                            "id_conta": sessionStorage.getItem('idConta'),
                                            "id_registro": response.data[0]._id,
                                            "id_registro_tag": "0",
                                            "id_registro_inventario": "0",
                                            "id_registro_coleta": "0",
                    
                                            "id_item": modoApi == 'one' ? item._id : item.id_item,
                                            "id_categoria": modoApi == 'one' ? item.id_categoria._id : '0',
                                            "ean": "0",
                                            "descricao": modoApi =='one' ? item.id_categoria.descricao : item.categoria,
                                            "tag": item.tag,
                                            "rssi": "-0",

                                            "externo": {
                                                "id_item": item.id_item,
                                            },
                    
                                            "inf_compl_1": item.inf_compl_1,
                                            "inf_compl_2": item.inf_compl_2,
                                            "inf_compl_3": item.inf_compl_3,
                                            "inf_compl_4": item.inf_compl_4,
                                            "inf_compl_5": item.inf_compl_5,

                                            'nivel_loc_1': nv1Info ? nv1Info.descricao : '',
                                            'nivel_loc_2': nv2Info ? nv2Info.descricao : '',
                                            'nivel_loc_3': nv3Info ? nv3Info.descricao : '',
                                            'nivel_loc_4': nv4Info ? nv4Info.descricao : '',
                    
                                            "encontrado": "0",
                                            "auditado": "0",
                                            "excedente": "0",
                                            "incorporado": "0",
                    
                                            "foto": "",
                                            "dt_leitura":  "",
                                            "observacao" : ""
                                        }
                                        return itemReg
                                    })
                                    api.post(`/registro_tag_picking`, formatedItens,option).then(
                                        async response => {
                                            Swal.fire({
                                                title: "Sucesso !",
                                                text: 'Inventário registrado',
                                                icon: 'success',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                            await sendEmail(reg[0].identificador, reg[0].externo)
                                            //await sendWhatsapp(reg[0].identificador, reg[0].externo)
                                            clear()
                                        },
                                        response => {
                                            Swal.fire({
                                                title: "Ops... !",
                                                text: 'Houve um erro ao criar itens do registro de inventário',
                                                icon: 'error',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                        }
                                    )
                                },
                                response => {
                                    Swal.fire({
                                        title: "Ops... !",
                                        text: 'Houve um erro ao criar um registro de inventário',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                },
                            )
                        }
                    }
                )
            }
        }else{
            Swal.fire({
                title: "Ops... !",
                text: 'Selecione um parceiro e ao menos um nivel de localização',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }
    }

    async function testeLista() {
        let parceirosListInv = []
        await itensLoaded.map((item, index) => {
            if(modoApi == 'local'){
                if(item.id_parceiro != "" && parceirosListInv.indexOf(item.id_parceiro) <= -1) {
                    parceirosListInv.push(item.id_parceiro)
                }
            }else{
                if(item.id_parceiro._id != "" && parceirosListInv.indexOf(item.id_parceiro._id) <= -1) {
                    parceirosListInv.push(item.id_parceiro._id)
                }
            }
        })

        if(partnerSelected != '' && nv1Selected != '') {
            //await loadingItens()
            if(itensLoaded.length == 0) {
                Swal.fire({
                    title: "Ops... !",
                    text: 'Não há itens para ser inventáriado',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }else {
                Swal.fire({
                    title: "Atenção !",
                    html: 'Tem certeza que deseja gerar inventário para responsáveis selecionados nesta localização?',
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sim",
                    cancelButtonText: "Não"
                }).then(
                    async result => {
                        if(result.isConfirmed) {
                            let dt = new Date()
                            let dt_reg = ("0" + dt.getDate()).slice(-2) + "" + ("0" + (dt.getMonth() + 1)).slice(-2) + "" + dt.getFullYear()
                            dt_reg += "" + ("0" + dt.getHours()).slice(-2) + "" + ("0" +dt.getMinutes()).slice(-2) + "" + ("0" +dt.getSeconds()).slice(-2)
                            let nv1Info = nv1List.find((nv) => (nv._id == nv1Selected))
                            let nv2Info = nv2List.find((nv) => (nv._id == nv2Selected))
                            let nv3Info = nv3List.find((nv) => (nv._id == nv3Selected))
                            let nv4Info = nv4List.find((nv) => (nv._id == nv4Selected))
                            console.log({
                                nv1Info,
                                nv2Info,
                                nv3Info,
                                nv4Info
                            })
                            let reg = []
                            await parceirosListInv.map((partner) => {
                                let indexPart = partnerList.findIndex((partnerFind) => {return (partnerFind._id == partner)})
                                if(indexPart > -1) {
                                    let partnerInfo = partnerList[indexPart]
                                    let qtd = itensLoaded.filter((item) => {return (item.id_parceiro == partner)}).length
                                    reg.push({
                                        '_id': `${partnerInfo._id}_${dt_reg}`,
                                        'id_conta': sessionStorage.getItem('idConta'),
                                        'modo': 'ext',
                                        'identificador': `${partnerInfo._id}_${dt_reg}`,
                                        'dt_registro': dt,
                                        'quantidade': qtd.toString(),
                                        'encontrado': '0',
                                        'status': '0',
                                        'dt_alteração': dt,
                                        'observacao': '',
                                        'id_parceiro': partnerInfo._id,
                                        'externo': {
                                            'nome_responsavel': partnerInfo.nome,
                                            'telefone': partnerInfo.telefone,
                                            'email': partnerInfo.email,
                                            'id_nivel_loc_3': nv3Selected ? nv3Selected : '0'
                                        },
                                        'nivel_loc_1': ![null,undefined].includes(nv1Info) && nv1Info ? nv1Info.descricao : '',
                                        'nivel_loc_2': ![null,undefined].includes(nv2Info) && nv2Info ? nv2Info.descricao : '',
                                        'nivel_loc_3': ![null,undefined].includes(nv3Info) && nv3Info ? nv3Info.descricao : '',
                                        'nivel_loc_4': ![null,undefined].includes(nv4Info) && nv4Info ? nv4Info.descricao : '',
                                    })
                                }
                            })

                            let option = {
                                headers: { 'Content-Type': ['application/json'] }
                            }

                            api.post(`/registro?tr=icd3vatgr@23!`, reg, option).then(
                                async response => {
                                    let formatedItens = await itensLoaded.map((item) => {
                                        let idPartner = ''
                                        if(item.id_parceiro._id){
                                            idPartner=item.id_parceiro._id
                                        } else if(item.id_parceiro){
                                            idPartner=item.id_parceiro
                                        }
                                        let regIndex = reg.findIndex((regis) => (regis.id_parceiro == idPartner))
                                        if(regIndex > -1) {
                                            let itemReg = {
                                                "id_conta": sessionStorage.getItem('idConta'),
                                                "id_registro": reg[regIndex]._id,
                                                "id_registro_tag": "0",
                                                "id_registro_inventario": "0",
                                                "id_registro_coleta": "0",
                        
                                                "id_item": modoApi == 'one' ? item._id : item.id_item,
                                                "id_categoria": modoApi == 'one' ? item.id_categoria._id : '0',
                                                "id_parceiro": idPartner,
                                                "ean": "0",
                                                "descricao": modoApi =='one' ? item.id_categoria.descricao : item.categoria,
                                                "tag": item.tag,
                                                "rssi": "-0",
    
                                                "externo": {
                                                    "id_item": modoApi == 'one' ? item._id : item.id_item,
                                                },
                        
                                                "inf_compl_1": item.inf_compl_1,
                                                "inf_compl_2": item.inf_compl_2,
                                                "inf_compl_3": item.inf_compl_3,
                                                "inf_compl_4": item.inf_compl_4,
                                                "inf_compl_5": item.inf_compl_5, //NFe
                                                
                                                "inf_compl_6": modoApi == 'local' && enderecoApi.includes('/impa') ? (`${item.inf_compl_6.substr(6,2)}/${item.inf_compl_6.substr(4,2)}/${item.inf_compl_6.substr(0,4)}`) : item.inf_compl_6, //data
                                                "inf_compl_7": modoApi == 'local' && enderecoApi.includes('/impa') ? item.id_item : item.inf_compl_7, //id_item
                                                "inf_compl_8": modoApi == 'local' && enderecoApi.includes('/impa') ? item.observacao : item.inf_compl_8, //obs
                                                "inf_compl_9": item.inf_compl_9, 
                                                "inf_compl_10": item.inf_compl_10,
    
                                                'nivel_loc_1': item.nivel_loc_1 ? item.nivel_loc_1 : (item.id_nivel_loc_1 ? item.id_nivel_loc_1.descricao :''),
                                                'nivel_loc_2': item.nivel_loc_2 ? item.nivel_loc_2 : (item.id_nivel_loc_2 ? item.id_nivel_loc_2.descricao :''),
                                                'nivel_loc_3': item.nivel_loc_3 ? item.nivel_loc_3 : (item.id_nivel_loc_3 ? item.id_nivel_loc_3.descricao :''),
                                                'nivel_loc_4': item.nivel_loc_4 ? item.nivel_loc_4 : (item.id_nivel_loc_4 ? item.id_nivel_loc_4.descricao :''),
                        
                                                "encontrado": "0",
                                                "auditado": "0",
                                                "excedente": "0",
                                                "incorporado": "0",
                        
                                                "foto": "",
                                                "dt_leitura":  "",
                                                "observacao" : ""
                                            }
                                            return itemReg
                                        }
                                    })
                                    formatedItens = await formatedItens.filter((item) => {
                                        if(item) {
                                            return item
                                        }
                                    })
                                    api.post(`/registro_tag_picking`, formatedItens,option).then(
                                        async response => {
                                            Swal.fire({
                                                title: "Sucesso !",
                                                text: 'Inventário registrado',
                                                icon: 'success',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                            await reg.map(async(regi, index) => {
                                                await sendEmail(regi.identificador, regi.externo, false)
                                                //await sendWhatsapp(reg[0].identificador, reg[0].externo)
                                                if(index == reg.length - 1) {
                                                    Swal.fire({
                                                        title: "Sucesso !",
                                                        text: `Links enviados para os e-mails:`,
                                                        icon: 'success',
                                                        showConfirmButton: false,
                                                        timerProgressBar: true,
                                                        timer: '2500'
                                                    })
                                                }
                                            })
                                            clear()
                                        },
                                        response => {
                                            Swal.fire({
                                                title: "Ops... !",
                                                text: 'Houve um erro ao criar itens do registro de inventário',
                                                icon: 'error',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                        }
                                    )
                                },
                                response => {
                                    Swal.fire({
                                        title: "Ops... !",
                                        text: 'Houve um erro ao criar um registro de inventário',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                },
                            )
                        }
                    }
                )
            }
        }else{
            Swal.fire({
                title: "Ops... !",
                text: 'Selecione um parceiro e ao menos um nivel de localização',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }
    }

    function dates(days, direction) {
        let dt   = new Date();
        if(direction == '+') {
            dt = new Date(dt.getTime() + (days * 24 * 60 * 60 * 1000))
        }
        else if(direction == '-') {
            dt = new Date(dt.getTime() - (days * 24 * 60 * 60 * 1000))
        }
        return (dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2))
    }

    function numberListGet() {
        let list = []
        let qtdpages = Math.ceil((invList[invList.length - 1].total_reg / 30))
        
        if(qtdpages >= 2 && qtdpages <= 5) {
            for(let i= 1; i <= qtdpages; i++) {
                list.push(i)
            }
        }else if(qtdpages > 5) {
            if(pageNumber <= 1) {
                for(let i= 1; i <= 5; i++) {
                    list.push(i)
                }
            } else if(pageNumber > 1 && pageNumber < (qtdpages - 2)) {
                for(let i= (pageNumber - 1); i <= (pageNumber + 3); i++) {
                    list.push(i)
                }
            } else {
                for(let i= (qtdpages - 4); i <= qtdpages; i++) {
                    list.push(i)
                }
            }
        }
        return list
    }

    function remove(idRegistro) {
        Swal.fire({
            title: "Atenção !",
            html: `Deseja realmente excluir este Inventário ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
            
                    api.delete(`/registro/${idRegistro}`, option
                    ).then(
                        response => {
                            Swal.fire({
                                title: "Sucesso !",
                                text: 'Inventário foi excluido de sua base.',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            clear()
                        },
                        response => {
                            Swal.fire({
                                title: "Ops... !",
                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }
                    )
                }
            }
        )
    }

    function confInv(identificador) {
        setChangeValue(3)
        setInvLoadingConf(true)
        api.get(`/registroPage/*/*/externo/*/*/${identificador}/*/*/${0 * 30}`).then(
            response => {
                if(response.data[response.data.length - 1].total_reg == 0) {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Não existe nenhum inventário relacionado a este identificador',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                    setInvLoadingConf(false)
                }else{
                    setInvInfo(response.data)
                    api.get(`/registro_tag/*/${response.data[0]._id}/0`).then(
                        response2 => {
                            setInvItems(response2.data)
                            setInvLoadingConf(false)
                        },
                        response2 => {setInvLoadingConf(false)}
                    )
                }
            },
            response => {setInvLoadingConf(false)}
        )
    }

    function changeSwitch(key) {
        switch (key) {
            case 0:
                return(
                    <div >
                        <Container fluid>
                            <Col className="d-flex justify-content-between my-2">
                                <h3>{t('common.inventories_extern')}</h3>
                                <Button variant="custom-primary" className="ms-2 text-uppercase" onClick={e=>{setChangeValue(1)}}>{t('actions.create')} {t('common.inventorie')}</Button>
                            </Col>
                            <Col className="border rounded bg-white p-3">
                                <Col className="rounded p-3 mt-3" style={{background:'#f5f6fb'}}>
                                    <div className="d-flex align-items-center">
                                        <h5 className="fw-bold text-primary">
                                            <FiFilter/>
                                            <span className="px-2">{t('common.filter')}</span>
                                        </h5>
                                    </div>
                                    <Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="fw-bold">{t('common.partners')}</Form.Label>
                                            <Form.Select 
                                                value={partnerSearch}
                                                onChange={e=>{setPartnerSearch(e.target.value)}}
                                            >
                                                <option value={'*'}>Todos</option>
                                                {partnerList.map((partner, index) => (
                                                    <option key={index} value={partner._id}>{partner.nome}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label className="fw-bold">{t('common.date')}</Form.Label>
                                            <div className="date">
                                                <input 
                                                    type="date"
                                                    value={dataInicio}
                                                    onChange={e => {setDataInicio(e.target.value)}}
                                                />
                                                <input
                                                    type="date"
                                                    value={dataFim}
                                                    onChange={e => {setDataFim(e.target.value)}}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Row className="justify-content-end px-0 py-3">
                                            <Button 
                                                variant="custom-primary" 
                                                style={{width:'150px'}} 
                                                onClick={e => {
                                                    setPageNumber(0)
                                                    getInvExtList()()}}>
                                                Pesquisar
                                            </Button>
                                        </Row>
                                    </Row>
                                </Col>
                                <Col className="mt-3">
                                    <Table responsive>
                                        <thead>
                                            <tr className="bg-white text-uppercase">
                                                <th>#</th>
                                                <th>{t('common.identify')}</th>
                                                <th>{t('common.contact')}</th>
                                                <th>{t('common.levels')}</th>
                                                <th>STATUS</th>
                                                <th>LINK</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invList.map((inv, index) => {
                                                if(inv.total_reg == undefined) {
                                                    return (
                                                        <tr key={index}>
                                                            <th>{pageNumber * 30 + index + 1}</th>
                                                            <th>
                                                                <div>{inv.identificador}</div>
                                                                <div>{inv.externo.nome_responsavel}</div>
                                                            </th>
                                                            <th>
                                                                <div>{inv.externo.email}</div>
                                                                <div>{inv.externo.telefone}</div>
                                                            </th>
                                                            <th>
                                                                <div>{inv.nivel_loc_1}</div>
                                                                <div>{inv.nivel_loc_2}</div>
                                                                <div>{inv.nivel_loc_3}</div>
                                                                <div>{inv.nivel_loc_4}</div>
                                                            </th>
                                                            <th>
                                                                <div>{inv.status == '0' ? 'Aguardando' : 'Concluida'}</div>
                                                            </th>
                                                            <th>
                                                                <div className='listSendOptions'>
                                                                    <div style={{backgroundColor:'#2BA3D7'}} onClick={e=>{copyToClipBoard(inv.identificador)}}><MdContentCopy/></div>
                                                                    {false?<div style={{backgroundColor:'#0DC143'}} onClick={e=>{sendWhatsapp(inv.identificador,inv.externo)}}><ImWhatsapp/></div>:null}
                                                                    <div style={{backgroundColor:'#D34F43'}} onClick={e=>{sendEmail(inv.identificador,inv.externo)}}><HiOutlineMail/></div>
                                                                </div>
                                                            </th>
                                                            <th>
                                                                <button onClick={e=>{confInv(inv.identificador)}}>Conferir</button>
                                                            </th>
                                                            <th>
                                                                <div onClick={e=>{remove(inv._id)}}><FiTrash2/></div>
                                                            </th>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Col>
                        </Container>
                    </div>
                )
            case 1:
                return(
                    <div >
                        <Container fluid>
                            <Row>
                                <Col><h3>{t('common.categories')}</h3></Col>
                                <Col className="d-flex justify-content-end">
                                    <Button variant="custom-danger" className="me-2 text-uppercase" onClick={e=> {testeLista()}}><FiSave className="me-2"/>{t('actions.save')}</Button>
                                    <Button variant="light" className="text-uppercase" onClick={e => clear()}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                </Col>
                            </Row>
                            <Col className="p-3 bg-white rounded border">
                                <Col className="rounded p-3 mt-3" style={{background:'#f5f6fb'}}>
                                    <Row>
                                        <Form.Group as={Col} sm={12} lg={6}>
                                            <Form.Label>Mode API</Form.Label>
                                            <Form.Select
                                                value={modoApi}
                                                disabled
                                            >
                                                <option value={'one'}>iNOVAONE</option>
                                                <option value={'local'}>Local/Cliente</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={12} lg={6}>
                                            <Form.Label>Endereço API</Form.Label>
                                            <Form.Control
                                                value={modoApi == 'one' ? '' : enderecoApi}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="my-3">
                                        <Form.Group as={Col} sm={12} lg={6}>
                                            <Form.Label>Responsável</Form.Label>
                                            <Form.Select
                                                value={partnerSelected}
                                                onChange={e=>{setPartnerSelected(e.target.value)}}
                                            >
                                                <option value={''}>Selecione um Responsável</option>
                                                <option value={'*'}>Todos</option>
                                                {partnerList.map((partner, index) => (
                                                    <option key={index} value={partner._id}>{partner.nome}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} sm={12} lg={3}>
                                            <Form.Label>Nivel 1</Form.Label>
                                            <Form.Select
                                                value={nv1Selected}
                                                onChange={e=>{locationsChoice('1', e.target.value)}}
                                            >
                                                <option value={''}>Selecione um Nivel 1</option>
                                                {nv1List.map((nivel, index) => {
                                                    return (
                                                        <option key={index} value={nivel._id}>{nivel.descricao}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={12} lg={3}>
                                            <Form.Label>Nivel 2</Form.Label>
                                            <Form.Select
                                                value={nv2Selected}
                                                onChange={e=>{locationsChoice('2', e.target.value)}}
                                            >
                                                <option value={''}>Selecione um Nivel 2</option>
                                                {nv2List.map((nivel, index) => {
                                                    if(nivel.id_nivel_loc1 == nv1Selected) {
                                                        return (
                                                            <option key={index} value={nivel._id}>{nivel.descricao}</option>
                                                        )
                                                    }
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={12} lg={3}>
                                            <Form.Label>Nivel 3</Form.Label>
                                            <Form.Select
                                                value={nv3Selected}
                                                onChange={e=>{locationsChoice('3', e.target.value)}}
                                            >
                                                <option value={''}>Selecione um Nivel 3</option>
                                                {nv3List.map((nivel, index) => {
                                                    if(nivel.id_nivel_loc2 == nv2Selected) {
                                                        return (
                                                            <option key={index} value={nivel._id}>{nivel.descricao}</option>
                                                        )
                                                    }
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={12} lg={3}>
                                            <Form.Label>Nivel 4</Form.Label>
                                            <Form.Select
                                                value={nv4Selected}
                                                onChange={e=>{locationsChoice('4', e.target.value)}}
                                            >
                                                <option value={''}>Selecione um Nivel 4</option>
                                                {nv4List.map((nivel, index) => {
                                                    if(nivel.id_nivel_loc3 == nv3Selected) {
                                                        return (
                                                            <option key={index} value={nivel._id}>{nivel.descricao}</option>
                                                        )
                                                    }
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                    <Row className="justify-content-end px-0 py-3">
                                        <Button variant="custom-primary" style={{width:'150px'}} onClick={e=>{loadingItens()}}>
                                            {t('common.load_itens')}
                                        </Button>
                                    </Row>
                                </Col>
                                {itensLoading ?
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                    </div>
                                    :
                                    <div className="list">
                                        <Table responsive hover>
                                            <thead>
                                                <tr className="text-uppercase">
                                                    <th>#</th>
                                                    <th>{t('common.categories')}</th>
                                                    <th>{t('common.levels')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itensLoaded.map((item, index) => (
                                                    <tr key={index}>
                                                        <th>{index + 1}</th>
                                                        <th>
                                                            <div>{item.id_categoria.descricao ? item.id_categoria.descricao : item.categoria}</div>
                                                            <div>{item.tag}</div>
                                                        </th>
                                                        <th>
                                                            {item.nivel_loc_1 ? <div>{item.nivel_loc_1}</div> : <div>{item.id_nivel_loc_1 ? item.id_nivel_loc_1.descricao: ''}</div>}
                                                            {item.nivel_loc_2 ? <div>{item.nivel_loc_2}</div> : <div>{item.id_nivel_loc_2 ? item.id_nivel_loc_2.descricao: ''}</div>}
                                                            {item.nivel_loc_3 ? <div>{item.nivel_loc_3}</div> : <div>{item.id_nivel_loc_3 ? item.id_nivel_loc_3.descricao: ''}</div>}
                                                            {item.nivel_loc_4 ? <div>{item.nivel_loc_4}</div> : <div>{item.id_nivel_loc_4 ? item.id_nivel_loc_4.descricao: ''}</div>}
                                                        </th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            </Col>
                        </Container>
                    </div>
                )
            case 3:
                return(
                <div >
                    {invLoadingConf ?
                        <>
                            <Row>
                                <Col><h3>{t('common.inventories_extern')}</h3></Col>
                                <Col className="d-flex justify-content-end">
                                    <Button variant="light" className="text-uppercase" onClick={e => clear()}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                </Col>
                            </Row>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                            </div>
                        </>
                        :
                        <Container>
                            <Row>
                                <Col><h3>{t('common.inventories_extern')} - {invInfo[0] ? invInfo[0].identificador : ''}</h3></Col>
                                <Col className="d-flex justify-content-end">
                                    <Button variant="light" className="text-uppercase" onClick={e => clear()}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                </Col>
                            </Row>
                            <div className='export'>
                                <div style={{padding: '10px', background: (invInfo[0].status == '0' ? '#B40C0C' : '#4EA90B'), width: '100%', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{invInfo[0].status == '0' ? 'Aguardando' : 'Concluida'}</div>
                            </div>
                            <div className="filters">
                                <div className="filter">
                                    <label>Nome</label>
                                    <input value={invInfo[0] ? invInfo[0].externo.nome_responsavel : ''} readOnly/>
                                </div>
                                <div className="filter">
                                    <label>Email</label>
                                    <input value={invInfo[0] ? invInfo[0].externo.email:''} readOnly/>
                                </div>
                                <div className="filter">
                                    <label>Telefone</label>
                                    <input value={invInfo[0]? invInfo[0].externo.telefone:''} readOnly/>
                                </div>
                            </div>
                            <div className="filters">
                                <div className="filter">
                                    <label>NIVEL_1</label>
                                    <input value={invInfo[0] ? invInfo[0].nivel_loc_1 : ''} readOnly/>
                                </div>
                                <div className="filter">
                                    <label>NIVEL_2</label>
                                    <input value={invInfo[0] ? invInfo[0].nivel_loc_2 : ''} readOnly/>
                                </div>
                                <div className="filter">
                                    <label>NIVEL_3</label>
                                    <input value={invInfo[0] ? invInfo[0].nivel_loc_3 : ''} readOnly/>
                                </div>
                                <div className="filter">
                                    <label>NIVEL_4</label>
                                    <input value={invInfo[0] ? invInfo[0].nivel_loc_4 : ''} readOnly/>
                                </div>
                            </div>
                            <div className="list">
                                <table>
                                    <thead>
                                        <tr id="header">
                                            <th>#</th>
                                            <th>TAG</th>
                                            <th>INF. COMPL</th>
                                            {false?<th>NIVEIS</th>:null}
                                            <th>OBSERVAÇÃO</th>
                                            <th>STATUS</th>
                                            <th>FOTOS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invItems.map((inv, index) => {
                                            if(inv.total_reg == undefined) {
                                                let info = inv.encontrado == '0' ? 'Ausente' : 'Encontrado'
                                                let color = inv.encontrado == '0' ? '#B40C0C' : '#4EA90B'
                                                return (
                                                    <tr key={index}>
                                                        <th>{index + 1}</th>
                                                        <th>
                                                            <div>{inv.descricao}</div>
                                                            <div>{inv.tag}</div>
                                                        </th>
                                                        <th>
                                                            <div>{inv.inf_compl_1}</div>
                                                            <div>{inv.inf_compl_2}</div>
                                                            <div>{inv.inf_compl_3}</div>
                                                            <div>{inv.inf_compl_4}</div>
                                                            <div>{inv.inf_compl_5}</div>
                                                        </th>
                                                        {false?<th>
                                                            <div>{inv.nivel_loc_1}</div>
                                                            <div>{inv.nivel_loc_2}</div>
                                                            <div>{inv.nivel_loc_3}</div>
                                                            <div>{inv.nivel_loc_4}</div>
                                                        </th>:null}
                                                        <th>
                                                            <textarea value={inv.observacao} readOnly></textarea>
                                                        </th>
                                                        <th>
                                                        <div style={{padding: '10px', background: color, width: '120px', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{info}</div>
                                                            <div>{inv.dt_leitura}</div>
                                                        </th>
                                                        <th>
                                                            {inv.foto && inv.foto != '' ?
                                                                <img src={`https://api.inovacode.app.br/${inv.foto}`}/>
                                                            :
                                                                'Sem Foto'
                                                            }
                                                        </th>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Container>
                    }
                </div>
                )
            default:
                return(
                    <div></div>
                )
        }
    }

    return (
        <>
            {changeSwitch(changeValue)}
        </>
    )
}

export default ExportInventories