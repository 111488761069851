import React, {useState, useEffect} from 'react'
import ReactLoading from 'react-loading'

import Swal from 'sweetalert2'

import {parse} from 'papaparse'

import {FaFileCsv, FaFilePdf} from 'react-icons/fa'
import {FiArrowLeft, FiLogOut, FiUpload} from 'react-icons/fi'

//
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {Impressao} from '../../services/pdfGenerator'
import {CSVLink} from 'react-csv'
//

import api from '../../services/api'
import Menu from '../../components/Menu'

import './style.css'
import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, CloseButton } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

function Locations() {
    const { t } = useTranslation();
    
    const [nivelLoc1, setNivelLoc1] = useState('*')
    const [nivelLoc2, setNivelLoc2] = useState('*')
    const [nivelLoc3, setNivelLoc3] = useState('*')
    const [nivelLoc4, setNivelLoc4] = useState('*')
    const [nivelLoc1Display, setNivelLoc1Display] = useState(false)
    const [nivelLoc2Display, setNivelLoc2Display] = useState(false)
    const [nivelLoc3Display, setNivelLoc3Display] = useState(false)
    const [nivelLoc4Display, setNivelLoc4Display] = useState(false)
    const [nivelLoc1List, setNivelLoc1List] = useState([])
    const [nivelLoc2List, setNivelLoc2List] = useState([])
    const [nivelLoc3List, setNivelLoc3List] = useState([])
    const [nivelLoc4List, setNivelLoc4List] = useState([])
    const [idNivel1, setIdNivel1] = useState('0')
    const [descricao1, setDescricao1] = useState('')
    const [idExterno1, setIdExterno1] = useState('')
    const [tag1, setTag1] = useState('')
    const [ativo1, setAtivo1] = useState('1')
    const [observacao1,setObservacao1] = useState('')
    const [idNivel2, setIdNivel2] = useState('0')
    const [descricao2, setDescricao2] = useState('')
    const [idExterno2, setIdExterno2] = useState('')
    const [tag2, setTag2] = useState('')
    const [ativo2, setAtivo2] = useState('1')
    const [observacao2,setObservacao2] = useState('')
    const [idNivel3, setIdNivel3] = useState('0')
    const [descricao3, setDescricao3] = useState('')
    const [idExterno3, setIdExterno3] = useState('')
    const [tag3, setTag3] = useState('')
    const [ativo3, setAtivo3] = useState('1')
    const [observacao3,setObservacao3] = useState('')
    const [idNivel4, setIdNivel4] = useState('0')
    const [descricao4, setDescricao4] = useState('')
    const [idExterno4, setIdExterno4] = useState('')
    const [tag4, setTag4] = useState('')
    const [ativo4, setAtivo4] = useState('1')
    const [observacao4,setObservacao4] = useState('')
    const [change, setChange] = useState(0)
    const [importList, setImportList] = useState([])
    //const [uploaded, setUploaded] = useState(0)
    const [exportList, setExportList] = useState([])
    const [exportTitle, setExportTitle] = useState('')
    const [listExportLoading, setListExportLoading] = useState(false)
    const [listExport, setListExport] = useState([])

    const [uploaded, setUploaded] = useState([])
    const [importLoading, setImportLoading] = useState(false)
    const [uppingImport, setUppingImport] = useState(false)
    const [importOkay, setImportOkay] = useState(0)
    const [importErro, setImportErro] = useState(0)
    const [importPage, setImportPage] = useState(0)
    const [importView0, setImportView0] = useState(true)
    const [importView1, setImportView1] = useState(true)
    const [importView2, setImportView2] = useState(true)
    const [importView3, setImportView3] = useState(true)
    const [inBaseProps, setInBaseProps] = useState({})

    const fileTypes = ["text/csv", "application/vnd.ms-excel", "text/plain"]

    const [upd, setUpd] =  useState(false)
    function forceTheUpdate() {
        setUpd(!upd)
    }
    
    function exclusion(nivel) {
        deleteFunction(nivel, getValues(nivel, 'id'))
    }

    function openWindowRemove(nivel) {
        Swal.fire({
            title: "Atenção !",
            text: `Tem certeza que deseja excluir este Nivel ${nivel} e seus Subniveis ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            async result => {
                if(result.isConfirmed) {
                    exclusion(nivel)
                }
            }
        )
    }

    useEffect(() => {
        if(change == 1) {
            verififyBase()
        }
    }, [change])

    useEffect(() => {
        nivel1Get()
    }, [])

    useEffect(() => {
        nivel2Get(nivelLoc1)
    }, [nivelLoc1])

    useEffect(() => {
        nivel3Get(nivelLoc2)
    }, [nivelLoc2])
    
    useEffect(() => {
        nivel4Get(nivelLoc3)
    }, [nivelLoc3])

    useEffect(async () => {
        if(true) {
            let lv1 = []
            let lv2 = []
            let lv3 = []
            let lv4 = []
            let lvList = []
            setListExportLoading(true)
            await api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
                async response => {
                    response.data.map((item) => {
                        lvList.push([
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            item.id_nivel_loc3,
                            '',
                            '',
                            item.id_externo ? item.id_externo : '',
                            item.ativo,
                            item.descricao,
                            item.tag ? item.tag : '',
                            item.observacao ? item.observacao : ''
                        ])
                    })
                    await setListExport(lvList)
                }
            ).finally(() => {
                setListExportLoading(false)
            })
            await api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(
                async levels1 => {
                    lv1 = await levels1.data
                    await api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
                        async levels2 => {
                            lv2 = await levels2.data
                            await api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
                                async levels3 => {
                                    lv3 = await levels3.data
                                }
                            )
                        }
                    )
                }
            )
        }
    }, [])

    async function verififyBase() {
        let inBase = {
            nivel1: [],
            nivel2: [],
            nivel3: [],
            nivel4: []
        }

        setImportLoading(true)
        
        await api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel1.push({
                        _id: item._id,
                        descricao: item.descricao
                    })
                })
            }
        )
        await api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel2.push({
                        _id: item._id,
                        descricao: item.descricao,
                        id_nivel_loc1: item.id_nivel_loc1
                    })
                })
            }
        )
        await api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel3.push({
                        _id: item._id,
                        descricao: item.descricao,
                        id_nivel_loc2: item.id_nivel_loc2
                    })
                })
            }
        )
        await api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel4.push({
                        _id: item._id,
                        descricao: item.descricao,
                        id_nivel_loc3: item.id_nivel_loc3
                    })
                })
            }
        )

        setInBaseProps(inBase)
        setImportLoading(false)
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs
    const visualizarImpressao = async () => {
        let pdfFilter = await exportList.map((item, index) => {
            return [
                index + 1,
                exportTitle,
                item.modulo,
                formatDate(item.dt_alteracao),
                item.valor
            ]
        })

        const classeImpressao = await new Impressao(
            {
            hTitle: exportTitle,
            hItems: [
                "#",
                "NOME MÓD.",
                "MÓDULO",
                "DATA",
                "VALOR"
            ],
            bItems: pdfFilter
        });
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }

    function csv() {
        let head = [
            "NOME MÓD.",
            "MÓDULO",
            "DATA",
            "VALOR"
        ]

        let body = exportList.map((item) => {
            return [
                exportTitle,
                item.modulo,
                item.dt_alteracao,
                item.valor
            ]
        })
        return [...body]
    }
    
    function nivel1Get() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setNivelLoc1List(response.data)})
    }

    function nivel2Get(nivel1) {
        if(nivel1 != "*") {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1}/*/*/*/*`, {}
            ).then(response => {setNivelLoc2List(response.data)})
        }else {
            setNivelLoc2List([])
        }
    }

    function nivel3Get(nivel2) {
        if(nivel2 != "*") {
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2}/*/*/*/*`, {}
            ).then(response => {setNivelLoc3List(response.data)})
        }else {
            setNivelLoc3List([])
        }
    }

    function nivel4Get(nivel3) {
        if(nivel3 != "*") {
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel3}/*/*/*/*`, {}
            ).then(response => {setNivelLoc4List(response.data)})
        }else {
            setNivelLoc4List([])
        }
    }

    function getListLocalValue(id, title) {
        api.get(`registro_valor_local/${id}`).then(
            response => {
                if(response.data.length != 0) {
                    setExportTitle(title)
                    setExportList(response.data)
                    setChange(2)
                }else {
                    window.alert('Não há nada a ser exportado!')
                }
            }
        )
    }

    function cleaner() {
        setImportList([])
        setUploaded([])
        setImportLoading(false)
        setImportPage(0)
        setImportOkay(0)
        setImportErro(0)
        
        setExportTitle('')
        setExportList([])
        setChange(0)
    }

    function formatDate(data) {
        let dt = new Date(data)
        let dt_reg = ("0" + dt.getDate()).slice(-2) + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + dt.getFullYear()
        dt_reg += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
        return (dt_reg.slice(0,10) + ' ' + dt_reg.slice(11,19))
    }

    /*async function chooseFileImport(file) {
        const text = await file.text()
        const result = parse(text)
        const filter = result.data.filter(item => {
            if(!(item.length <= 1)) {return item}
        })
        setImportList(filter)
    }*/

    async function chooseFileImport(file) {
        setImportLoading(true)
        setUploaded([])
        setImportList([])
        setImportPage(0)
        setImportErro(0)
        const text = await file.text()
        const result = await parse(text)
        if(result.data.length <= 70000) {
            let erro = 0
            let okay = 0
            await setImportList(result.data.filter((item, index) => {
                if(!(item.length <= 1)) {
                    let importItem = item
                    if(
                        (item[17] != '' && item[12] == '') ||
                        (item[12] != '' && item[7] == '') ||
                        (item[7] != '' && item[2] == '')
                    ) {
                        importItem[23] = 'erro'
                        erro++
                    }else{
                        importItem[23] = 'ok'
                        okay++
                    }
                    return importItem
                }
            }))
            setImportOkay(okay)
            setImportErro(erro)
        }else {
            Swal.fire({
                title: "Ops... !",
                text: `Seu arquivo excede 70K de niveis`,
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })  
        }
        setImportLoading(false)
    }

    function colorImportList(stat) {
        if(stat == 'erro') {
            return {background: '#C40233', color: '#F8F8F8'}
        }else if(stat == 'repetido') {
            return {background: '#909090', color: '#F8F8F8'}
        }else if(stat == 'registrado') {
            return {background: '#338333', color: '#F8F8F8'}
        }else if(stat == 'ok'){
            return {}
        }
    }

    function importNumberListGet() {
        let list = []
        let qtdpages = Math.ceil((importList.filter((item) => {
            if(importView2 && item[23] == 'erro'){ return item}
            if(importView3 && item[23] == 'ok'){ return item}
        }).length / 1000))
        
        if(qtdpages >= 2 && qtdpages <= 5) {
            for(let i= 1; i <= qtdpages; i++) {
                list.push(i)
            }
        }else if(qtdpages > 5) {
            if(importPage <= 1) {
                for(let i= 1; i <= 5; i++) {
                    list.push(i)
                }
            } else if(importPage > 1 && importPage < (qtdpages - 2)) {
                for(let i= (importPage - 1); i <= (importPage + 3); i++) {
                    list.push(i)
                }
            } else {
                for(let i= (qtdpages - 4); i <= qtdpages; i++) {
                    list.push(i)
                }
            }
        }
        return list
    }

    function importViewChange(index) {
        setImportPage(0)
        switch (index) {
            case 0:
                setImportView0(!importView0)
                break;
            case 1:
                setImportView1(!importView1)
                break;
            case 2:
                setImportView2(!importView2)
                break;
            case 3:
                setImportView3(!importView3)
                break;
            default:
                break;
        }
    }

    function changeFunction(key) {
        switch (key) {
            case 0:
                return (
                    <div >
                        <Container fluid className="p-0">
                            <Row>
                                <Col><h3>{t('common.items_location')}</h3></Col>
                                <Col className="d-flex justify-content-end">
                                    <Button variant="light" className={sessionStorage.getItem('idConta') === 'c68AeC-CZ' && sessionStorage.getItem('perfil') == 'usu' || sessionStorage.getItem('idConta') === 'JAxNy36WU' && sessionStorage.getItem('perfil') == 'usu' ? "visually-hidden" :  ""} onClick={e => setChange(1)}>{t('actions.import')}</Button>
                                    {false ? <Button variant="light" onClick={e => setChange(3)}>{t('actions.export')}</Button> : ''}
                                </Col>
                            </Row>
                            <Row className="g-3">
                                {selectedFunction(nivelLoc1Display, 1)}
                                {selectedFunction(nivelLoc2Display, 2)}
                                {selectedFunction(nivelLoc3Display, 3)}
                                {selectedFunction(nivelLoc4Display, 4)}
                            </Row>
                        </Container>
                    </div>
                )
                break
            case 1:
                return (
                    <div >
                        {uppingImport ?
                            <div className='extra'>
                                <div style={{marginLeft: 'calc(50vw - 75px)', marginTop: 'calc(50vh - 75px)', minHeight: 'auto', height: '200px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                    </div>
                                </div>
                            </div>
                        :''}
                        <Container fluid>
                            <Row>
                                <Col><h3>{t('common.items_location')}</h3></Col>
                                <Col className="d-flex justify-content-end">
                                    <Button variant="light" onClick={e => {importSave()}}>{t('actions.import')}</Button>
                                    <Button variant="light" className="ms-2" onClick={e => {cleaner()}}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                </Col>
                            </Row>
                            <Col className="border rounded shadow-sm bg-white p-3">
                                <Row className="justify-content-center">
                                    {!importLoading ?
                                        <Col className="p-3" lg={6}>
                                            <input id='dropFile' style={{display: 'none'}} type='file' accept={fileTypes} onChange={e=> {
                                                e.preventDefault()
                                                if(e.target.files[0]) {
                                                    chooseFileImport(e.target.files[0])
                                                }
                                            }}/>
                                            <div 
                                                className="p-5 rounded d-flex justify-content-center align-items-center flex-column input-file"
                                                style={{
                                                    border:'3px dashed #d8e4f7'
                                                }}
                                                onDragOver={e=> {e.preventDefault()}}
                                                onDrop={e=> {
                                                    e.preventDefault()
                                                    console.log(e.dataTransfer.files[0])
                                                    if(fileTypes.includes(e.dataTransfer.files[0].type))
                                                    chooseFileImport(e.dataTransfer.files[0])
                                                }}
                                                onClick={e=>{
                                                    let getFile = window.document.getElementById('dropFile')
                                                    getFile.click()
                                                }}
                                            >
                                                <FiUpload fontSize={64} style={{color:'var(--primary-color)'}}/>
                                                <h6>{t('items.upload_file')} ( .CSV, .TXT)</h6>
                                            </div>
                                        </Col>
                                    : ' '}
                                </Row>
                                {!importLoading ?
                                    <div className='counter'>
                                        {importList.filter((item) => {
                                                        if(importView2 && item[23] == 'erro'){ return item}
                                                        if(importView3 && item[23] == 'ok'){ return item}
                                                    }).length > 1000 ? 
                                            <div className="pagesContent" style={{marginTop: '10px'}}>
                                                <div className="numberPages" onClick={e=> {importPage > 0 ? setImportPage(importPage - 1) : setImportPage(importPage)}}>Anterior</div>
                                                {importNumberListGet().map((number, index) => (
                                                    <div className="numberPages" key={index} onClick={e => {setImportPage(number - 1)}}>{number}</div>
                                                ))}
                                                <div className="numberPages" onClick={e=> {importPage < Math.ceil(importList.length / 1000) - 1 ? setImportPage(importPage + 1) : setImportPage(importPage)}}>Próximo</div>
                                                <div className="numberPages">{t('common.items')}: {importList.length}</div>
                                                <div className="numberPages">{t('common.imported')}: {uploaded.length}</div>
                                            </div>
                                        :
                                            <div className="pagesContent" style={{marginTop: '10px'}}>
                                                <div className="numberPages">{t('common.items')}: {importList.length}</div>
                                                <div className="numberPages">{t('common.imported')}: {uploaded.length}</div>
                                            </div>
                                        }
                                        <Col className='d-flex flex-row justify-content-end'>
                                            <div className="d-flex flex-row align-items-center me-3">
                                                <div className="custom-checkbox fw-bold me-1 border" style={{backgroundColor: '#FFFFFF', textAlign:'center', cursor: 'pointer'}} onClick={e=>{importViewChange(3)}}>{importView3 ? '✓' : ''}</div> Okay: {importOkay}
                                            </div>
                                            <div className="d-flex flex-row align-items-center me-3">
                                                <div className="custom-checkbox fw-bold me-1 text-white" style={{backgroundColor: '#C40233', textAlign:'center', cursor: 'pointer'}} onClick={e=>{importViewChange(2)}}>{importView2 ? '✓' : ''}</div> {t('common.items_incorrect')}: {importErro}
                                            </div>
                                        </Col>
                                    </div> : ''}
                                    {importLoading ?
                                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '150px'}}>
                                            <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                        </div>
                                    :
                                        <div className="list">
                                            <Table responsive>
                                                <thead>
                                                    <tr id="header">
                                                        <th>#</th>
                                                        <th>CÓD._EXTERNO_1</th>
                                                        <th>ATIVO_1</th>
                                                        <th>NIVEL_1</th>
                                                        <th>TAG_1</th>
                                                        <th>OBSERVAÇÃO_1</th>
                                                        <th>CÓD._EXTERNO_2</th>
                                                        <th>ATIVO_2</th>
                                                        <th>NIVEL_2</th>
                                                        <th>TAG_2</th>
                                                        <th>OBSERVAÇÃO_2</th>
                                                        <th>CÓD._EXTERNO_3</th>
                                                        <th>ATIVO_3</th>
                                                        <th>NIVEL_3</th>
                                                        <th>TAG_3</th>
                                                        <th>OBSERVAÇÃO_3</th>
                                                        <th>CÓD._EXTERNO_4</th>
                                                        <th>ATIVO_4</th>
                                                        <th>NIVEL_4</th>
                                                        <th>TAG_4</th>
                                                        <th>OBSERVAÇÃO_4</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {importList.filter((item) => {
                                                            //if(importView0 && item[23] == 'registrado'){ return item}
                                                            //if(importView1 && item[23] == 'repetido'){ return item}
                                                            if(importView2 && item[23] == 'erro'){ return item}
                                                            if(importView3 && item[23] == 'ok'){ return item}
                                                        }).slice(importPage * 1000, (importPage * 1000) + 1000).map((item, index) => (
                                                            <tr
                                                                key={index}
                                                                style={colorImportList(item[23])}
                                                            >
                                                                <th>{index + 1}</th>
                                                                <th>{item[0]}</th>
                                                                <th>{item[1]}</th>
                                                                <th>{item[2]}</th>
                                                                <th>{item[3]}</th>
                                                                <th>{item[4]}</th>
                                                                <th>{item[5]}</th>
                                                                <th>{item[6]}</th>
                                                                <th>{item[7]}</th>
                                                                <th>{item[8]}</th>
                                                                <th>{item[9]}</th>
                                                                <th>{item[10]}</th>
                                                                <th>{item[11]}</th>
                                                                <th>{item[12]}</th>
                                                                <th>{item[13]}</th>
                                                                <th>{item[14]}</th>
                                                                <th>{item[15]}</th>
                                                                <th>{item[16]}</th>
                                                                <th>{item[17]}</th>
                                                                <th>{item[18]}</th>
                                                                <th>{item[19]}</th>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                            </Col>
                        </Container>
                    </div>
                )
                break
            case 2:
                return (
                    <div >                  
                        <div className="title" id="colection">
                            <h1>Valores do Local</h1>
                            <div>
                                <button onClick={e => cleaner()}>VOLTAR</button>
                            </div>
                        </div>
                        <div className="export">
                            <button onClick={visualizarImpressao}><FaFilePdf className="dIcons"/></button>
                            <CSVLink className="csv" data={csv()} separator={';'}>
                                <button><FaFileCsv className="dIcons"/></button>
                            </CSVLink>
                        </div>
                        <div>
                            <Table responsive>
                                <thead>
                                    <tr id="header">
                                        <th>#</th>
                                        <th>NOME MÓD.</th>
                                        <th>MÓDULO</th>
                                        <th>DATA</th>
                                        <th>VALOR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {exportList.map((item, index) => {
                                    return (
                                        <tr
                                            key={index}
                                        >
                                            <th>{index + 1}</th>
                                            <th>{exportTitle}</th>
                                            <th>
                                                <div>{item.modulo}</div>
                                            </th>
                                            <th>{formatDate(item.dt_alteracao)}</th>
                                            <th style={{fontWeight: 'bold', color: '#2488f7'}}>{item.valor}</th>
                                        </tr>
                                    )})}
                                </tbody>
                            </Table>
                        </div>  
                    </div>
                )
                break
            case 3:
                return (
                    <div >                  
                        <div className="title" id="colection">
                            <h1>{t('common.items_location')}</h1>
                            <div>
                                <button onClick={e => cleaner()}>{t('actions.exit')}</button>
                            </div>
                        </div>
                        {listExportLoading ? '' :
                            <div className="export">
                                <button onClick={visualizarImpressao}><FaFilePdf className="dIcons"/></button>
                                <CSVLink className="csv" data={csv()} separator={';'}>
                                    <button><FaFileCsv className="dIcons"/></button>
                                </CSVLink>
                            </div>
                        }
                        {listExportLoading ?
                            <div style={{marginTop:'150px'}}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                </div>
                            </div>
                            :
                            <div className="list">
                                <table>
                                    <thead>
                                        <tr id="header">
                                            <th>#</th>
                                            <th>CÓD._EXTERNO_1</th>
                                            <th>ATIVO_1</th>
                                            <th>NIVEL_1</th>
                                            <th>TAG_1</th>
                                            <th>OBSERVAÇÃO_1</th>
                                            <th>CÓD._EXTERNO_2</th>
                                            <th>ATIVO_2</th>
                                            <th>NIVEL_2</th>
                                            <th>TAG_2</th>
                                            <th>OBSERVAÇÃO_2</th>
                                            <th>CÓD._EXTERNO_3</th>
                                            <th>ATIVO_3</th>
                                            <th>NIVEL_3</th>
                                            <th>TAG_3</th>
                                            <th>OBSERVAÇÃO_3</th>
                                            <th>CÓD._EXTERNO_4</th>
                                            <th>ATIVO_4</th>
                                            <th>NIVEL_4</th>
                                            <th>TAG_4</th>
                                            <th>OBSERVAÇÃO_4</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listExport.map((item, index) => {
                                            <tr>
                                                <th>{index + 1}</th>
                                                <th>{item[0]}</th>
                                                <th>{item[1]}</th>
                                                <th>{item[2]}</th>
                                                <th>{item[3]}</th>
                                                <th>{item[4]}</th>
                                                <th>{item[5]}</th>
                                                <th>{item[6]}</th>
                                                <th>{item[7]}</th>
                                                <th>{item[8]}</th>
                                                <th>{item[9]}</th>
                                                <th>{item[10]}</th>
                                                <th>{item[11]}</th>
                                                <th>{item[12]}</th>
                                                <th>{item[13]}</th>
                                                <th>{item[14]}</th>
                                                <th>{item[15]}</th>
                                                <th>{item[16]}</th>
                                                <th>{item[17]}</th>
                                                <th>{item[18]}</th>
                                                <th>{item[19]}</th>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                )
                break
            default:
                <div >Error</div>
                break
        }
    }

    function selectedFunction(display, nivel) {
        if((nivel == 1) || (nivel == 2 && nivelLoc1 != '*') ||
        (nivel == 3 && nivelLoc2 != '*') || (nivel == 4 && nivelLoc3 != '*')){
            if(display) {
                return (
                    <>
                        <Col className="rounded border bg-white shadow-sm p-0 me-2 h-100" style={{maxWidth:'350px'}}>
                            <Col className="px-3 py-2 border-bottom d-flex justify-content-between">
                            <h5>
                                {sessionStorage.getItem('idConta') === 'c68AeC-CZ' && nivel === 1
                                ? 'GEO'
                                : sessionStorage.getItem('idConta') === 'c68AeC-CZ' && nivel === 2
                                ? 'Unidade'
                                : sessionStorage.getItem('idConta') === 'c68AeC-CZ' && nivel === 3
                                ? 'Área'
                                : sessionStorage.getItem('idConta') === 'c68AeC-CZ' && nivel === 4
                                ? 'Departamento'
                                : `${t('common.level')} ${nivel}`}
                            </h5>
                                <CloseButton onClick={e => {setValues(nivel, 'display', false)}}/>
                            </Col>
                            <Row className="p-2 g-1">
                                <Form.Group>
                                    <Form.Label>Nível</Form.Label>
                                    <Form.Select
                                        value={getValues(nivel, 'nivel')}
                                        onChange={e => setValues(nivel, 'nivel', e.target.value)}
                                    >
                                        <option value={"*"}></option>
                                        {getValues(nivel, 'lista').map((item, index) => (
                                        <option key={index} value={item._id}>{item.descricao}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={getValues(nivel, 'descricao')}
                                        onChange={e=> {setValues(nivel, 'descricao', e.target.value)}}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Tag</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={getValues(nivel, 'tag')}
                                        onChange={e=> {setValues(nivel, 'tag', e.target.value)}}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Ativo</Form.Label>
                                    <Form.Select
                                        value={getValues(nivel, 'ativo')}
                                        onChange={e => {setValues(nivel, 'ativo', e.target.value)}}
                                    >
                                        <option value={'0'}>Não</option>
                                        <option value={'1'}>Sim</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={getValues(nivel, 'observacao')}
                                        onChange={e=> {setValues(nivel, 'observacao', e.target.value)}}
                                    />
                                </Form.Group>
                            </Row>
                            <Col className='p-2'>
                                {getValues(nivel, 'id') == '0' || getValues(nivel, 'id') == '*' ? '' : <Button variant="light" className="text-uppercase w-100" onClick={e => {getListLocalValue(getValues(nivel, 'id'), getValues(nivel, 'descricao'))}}><FiLogOut className="me-2"/> Exportar valores</Button>}
                                {sessionStorage.getItem('idConta') === 'c68AeC-CZ' && sessionStorage.getItem('perfil') == 'usu' || sessionStorage.getItem('idConta') === 'JAxNy36WU' && sessionStorage.getItem('perfil') == 'usu' ? '' 
                                :
                                    <>
                                        {getValues(nivel, 'id') == '0' || getValues(nivel, 'id') == '*' ? '' : <Button variant="outline-dark" className="w-100 my-2" onClick={e => {openWindowRemove(nivel)}}>EXCLUIR</Button>}
                                        <Button variant="custom-danger" className="w-100" onClick={e => {save(nivel)}}>SALVAR</Button>
                                    </>
                                }
                            </Col>
                        </Col>
                    </>
                )
            }else {
                return (
                    <Col lg={3} className="border bg-white rounded h-100 p-2">
                        <Col className="border-bottom p-2"><h4>{t('common.level')}</h4></Col>
                        <Form.Group className="mt-2">
                            <Form.Label>{t('common.item_select')}</Form.Label>
                            <Form.Select
                                value={getValues(nivel, 'nivel')} 
                                onChange={e => {setValues(nivel, 'nivel', e.target.value)}}
                            >
                                <option value={"*"}></option>
                                {getValues(nivel, 'lista').map((item, index) => (
                                    <option key={index} value={item._id}>{item.descricao}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Button as={Col} className={sessionStorage.getItem('idConta') === 'c68AeC-CZ' && sessionStorage.getItem('perfil') == 'usu' || sessionStorage.getItem('idConta') === 'JAxNy36WU' && sessionStorage.getItem('perfil') == 'usu' ? "visually-hidden" :  "w-100 my-3"} onClick={e => {setValues(nivel, 'display', true)}}>{t('actions.new')}</Button>
                    </Col>
                )
            }
        }
    }

    function getValues(nivel, element) {
        switch (nivel) {
            case 1:
                switch (element) {
                    case 'nivel':
                        return nivelLoc1
                        break
                    case 'lista':
                        return nivelLoc1List
                        break
                    case 'descricao':
                        return descricao1
                        break
                    case 'tag':
                        return tag1 ? tag1 : ''
                        break
                    case 'ativo':
                        return ativo1
                        break
                    case 'observacao':
                        return observacao1 ? observacao1 : '' 
                        break
                    case 'id':
                        return idNivel1
                        break
                    default:
                        break
                }
                break
            case 2:
                switch (element) {
                    case 'nivel':
                        return nivelLoc2
                        break
                    case 'lista':
                        return nivelLoc2List
                        break
                    case 'descricao':
                        return descricao2
                        break
                    case 'tag':
                        return tag2 ? tag2 : ''
                        break
                    case 'ativo':
                        return ativo2
                        break
                    case 'observacao':
                        return observacao2 ? observacao2 : ''
                        break
                    case 'id':
                        return idNivel2
                        break
                    default:
                        break
                }
                break
            case 3:
                switch (element) {
                    case 'nivel':
                        return nivelLoc3
                        break
                    case 'lista':
                        return nivelLoc3List
                        break
                    case 'descricao':
                        return descricao3
                        break
                    case 'tag':
                        return tag3 ? tag3 : ''
                        break
                    case 'ativo':
                        return ativo3
                        break
                    case 'observacao':
                        return observacao3 ? observacao3 : ''
                        break
                    case 'id':
                        return idNivel3
                        break
                    default:
                        break
                }
                break
            case 4:
                switch (element) {
                    case 'nivel':
                        return nivelLoc4
                        break
                    case 'lista':
                        return nivelLoc4List
                        break
                    case 'descricao':
                        return descricao4
                        break
                    case 'tag':
                        return tag4 ? tag4 : ''
                        break
                    case 'ativo':
                        return ativo4
                        break
                    case 'observacao':
                        return observacao4 ? observacao4 : ''
                        break
                    case 'id':
                        return idNivel4
                        break
                    default:
                        break
                }
                break
            default:
                break
        }
    }

    function setValues(nivel, element, value, itemInfos=null) {
        switch (nivel) {
            case 1:
                switch (element) {
                    case 'nivel':
                        if(value != '*') {
                            if(itemInfos != null) {
                                setIdNivel1(itemInfos._id)
                                setDescricao1(itemInfos.descricao)
                                setIdExterno1(itemInfos.id_externo)
                                setTag1(itemInfos.tag)
                                setAtivo1(itemInfos.ativo)
                                setObservacao1(itemInfos.observacao)
                                setNivelLoc1Display(true)
                            }else{
                                nivelLoc1List.map((item) => {
                                    if(item._id == value){
                                        console.log('achou o id')
                                        setIdNivel1(item._id)
                                        setDescricao1(item.descricao)
                                        setIdExterno1(item.id_externo)
                                        setTag1(item.tag)
                                        setAtivo1(item.ativo)
                                        setObservacao1(item.observacao)
                                        setNivelLoc1Display(true)
                                    }
                                })
                            }
                        }else {
                            setIdNivel1('0')
                            setDescricao1('')
                            setIdExterno1('')
                            setTag1('')
                            setAtivo1('1')
                            setObservacao1('')
                            setNivelLoc1Display(false)
                        }
                        setValues(2, 'nivel', '*')
                        setValues(3, 'nivel', '*')
                        setValues(4, 'nivel', '*')
                        setNivelLoc1(value)
                        break
                    case 'descricao':
                        setDescricao1(value)
                        break
                    case 'tag':
                        setTag1(value)
                        break
                    case 'ativo':
                        setAtivo1(value)
                        break
                    case 'observacao':
                        setObservacao1(value)
                        break
                    case 'display':
                        if(value == false) {
                            setNivelLoc1('*')
                            setIdNivel1('0')
                            setDescricao1('')
                            setIdExterno1('')
                            setTag1('')
                            setAtivo1('1')
                            setObservacao1('')
                            setObservacao1('')
                        }
                        setValues(2, 'nivel', '*')
                        setValues(3, 'nivel', '*')
                        setValues(4, 'nivel', '*')
                        setNivelLoc1Display(value)
                        break
                    default:
                        break
                }
                break
            case 2:
                switch (element) {
                    case 'nivel':
                        if(value != '*') {
                            if(itemInfos != null) {
                                setIdNivel2(itemInfos._id)
                                setDescricao2(itemInfos.descricao)
                                setIdExterno2(itemInfos.id_externo)
                                setTag2(itemInfos.tag)
                                setAtivo2(itemInfos.ativo)
                                setObservacao2(itemInfos.observacao)
                                setNivelLoc2Display(true)
                            }else{
                                nivelLoc2List.map((item) => {
                                    if(item._id == value){
                                        setIdNivel2(item._id)
                                        setDescricao2(item.descricao)
                                        setIdExterno2(item.id_externo)
                                        setTag2(item.tag)
                                        setAtivo2(item.ativo)
                                        setObservacao2(item.observacao)
                                        setNivelLoc2Display(true)
                                    }
                                })
                            }
                        }else {
                            setIdNivel2('0')
                            setDescricao2('')
                            setIdExterno2('')
                            setTag2('')
                            setAtivo2('1')
                            setObservacao2('')
                            setNivelLoc2Display(false)
                        }
                        setValues(3, 'nivel', '*')
                        setValues(4, 'nivel', '*')
                        setNivelLoc2(value)
                        break
                    case 'descricao':
                        setDescricao2(value)
                        break
                    case 'tag':
                        setTag2(value)
                        break
                    case 'ativo':
                        setAtivo2(value)
                        break
                    case 'observacao':
                        setObservacao2(value)
                        break
                    case 'display':
                        if(value == false) {
                            setNivelLoc2('*')
                            setIdNivel2('0')
                            setDescricao2('')
                            setIdExterno2('')
                            setTag2('')
                            setAtivo2('1')
                            setObservacao2('')
                        }
                        setValues(3, 'nivel', '*')
                        setValues(4, 'nivel', '*')
                        setNivelLoc2Display(value)
                        break
                    default:
                        break
                }
                break
            case 3:
                switch (element) {
                    case 'nivel':
                        if(value != '*') {
                            if(itemInfos != null) {
                                setIdNivel3(itemInfos._id)
                                setDescricao3(itemInfos.descricao)
                                setIdExterno3(itemInfos.id_externo)
                                setTag3(itemInfos.tag)
                                setAtivo3(itemInfos.ativo)
                                setObservacao3(itemInfos.observacao)
                                setNivelLoc3Display(true)
                            }else{
                                nivelLoc3List.map((item) => {
                                    if(item._id == value){
                                        setIdNivel3(item._id)
                                        setDescricao3(item.descricao)
                                        setIdExterno3(item.id_externo)
                                        setTag3(item.tag)
                                        setAtivo3(item.ativo)
                                        setObservacao3(item.observacao)
                                        setNivelLoc3Display(true)
                                    }
                                })
                            }
                        }else {
                            setIdNivel3('0')
                            setDescricao3('')
                            setIdExterno3('')
                            setTag3('')
                            setAtivo3('1')
                            setObservacao3('')
                            setNivelLoc3Display(false)
                        }
                        setValues(4, 'nivel', '*')
                        setNivelLoc3(value)
                        break
                    case 'descricao':
                        setDescricao3(value)
                        break
                    case 'tag':
                        setTag3(value)
                        break
                    case 'ativo':
                        setAtivo3(value)
                        break
                    case 'observacao':
                        setObservacao3(value)
                        break
                    case 'display':
                        if(value == false) {
                            setNivelLoc3('*')
                            setIdNivel3('0')
                            setDescricao3('')
                            setIdExterno3('')
                            setTag3('')
                            setAtivo3('1')
                            setObservacao3('')
                        }
                        setValues(4, 'nivel', '*')
                        setNivelLoc3Display(value)
                        break
                    default:
                        break
                }
                break
            case 4:
                switch (element) {
                    case 'nivel':
                        if(value != '*') {
                            if(itemInfos != null) {
                                setIdNivel4(itemInfos._id)
                                setDescricao4(itemInfos.descricao)
                                setIdExterno4(itemInfos.id_externo)
                                setTag4(itemInfos.tag)
                                setAtivo4(itemInfos.ativo)
                                setObservacao4(itemInfos.observacao)
                                setNivelLoc4Display(true)
                            }else{
                                nivelLoc4List.map((item) => {
                                    if(item._id == value){
                                        setIdNivel4(item._id)
                                        setDescricao4(item.descricao)
                                        setIdExterno4(item.id_externo)
                                        setTag4(item.tag)
                                        setAtivo4(item.ativo)
                                        setObservacao4(item.observacao)
                                        setNivelLoc4Display(true)
                                    }
                                })
                            }
                        }else {
                            setIdNivel4('0')
                            setDescricao4('')
                            setIdExterno4('')
                            setTag4('')
                            setAtivo4('1')
                            setObservacao4('')
                            setNivelLoc4Display(false)
                        }
                        setNivelLoc4(value)
                        break
                    case 'descricao':
                        setDescricao4(value)
                        break
                    case 'tag':
                        setTag4(value)
                        break
                    case 'ativo':
                        setAtivo4(value)
                        break
                    case 'observacao':
                        setObservacao4(value)
                        break
                    case 'display':
                        if(value == false) {
                            setNivelLoc4('*')
                            setIdNivel4('0')
                            setDescricao4('')
                            setIdExterno4('')
                            setTag4('')
                            setAtivo4('1')
                            setObservacao4('')
                        }
                        setNivelLoc4Display(value)
                        break
                    default:
                        break
                }
                break
            default:
                break
        }
    }

    function save(nivel) {
        switch (nivel) {
            case 1:
                if(descricao1 == '') {
                    window.alert('Ops...\nInforme a descrição do Local 1')
                }else {
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    let reg = []
                    reg.push({
                        ativo: ativo1,
                        descricao: descricao1,
                        id_conta: sessionStorage.getItem('idConta'),
                        id_externo: idExterno1,
                        observacao: observacao1,
                        tag: tag1
                    })

                    if(idNivel1 != '0') {
                        reg[0]['_id'] = idNivel1
                    }

                    api.post('/nivel_loc1', reg, option
                    ).then(
                        async response => {
                            window.alert("Registrado !\nLocal 1 salvo com sucesso")
                            nivel1Get()
                            setValues(nivel, 'nivel', response.data[0]._id, response.data[0])
                        },
                        response => {
                            window.alert(`Temos um problema...\n${response.data.error}`)
                        }
                    )
                }
                break
            case 2:
                if(descricao2 == '') {
                    window.alert('Ops...\nInforme a descrição do Local 2')
                }else {
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    let reg = []
                    reg.push({
                        ativo: ativo2,
                        descricao: descricao2,
                        id_conta: sessionStorage.getItem('idConta'),
                        id_externo: idExterno2,
                        id_nivel_loc1: idNivel1,
                        observacao: observacao2,
                        tag: tag2
                    })

                    if(idNivel2 != '0') {
                        reg[0]['_id'] = idNivel2
                    }

                    api.post('/nivel_loc2', reg, option
                    ).then(
                        async response => {
                            await nivel2Get(response.data[0].id_nivel_loc1)
                            setValues(nivel, 'nivel', response.data[0]._id, response.data[0])
                            window.alert("Registrado !\nLocal 2 salvo com sucesso")
                        },
                        response => {
                            window.alert(`Temos um problema...\n${response.data.error}`)
                        }
                    )
                }
                break
            case 3:
                if(descricao3 == '') {
                    window.alert('Ops...\nInforme a descrição do Local 3')
                }else {
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    let reg = []
                    reg.push({
                        ativo: ativo3,
                        descricao: descricao3,
                        id_conta: sessionStorage.getItem('idConta'),
                        id_externo: idExterno3,
                        id_nivel_loc2: idNivel2,
                        observacao: observacao3,
                        tag: tag3
                    })

                    if(idNivel3 != '0') {
                        reg[0]['_id'] = idNivel3
                    }

                    api.post('/nivel_loc3', reg, option
                    ).then(
                        async response => {
                            await nivel3Get(response.data[0].id_nivel_loc2)
                            setValues(nivel, 'nivel', response.data[0]._id, response.data[0])
                            window.alert("Registrado !\nLocal 3 salvo com sucesso")
                        },
                        response => {
                            window.alert(`Temos um problema...\n${response.data.error}`)
                        }
                    )
                }
                break
            case 4:
                if(descricao4 == '') {
                    window.alert('Ops...\nInforme a descrição do Local 4')
                }else {
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    let reg = []
                    reg.push({
                        ativo: ativo4,
                        descricao: descricao4,
                        id_conta: sessionStorage.getItem('idConta'),
                        id_externo: idExterno4,
                        id_nivel_loc3: idNivel3,
                        observacao: observacao4,
                        tag: tag4
                    })

                    if(idNivel4 != '0') {
                        reg[0]['_id'] = idNivel4
                    }
    
                    api.post('/nivel_loc4', reg, option
                    ).then(
                        async response => {
                            await nivel4Get(response.data[0].id_nivel_loc3)
                            setValues(nivel, 'nivel', response.data[0]._id, response.data[0])
                            window.alert("Registrado !\nLocal 4 salvo com sucesso")
                        },
                        response => {
                            window.alert(`Temos um problema...\n${response.data.error}`)
                        }
                    )
                }
                break
            default:
                break
        }
    }

    function deleteFunction(nivel, id) {
        switch (nivel) {
            case 1:
                del_nivel1(id, true)
                break
            case 2:
                del_nivel2(id, true)
                break
            case 3:
                del_nivel3(id, true)
                break
            case 4:
                del_nivel4(id, true)
                break
            default:
                break
        }
    }

    function del_nivel1(id, caller = false) {
        api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${id}/*/*/*/*`).then(
            response => {
                let delNivelList = response.data
                if(delNivelList.length == 0) {
                    api.delete(`/nivel_loc1/${id}`).then(
                        response => {
                            if(caller){
                                setValues(1, 'nivel', '*')
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: 'Este nivel foi excluido de sua base',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                nivel1Get()
                            }
                        },
                        response => {
                            if(caller){
                                if(response.response.data.error && response.response.data.error == 'Existe itens relacionado a esse nível!') {
                                    Swal.fire({
                                        title: "Ops... !",
                                        html: 'Ainda há itens vinculados a este nivel</br>Exclua estes itens, desvincule ou altere este nivel nestes itens',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '4000'
                                    })
                                }else{
                                    Swal.fire({
                                        title: "Ops... !",
                                        text: 'Algo deu errado, por favor tente novamente mais tarde',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                }
                            }
                        }
                    )
                }else {
                    delNivelList.map((loc, index) => {
                        del_nivel2(loc._id)
                        if(index == (delNivelList.length - 1)){
                            api.delete(`/nivel_loc1/${id}`).then(
                                response => {
                                    if(caller){
                                        setValues(1, 'nivel', '*')
                                        window.alert('Nivel 1 deletado!')
                                        nivel1Get()
                                    }
                                },
                                response => {
                                    if(caller){
                                        if(response.response.data.error && response.response.data.error == 'Existe itens relacionado a esse nível!') {
                                            Swal.fire({
                                                title: "Ops... !",
                                                html: 'Ainda há itens vinculados a este nivel</br>Exclua estes itens, desvincule ou altere este nivel nestes itens',
                                                icon: 'error',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '4000'
                                            })
                                        }else{
                                            Swal.fire({
                                                title: "Ops... !",
                                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                                icon: 'error',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            }
        )
    }
    function del_nivel2(id, caller = false) {
        api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${id}/*/*/*/*`).then(
            response => {
                let delNivelList = response.data
                if(delNivelList.length == 0) {
                    api.delete(`/nivel_loc2/${id}`).then(
                        response => {
                            if(caller){
                                setValues(2, 'display', false)
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: 'Este nivel foi excluido de sua base',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                nivel2Get(idNivel1)
                            }
                        },
                        response => {
                            if(caller){
                                if(response.response.data.error && response.response.data.error == 'Existe itens relacionado a esse nível!') {
                                    Swal.fire({
                                        title: "Ops... !",
                                        html: 'Ainda há itens vinculados a este nivel</br>Exclua estes itens, desvincule ou altere este nivel nestes itens',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '4000'
                                    })
                                }else{
                                    Swal.fire({
                                        title: "Ops... !",
                                        text: 'Algo deu errado, por favor tente novamente mais tarde',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                }
                            }
                        }
                    )
                }else {
                    delNivelList.map((loc, index) => {
                        del_nivel3(loc._id)
                        if(index == (delNivelList.length - 1)){
                            api.delete(`/nivel_loc2/${id}`).then(
                                response => {
                                    if(caller){
                                        setValues(2, 'display', false)
                                        Swal.fire({
                                            title: "Sucesso !",
                                            text: 'Este nivel foi excluido de sua base',
                                            icon: 'success',
                                            showConfirmButton: false,
                                            timerProgressBar: true,
                                            timer: '2500'
                                        })
                                        nivel2Get(idNivel1)
                                    }
                                },
                                response => {
                                    if(caller){
                                        if(response.response.data.error && response.response.data.error == 'Existe itens relacionado a esse nível!') {
                                            Swal.fire({
                                                title: "Ops... !",
                                                html: 'Ainda há itens vinculados a este nivel</br>Exclua estes itens, desvincule ou altere este nivel nestes itens',
                                                icon: 'error',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '4000'
                                            })
                                        }else{
                                            Swal.fire({
                                                title: "Ops... !",
                                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                                icon: 'error',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            }
        )
    }
    function del_nivel3(id, caller = false) {
        api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${id}/*/*/*/*`).then(
            response => {
                let delNivelList = response.data
                if(delNivelList.length == 0) {
                    api.delete(`/nivel_loc3/${id}`).then(
                        response => {
                            if(caller){
                                setValues(3, 'display', false)
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: 'Este nivel foi excluido de sua base',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                nivel3Get(idNivel2)
                            }
                        },
                        response => {
                            if(caller){
                                if(response.response.data.error && response.response.data.error == 'Existe itens relacionado a esse nível!') {
                                    Swal.fire({
                                        title: "Ops... !",
                                        html: 'Ainda há itens vinculados a este nivel</br>Exclua estes itens, desvincule ou altere este nivel nestes itens',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '4000'
                                    })
                                }else{
                                    Swal.fire({
                                        title: "Ops... !",
                                        text: 'Algo deu errado, por favor tente novamente mais tarde',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                }
                            }
                        }
                    )
                }else {
                    delNivelList.map((loc, index) => {
                        del_nivel4(loc._id)
                        if(index == (delNivelList.length - 1)){
                            api.delete(`/nivel_loc3/${id}`).then(
                                response => {
                                    if(caller){
                                        setValues(3, 'display', false)
                                        window.alert('Nivel 3 deletado!')
                                        nivel3Get(idNivel2)
                                    }
                                },
                                response => {
                                    if(caller){
                                        if(response.response.data.error && response.response.data.error == 'Existe itens relacionado a esse nível!') {
                                            Swal.fire({
                                                title: "Ops... !",
                                                html: 'Ainda há itens vinculados a este nivel</br>Exclua estes itens, desvincule ou altere este nivel nestes itens',
                                                icon: 'error',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '4000'
                                            })
                                        }else{
                                            Swal.fire({
                                                title: "Ops... !",
                                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                                icon: 'error',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            }
        )
    }
    function del_nivel4(id, caller = false) {
        api.delete(`/nivel_loc4/${id}`).then(
            response => {
                if(caller){
                    setValues(4, 'display', false)
                    Swal.fire({
                        title: "Sucesso !",
                        text: 'Este nivel foi excluido de sua base',
                        icon: 'success',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                    nivel4Get(idNivel3)
                }
            },
            response => {
                if(caller){
                    if(response.response.data.error && response.response.data.error == 'Existe itens relacionado a esse nível!') {
                        Swal.fire({
                            title: "Ops... !",
                            html: 'Ainda há itens vinculados a este nivel</br>Exclua estes itens, desvincule ou altere este nivel nestes itens',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '4000'
                        })
                    }else{
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Algo deu errado, por favor tente novamente mais tarde',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }
                }
            }
        )
    }
    
    function getID(){	
        return 'xxxxxxxx-yxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })
    }

    async function importSave() {
        if(importList.length == uploaded) {
            window.alert('Não há nada a ser importado!')
        }else {
            let nivel1I = []
            let nivel2I = []
            let nivel3I = []
            let nivel4I = []
            let status = true

            importList.map(async (item, index) => {
                if(status) {
                    let ok = true
                    if(item[2] != ''){ok = true
                    nivel1I.map((it) => {
                        if(it.descricao == item[2]) {
                            ok = false
                        }
                    })
                    if(ok) {
                        nivel1I.push({
                            descricao: item[2],
                            id_conta: sessionStorage.getItem('idConta'),
                            _id: getID()
                        })}
                    }
                
                    if(item[7] != ''){ok = true
                    let n1id
                    nivel1I.map(it => {
                        if(it.descricao == item[2]) {
                            n1id = it._id
                        }
                    })
                    nivel2I.map((it) => {
                        if(it.descricao == item[7]) {
                            ok = false
                        }
                    })
                    if(ok){
                        nivel2I.push({
                            descricao: item[7],
                            id_conta: sessionStorage.getItem('idConta'),
                            id_nivel_loc1: n1id,
                            _id: getID()
                        })}
                    }

                    if(item[12] != '') {ok = true
                    let n2id
                    nivel2I.map(it => {
                        if(it.descricao == item[7]) {
                            n2id = it._id
                        }
                    })
                    nivel3I.map((it) => {
                        if(it.descricao == item[12]) {
                            ok = false
                        }
                    })
                    if(ok) {
                        nivel3I.push({
                            descricao: item[12],
                            id_conta: sessionStorage.getItem('idConta'),
                            id_nivel_loc2: n2id,
                            _id: getID()
                        })}
                    }

                    if(item[17] != '') {ok = true
                    let n3id
                    nivel3I.map(it => {
                        if(it.descricao == item[12]) {
                            n3id = it._id
                        }
                    })
                    nivel4I.map((it) => {
                        if(it.descricao == item[17]) {
                            ok = false
                        }
                    })
                    if(ok){
                        nivel4I.push({
                            descricao: item[17],
                            id_conta: sessionStorage.getItem('idConta'),
                            id_nivel_loc3: n3id,
                            _id: getID()
                        })}
                    }
                }else{
                    status = false
                }
            })
            if(status == false) {
                window.alert('Ops, há algo errado no arquivo')
            }

            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
            api.post(`/itensDef/${sessionStorage.getItem('idConta')}`, [{
                nivel1: nivel1I,
                nivel2: nivel2I,
                nivel3: nivel3I,
                nivel4: nivel4I
            }], option).then(
                response => {
                    window.alert('Localizações importadas com sucesso')
                    setUploaded(importList.length)
                    nivel1Get()
                }
            )
        }
    }

    function importSave() {
        Swal.fire({
            title: "Atenção !",
            html: 'Níveis incorretos serão ignorados.<br/>Itens já registrados serão atualizados.<br/>Tem certeza que deseja importar estes itens?',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            async result => {
                if(result.isConfirmed) {
                    setUppingImport(true)
                    let nivel1I = []
                    let nivel2I = []
                    let nivel3I = []
                    let nivel4I = []
                    let importado = []
                    let status = true
                    
                    await importList.map(async (item, index) => {
                        if(item[23] == 'ok' || item[23] == 'registrado') {
                            if(status) {
                                if(item[2] != ''){
                                    let ok = true
                                    let have = false
                                
                                    if(item[2] != ''){
                                        ok = true
                                        nivel1I.map((it) => {
                                            if(it.descricao == item[2]) {
                                                ok = false
                                            }
                                        })
                                        if(ok) {
                                            have = false
                                            inBaseProps.nivel1.map((itemB) => {
                                                if(itemB.descricao == item[2]) {
                                                    nivel1I.push({
                                                        descricao: itemB.descricao,
                                                        id_conta: sessionStorage.getItem('idConta'),
                                                        _id: itemB._id
                                                    })
                                                    have = true
                                                }
                                            })
                                            if(!have){
                                                nivel1I.push({
                                                    descricao: item[2],
                                                    id_conta: sessionStorage.getItem('idConta'),
                                                    _id: getID()
                                                })
                                            }
                                        }
                                        if(item[7] != ''){
                                            ok = true
                                            let n1id
                                            nivel1I.map(it => {
                                                if(it.descricao == item[2]) {
                                                    n1id = it._id
                                                }
                                            })
                                            nivel2I.map((it) => {
                                                if(it.descricao == item[7] && it.id_nivel_loc1 == n1id) {
                                                    ok = false
                                                }
                                            })
                                            if(ok){
                                                have = false
                                                inBaseProps.nivel2.map((itemB) => {
                                                    if(itemB.descricao == item[7] && itemB.id_nivel_loc1 == n1id) {
                                                        nivel2I.push({
                                                            descricao: itemB.descricao,
                                                            id_conta: sessionStorage.getItem('idConta'),
                                                            id_nivel_loc1: n1id,
                                                            _id: itemB._id
                                                        })
                                                        have = true
                                                    }
                                                })
                                                if(!have){
                                                    nivel2I.push({
                                                        descricao: item[7],
                                                        id_conta: sessionStorage.getItem('idConta'),
                                                        id_nivel_loc1: n1id,
                                                        _id: getID()
                                                    })
                                                }
                                            }
                                            if(item[12] != '') {
                                                ok = true
                                                let n2id
                                                nivel2I.map(it => {
                                                    if(it.descricao == item[7]) {
                                                        n2id = it._id
                                                    }
                                                })
                                                nivel3I.map((it) => {
                                                    if(it.descricao == item[12] && it.id_nivel_loc2 == n2id) {
                                                        ok = false
                                                    }
                                                })
                                                if(ok) {
                                                    inBaseProps.nivel3.map((itemB) => {
                                                        if(itemB.descricao == item[12] && itemB.id_nivel_loc2 == n2id) {
                                                            nivel3I.push({
                                                                descricao: itemB.descricao,
                                                                id_conta: sessionStorage.getItem('idConta'),
                                                                id_nivel_loc2: n2id,
                                                                _id: itemB._id
                                                            })
                                                            have = true
                                                        }
                                                    })
                                                    if(!have){
                                                        nivel3I.push({
                                                            descricao: item[12],
                                                            id_conta: sessionStorage.getItem('idConta'),
                                                            id_nivel_loc2: n2id,
                                                            _id: getID()
                                                        })
                                                    }
                                                }
                                                if(item[17] != '') {
                                                    ok = true
                                                    let n3id
                                                    nivel3I.map(it => {
                                                        if(it.descricao == item[12]) {
                                                            n3id = it._id
                                                        }
                                                    })
                                                    nivel4I.map((it) => {
                                                        if(it.descricao == item[17] && it.id_nivel_loc3 == n3id) {
                                                            ok = false
                                                        }
                                                    })
                                                    if(ok){
                                                        inBaseProps.nivel4.map((itemB) => {
                                                            if(itemB.descricao == item[17] && itemB.id_nivel_loc3 == n3id) {
                                                                nivel4I.push({
                                                                    descricao: itemB.descricao,
                                                                    id_conta: sessionStorage.getItem('idConta'),
                                                                    id_nivel_loc3: n3id,
                                                                    _id: itemB._id
                                                                })
                                                                have = true
                                                            }
                                                        })
                                                        if(!have){
                                                            nivel4I.push({
                                                                descricao: item[17],
                                                                id_conta: sessionStorage.getItem('idConta'),
                                                                id_nivel_loc3: n3id,
                                                                _id: getID()
                                                            })
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    
                                    if(item[2] != '') {
                                        var idn1 = null
                                        nivel1I.map(it => {
                                            if(it.descricao == item[2]) {
                                                idn1 = it._id
                                            }
                                        })
                                        if(item[7] != '') {
                                            var idn2
                                            nivel2I.map(it => {
                                                if(it.descricao == item[7] && it.id_nivel_loc1 == idn1) {
                                                    idn2 = it._id
                                                }
                                            })
                                            if(item[12] != '') {
                                                var idn3
                                                nivel3I.map(it => {
                                                    if(it.descricao == item[12] && it.id_nivel_loc2 == idn2) {
                                                        idn3 = it._id
                                                    }
                                                })
                                                if(item[17] != '') {
                                                    var idn4
                                                    nivel4I.map(it => {
                                                        if(it.descricao == item[17] && it.id_nivel_loc3 == idn3) {
                                                            idn4 = it._id
                                                        }
                                                    })
                                                }
                                            }
                                        }
                                    }
                                    importado.push(index)
                                }else{
                                    status = false
                                }
                            }
                        }
                    })
                    if(status) {
                        let option = {
                        headers: { 
                                'Content-Type': ['application/json']
                            }
                        }
                        api.post(`/itensDef/${sessionStorage.getItem('idConta')}`,
                        [{
                            nivel1: nivel1I.filter((item) => {
                                let drop = false
                                inBaseProps.nivel1.map((itemB) => {
                                    if(item._id == itemB._id) {
                                        drop = true
                                    }
                                })
                                if(!drop){
                                    return item
                                }
                            }),
                            nivel2: nivel2I.filter((item) => {
                                let drop = false
                                inBaseProps.nivel2.map((itemB) => {
                                    if(item._id == itemB._id) {
                                        drop = true
                                    }
                                })
                                if(!drop){
                                    return item
                                }
                            }),
                            nivel3: nivel3I.filter((item) => {
                                let drop = false
                                inBaseProps.nivel3.map((itemB) => {
                                    if(item._id == itemB._id) {
                                        drop = true
                                    }
                                })
                                if(!drop){
                                    return item
                                }
                            }),
                            nivel4: nivel4I.filter((item) => {
                                let drop = false
                                inBaseProps.nivel4.map((itemB) => {
                                    if(item._id == itemB._id) {
                                        drop = true
                                    }
                                })
                                if(!drop){
                                    return item
                                }
                            })
                        }], option).then(
                            response => {
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: 'Itens importados com sucesso',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                setUploaded(importado)
                                setUppingImport(false)
                                nivel1Get()
                            },
                            response => {
                                Swal.fire({
                                    title: "Ops... !",
                                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                        )
                    }
                }
            }
        )
    }

    return (
        <div    >
            <div id="background">
                {changeFunction(change)}
            </div>
        </div>
    )
}

export default Locations