import { useEffect, useState } from "react";

import { t } from "i18next";

import api from "../../services/api";

import secureLocalStorage from "react-secure-storage";

import Alert from "../../components/ui/Alert";

import { Backdrop, Button, CircularProgress, FormGroup, FormLabel, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";

//----------------------------------------------------------------

export default function ConfigApi({config}){
    const [APIMode, setAPIMode] = useState('')

    const [apiAddress, setAPIAdress] = useState('')

    const [data, setData] = useState([])

    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState(null)

    useEffect(()=>{
        getData()
    },[])

    function getData() {
        if(config){
            setData(config)
            setAPIAdress(config.api_endereco)
            setAPIMode(config.api_modo)
        }
    }
    
    function handleSave(){
        const update = data
        update.id_conta = sessionStorage.getItem('idConta');
        update.api_endereco = apiAddress
        update.api_modo = APIMode

        let option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        setLoading(true)

        api.post('/conta_config', [update], option
        ).then(
            response => {
                setLoading(false)
                setMessage({
                    "type": "success",
                    "message": t('messages.success_save'),
                    "title": t('messages.success')
                })
            },
            error => {
                setLoading(false)
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        )
    }

    return (
        <>  
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Typography variant="h2">{t('settings.config_API')}</Typography>

            <Typography variant="subtitle">{t('settings.config_API_subtitle')}</Typography>

            <Grid container spacing={3} xs={12} md={10} sx={{mt:1}}>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel sx={{wordWrap: 'none'}}>{t('settings.items.mode')}</FormLabel>
                        <Select size="small" value={APIMode} onChange={e=>{setAPIMode(e.target.value)}} fullWidth>
                            <MenuItem value=""></MenuItem>
                            <MenuItem value="local">Local</MenuItem>
                            <MenuItem value="one">InovaOne</MenuItem>
                        </Select>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{t('settings.items.address')}</FormLabel>
                        <TextField size="small" value={apiAddress} onChange={e=>{setAPIAdress(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs>
                    <Button variant="contained" color="error" sx={{borderRadius: 5}} onClick={e=>{handleSave()}}>{t('actions.save')}</Button>
                </Grid>
            </Grid>

            {message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{setMessage(e)}}
                />
            : ''}
        </>
    )
}