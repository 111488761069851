import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Icon } from '@iconify/react';
import { t } from 'i18next';
import { ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------

export default function AccountMenu() {

    const [anchorEl, setAnchorEl] = useState(null);

    const [icon, setIcon] = useState()

    const open = Boolean(anchorEl);

    const languages = [
        {
            "icon": "twemoji:flag-brazil",
            "name": "pt-BR",
            "description": t('languages.portuguese')
        },
        {
            "icon": "twemoji:flag-us-outlying-islands",
            "name": "en",
            "description": t('languages.english')
        },
        {
            "icon": "twemoji:flag-spain",
            "name": "es",
            "description": t('languages.spanish')
        }
    ]

    useEffect(() => {
        if(sessionStorage.getItem('language')){
            setIcon(languages.filter(l => l.name === sessionStorage.getItem('language'))[0].icon)
        }
    },[])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleLanguage(value){
        setIcon(value.icon)
        sessionStorage.setItem('language', value.name)
        window.location.reload()
    };

    return (
        <>
            <Box>
                <Tooltip title="Language">
                    <IconButton
                        onClick={handleClick}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Icon icon={icon ?? 'twemoji:flag-brazil'}/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {languages.map((language)=>(
                    <MenuItem onClick={e=>{handleLanguage(language)}}>
                        <ListItemIcon>
                            <Icon icon={language.icon}/> 
                        </ListItemIcon>
                        <ListItemText>{language.description}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
