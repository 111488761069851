import { useEffect, useState } from "react";

import {useNavigate } from 'react-router-dom'

import Menu from "../../../../components/Menu";

import { Box, Button, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, Stack, Switch, TextField, Typography } from "@mui/material";

import api from "../../../../services/api";

import secureLocalStorage from "react-secure-storage";

import { Icon } from "@iconify/react/dist/iconify.js";
import { t } from "i18next";

//----------------------------------------------------------------

export default function FormChecagem({onSave, onCreateModel, decoder, of, quant_esperada, api }){
    const history = useNavigate()

    const [decoderCodes, setDecoderCodes] = useState(decoder || [{}])

    const [tagCount, setTagCount] = useState(quant_esperada || '')

    const [OF, setOF] = useState(of || "")

    const [system, setSystem] = useState(api || "")

    function handleMode(newValue, newParams, index){
        const updateDecorList = [...decoderCodes]
        updateDecorList[index][newParams] = newValue
        setDecoderCodes(updateDecorList)
    }

    function handleButtonMode(){
        const updateDecorList = [...decoderCodes]
        updateDecorList.push([])
        setDecoderCodes(updateDecorList)
    }

    function generateID() {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var id = '';
        for (var i = 0; i < 6; i++) {
          var randomIndex = Math.floor(Math.random() * characters.length);
          id += characters[randomIndex];
        }
        return id;
    }

    function handleStart(){

        const id = generateID()

        const decoderList = {}

        decoderCodes.forEach(codes=>{
            decoderList[codes.mode] = codes
        })

        let startConfig = {
            "_id": id,
            "decoder": decoderList,
            "quant_esperada": tagCount
        }
        if(OF){
            startConfig.ordem_servico = {
                "of": OF,
                "api": system
            }
        }
    
        for (let key in startConfig) {
            if (!startConfig[key]) {
                delete startConfig[key];
            }
        }

        onSave(startConfig)
    }

    function handleNewModel(){
        let config = {
            "decoder": decoderCodes,
            "quant_esperada": tagCount,
            "of": OF,
            "api": system
        }

        onCreateModel(config)
    }
    
    return (
        <>
            <Stack spacing={2}>
                <Box>
                    <FormGroup>
                        <Typography>Quantidade Esperada por Leitura</Typography>
                        <TextField size="small" type="number" placeholder="(opcional)" value={tagCount} onChange={e=>{setTagCount(parseInt(e.target.value))}}/>
                    </FormGroup>
                </Box>
                <Box>
                    <Typography>Codificador</Typography>
                    <Grid container spacing={2}>
                        {decoderCodes.map((decription, index)=> (
                            <Grid item sm={12} container spacing={2} alignItems="center">
                                <Grid item sx={{pr: 2}}>
                                    <Button variant="light" onClick={e=>{handleButtonMode()}}><Icon icon="ph:plus-bold"/></Button>
                                </Grid>
                                <Grid item md>
                                    <Typography>Decodificador</Typography>
                                    <Select size="small" value={decription.mode} onChange={e=>{handleMode(e.target.value, 'mode', index)}} fullWidth>
                                        <MenuItem value=""><Typography variant="subtitle2">Decodificador</Typography></MenuItem>
                                        <MenuItem value="Manual">Manual</MenuItem>
                                        <MenuItem value="GTIN">GTIN</MenuItem>
                                        <Typography variant="subtitle2" sx={{px: 2, py: 1}}>Opções pré-configuradas</Typography>
                                        <MenuItem value="Havan">Havan</MenuItem>
                                        <MenuItem value="Renner">Renner</MenuItem>
                                        <MenuItem value="Riachuelo">Riachuelo</MenuItem>
                                    </Select>
                                </Grid>
                                {decription.mode === 'Manual' ? 
                                    <>
                                        <Grid item md>
                                            <Typography>Posição incial</Typography>
                                            <TextField size="small" value={decription.inicial} onChange={e=>{handleMode(e.target.value, 'inicial', index)}} fullWidth/>
                                        </Grid>
                                        <Grid item md>
                                            <Typography>Tamanho do código</Typography>
                                            <TextField type="number" size="small" value={decription.comprimento} onChange={e=>{handleMode(e.target.value, 'comprimento', index)}} fullWidth/>
                                        </Grid>
                                        <Grid item md>
                                            <Typography>Tratativa</Typography>
                                            <Select type="number" size="small" value={decription.tratativa} onChange={e=>{handleMode(e.target.value, 'tratativa', index)}} fullWidth>
                                                <MenuItem value>Nenhuma</MenuItem>
                                                <MenuItem value="Hexadecimal">Hexadecima</MenuItem>
                                            </Select>
                                        </Grid>
                                    </>
                                : ""}
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Stack>
            <Stack direction="row" spacing={1}>
                <Grid item xs>
                    <Typography>Ordem</Typography>
                    <TextField id="order" size="small" value={OF} onChange={e=>{setOF(e.target.value)}} fullWidth/>
                </Grid>
                <Grid item xs>
                    <Typography>Sistema</Typography>
                    <Select id="system" size="small" value={system} onChange={e=>{setSystem(e.target.value)}} fullWidth>
                        <MenuItem value="">--</MenuItem>
                        <MenuItem value="forboys2">Sisplan</MenuItem>
                    </Select>
                </Grid>
            </Stack>
                        
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button 
                    variant="outlined" 
                    color="dark" 
                    onClick={e=>{handleNewModel('saveModel')}}
                    startIcon={<Icon icon="mingcute:save-2-line"/>}
                >
                    {t('actions.create')} {t('settings.model')}
                </Button>

                <Button 
                    variant="contained" 
                    onClick={e=>{handleStart('startInventory')}}
                >
                    Iniciar
                </Button>
            </Stack>
        </>
    )
}