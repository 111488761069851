import { Backdrop, Box, CircularProgress, Stack, Typography } from "@mui/material";

//----------------------------------------------------------------

export default function Loading({show}){
    return (
        <>
            {show ? 
                <>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={show}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            : ''}
        </>
    )
}