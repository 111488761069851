import React, {useState, useEffect} from 'react'
import Menu from '../../components/Menu'
import api from '../../services/api'
import { useTranslation } from 'react-i18next'

import { Container, Row, Col, Nav, NavLink, Button, Spinner } from 'react-bootstrap'
import PDFExporter from './export-PDF'

function ExportActivity(){
  const { t } = useTranslation();
  const [activities, setActivities] = useState([])
  const [filterBy, setFilterbyUser] = useState('*')
  const [isLoaded, setisLoaded] = useState(false)

  useEffect(()=>{
      async function getHistory() {
          setisLoaded(false)
          await api.get(`/conta_historico/${sessionStorage.getItem('idConta')}/${filterBy}`, {headers: { key: 'icdeva@23!' }})
          .then(response => {
              console.log(response.data)
              setActivities(response.data[0].acoes.reverse())
          })
          setisLoaded(true)
      }
      getHistory()
  },[filterBy])

  return (
      <>
          {isLoaded ? 
              <PDFExporter data={activities}></PDFExporter>
            : 
              <>
                <Spinner animation="grow" variant="primary" />
              </>
          }
      </>
  )
}

export default ExportActivity