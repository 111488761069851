import React, {useState, useEffect} from "react"
import Swal from "sweetalert2"
import ReactLoading from 'react-loading'

import Menu from "../../components/Menu"
import api from "../../services/api"
import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, FloatingLabel, CloseButton } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function PrintAdress() {
    const { t } = useTranslation();

    const [adressList, setAdressList] = useState([])
    const [id, setId] = useState('0')
    const [nome, setNome] = useState('')
    const [endereco, setEndereco] = useState('')
    const [obs, setObs] = useState('')
    const [adressLoading, setAdressLoading] = useState(true)
    const [changeViewKey, setChangeViewKey] = useState(0)

    useEffect(() => {
        getAdressList()
    }, [])

    function getAdressList() {
        setAdressLoading(true)
        api.get(`/endereco_impressao/${sessionStorage.getItem('idConta')}/*`).then(
            response => {
                setAdressList(response.data)
            }
        ).finally(
            () => {
                setAdressLoading(false)
            }
        )
    }

    function editItem(printer) {
        setId(printer._id)
        setNome(printer.nome)
        setEndereco(printer.endereco)
        setObs(printer.observacao)
        setChangeViewKey(1)
    }

    function clear() {
        getAdressList()
        setId('0')
        setNome('')
        setEndereco('')
        setObs('')
        setChangeViewKey(0)
    }

    function save() {
        Swal.fire({
            title: "Atenção !",
            text: id == '0' ? "Deseja realmente criar este endereço ?" : "Deseja realmente editar este endereço ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    if(nome == ''){
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Faltou informar o nome da impressora',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else if(endereco == '') {
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Faltou informar o endereço da impressora',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else{
                        let reg = []
                        reg.push({
                            id_conta: sessionStorage.getItem('idConta'),
                            nome: nome,
                            endereco: endereco,
                            observacao: obs,
                            _id: id
                        })
            
                        let option = {
                            headers: { 'Content-Type': ['application/json'] }
                        }
            
                        api.post(`/endereco_impressao`, reg, option
                        ).then(
                            response=> {
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: id == '0' ? 'Endereço foi criado com sucesso' : 'Endereço foi editado com sucesso',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                editItem(response.data[0])
                            },
                            response=> {
                                Swal.fire({
                                    title: "Ops... !",
                                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                        )
                    }
                }
            }
        )
    }

    function remove() {
        Swal.fire({
            title: "Atenção !",
            html: `Impressora: ${nome}<br/>Deseja realmente excluir este endereço ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
            
                    api.delete(`endereco_impressao/${sessionStorage.getItem('idConta')}/${id}`, option
                    ).then(
                        response=> {
                            getAdressList()
                            Swal.fire({
                                title: "Sucesso !",
                                text: 'Endereço foi excluido de sua base',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            clear()
                        },
                        response=> {
                            Swal.fire({
                                title: "Ops... !",
                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }
                    )
                }
            }
        )
    }

    function changeView(key) {
        switch (key) {
            case 0:
                return (
                    <div >
                        <Row>
                            <Col><h3>{t('printadress.printadress')}</h3></Col>
                            <Col className="container-btn">
                                <Button onClick={e => {setChangeViewKey(1)}}> + {t('actions.new')}</Button>
                            </Col>
                        </Row>
                        {adressLoading ?
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                            </div>
                        :
                            <div className="list">
                                <Table>
                                    <thead>
                                        <tr id="header">
                                            <th>#</th>
                                            <th>{t('printadress.name')}</th>
                                            <th>{t('printadress.adress')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {adressList.map((printer, index) => {
                                            return(
                                                <tr
                                                    className="edit"
                                                    key={index}
                                                    onClick={e=> {editItem(printer)}}
                                                >
                                                    <th>{index + 1}</th>
                                                    <th>{printer.nome}</th>
                                                    <th>{printer.endereco}</th>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>  
                        }
                    </div>
                )
            case 1:
                return (
                    <div >
                        <Row>
                            <Col><h3>{t('printadress.printadress')}</h3></Col>
                            <Col className="container-btn">
                                {sessionStorage.getItem('perfil') == 'admin' && id != '0' ? <Button variant="light" onClick={e=> {remove()}}>{t('actions.delete')}</Button> : ''}
                                <Button variant="custom-danger" onClick={e=> {save()}}>{t('actions.save')}</Button>
                                <Button variant="light" onClick={e => clear()}>{t('actions.exit')}</Button>
                            </Col>
                        </Row>
                        <div>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{t('printadress.name')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={nome}
                                        onChange={e=> {setNome(e.target.value)}}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>{t('printadress.adress')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={endereco}
                                        onChange={e=> {setEndereco(e.target.value)}}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{t('common.observation')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={obs}
                                        onChange={e=> {setObs(e.target.value)}}
                                    />
                                </Form.Group>
                            </Row>
                        </div>
                    </div>
                )
            default:
                return (
                    <div></div>
                )
        }
    }

    return (
        <div    >
            <div id="background">
                {changeView(changeViewKey)}
            </div>
        </div>
    )
}

export default PrintAdress