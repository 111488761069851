import React, {useState, useEffect} from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import {CSVLink} from 'react-csv'
import {FaFileCsv} from 'react-icons/fa'
import {FiMoreVertical} from 'react-icons/fi'

import Swal from "sweetalert2"
import ReactLoading from 'react-loading'

import api from "../services/api"

import {GoLocation} from 'react-icons/go'
import {FiTrash2, FiFilter, FiChevronsLeft, FiChevronsRight, FiRefreshCcw, FiArrowLeft, FiPlus, FiSave, FiX, FiUpload} from 'react-icons/fi'

import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, FloatingLabel, CloseButton } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

function ExportCSV(props) {
  const { t } = useTranslation();

  const [idExport, setIdExport] = useState('0')
  const [moduloExport, setModuloExport] = useState(props.modulo)
  const [itemsFromBackend, setItemsFromBackend] = useState(returnList(props.headers))
  const [listItens, setListItens] = useState(props.listItens)
  const [fotosCompl, setFotoCompl] = useState(props.listFotosCompl)
  const [showFtc, setShowFtc] = useState('false')

  const [loading, setLoading] = useState(false)
    
  const [columnsFromBackend, setColumnsFromBackend] = useState({
      ['collumns_0']: {
          name: `${t('export.available_columns')}`,
          items: itemsFromBackend
      },
      ['collumns_1']: {
          name: `${t('export.column')} ${t('export.on_CSV')}`,
          items: []
      }
  })

  const [columns, setColumns] = useState(columnsFromBackend)

  useEffect(() => {
    getProps()
  }, [])

  function getProps() {
    setLoading(true)
    api.get(`/export_config/${sessionStorage.getItem('idConta')}/${props.modulo}/csv`).then(
      result => {
        if(result.data.length) {
          setIdExport(result.data[0]._id)
          setModuloExport(result.data[0].modulo)
          setColumns(result.data[0].modelo)
        }
        setLoading(false)
      }
    )
  }
  
  function saveProps() {
    Swal.fire({
      title: "Atenção !",
      text: idExport == '0' ? "Deseja realmente salvar este modelo ?" : "Deseja realmente alterar este modelo ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Não"
    }).then(
      result => {
        if(result.isConfirmed) {
          let option = {
            headers: { 'Content-Type': ['application/json'] }
          }
          let reg = [
            {
              id_conta: sessionStorage.getItem('idConta'),
              tipo: 'csv',
              modulo: moduloExport,
              modelo: columns,
            }
          ]
          if(idExport != '0') {
            reg[0]._id = idExport
          }
          api.post('/export_config', reg, option).then(
            result => {
              setIdExport(result.data[0]._id)
              Swal.fire({
                title: "Sucesso !",
                text: 'Modelo foi salvo em sua base',
                icon: 'success',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
              })
            },
            result => {
              Swal.fire({
                title: "Ops... !",
                text: 'Algo deu errado tente novamente mais tarde!!',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
              })
            }
          )
        }
      }
    )
  }

  function removeProps() {
    if(idExport != '0') {
      Swal.fire({
        title: "Atenção !",
        text: "Deseja realmente excluir este modelo ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      }).then(
        result => {
          if(result.isConfirmed) {
            api.delete(`/export_config/${sessionStorage.getItem('idConta')}/${idExport}`).then(
              result => {
                setIdExport('0')
                setColumns(columnsFromBackend)
                Swal.fire({
                  title: "Sucesso !",
                  text: 'Modelo foi excluido de sua base',
                  icon: 'success',
                  showConfirmButton: false,
                  timerProgressBar: true,
                  timer: '2500'
                })
              }
            )
          }
        }
      )
    }else {
      Swal.fire({
        title: "Ops... !",
        text: 'Não há um modelo cadastrado',
        icon: 'error',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: '2500'
      })
    }
  }

  function returnList(itemsList) {
    return itemsList.map((item, index) => {
      return { id: `item_${index}`, content: item, index }
    })
  }

  function pushAllToOtherSide(column) {
    if(column == 'Colunas disponiveis') {
      setColumns({
        ['collumns_0']: {
            name: "Colunas disponiveis",
            items: itemsFromBackend
        },
        ['collumns_1']: {
            name: "Colunas no CSV",
            items: []
        }
      })
    }else{
      setColumns({
        ['collumns_0']: {
            name: "Colunas disponiveis",
            items: []
        },
        ['collumns_1']: {
            name: "Colunas no CSV",
            items: itemsFromBackend
        }
      })
    }
  }

  const onDragEnd = (result, columns, setColumns) => {
      if (!result.destination) return;
      const { source, destination } = result;
    
      if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems
          }
        });
      } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems
          }
        });
      }
  }

  function csv(csvHeader=false) {
    let body = listItens.map((item,ind) => {
      let reg = columns.collumns_1.items.map(ids => {
        return item[ids.index]
      })
      if(moduloExport=='itens' && showFtc == 'true') {
        fotosCompl[ind].map((ftc) => {
          reg.push(ftc)
        })
      }
      return reg
    })
    let body2 = body.filter((item) => {
      if(item) {
        return item
      }
    })
    if(csvHeader){
      let headerNames = columns.collumns_1.items.map(names => names.content)
      if(moduloExport=='itens' && showFtc == 'true') {
        headerNames.push('FOTOS COMPLEMENTARES')
      }
      if(body2.length){
        return [headerNames, ...body2]
      }else{
        return [headerNames]
      }
    }else{
      if(body2.length){
        return [...body2]
      }else{
        return [['']]
      }
    }
  }

  return (loading ? 
    <div style={{width:'100%' }}>
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '150px'}}>
        <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
      </div>
    </div>
  :
    <Col className="bg-white border rounded p-3">
      <Col className="d-flex justify-content-between p-3">
          <h3>{t('common.items')}</h3>
          <div>
              <Button variant="light" onClick={e=> {saveProps()}}><FiSave className="me-2"/>{t('actions.save')} {t('export.model')}</Button>
              <Button variant="light" className="ms-2" onClick={e=> {removeProps()}}><FiTrash2 className="me-2"/>{t('actions.delete')} {t('export.model')}</Button>
          </div>
      </Col>
      <DragDropContext
        onDragEnd={result => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width:'auto'
              }}
              key={columnId}
            >
              <Row className="justify-content-between my-3 w-100">
                <Col className="d-flex">
                  <h2>{column.name}</h2>
                  <Button variant="custom-primary" className="ms-3" onClick={e=>{pushAllToOtherSide(column.name)}}><FiPlus/> {t('actions.select_all')} </Button>
                </Col>
                <Col className="d-flex justify-content-end">
                  {moduloExport == 'itens' && index == 1?
                  <select
                        value={showFtc}
                        onChange={e=>{setShowFtc(e.target.value)}}
                    >
                        <option value={'false'}>{t('export.without')} {t('export.adicional_image')}</option>
                        <option value={'true'}>{t('export.with')} {t('export.adicional_image')}</option>
                    </select>:''}
                  </Col>
              </Row>
              <div style={{ margin: 8 ,width: '100%', overflowX: 'auto'}}>
                <Droppable droppableId={columnId} direction='horizontal' key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          border: snapshot.isDraggingOver
                            ? "2px dashed var(--text-secondary)"
                            : "1px solid var(--bg-light)",
                          background: snapshot.isDraggingOver
                            ? "var(--bg-light)"
                            : "#fff",
                          display: 'flex',
                          padding: 8,
                          overflowX: 'auto',
                          minHeight: '55px'
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="shadow-sm border bg-white fw-bold"
                                    style={{
                                      userSelect: "none",
                                      padding: 16,
                                      margin: "4px 2px",
                                      whiteSpace:'nowrap',
                                      borderRadius: '5px',
                                      color: snapshot.isDragging
                                      ? "var(--primary-color)"
                                      : "var(--text-primary)",
                                      ...provided.draggableProps.style
                                    }}
                                  >
                                    <FiMoreVertical className="me-3"/>
                                    {item.content}
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
      <Col className="my-3 d-flex justify-content-end">
        <CSVLink className="csv mx-3" data={csv(false)} separator={';'}>
          <Button variant="outline-primary"><FaFileCsv className="dIcons"/> {t('items.generateCSV')}</Button>
        </CSVLink>
        <CSVLink className="csv" data={csv(true)} separator={';'}>
          <Button variant="outline-primary"><FaFileCsv className="dIcons"/> {t('items.generateCSV_header')}</Button>
        </CSVLink>
      </Col>
    </Col>
  )
}

export default ExportCSV
