import React, { useEffect, useState } from "react";

import { t } from "i18next";

import PropTypes from 'prop-types';

import { IMaskInput } from 'react-imask';

import api from "../../services/api";

import Alert from "../../components/ui/Alert";

import CustomModal from "../../components/ui/CustomModal";

import { Backdrop, Button, CircularProgress, FormGroup, FormLabel, Grid, TextField, Typography } from "@mui/material";

// ----------------------------------------------------------------

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+00 (00) 00000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
});
  
  TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
export default function Account({userDataSet}){
    const [name, setName] = useState()

    const [email, setEmail] = useState()

    const [number, setNumber] = useState()

    const [active, setActive] = useState()

    const [uuid, setUuid] = useState()

    const [cnpj, setCnpj] = useState()

    const [observation, setObservation] = useState()

    const [address, setAddress] = useState()

    const [dates, setDates] = useState()

    const [message, setMessage] = useState()

    const [loading, setLoading] = useState(false)

    const [showModal, setModal] = useState(false)

    useEffect(() =>{
        if(userDataSet){
            setDates({
                "dt_licenca": userDataSet.dt_licenca,
                "dt_registro": userDataSet.dt_registro
            })
            setName(userDataSet.nome)
            setEmail(userDataSet.email)
            setNumber(userDataSet?.telefone)
            setActive(userDataSet?.ativo)
            setUuid(userDataSet?.uuid)
            setCnpj(userDataSet?.cnpj)
            setObservation(userDataSet?.observacao)
            setAddress(userDataSet?.endereco)
        }
    },[])

    function handleSave(){
        setLoading(true)
        const updateData = [{
            "ativo": active,
            "cnpj": cnpj,
            "dt_licenca": "2023-01-10 10:18:02",
            "dt_registro": "2023-01-10 10:18:02",
            "email": email,
            "endereco": address,
            "foto": "",
            "id_conta": sessionStorage.getItem('idConta'),
            "nome": name,
            "observacao": observation,
            "telefone": number,
            "uuid": uuid,
            ...dates
        }]

        let option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        api.post('/conta', updateData, option
        ).then(
            response => {
                setLoading(false)
                setMessage({
                    "type": "success",
                    "message": t('messages.success_save'),
                    "title": t('messages.success')
                })
            },
            error=>{
                setLoading(false)
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        )
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Typography variant="h2">{t('settings.data.data')}</Typography>

            <Typography variant="subtitle">{t('settings.data.data_subtitle')}</Typography>

            <Grid container spacing={3} xs={12} md={10} sx={{mt:1}}>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{t('settings.data.name')}</FormLabel>
                        <TextField size="small" value={name} onChange={e=>{setName(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{t('settings.data.number')}</FormLabel>
                        <TextField size="small" value={number} onChange={e=>{setNumber(e.target.value)}} InputProps={{inputComponent: TextMaskCustom}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{t('settings.data.email')}</FormLabel>
                        <TextField size="small" value={email} onChange={e=>{setEmail(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>CNPJ</FormLabel>
                        <TextField size="small" value={cnpj} onChange={e=>{setCnpj(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>{t('settings.data.active')}</FormLabel>
                        <TextField size="small" value={active === 1 ? t('common.yes') : t('common.no')} onChange={e=>{setActive(e.target.value)}} disabled fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormGroup>
                        <FormLabel>UUID</FormLabel>
                        <TextField size="small" value={uuid} onChange={e=>{setUuid(e.target.value)}} disabled fullWidth />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormGroup>
                        <FormLabel>Endereço</FormLabel>
                        <TextField size="small" value={address} onChange={e=>{setAddress(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormGroup>
                        <FormLabel>Observação</FormLabel>
                        <TextField value={observation} onChange={e=>{setObservation(e.target.value)}} multiline fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="error" onClick={e=>{setModal(!showModal)}} sx={{borderRadius: 5}}>{t('actions.save')}</Button>
                </Grid>
            </Grid>

            {showModal ? 
                <CustomModal
                    title={t('messages.attention')}
                    onConfirm={e=>{handleSave()}}
                    onClose={e=>{setModal(false)}}
                >
                    <Typography variant="subtitle" paragraph>{t('settings.messages.confirm_save')}</Typography>
                    <Typography variant="subtitle">{t('messages.confirm.ask_confirm')}</Typography>
                </CustomModal>
            : ''}

            {message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{setMessage(e)}}
                />
            : ''}
        </>
    )
}