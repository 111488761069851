import { Box, Drawer, Fab, Toolbar } from '@mui/material';

import NavItems from './nav-items'

import { Icon } from '@iconify/react';

import { useState } from 'react';

// ----------------------------------------------------------------

export default function Menu({openNav, onCloseNav}) {
    const [open, setOpen] = useState(openNav)

    const handleMenu = (e) => {
        sessionStorage.setItem('show-menu', e)
        onCloseNav(e)
        setOpen(e)
    };

    return (
        <>
            <Drawer
                variant="permanent"
                sx={{
                    zIndex: 2,
                    width: open ? '13%': 'auto',
                    minWidth: open ? 200 : 70,
                    flexShrink: 0,
                    display: { xs: 'none', md: 'block' },
                    [`& .MuiDrawer-paper`]: { width: open ? '13%': 'auto', minWidth: open ? 200: 70, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <NavItems openNav={open} onCloseNav={e=>{handleMenu(false)}}/>   
                </Box>
            </Drawer> 

            {!open && (
                <Fab
                    size="small"
                    sx={{
                        display: {xs: 'none', md: 'block'},
                        position: 'fixed',
                        left: 70,
                        top: 100,
                        boxShadow: 3,
                        bgcolor: 'dark.light',
                        borderRadius: 1,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        color: 'common.white',
                        '&:hover': {
                            color: 'common.black'
                        }
                    }}
                    onClick={e=>{handleMenu(!open)}}
                >
                    <Icon icon={open ? 'tabler:chevrons-left' : 'tabler:chevrons-right'} width="16"/>
                </Fab>
            )}
        </>
    )
}