import { Box, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, Stack, Switch, TextField, Typography } from "@mui/material";

import { Button } from "react-bootstrap";

// ----------------------------------------------------------------

export default function FormTagPicking({onSave}){
    function handleStart(){
        onSave()
    }
    
    return (
        <>                
            <Grid container justifyContent="flex-end">
                <Button className="px-3" onClick={e=>{handleStart()}}>Iniciar</Button>
            </Grid>
        </>
    )
}