import { BsBoxSeam } from "react-icons/bs"; 
import { BsFillBoxFill } from "react-icons/bs"; 
import { MdInventory2 } from "react-icons/md"; 
import { MdAttachMoney } from "react-icons/md"; 
import { BiRfid } from "react-icons/bi"; 
import { Col, Container, Row } from "react-bootstrap";

// ----------------------------------------------------------------

export default function Cards({ total, label}){
    return(
        <>
            <div className="rounded bg-white shadow-sm p-3 d-flex justify-content-between">
                <div>
                    <p className="fw-bold">{label}</p>
                    <h1 className="fw-bold">
                        {label.includes('Valor') ? 
                            <span className="h6">R$</span>
                        : '' }
                        {total}
                    </h1>
                </div>
                <div>
                    {label.includes('Itens') ? 
                        <BiRfid />
                    : label.includes('Valor') ?
                        <MdAttachMoney/>
                    : <BsBoxSeam />}
                </div>
            </div>
        </>
    )
}