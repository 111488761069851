import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

function Redirect() {
    const history = useNavigate()
    
    useEffect(() => {
        history('/login')
    }, [])
    
    return (
        <div></div>
    )
}

export default Redirect