import { IoMdCheckmarkCircle } from "react-icons/io"; 
import { IoMdCloseCircle } from "react-icons/io"; 
import { BsCheckCircleFill } from "react-icons/bs"; 
import { CiCircleInfo } from "react-icons/ci"; 
import { MdKeyboardArrowUp } from "react-icons/md"; 
import { MdKeyboardArrowDown } from "react-icons/md"; 
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Button, ButtonGroup, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { Chip, Grid } from "@mui/material";
import './activities.css'
import api from "../../services/api";


function Row(props) {
    const { row, user, categoria, nivel1, nivel2, nivel3, nivel4} = props;
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell component="th" scope="row">
                    <p className="m-0 p-0 fw-bold">{new Date(row.dt_acao).toLocaleDateString('pt-BR', {day: '2-digit', month: 'short', year: 'numeric'})}</p>
                    <p className="m-0 p-0">{new Date(row.dt_acao).getHours()}:{new Date(row.dt_acao).getMinutes()}</p>
                </TableCell>
                <TableCell>{row.acao}</TableCell>
                <TableCell>
                    <OverlayTrigger
                    trigger="click"
                    key={'top'}
                    placement={'top'}
                    overlay={
                    <Popover id={`popover-positioned-top`}>
                        <Popover.Header as="h3">Usuário</Popover.Header>
                        <Popover.Body>
                            <p className='fw-bold m-0'>Email: <span className='fw-normal'>{user?.login}</span></p>
                            <p className='fw-bold'>Perfil: <span className='fw-normal'>{user?.perfil}</span></p>
                        </Popover.Body>
                    </Popover>
                    }
                    >
                        <a variant="light" className='fw-bold btn text-primary'>{user?.nome}</a>
                    </OverlayTrigger>
                </TableCell> 
                <TableCell>
                    <Button variant="light"
                        onClick={() => setOpen(!open)}
                        className="p"
                    >
                        {open ? <p> Ver menos <MdKeyboardArrowUp/></p>: <p> Ver mais <MdKeyboardArrowDown /></p>}
                    </Button>
                </TableCell>
                <Modal show={open} onHide={e=>{setOpen(!open)}} centered dialogClassName="modal-size">
                    <Modal.Header closeButton>
                    <Modal.Title>Detalhes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {row.acao.includes('Atualizacao de item') && row.valor[0]?.valor_anterior ?
                            <>
                                <Typography variant="h6" gutterBottom component="div">
                                    Tag:
                                    <Chip sx={{ml: 2}} label={`${row.valor[0].valor_atual.tag}`}/>
                                </Typography>
                                <Typography variant="h6">
                                    Informações
                                </Typography>
                                <Grid>
                                    <Table  aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Valor anterior</TableCell>
                                                <TableCell >Valor atual</TableCell>
                                                <TableCell style={{ width: '20%' }} className="text-center"> Alteração </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Categoria</TableCell>
                                                <TableCell>{categoria?.anterior}</TableCell>
                                                <TableCell>{categoria?.atual}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.id_categoria !== row.valor[0].valor_atual.id_categoria ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Nivel 1</TableCell>
                                                <TableCell>{nivel1?.anterior}</TableCell>
                                                <TableCell>{nivel1?.atual}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.id_nivel_loc_1 !== row.valor[0].valor_atual.id_nivel_loc_1 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Nivel 2</TableCell>
                                                <TableCell>{nivel2?.anterior}</TableCell>
                                                <TableCell>{nivel2?.atual}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.id_nivel_loc_2 !== row.valor[0].valor_atual.id_nivel_loc_2 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Nivel 3</TableCell>
                                                <TableCell>{nivel3?.anterior}</TableCell>
                                                <TableCell>{nivel3?.atual}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.id_nivel_loc_3 !== row.valor[0].valor_atual.id_nivel_loc_3 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Nivel 4</TableCell>
                                                <TableCell>{nivel4?.anterior}</TableCell>
                                                <TableCell>{nivel4?.atual}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.id_nivel_loc_4 !== row.valor[0].valor_atual.id_nivel_loc_4 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Info. complementar 1</TableCell>
                                                <TableCell>{row.valor[0].valor_anterior.inf_compl_1}</TableCell>
                                                <TableCell>{row.valor[0].valor_atual.inf_compl_1}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.inf_compl_1 !== row.valor[0].valor_atual.inf_compl_1 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Info. complementar 2</TableCell>
                                                <TableCell>{row.valor[0].valor_anterior.inf_compl_2}</TableCell>
                                                <TableCell>{row.valor[0].valor_atual.inf_compl_2}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.inf_compl_2 !== row.valor[0].valor_atual.inf_compl_2 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Info. complementar 3</TableCell>
                                                <TableCell>{row.valor[0].valor_anterior.inf_compl_3}</TableCell>
                                                <TableCell>{row.valor[0].valor_atual.inf_compl_3}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.inf_compl_3 !== row.valor[0].valor_atual.inf_compl_3 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Info. complementar 4</TableCell>
                                                <TableCell>{row.valor[0].valor_anterior.inf_compl_4}</TableCell>
                                                <TableCell>{row.valor[0].valor_atual.inf_compl_4}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.inf_compl_4 !== row.valor[0].valor_atual.inf_compl_4 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Info. complementar 5</TableCell>
                                                <TableCell>{row.valor[0].valor_anterior.inf_compl_5}</TableCell>
                                                <TableCell>{row.valor[0].valor_atual.inf_compl_5}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.inf_compl_5 !== row.valor[0].valor_atual.inf_compl_5 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Info. complementar 6</TableCell>
                                                <TableCell>{row.valor[0].valor_anterior.inf_compl_6}</TableCell>
                                                <TableCell>{row.valor[0].valor_atual.inf_compl_6}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.inf_compl_6 !== row.valor[0].valor_atual.inf_compl_6 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Info. complementar 7</TableCell>
                                                <TableCell>{row.valor[0].valor_anterior.inf_compl_7}</TableCell>
                                                <TableCell>{row.valor[0].valor_atual.inf_compl_7}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.inf_compl_7 !== row.valor[0].valor_atual.inf_compl_7 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Info. complementar 8</TableCell>
                                                <TableCell>{row.valor[0].valor_anterior.inf_compl_8}</TableCell>
                                                <TableCell>{row.valor[0].valor_atual.inf_compl_8}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.inf_compl_8 !== row.valor[0].valor_atual.inf_compl_8 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Info. complementar 9</TableCell>
                                                <TableCell>{row.valor[0].valor_anterior.inf_compl_9}</TableCell>
                                                <TableCell>{row.valor[0].valor_atual.inf_compl_9}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.inf_compl_9 !== row.valor[0].valor_atual.inf_compl_9 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Info. complementar 10</TableCell>
                                                <TableCell>{row.valor[0].valor_anterior.inf_compl_10}</TableCell>
                                                <TableCell>{row.valor[0].valor_atual.inf_compl_10}</TableCell>
                                                <TableCell className="text-center">{row.valor[0].valor_anterior.inf_compl_10 !== row.valor[0].valor_atual.inf_compl_10 ?  <IoMdCheckmarkCircle className="text-primary h3"/> :<IoMdCloseCircle className="text-secondary text-opacity-25 h3"/>}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </>
                        :
                            <>
                                <Table>
                                    <TableHead>
                                        <TableRow className="fw-bold text-uppercase">
                                            <TableCell>Informações</TableCell>
                                            <TableCell>Valor</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.valor.tag || row.valor.tag === "" ? 
                                            <TableRow>
                                                <TableCell className="fw-bold">Tag</TableCell>
                                                <TableCell>{row.valor.tag}</TableCell>
                                            </TableRow> 
                                        :''}
                                        {row.valor.id_categoria || row.valor.id_categoria === "" ? 
                                            <TableRow>
                                                <TableCell className="fw-bold">Categoria</TableCell>
                                                <TableCell>{categoria}</TableCell>
                                            </TableRow> : '' }

                                        {row.valor.marca || row.valor.marca === "" ? 
                                            <TableRow>
                                                <TableCell className="fw-bold">Marca</TableCell>
                                                <TableCell>{row.valor.marca}</TableCell>
                                            </TableRow> 
                                        :''}
                                        {row.valor?.modelo ? 
                                            <TableRow>
                                                <TableCell className="fw-bold">Modelo</TableCell>
                                                <TableCell>{row.valor.modelo[0]?.descricao}</TableCell>
                                            </TableRow> 
                                        :''}
                                        {row.valor.id_nivel_loc_1 || row.valor.id_nivel_loc_1 === "" ?
                                            <> 
                                                <TableRow>
                                                    <TableCell className="fw-bold">Nivel 1</TableCell>
                                                    <TableCell>{nivel1 ?? 'Não registrado'}</TableCell>
                                                </TableRow>
                                                
                                                <TableRow>
                                                    <TableCell className="fw-bold">Nivel 2</TableCell>
                                                    <TableCell>{nivel2 ?? 'Não registrado'}</TableCell>
                                                </TableRow>
                                                
                                                <TableRow>
                                                    <TableCell className="fw-bold">Nivel 3</TableCell>
                                                    <TableCell>{nivel3 ?? 'Não registrado'}</TableCell>
                                                </TableRow>
                                                
                                                <TableRow>
                                                    <TableCell className="fw-bold">Nivel 4</TableCell>
                                                    <TableCell>{nivel4 ?? 'Não registrado'}</TableCell>
                                                </TableRow> 
                                            </>
                                        : ''}
                                        

                                        {row.valor.ean || row.valor.ean === "" ? 
                                            <>
                                                <TableRow>
                                                    <TableCell className="fw-bold">Ean</TableCell>
                                                    <TableCell>{row.valor.ean}</TableCell>
                                                </TableRow>
                                            </>
                                        : ''}

                                        {row.valor.descricao || row.valor.descricao === "" ? 
                                            <TableRow   >
                                                <TableCell className="fw-bold">Descrição</TableCell>
                                                <TableCell>{row.valor.descricao}</TableCell>
                                            </TableRow>
                                        : ''}
                                        {row.valor.observacao || row.valor.observacao === "" ? 
                                            <TableRow>
                                                <TableCell className="fw-bold">Observação</TableCell>
                                                <TableCell>{row.valor.observacao}</TableCell>
                                            </TableRow>
                                        : ''}

                                        {row.valor.inf_compl_1 || row.valor.inf_compl_1 === "" ? 
                                            <>
                                                <TableRow>
                                                    <TableCell className="fw-bold">Info. Compl. 1</TableCell>
                                                    <TableCell>{row.valor.inf_compl_1}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="fw-bold">Info. Compl. 2</TableCell>
                                                    <TableCell>{row.valor.inf_compl_2}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="fw-bold">Info. Compl. 3</TableCell>
                                                    <TableCell>{row.valor.inf_compl_3}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="fw-bold">Info. Compl. 4</TableCell>
                                                    <TableCell>{row.valor.inf_compl_4}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="fw-bold">Info. Compl. 5</TableCell>
                                                    <TableCell>{row.valor.inf_compl_5}</TableCell>
                                                </TableRow>
                                            </>
                                        : ''}

                                        {row.valor.inf_compl_6 || row.valor.inf_compl_6 === ""  ? 
                                            <>
                                                <TableRow>
                                                    <TableCell className="fw-bold">Info. Compl. 6</TableCell>
                                                    <TableCell>{row.valor.inf_compl_6}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="fw-bold">Info. Compl. 7</TableCell>
                                                    <TableCell>{row.valor.inf_compl_7}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="fw-bold">Info. Compl. 8</TableCell>
                                                    <TableCell>{row.valor.inf_compl_8}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="fw-bold">Info. Compl. 9</TableCell>
                                                    <TableCell>{row.valor.inf_compl_9}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="fw-bold">Info. Compl. 10</TableCell>
                                                    <TableCell>{row.valor.inf_compl_10}</TableCell>
                                                </TableRow>
                                            </>
                                        : ''}
                                    </TableBody>
                                </Table>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={e=>{setOpen(!open)}}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>           
            </TableRow>
        </React.Fragment>
  );
}

export default function ListActivities({header, body, users, categoria, nivel1, nivel2, nivel3, nivel4}) {

    function getUsername(id){
        let username = users.filter(item => item._id == id)
        if(!username){
            return 'Usuário não identificado'
        }

        return username[0]
    }

    function getCategoria(row) {
        let cat

        if(row.acao.includes('Atualizacao de item') && row.valor[0]?.valor_anterior){
            cat = {}
            cat['anterior'] = categoria.filter(item => item._id == row.valor[0]?.valor_anterior.id_categoria)[0]?.descricao
            cat['atual'] = categoria.filter(item => item._id == row.valor[0]?.valor_atual.id_categoria)[0]?.descricao
            return cat
        }
        cat = categoria.filter(item => item._id == row.valor.id_categoria)
        
        return cat[0]?.descricao
    }

    function getNivel1(row) {
        let nivel
        if(row.acao.includes('Atualizacao de item') && row.valor[0]?.valor_anterior){
            nivel = {}
            nivel['anterior'] = nivel1.filter(item => item._id == row.valor[0]?.valor_anterior.id_nivel_loc_1)[0]?.descricao
            nivel['atual'] = nivel1.filter(item => item._id == row.valor[0]?.valor_atual.id_nivel_loc_1)[0]?.descricao
            return nivel
        }
        nivel = nivel1.filter(item => item._id == row.valor.id_nivel_loc_1)
        return nivel[0]?.descricao
    }

    function getNivel2(row) {
        let nivel

        if(row.acao.includes('Atualizacao de item') && row.valor[0]?.valor_anterior){
            nivel = {}
            nivel['anterior'] = nivel2.filter(item => item._id == row.valor[0]?.valor_anterior.id_nivel_loc_2)[0]?.descricao
            nivel['atual'] = nivel2.filter(item => item._id == row.valor[0]?.valor_atual.id_nivel_loc_2)[0]?.descricao
            return nivel
        }
        nivel = nivel2.filter(item => item._id == row.valor.id_nivel_loc_2)
        return nivel[0]?.descricao
    }

    function getNivel3(row) {
        let nivel
        if(row.acao.includes('Atualizacao de item') && row.valor[0]?.valor_anterior){
            nivel = {}
            nivel['anterior'] = nivel3.filter(item => item._id == row.valor[0]?.valor_anterior.id_nivel_loc_3)[0]?.descricao
            nivel['atual'] = nivel3.filter(item => item._id == row.valor[0]?.valor_atual.id_nivel_loc_3)[0]?.descricao
            return nivel
        }
        nivel = nivel3.filter(item => item._id == row.valor.id_nivel_loc_3)
        return nivel[0]?.descricao
    }

    function getNivel4(row) {
        let nivel
        if(row.acao.includes('Atualizacao de item') && row.valor[0]?.valor_anterior){
            nivel = {}
            nivel['anterior'] = nivel4.filter(item => item._id == row.valor[0]?.valor_anterior.id_nivel_loc_4)[0]?.descricao
            nivel['atual'] = nivel4.filter(item => item._id == row.valor[0]?.valor_atual.id_nivel_loc_4)[0]?.descricao
            return nivel
        }
        nivel = nivel4.filter(item => item._id == row.valor.id_nivel_loc_4)
        return nivel[0]?.descricao
    }

    return (
        <TableContainer component={Paper}>
            <Table>
            <TableHead>
                <TableRow>
                
                {header?.map((row)=>(
                    <TableCell>{row}</TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {body.map((row) => (
                    <Row key={row.name} row={row} user={getUsername(row.valor.id_usuario)} categoria={getCategoria(row)} nivel1={getNivel1(row)} nivel2={getNivel2(row)} nivel3={getNivel3(row)} nivel4={getNivel4(row)}/>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    );
}
