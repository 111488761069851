// @mui
import { alpha } from '@mui/material/styles';
//
import palette from './palette';

// ----------------------------------------------------------------------

export default function shadows() {
  return [
    'none',
    `rgba(9, 30, 66, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;`,
    `rgba(9, 30, 66, 0.05) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;`,
    `rgba(9, 30, 66, 0.25) 0px 20px 30px -10px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;`,
  ];
}
