import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'

function SearchSelect(props) {
    const { t } = useTranslation();

    const [preValue, setPreValue] = useState('')
    const [whileWrite, setWhileWrite] = useState('')

    useEffect(() => {
        if(props.preValue != '') {
            findId(props.preValue)
        }
    },[])

    function findId(value) {
        let index = -1
        props.lista.map((itemList, i) => {
            if(itemList['_id'] == value){
                index = i
            }
        })
        if(index > -1) {
            setPreValue(props.lista[index][props.target])
            setWhileWrite(props.lista[index][props.target])
            props.returnId(index > -1 ? props.lista[index]._id : '*')
        }else{
            setWhileWrite(preValue)
        }
    }

    function findItem(value) {
        let index = -1
        props.lista.map((itemList, i) => {
            if(itemList[props.target] == value){
                index = i
            }
        })
        if(index > -1 || value == '') {
            setPreValue(value)
            props.returnId(index > -1 ? props.lista[index]._id : '*')
        }else{
            setWhileWrite(preValue)
        }
    }

    return (
        <div>
            <Form.Control
                list={props.dataName}
                value={whileWrite}
                onBlur={e=>{findItem(e.target.value)}}
                onChange={e=>{setWhileWrite(e.target.value)}}
                disabled={props.disable}
            />
            <datalist id={props.dataName}>
                {props.lista.filter(toFill => {
                    if(preValue == '' || toFill[props.target]?.includes(whileWrite)) {
                        return toFill
                    }
                }).map((desc, index) => (
                    <option key={index} value={desc.descricao}/>
                ))}
            </datalist>
        </div>
    )
}

export default SearchSelect