import React, {useState, useEffect} from 'react'
import Menu from '../../components/Menu'

import { Container, Row, Col, Nav, NavLink, Button } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import { HiDownload } from 'react-icons/hi'
import { FiArrowLeft } from 'react-icons/fi'

import logo from '../../assets/logo.png'

function TermsAndConditions() {
    const { t } = useTranslation();

    return (
        <>
            <div >
                <Container className='py-4' fluid>
                    <Row className='justify-content-center'>
                        <Col md={8}>
                            <div>
                                <h1 className='fw-bold'>{t('terms.termsAndConditions.title')}</h1>
                                <p> {t('terms.termsAndConditions.intro')}</p>
                                <p> {t('terms.termsAndConditions.content')}</p>
                                <p> {t('terms.termsAndConditions.agreements')}</p>
                            </div>
                            <div className='mt-5'>
                                <h3 className='fw-semibold'>{t('terms.privacyPolicy.title')}</h3>
                                <p> {t('terms.privacyPolicy.intro')}</p>
                                <p> {t('terms.privacyPolicy.content')}</p>
                                <p> {t('terms.privacyPolicy.agreements')}</p>
                            </div>
                            <div className='mt-5'>
                                <h3 className='fw-semibold'>{t('terms.cookies.title')}</h3>
                                <p> {t('terms.cookies.intro')}</p>
                                <p> {t('terms.cookies.content')}</p>
                            </div>
                            <div className='mt-5'>
                                <h3 className='fw-semibold'>{t('terms.termsOfUse.title')}</h3>
                                <p>{t('terms.termsOfUse.intro')}</p>
                                <ul>
                                    <li style={{ listStyle: "inside" }} className="mb-2">{t('terms.termsOfUse.rules.1')}</li>
                                    <li style={{ listStyle: "inside" }} className="mb-2">{t('terms.termsOfUse.rules.2')}</li>
                                    <li style={{ listStyle: "inside" }} className="mb-2">{t('terms.termsOfUse.rules.3')}</li>
                                    <li style={{ listStyle: "inside" }} className="mb-2">{t('terms.termsOfUse.rules.4')}</li>
                                </ul>
                                <p> {t('terms.termsOfUse.agreements')}</p>
                            </div>
                            <p> {t('terms.effectiveDate')}</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default TermsAndConditions