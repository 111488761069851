import React, {useState, useEffect} from 'react'
import InputMask from 'react-input-mask'
import ReactLoading from 'react-loading'
import api from '../../services/api'
import Menu from '../../components/Menu'

import { Container, Row, Col, Nav, NavLink, Button } from 'react-bootstrap'


import { useTranslation } from 'react-i18next'
import { HiDownload } from 'react-icons/hi'
import { FiArrowLeft } from 'react-icons/fi'

function UserGuide() {
    return (
        <>
            <Container className='py-4'>
                <a href="/account" className='btn btn-light fw-light border-0'><FiArrowLeft className='me-1'/> Voltar</a>
                <div className='py-4'>
                    <h1 className='fw-bold text-info'>Guia de tipos de perfil</h1> 
                    <Button variant="outline-secondary"><HiDownload className='me-1'/> Baixar PDF</Button>
                </div>
                <Row className='justify-content-between py-3'>
                    <Col md={7}>
                    <div
                        data-bs-spy="scroll"
                        data-bs-target="#scrollspy"
                        data-bs-offset="0"
                        className="scrollspy-example"
                    >
                        <section id="intro">
                            <h3>Introdução</h3>
                            <p>Bem-vindo ao guia de tipos de usuários do nosso sistema! Para garantir uma experiência segura e eficiente para todos os nossos usuários,
                            dividimos o acesso em vários niveis com suas devidas permissões. A seguir, você encontrará uma descrição de cada tipo de usuário e as permissões 
                            correspondentes.</p>
                        </section>
                        <section id="admin">
                            <h2>Administrador</h2>
                            <p>O Administrador da Conta é o nível mais alto de acesso no sistema. Ele possui controle total sobre todas as funcionalidades e configurações, podendo editar e excluir itens conforme necessário. Suas principais responsabilidades incluem:</p>
                            <ul>
                                <li style={{ listStyle: "inside" }}>Acesso a todas as áreas e recursos do sistema.</li>
                                <li style={{ listStyle: "inside" }}>Capacidade de criar, editar e excluir itens.</li>
                                <li style={{ listStyle: "inside" }}>Capacidade de criar, editar e excluir contas de usuários.</li> 
                                <li style={{ listStyle: "inside" }}>Definição de permissões para outros usuários, incluindo atribuir a função e níveis de acesso.</li>
                                <li style={{ listStyle: "inside" }}>Monitoramento e análise de atividades do sistema por meio do histórico.</li>
                                <li style={{ listStyle: "inside" }}>Limpeza de conta.</li>
                            </ul>
                        </section>
                        <section id="user">
                            <h2>Usuário</h2>
                            <p>Os Usuários são aqueles que utilizam o sistema para executar suas tarefas, sem acesso às configurações e funções administrativas. Esse tipo de usuário é mais comum e designado a pessoas que não possuem necessidade ou autoridade para realizar alterações no sistema. Alguns recursos e permissões dos Usuários incluem:</p>
                            <ul>
                                <li style={{ listStyle: "inside" }}>Acesso limitado às áreas e recursos do sistema, conforme definido pelo Administrador.</li>
                                <li style={{ listStyle: "inside" }}>Capacidade de visualizar, inserir e atualizar itens conforme as restrições </li>
                                <li style={{ listStyle: "inside" }}>Execução de tarefas e atividades designadas dentro do escopo definido.</li>
                                <li style={{ listStyle: "inside" }}>Possibilidade de gerar relatórios e visualizar informações relevantes.</li>
                                <li style={{ listStyle: "inside" }}>Interagir com o sistema de acordo com as permissões e restrições atribuídas pelo Administrador.</li>
                                <li style={{ listStyle: "inside" }}>Recurso de solicitação de suporte e assistência técnica.</li>
                            </ul>
                        </section>
                    </div>
                    </Col>
                    <Col md={2} className="border-start">
                        <Nav className="flex-column">
                            <NavLink href="#intro">Introdução</NavLink>
                            <NavLink href="#admin">Administrador</NavLink>
                            <NavLink href="#user">Usuário</NavLink>
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UserGuide