import { useState } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import Header from "./header";

import Menu from "./menu";

import { styled } from '@mui/material/styles';

const APP_BAR_MOBILE = 64;

const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 12,
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 12,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------

export default function Layout() {

  const [open, setOpen] = useState( sessionStorage.getItem('show-menu') === 'false'  ? false : true);
  
  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Menu openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}