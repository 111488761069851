import { useState } from "react"

import { Button } from "react-bootstrap"

import CustomModal from "../../../../components/ui/CustomModal"

import { TbTruckDelivery } from "react-icons/tb"

import { t } from "i18next"

import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material"

import api from "../../../../services/api"

import Swal from "sweetalert2"

export default function MovimentationButton({locations, idRegistro}){
    const [loading, setLoading] = useState(false)

    const {nivel1, nivel2, nivel3, nivel4} = locations

    const [showModal, setShowModal] = useState(false)

    const [tags, setTags] = useState()

    const [encontrados, setEncontrados] = useState(false)

    const [ausentes, setAusentes] = useState(false)

    const [outrosAmbientes, setOutrosAmbientes] = useState(false)

    const [auditado, setAuditado] = useState(false)

    const [incorporado, setIncorporado] = useState(false)

    function getStatus(item){
        let status
        if(item.encontrado == 0 && item.excedente == 0) {
            status = "Ausente"
        }
        if(item.encontrado == 1 && item.excedente == 0) {
            status = "Encontrado"
        }
        if(item.excedente == 1) {
            status = "Excedente"
        }
        if(item.excedente == 1 && ((item.id_item != '0' && item.id_item != null) || item.descricao != "SEM DESCRICAO" && item.descricao != "SEM DESCRIÇÃO")) {
            status = "Outro_Ambiente"
        }
        if(item.auditado == 1) {
            status = "Auditado"
        }
        if(item.incorporado == 1) {
            status = 'Incorporado'
        }
        return status
    }

    async function getTags(){
        if(!idRegistro){
            return
        }
        setLoading(true)

        await api.get(`/registro_tag/*/${idRegistro}/0`).then(
            response =>{
                const data = response.data.filter(item => item.tag)
                const tagsData = data.map((item) => {
                    return {
                        ...item,
                        status: getStatus(item)
                    };
                });
                setTags(tagsData)
                handleUpload(tagsData)
            },
            error=>{
                Swal.fire({
                    title: "Ops... !",
                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        ).finally(()=>{
            setLoading(false)
            setShowModal(false)
        })
    }

    async function handleUpload(tagList){
        setLoading(true)
        let option = {
            headers: { 
                'Content-Type': ['application/json']
            }
        }

        const statusFiltrados = [
            encontrados ? "Encontrado" : "",
            ausentes ? "Ausente" : "",
            outrosAmbientes ? "Outro_Ambiente" : "",
            auditado ? "Auditado" : "",
            incorporado ? "Incorporado" : ""
        ];

        const itensFiltrados = tagList.filter(item => statusFiltrados.includes(item.status));

        const rows = itensFiltrados.map((item, index) => ({
            ...item?.id_item,
            id_nivel_loc_1: nivel1?._id || '',
            id_nivel_loc_2: nivel2?._id || '',
            id_nivel_loc_3: nivel3?._id || '',
            id_nivel_loc_4: nivel4?._id || ''
        }));

        const itens = rows.filter(item=>item?._id)

        await api.post(`/itensDef/${sessionStorage.getItem('idConta')}`,[{itens: itens}], option).then(
            response =>{
                Swal.fire({
                    title: "Sucesso !",
                    text: 'Itens atualizados com sucesso',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            },
            error=>{
                Swal.fire({
                    title: "Ops... !",
                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        ).finally(()=>{
            setLoading(false)
            setShowModal(false)
        })
    }

    function handleMovimentation(){
        if(tags){
            handleUpload(tags)
        }else{
            getTags()
        }
        setShowModal(false)
    }

    return (
        <>
            <Button variant="light" className="me-2" onClick={e=> {setShowModal(true)}}><TbTruckDelivery className="me-2"/>{t('actions.moviment')}</Button>

            {showModal ? 
                <CustomModal
                    title={t('actions.moviment') + ' ' + t('common.items')}
                    onDeny={e=>{setShowModal(false)}}
                    onConfirm={e=>{handleMovimentation()}}
                >
                    <Typography>Esses itens serão alterados para a localização do inventario.</Typography>
                    <Typography>Deseja continuar ?</Typography>

                    <Stack sx={{py: 2}}>
                        {nivel1 ? <Typography variant="subtitle">Nivel 1: {nivel1?.descricao}</Typography> : ""}
                        {nivel2 ? <Typography variant="subtitle">Nivel 2: {nivel2?.descricao}</Typography> : ""}
                        {nivel3 ? <Typography variant="subtitle">Nivel 3: {nivel3?.descricao}</Typography> : ""}
                        {nivel4 ? <Typography variant="subtitle">Nivel 4: {nivel4?.descricao}</Typography> : ""}
                    </Stack>

                    <Typography>Status que serão movimentados:</Typography>
                    <FormControlLabel control={<Checkbox checked={encontrados} onChange={e=>{setEncontrados(e.target.checked)}} />} label="Encontrados" />
                    <FormControlLabel control={<Checkbox checked={ausentes} onChange={e=>{setAusentes(e.target.checked)}} />} label="Ausentes" />
                    <FormControlLabel control={<Checkbox checked={outrosAmbientes} onChange={e=>{setOutrosAmbientes(e.target.checked)}} />} label="Outros Ambientes" />
                    <FormControlLabel control={<Checkbox checked={auditado} onChange={e=>{setAuditado(e.target.checked)}} />} label="Auditado" />
                    <FormControlLabel control={<Checkbox checked={incorporado} onChange={e=>{setIncorporado(e.target.checked)}} />} label="Incorporado" />
                </CustomModal>
            : ""}
        </>
    )
}