// ----------------------------------------------------------------------

export default function Paper(theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          boxShadow: theme.shadows[2], // Adiciona o box shadow 2
          borderRadius: theme.shape.borderRadius[3], // Adiciona o border radius 3
        },
      },
    },
  };
}
