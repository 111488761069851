import React from 'react';

import { BrowserRouter, Navigate, useNavigate, useRoutes } from 'react-router-dom';

import Login from './pages/Auth/LoginPage';

import Register from './pages/Auth/RegisterPage';

import PBC_Login from './pages/Auth/PBC/LoginPage';

import PBC_Register from './pages/Auth/PBC/RegisterPage';

import Logs from './pages/Logs';

import BalanceSheet from './pages/BalanceSheet';

import Collects from './pages/Collects';

import Inventories from './pages/Inventories';

import CreateInventory from './pages/Inventories/CreateInventory';

import OrderInventory from './pages/Inventories/CreateInventory/Monitoring/Checagem';

import MonitoringInventory from './pages/Inventories/CreateInventory/Monitoring/TagPicking';

import Almoxarifado from './pages/Inventories/CreateInventory/Monitoring/Almoxarifado';

import ExternInventories from './pages/ExternInventories';

import RegistroRotas from './pages/RegistroRotas';

import EILink from './pages/EILink';

import Monitoring from './pages/Monitoring';

import Maps from './pages/Maps';

import MapsC from './pages/MapsContelurb';

import BI from './pages/BI';

import Items from './pages/Items';

import ItemsCopy from './pages/Items/ItemsCopy';

import Partners from './pages/Partners';

import PartnerDetails from './pages/Partners/details';

import Readers from './pages/Readers';

import Categories from './pages/Categories';

import Locations from './pages/Locations';

import Types from './pages/Types';

import Print from './pages/Print';

import Account from './pages/Account';

import Redirect from './components/Redirect';

import PrintOrder from './pages/PrintOrder';

import PrintAdress from './pages/PrintAdress';

import Dashboard from './pages/Dashboard';

import PrivacyPolicy from './pages/Terms/privacyPolicy';

import TermsAndConditions from './pages/Terms/termsConditions';

import UserGuide from './pages/Guide';

import Home from './pages/Home';

import LogActivity from './pages/Activities/index';

import ExportActivity from './pages/Activities/export-page';

import TransportLogin from './pages/Transport/TransportLogin';

import Transport from './pages/Transport';

import ScanQRCode from './pages/Transport/ScanQRCode';

import UploadPhoto from './pages/Transport/UploadPhoto';

import SendData from './pages/Transport/SendData';

import Settings from './pages/settings' 

import Orders from './pages/Orders'

import OrderImport from './pages/Orders/OrderImport'

import Layout from './layout';

import OrderDetails from './pages/Orders/OrderDetails';

import dayjs from 'dayjs';

import secureLocalStorage from 'react-secure-storage';

// ----------------------------------------------------------------------

function isAuthenticated() {
  const hasToken = sessionStorage.getItem('idConta') ? true : false; 

  if (sessionStorage.getItem('disconnect')) {
    const disconnectDate = new Date(sessionStorage.getItem('disconnect'));
    if (dayjs().isSame(disconnectDate) || dayjs().isAfter(disconnectDate)) {
        secureLocalStorage.clear();
        sessionStorage.clear();
    }
  }

  return hasToken; 
}

export default function Router() {
    const authenticated = isAuthenticated();

    const navigate = useNavigate()

    const PrivateRoute = ({ element, ...rest }) => {
      if(authenticated){
        return element
      }
      return <Redirect />
    };
  
    const routes = useRoutes([
        {
          path: '',
          element: <Layout />,
          children: [
            {element: <PrivateRoute element={<Navigate to="/home" />} />, index: true },
            { path: '/home', element: <PrivateRoute element={<Home />} />},
            { path: '/logs', element: <PrivateRoute element={ <Logs />} /> },
            { path: '/balance-sheet', element: <PrivateRoute element={<BalanceSheet />} /> },
            { path: '/collections/collects', element: <PrivateRoute element={<Collects />} /> },
            { path: '/collections/inventories', element: <PrivateRoute element={<Inventories />} /> },
            { path: '/collections/create/checagem/:id', element: <PrivateRoute element={<OrderInventory />} /> },
            { path: '/collections/create/tag_picking/:id', element: <PrivateRoute element={<MonitoringInventory />} /> },
            { path: '/collections/create/almoxarifado/:id', element: <PrivateRoute element={<Almoxarifado />} /> },
            { path: '/collections/create/:id_modelo?', element: <PrivateRoute element={<CreateInventory />} /> },
            { path: '/collections/extern-inventories/:identificador', element: <PrivateRoute element={<EILink />} /> },
            { path: '/collections/extern-inventories', element: <PrivateRoute element={<ExternInventories />} /> },
            { path: '/collections/registers', element: <PrivateRoute element={<RegistroRotas />} /> },
            { path: '/monitoring', element: <PrivateRoute element={<Monitoring />} /> },
            { path: '/maps', element: <PrivateRoute element={<Maps />} /> },
            { path: '/mapsc/:conta', element: <PrivateRoute element={<MapsC />} /> },
            { path: '/BI', element: <PrivateRoute element={<BI />} /> },
            { path: '/items', element: <PrivateRoute element={<Items />} /> },
            { path: '/items/V2', element: <PrivateRoute element={<ItemsCopy />} /> },
            { path: '/partners/details/:id_parceiro', element: <PrivateRoute element={<PartnerDetails />} /> },
            { path: '/partners', element: <PrivateRoute element={<Partners />} /> },
            { path: '/readers', element: <PrivateRoute element={<Readers />} /> },
            { path: '/categories', element: <PrivateRoute element={<Categories />} /> },
            { path: '/locations', element: <PrivateRoute element={<Locations />} /> },
            { path: '/types', element: <PrivateRoute element={<Types />} /> },
            { path: '/print', element: <PrivateRoute element={<Print />} /> },
            { path: '/print-adress', element: <PrivateRoute element={<PrintAdress />} /> },
            { path: '/print-order', element: <PrivateRoute element={<PrintOrder />} /> },
            { path: '/account/:page?', element: <PrivateRoute element={<Settings />} /> },
            { path: '/v2/account/:page?', element: <PrivateRoute element={<Account />} /> },
            { path: '/guides/user_guide', element: <PrivateRoute element={<UserGuide />} /> },
            { path: '/dashboard', element: <PrivateRoute element={<Dashboard />} /> },
            { path: '/home', element: <PrivateRoute element={<Home />} /> },
            { path: '/activity', element: <PrivateRoute element={<LogActivity />} /> },
            { path: '/activityexport', element: <PrivateRoute element={<ExportActivity />} /> },
            { path: '/orders', element: <PrivateRoute element={<Orders />} /> },
            { path: '/orders/:id_registro', element: <PrivateRoute element={<OrderDetails />} />},
            { path: '/orders/upload', element: <PrivateRoute element={<OrderImport />} />},
          ],
        },
        { path: '/login/:id_conta?/:token?', element: <Login /> },
        { path: '/register', element: <Register /> },
        { path: '/PBC/login', element: <PBC_Login /> },
        { path: '/PBC/register', element: <PBC_Register /> },
        { path: '/privacy_policy', element: <PrivacyPolicy /> },
        { path: '/terms-and-conditions', element: <TermsAndConditions /> },
        { path: '*', element: <PrivateRoute element={<Navigate to="home" />} />},
        {
            path: '/transport',
            element: <Transport />,
            children: [
              { element: <Navigate to="qrcode" />, index: true },
              { path: 'login', element: <TransportLogin />},
              { path: 'qrcode/:id_conta?/:tag?', element: <ScanQRCode />},
              { path: 'upload-foto', element: <UploadPhoto />},
              { path: 'confirm-data', element: <SendData />}
            ],
        }
    ]);
  
    return routes;
  }