import { useEffect, useState } from "react"

import { Col, Container, Form, Row, Table } from "react-bootstrap"

export default function CalendarActivities(props){
    const [actions, setActions] = useState([])
    const [year, setYear] = useState(new Date().getFullYear())
    const [yearsOptions, setYearsOptions] = useState([])
    const [month, setMonth] = useState(new Date().getMonth()+1)
    const [media, setMedia] = useState()

    function getUsername(id){
        let username = props.users.filter(item => item._id == id)
        return username[0]?.nome || username[0]?.login || 'Usuário removido'
    }

    useEffect(() => {
        formatActivity()
    }, [year, month])

    function formatActivity(){
        const activity_byUser = []
        const activity = []
        props.activity.forEach((element, index) => {
            activity_byUser[element.id_usuario ? getUsername(element.id_usuario) : `Usuário removido (${index + 1})`] = groupActionsByDate(element.acoes)
            activity.push(groupActionsByDate(element.acoes))
        });
        getAverage(props.activity)
        setActions(activity_byUser)
    }

    function getAverage(activity_byUser) {
        let total_activity = 0
        let worked_days = 0
        let days = []
        activity_byUser.forEach(element => {
            element.acoes.forEach((action) =>{
                if(new Date(action.dt_acao).getFullYear() === year && new Date(action.dt_acao).getMonth() + 1 === month){
                    
                    if(!days.includes(new Date(action.dt_acao).toLocaleString("default", { day: "numeric", month: "long"}))){
                        worked_days++
                        days.push(new Date(action.dt_acao).toLocaleString("default", { day: "numeric", month: "long"}))
                    }
                    total_activity++
                }     
            })
        });

        const calcMedia = total_activity / worked_days
        setMedia(calcMedia)
    }

    function groupActionsByDate( actions ) {
        const actionsByDate = {};
        const years = yearsOptions

        actions.forEach((action) => {
            const date = new Date(action.dt_acao);
            const date_year = date.getFullYear();
            const date_month = date.getMonth() + 1; // Adding 1 because months are zero-indexed
            const day = date.getDate();
            
            if(!yearsOptions.includes(date_year)){
                years.push(date_year)
            }
        
            if (!actionsByDate[date_year]) {
                actionsByDate[date_year] = {};
            }
        
            if (!actionsByDate[date_year][date_month]) {
                actionsByDate[date_year][date_month] = {};
            }
        
            if (!actionsByDate[date_year][date_month][day]) {
                actionsByDate[date_year][date_month][day] = [];
            }
        
            actionsByDate[date_year][date_month][day]++
        });
        setYearsOptions(years)
        return actionsByDate;
    };
    
    function getColor(count){
        if(count === 0){
            return 'bg-light'
        }
        if(count > 0 && count < media){
            return 'bg-danger bg-opacity-25'
        }
        if(count > media){
            return 'bg-success bg-opacity-25'
        }
        return ''
        
    }

    return (
        <>
            <div className="mb-3">
                <Row>
                    <Col sm={3}>
                        <Form.Label>Mês</Form.Label>
                        <Form.Select value={month} onChange={e=>{setMonth(Number(e.target.value))}}>
                            <option value={1}>Janeiro</option>
                            <option value={2}>Fevereiro</option>
                            <option value={3}>Março</option>
                            <option value={4}>Abril</option>
                            <option value={5}>Maio</option>
                            <option value={6}>Junho</option>
                            <option value={7}>Julho</option>
                            <option value={8}>Agosto</option>
                            <option value={9}>Setembro</option>
                            <option value={10}>Outubro</option>
                            <option value={11}>Novembro</option>
                            <option value={12}>Dezembro</option>
                        </Form.Select>
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Ano</Form.Label>
                        <Form.Select value={year} onChange={e=>{setYear(Number(e.target.value))}}>
                            {yearsOptions.map((year, i)=>(
                                <option>{year}</option>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
            </div>
            <div className="bg-white p-3 rounded border shadow-sm">
                <Table>
                    <thead>
                        <th className='col-2'>Usuário</th>
                        {[...Array(31).keys()].map((index) => (
                            <th className="text-center" key={index + 1}>{index + 1}</th> // Mostra os dias do mes (de 1 ao 31)
                        ))}
                    </thead>
                    <tbody>
                    {Object.entries(actions).map(([user]) => (
                        <tr className="text-center border-top">
                            <th >{user}</th>
                            {[...Array(31).keys()].map((day) => (
                                <th className={`text-center border-start ${getColor(actions[user][year] && actions[user][year][month] && actions[user][year][month][day + 1] ? actions[user][year][month][day + 1] : 0)}`} key={day}>
                                    {actions[user][year] && actions[user][year][month] && actions[user][year][month][day + 1] ? actions[user][year][month][day + 1] : '0'}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </>
    )
}