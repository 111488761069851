import * as React from 'react';

import Button from '@mui/material/Button';

import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';

import DialogTitle from '@mui/material/DialogTitle';

import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';

import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';

import { Icon } from '@iconify/react';

import { Box, Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: 400,
    borderRadius: 24
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
}));

export default function Alert({ title, message, type, icon, children, dividers = true, onClose }) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose(null)
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
      >
        <DialogContent>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon icon='line-md:close' />
          </IconButton>

          <Box sx={{p:3, maxWidth: 500}}>
            {type ? 
              <Grid container justifyContent="center" alignItems="center" sx={{p:5}}>
                <IconButton color={type}>
                  <Icon icon={icon ? icon : type.includes('error') ? 'lets-icons:close-ring-duotone' : type.includes('success') ? 'lets-icons:check-ring-duotone' : ''} width={64}/>
                </IconButton>
              </Grid>
            : ''}
            
            <Typography variant="h1" align="center">{title ?? type ?  t(`messages.${type}`) : ""}</Typography>

            <Typography align="center">{message ?? type ?  t(`messages.${type}_message`) : ""}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="dark" onClick={handleClose} fullWidth={true} sx={{borderRadius: 5, mx: 5}}>{t('actions.close')}</Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
