import React, {useState, useEffect} from 'react'
import ReactLoading from 'react-loading'

import Swal from 'sweetalert2'

import InputMask from 'react-input-mask'

import {FaFileCsv, FaFilePdf, FaRegEye} from 'react-icons/fa'
import {FiArrowLeft, FiFilter, FiSave, FiUpload} from 'react-icons/fi'
import {parse} from 'papaparse'

import api from '../../services/api'
import Menu from '../../components/Menu'
import ExportCSV from '../../components/exportCSV'
import ExportPDF from '../../components/exportPDF'

import './style.css'
import { Container, Row, Col, Table, Button, Dropdown, Form, FloatingLabel, Modal, CloseButton } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

import {useNavigate} from 'react-router-dom'
import { MdOutlineEdit } from 'react-icons/md'

function Partners() {
    const navigate = useNavigate()

    const { t } = useTranslation();

    const [items, setItems] = useState([])
    const [ativo, setAtivo] = useState('1')
    const [nome, setNome] = useState('*')
    const [uuid, setUuid] = useState('*')
    const [idParceiroNew, setIdParceiroNew] = useState('0')
    const [idExternoNew, setIdExternoNew] = useState('')
    const [nomeNew, setNomeNew] = useState('')
    const [tagNew, setTagNew] = useState('')
    const [uuidNew, setUuidNew] = useState('')
    const [ativoNew, setAtivoNew] = useState('1')
    const [emailNew, setEmailNew] = useState('')
    const [telefoneNew, setTelefoneNew] = useState('')
    const [enderecoNew, setEnderecoNew] = useState('')
    const [observacaoNew, setObservacaoNew] = useState('')
    const [change, setChange] = useState(0)
    const [editing, setEditing] = useState(0)
    const [uploaded, setUploaded] = useState(0)
    const [importList, setImportList] = useState([])
    const [itemsLoaded, setItemsLoaded] = useState(true)

    const fileTypes = ["text/csv", "application/vnd.ms-excel", "text/plain"]
    const headersCSV = [
        "COD_EXTERNO",
        "ATIVO",
        "TAG",
        "UUID",
        "NOME",
        "ENDEREÇO",
        "EMAIL",
        "TELEFONE",
        "OBSERVAÇÃO"
    ]

    useEffect(() => {
        parceiros()
    }, [nome, uuid, ativo])

    function parceiros() {
        setItemsLoaded(false)
        api.get(`/parceiro/${sessionStorage.getItem('idConta')}/*/${nome}/${uuid}/${ativo}`, {}
        ).then(response => {setItems(response.data)}).finally(
            () => {setItemsLoaded(true)}
        )
    }

    //
    function csv() {
        let body = items.map((item, index) => {
            return [
                item.id_externo ? item.id_externo : '',
                item.ativo ? item.ativo : '',
                item.tag ? item.tag : '',
                item.uuid ? item.uuid : '',
                item.nome ? item.nome : '',
                item.endereco ? item.endereco : '',
                item.email ? item.email : '',
                item.telefone ? item.telefone : '',
                item.observacao ? item.observacao : ''
            ]
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }
    //

    async function chooseFileImport(file) {
        const text = await file.text()
        const result = parse(text)
        const filter = result.data.filter(item => {
            if(!(item.length <= 1)) {return item}
        })
        setImportList(filter)
    }

    function changeFunction(key) {
        switch (key) {
            case 0:
                return (
                    <div >
                        <Container fluid>
                            <Row className="my-3">
                                <Col><h3>{t('common.partners')}</h3></Col>
                                <Col className="d-flex justify-content-end">
                                    <Button variant="light" className="me-2 text-uppercase" onClick={e => setChange(6)}><FaFilePdf className="me-2"/> {t('actions.export')} PDF</Button>
                                    <Button variant="light" className="me-2 text-uppercase" onClick={e => setChange(5)}><FaFileCsv className="me-2"/> {t('actions.export')} CSV</Button>
                                    {sessionStorage.getItem('idConta') === 'c68AeC-CZ' && sessionStorage.getItem('perfil') == 'usu' || sessionStorage.getItem('idConta') === 'JAxNy36WU' && sessionStorage.getItem('perfil') == 'usu' ? '' :
                                        <>
                                            <Button variant="light" className="me-2 text-uppercase" onClick={e => setChange(1)}>{t('actions.import')}</Button>
                                            <Button variant="custom-primary" className="text-uppercase" onClick={e => {setChange(2)}}>+ {t('actions.create')}</Button>
                                        </>
                                    }
                                </Col>
                            </Row>
                            <Col className="p-3 border rounded bg-white">
                                <Col className="rounded bg-primary-light p-3 mb-5">
                                    <div className="d-flex align-items-center">
                                        <h5 className="fw-bold text-primary">
                                            <FiFilter/>
                                            <span className="px-2">{t('common.filter')}</span>
                                        </h5>
                                    </div>
                                    <Row>
                                        <Form.Group as={Col}>
                                            <Form.Label className="fw-bold">Ativo</Form.Label>
                                            <Form.Select
                                                value={ativo}
                                                onChange={e=> {setAtivo(e.target.value)}}
                                            >
                                                <option value={'*'}>Todos</option>
                                                <option value={'1'}>Sim</option>
                                                <option value={'0'}>Não</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label className="fw-bold">Nome</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={e=> {e.target.value == '' ? setNome('*') : setNome(e.target.value)}}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label className="fw-bold">UUID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={e=> {e.target.value == '' ? setUuid('*') : setUuid(e.target.value)}}
                                            />
                                        </Form.Group>
                                    </Row>
                                </Col>
                                {!itemsLoaded ?
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                    </div>
                                :
                                    <>
                                        <Table responsive>
                                            <thead>
                                                <tr id="header">
                                                    <th>#</th>
                                                    <th>NOME</th>
                                                    <th>UUID</th>
                                                    <th>TELEFONE</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {items.map((item, index) => (
                                                    <tr key={index} onClick={e=> {navigate(`/partners/details/${item._id}`)}}>
                                                        <th>{index + 1}</th>
                                                        <th>{item.nome}</th>
                                                        <th>{item.uuid}</th>
                                                        <th>{item.telefone}</th>
                                                        <th><Button variant="outline-primary" className='me-1'><FaRegEye/></Button>
                                                        <Button 
                                                            variant="outline-primary" 
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                editItem(item)
                                                            }}
                                                        >
                                                            <MdOutlineEdit />
                                                        </Button></th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                }
                            </Col>
                        </Container>
                    </div>
                )
                break
            case 1:
                return (
                    <div >
                        <Row>
                            <Col><h3>{t('common.partners')}</h3></Col>
                            <Col className="d-flex justify-content-end">
                                <Button variant="light" className="me-2 text-uppercase" onClick={e => {importSave()}}><FiSave className="me-2"/>{t('actions.save')}</Button>
                                <Button variant="light" className="text-uppercase" onClick={e => {
                                    parceiros()
                                    cleaner()
                                }}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                            </Col>
                        </Row>
                        <Col className="bg-white rounded border p-3">
                            <Form.Group>
                                <input id='dropFile' style={{display: 'none'}} type='file' accept={fileTypes} onChange={e=> {
                                    e.preventDefault()
                                    if(e.target.files[0]) {
                                        chooseFileImport(e.target.files[0])
                                    }
                                }}/>
                                <div
                                    className="p-5 rounded d-flex justify-content-center align-items-center flex-column input-file"
                                    style={{
                                        border:'3px dashed #d8e4f7'
                                    }}
                                    onDragOver={e=> {e.preventDefault()}}
                                    onDrop={e=> {
                                        e.preventDefault()
                                        if(fileTypes.includes(e.dataTransfer.files[0].type))
                                        chooseFileImport(e.dataTransfer.files[0])
                                    }}
                                    onClick={e=>{
                                        let getFile = window.document.getElementById('dropFile')
                                        getFile.click()
                                    }}
                                >
                                    <FiUpload fontSize={64} style={{color:'var(--primary-color)'}}/>
                                    <h6>{t('items.upload_file')} ( .CSV, .TXT)</h6>
                                </div>
                            </Form.Group>
                            <div className="pagesContent" style={{marginTop: '10px'}}>
                                <div className="numberPages">Itens: {importList.length}</div>
                                <div className="numberPages">Importados: {uploaded}</div>
                            </div>
                            <Table>
                                <thead>    
                                    <tr id="header">
                                        <th>#</th>
                                        <th>CÓD. EXTERNO</th>
                                        <th>ATIVO</th>
                                        <th>TAG</th>
                                        <th>UUID</th>
                                        <th>NOME</th>
                                        <th>ENDEREÇO</th>
                                        <th>EMAIL</th>
                                        <th>TELEFONE</th>
                                        <th>OBSERVAÇÃO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {importList.map((item, index) => (
                                        <tr key={index} style={index + 1 <= uploaded? {background: '#919191', color: '#F8F8F8'} : {}}>
                                            <th>{index + 1}</th>
                                            <th>{item[0]}</th>
                                            <th>{item[1]}</th>
                                            <th>{item[2]}</th>
                                            <th>{item[3]}</th>
                                            <th>{item[4]}</th>
                                            <th>{item[5]}</th>
                                            <th>{item[6]}</th>
                                            <th>{item[7]}</th>
                                            <th>{item[8]}</th>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </div>
                )
                break
            case 2:
                return(
                    <div >
                        <Container fluid>
                            <Col lg={8}>
                                <Row className='my-3'>
                                    <Col><h3>{t('common.partners')}</h3></Col>
                                    <Col className=' d-flex justify-content-end'>
                                        <Button variant="light" className="text-uppercase" onClick={e => {cleaner()}}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={8}>
                                <Row className="gy-3">
                                    <Form.Group as={Col} lg={8}>
                                        <Form.Label className="fw-bold">Nome</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={nomeNew}
                                            onChange={e=> {setNomeNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg={4}>
                                        <Form.Label className="fw-bold">Tag</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={tagNew}
                                            onChange={e=> {setTagNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg={4}>
                                        <Form.Label className="fw-bold">ID Externo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={idExternoNew}
                                            onChange={e=> {setIdExternoNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg={4}>
                                        <Form.Label className="fw-bold">UUID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={uuidNew}
                                            onChange={e=> {setUuidNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg={4}>
                                        <Form.Label className="fw-bold">Ativo</Form.Label>
                                        <Form.Select className="fw-bold"
                                            value={ativoNew}
                                            onChange={e=> {setAtivoNew(e.target.value)}}
                                        >
                                            <option value="0">Não</option>
                                            <option value="1">Sim</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6}>
                                        <Form.Label className="fw-bold">Telefone</Form.Label>
                                        <InputMask
                                            className="d-flex w-100 form-control"
                                            mask="+99 (99) 99999-9999"
                                            value={telefoneNew}
                                            onChange={e=> {setTelefoneNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6}>
                                        <Form.Label className="fw-bold">Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            value={emailNew}
                                            onChange={e=> {setEmailNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="fw-bold">Endereço</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={enderecoNew}
                                            onChange={e=> {setEnderecoNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="fw-bold">Observação</Form.Label>
                                        <Form.Control
                                            as="textarea" rows={3}
                                            type="text"
                                            value={observacaoNew}
                                            onChange={e=> {setObservacaoNew(e.target.value)}}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="justify-content-end my-2">
                                {sessionStorage.getItem('idConta') === 'c68AeC-CZ' && sessionStorage.getItem('perfil') == 'usu' || sessionStorage.getItem('idConta') === 'JAxNy36WU' && sessionStorage.getItem('perfil') == 'usu' ? '':
                                    <>
                                        {editing == 1 ? (<Button variant="outline-dark" as={Col} lg={3} className="me-2" onClick={e=> {remove()}}>EXCLUIR</Button>): ''}
                                        <Button variant="custom-danger" as={Col} lg={3} onClick={e=> {save()}}>SALVAR</Button>
                                    </>
                                }   
                                </Row>
                            </Col>
                        </Container>
                    </div>
                )
                break
            case 5:
                return (
                    <div >   
                        <Container fluid>               
                            <Col className="d-flex justify-content-between" id="colection">
                                <h3>{t('common.partners')} - {t('actions.generate')} CSV</h3>
                                <Button variant="light" className="text-uppercase" onClick={e => {setChange(0)}}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                            </Col>
                            <ExportCSV headers={headersCSV} listItens={csv()} modulo={'parceiros'}/>
                        </Container>
                    </div>
                )
                break
            case 6:
            return (
                <div >                  
                    <Container fluid>
                        <Col className="d-flex justify-content-between" id="colection">
                            <h3>{t('items.generate')} CSV</h3>
                            <Button variant="light" className="text-uppercase" onClick={e => {setChange(0)}}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                        </Col>
                        <ExportPDF headers={headersCSV} listItens={csv()} modulo={'parceiros'}/>
                    </Container>
                </div>
            )
            break
            default:
                return (
                    <div >Error</div>
                )
                break
        }
    }

    function editItem(item) {
        setChange(2)
        setEditing(1)
        setIdParceiroNew(item._id)
        setIdExternoNew(item.id_externo ? item.id_externo : '')
        setNomeNew(item.nome ? item.nome : '')
        setTagNew(item.tag ? item.tag : '')
        setUuidNew(item.uuid ? item.uuid : '')
        setAtivoNew(item.ativo)
        setTelefoneNew(item.telefone ? item.telefone : '')
        setEmailNew(item.email ? item.email : '')
        setEnderecoNew(item.endereco ? item.endereco : '')
        setObservacaoNew(item.observacao ? item.observacao : '')
    }

    function cleaner() {
        setChange(0)
        setEditing(0)
        setUploaded(0)
        setIdParceiroNew('0')
        setIdExternoNew('')
        setNomeNew('')
        setTagNew('')
        setUuidNew('')
        setAtivoNew('1')
        setTelefoneNew('')
        setEmailNew('')
        setEnderecoNew('')
        setObservacaoNew('')
        setImportList([])
    }
    
    function save() {
        Swal.fire({
            title: "Atenção !",
            text: idParceiroNew == '0' ? "Deseja realmente criar este parceiro ?" : "Deseja realmente editar este parceiro ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    if(nomeNew == '') {
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Informe o nome do Parceiro',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else if(telefoneNew.includes('_')) {
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Informe o numero de telefone corretamente ou faça a limpeza do campo de informação',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else if(emailNew != '' && !(emailNew.includes('@') && emailNew.includes('.'))) {
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Informe o email corretamente ou faça a limpeza do campo de informação',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else {
                        let reg = []
                        reg.push({
                            "id_parceiro": idParceiroNew,
                            "id_conta": sessionStorage.getItem('idConta'),
                            "id_externo": idExternoNew,
                            "ativo": ativoNew,
                            "tag": tagNew,
                            "uuid": uuidNew,
                            "nome": nomeNew,
                            "endereco": enderecoNew,
                            "telefone": telefoneNew,
                            "email": emailNew,
                            "observacao": observacaoNew
                        })
            
                        let option = {
                            headers: { 'Content-Type': ['application/json'] }
                        }
            
                        api.post('/parceiro', reg, option
                        ).then(
                            response => {
                                parceiros()
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: idParceiroNew == '0' ? 'Parceiro criado com sucesso' : "Parceiro editado com sucesso",
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                cleaner()
                            },
                            response => {
                                Swal.fire({
                                    title: "Ops... !",
                                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                        )
                    }
                }
            }
        )
    }

    function importSave() {
        if(importList.length == uploaded) {
            window.alert('Não há nada a ser importado!')
        }else {
            let ok = true
            let importNumber = 0
            importList.map((item, index) => {
                if(ok) {
                    if(item[4] == '') {
                        window.alert(`Ops...\nInforme o nome do Parceiro na linha ${index + 1}.`)
                    }else {
                        let reg = []
                        reg.push({
                            "id_parceiro": "0",
                            "id_conta": sessionStorage.getItem('idConta'),
                            "id_externo": item[0],
                            "ativo": item[1],
                            "tag": item[2],
                            "uuid": item[3],
                            "nome": item[4],
                            "endereco": item[5],
                            "email": item[6],
                            "telefone": item[7],
                            "observacao": item[8]
                        })
            
                        let option = {
                            headers: { 'Content-Type': ['application/json'] }
                        }
            
                        api.post('/parceiro', reg, option
                        ).then(
                            response => {
                                importNumber++
                                setUploaded(importNumber)
                                if(item == importList[importList.length - 1]) {
                                    window.alert("Importado !\nLista de parceiros salvo com sucesso")
                                }
                            },
                            response => {
                                ok = false
                                window.alert(`Temos um problema na linha ${index + 1}...\n${response.data.error}`)
                            }
                        )
                    }
                }
            })
        }
    }

    function remove() {
        Swal.fire({
            title: "Atenção !",
            text: "Deseja realmente excluir este parceiro ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    api.delete(`/parceiro/${idParceiroNew}`, option
                    ).then(
                        response => {
                            parceiros()
                            Swal.fire({
                                title: "Sucesso !",
                                text: 'Parceiro foi excluido de sua base',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            cleaner()
                        },
                        response => {
                            Swal.fire({
                                title: "Ops... !",
                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            }) 
                        }
                    )
                }
            }
        )
    }

    return (
        <div    >
            <div id="background">
                {changeFunction(change)}
            </div>
        </div>
    )
}

export default Partners