import { forwardRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import InputMask from 'react-input-mask';

import { Icon } from "@iconify/react/dist/iconify.js"

import { Alert, Box, Button, FilledInput, FormControl, Input, InputAdornment, InputLabel, Stack, TextField, Typography } from "@mui/material"

import { t } from "i18next"

import logo from "../../assets/logo/inovaone/logotype.png"

import secureLocalStorage from 'react-secure-storage'; 

//-----------------------------------------------------------------

export default function Login(){
    const navigate = useNavigate()

    const [username, setUsername] = useState()

    const [cellphone, setCellphone] = useState()

    const [error, setError] = useState()

    function handleLogin(){
        if(!username || !cellphone){
            setError("Insira email e telefone.")
            return
        }

        const userData = {
            "usuario": username,
            "telefone": cellphone
        }

        secureLocalStorage.setItem('transport-user', userData)

        navigate('/transport/qrcode')
    }

    return (
        <>
            <Stack
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{
                    height: '100vh'
                }}
            >
                <Stack 
                    justifyContent={{xs: 'space-between', md: 'center'}}
                    alignItems="center"
                    sx={{
                        width: {
                            xs: '100vw',
                            md:'30vw', 
                        },
                        padding: 3,
                        paddingBlock: 10,
                        height: {
                            xs: '100vh'
                        }
                    }}
                    spacing={10}
                >
                    <Box
                        component="img"
                        width={150}
                        src={logo}
                    />
                    <Stack sx={{width: '100%'}} spacing={3}>
                        <FormControl fullWidth variant="filled">
                            <InputLabel>Nome</InputLabel>
                            <FilledInput
                                id="inovaone-name-input"
                                name="inovaone-name-input"
                                onChange={e=>{setUsername(e.target.value)}}
                                startAdornment={<InputAdornment position="start">
                                    <Icon icon="ph:user-bold"/>
                                </InputAdornment>}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="filled">
                            <InputLabel>Telefone</InputLabel>
                            <InputMask
                                id="inovaone-cellphone-input"
                                name="inovaone-cellphone-input"
                                mask="(99) 99999-9999"
                                value={cellphone}
                                onChange={(event) => setCellphone(event.target.value)}
                            >
                                {(inputProps) => (
                                    <FilledInput
                                        {...inputProps}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Icon icon="iconamoon:phone" />
                                            </InputAdornment>
                                        }
                                    />
                                )}
                            </InputMask>
                        </FormControl>

                        {error && (
                            <Alert variant="outlined" severity="error" fullWidth>
                                {error}
                            </Alert>
                        )}
                    </Stack>

                    <Button variant="contained" fullWidth sx={{borderRadius: 32, boxShadow: 1}} onClick={e=>{handleLogin()}}>{t('login.buttons.login')}</Button>
                </Stack>
            </Stack>
        </>
    )
}