import { alpha } from '@mui/material/styles';

export default function Chip(theme) {
    return {
        MuiChip: {
            variants: [
              {
                props: { variant: 'soft' },
                style: ({ theme, ownerState }) => {
                  const getColor = (color) => {
                    if (color === 'error') return theme.palette.error;
                    if (color === 'warning') return theme.palette.warning;
                    if (color === 'info') return theme.palette.info;
                    if (color === 'success') return theme.palette.success;
                    return theme.palette.primary; // Default to primary
                  };
      
                  const backgroundColor = ownerState.color
                    ? alpha(getColor(ownerState.color).main, 0.4) // Cor mais clara
                    : alpha(theme.palette.primary.main, 0.2);
      
                  const textColor = ownerState.color
                    ? getColor(ownerState.color).dark
                    : theme.palette.primary.main;
      
                  return {
                    backgroundColor,
                    color: textColor,
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: alpha(getColor(ownerState.color).main, 0.5),
                    },
                  };
                },
              },
            ],
        }
    };
}
