import { useEffect, useState } from 'react';

import { Icon } from '@iconify/react/dist/iconify.js';

import { Grid, Stack, Typography } from '@mui/material';

import { grey } from '@mui/material/colors';

import PieChart from '../../../components/ReCharts/PieChart.jsx';

//----------------------------------------------------------------

export default function StatusPieChart({data, colors}){
    return (
        <Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Icon icon="solar:pie-chart-broken"/>
                <Typography variant="h6">Quantidade por Status</Typography>
            </Stack>
            <PieChart data={data}/>
        </Stack>
    )
}