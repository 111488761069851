import { useEffect, useState } from "react";

import { t } from "i18next";

import api from "../../../services/api";

import { Backdrop, Button, CircularProgress, Stack, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import secureLocalStorage from "react-secure-storage";

import UsersList from "./UsersList";

import UsersDetails from "./UsersDetails";

//----------------------------------------------------------------

export default function ConfigUsers(){
    const [userList, setUserList] = useState([])

    const [userID, setUserID] = useState()

    const [loading, setLoading] = useState(false)

    const [showForm, setShowForm] = useState(false)

    useEffect(()=>{
        getUserList()
    },[])

    function getUserList(){
        setLoading(true)
        api.get(`/usuario/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {
            setLoading(false)
            setUserList(response.data)
        })
    }

    function handleEdit(e){
        setUserID(e)
        setShowForm(true)
    }

    function handleExit(){
        setUserID()
        setShowForm(false)
    }

    function handleSave(){
        getUserList()
        setUserID()
        setShowForm(false)
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {showForm ?
                <UsersDetails userData={userList[userID]} onExit={e=>{handleExit()}} onSave={e=>{handleSave()}}/> 
                : 
                <UsersList userList={userList} onEdit={e=>{handleEdit(e)}} onCreate={e=>{setShowForm(true)}}/>
            }
        </>
    )
}