import { useEffect, useState } from "react";

import { t } from "i18next";

import api from "../../services/api";

import b64Resize from "../../services/b64Resize";

import useToken from "../../hooks/useToken";

// Styles 

import { Icon } from "@iconify/react/dist/iconify.js";

import { Box, Button, Container, Paper, Stack, Typography, MenuItem, Select, TextField, styled, FormGroup, Grid, Divider, IconButton, Autocomplete, InputAdornment, OutlinedInput, FormControlLabel, Switch } from "@mui/material";

//----------------------------------------------------------------

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: '100%',
});

export default function ItemDetails({ item, infoComplLabels, onChange}){
    const { token } = useToken()
    
    const [categoria, setCategoria] = useState(item?.id_categoria?._id)

    const [marca, setMarca] = useState(item?.id_marca?._id)

    const [modelo, setModelo] = useState(item?.id_modelo)

    const [parceiro, setParceiro] = useState(item?.id_parceiro?._id)

    const [tag, setTag] = useState(item?.tag)

    const [IDExterno, setIDExterno] = useState(item?.id_externo)

    const [ativo, setAtivo] = useState(item?.ativo || 1)

    const [infoCompl1, setInfoCompl1] = useState(item?.inf_compl_1 ?? [])

    const [infoCompl2, setInfoCompl2] = useState(item?.inf_compl_2 ?? [])

    const [infoCompl3, setInfoCompl3] = useState(item?.inf_compl_3 ?? [])

    const [infoCompl4, setInfoCompl4] = useState(item?.inf_compl_4 ?? [])

    const [infoCompl5, setInfoCompl5] = useState(item?.inf_compl_5 ?? [])

    const [infoCompl6, setInfoCompl6] = useState(item?.inf_compl_6 ?? [])

    const [infoCompl7, setInfoCompl7] = useState(item?.inf_compl_7 ?? [])

    const [infoCompl8, setInfoCompl8] = useState(item?.inf_compl_8 ?? [])

    const [infoCompl9, setInfoCompl9] = useState(item?.inf_compl_9 ?? [])

    const [infoCompl10, setInfoCompl10] = useState(item?.inf_compl_10 ?? [])

    const [nivel1, setNivel1] = useState(item?.id_nivel_loc_1?._id)

    const [nivel2, setNivel2] = useState(item?.id_nivel_loc_2?._id)

    const [nivel3, setNivel3] = useState(item?.id_nivel_loc_3?._id)

    const [nivel4, setNivel4] = useState(item?.id_nivel_loc_4?._id)

    const [latitude, setLatitude] = useState(item?.latitude ?? [])

    const [longitude, setLongitude] = useState(item?.longitude ?? [])

    const [radius, setRadius] = useState(item?.raio)

    const [valor, setValor] = useState(item?.valor)

    const [registro, setRegistro] = useState(item?.id_registro ?? [])

    const [observacao, setObservacao] = useState(item?.observacao)

    const [fotoPrincipal, setFotoPrincipal]  = useState(item?.foto ? "https://api.inovacode.app.br/" + item.foto : "")

    const [fotosComplementares, setFotosComplementares]  = useState(item?.foto_compl || [])

    const [fotosRemovalList, setFotosRemovalList] = useState([])

    // List //
    const [categoriaList, setCategoriaList] = useState([])

    const [marcaList, setMarcaList] = useState([])

    const [modeloList, setModeloList] = useState([])

    const [parceiroList, setParceiroList] = useState([])

    const [nivel1List, setNivel1List] = useState([])

    const [nivel2List, setNivel2List] = useState([])

    const [nivel3List, setNivel3List] = useState([])

    const [nivel4List, setNivel4List] = useState([])

    const [infoComplList, setInfoComplList] = useState([]); // Informações complementares

    // Functions options
    const [expandInfos, setExpandInfos] = useState(false) // Mostrar todos os campos de informação complementares
    
    useState(()=>{
        function categoriaGet() {
            api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
            ).then(response => {
                setCategoriaList(response.data.filter(c => c.descricao));
            })
        }

        categoriaGet()
    }, [])

    useEffect(() => {
        async function getMarcas() {
            try {
                const response = await api.get(`/infs_compl/${sessionStorage.getItem('idConta')}?id_categoria=${categoria}&marca=&modelo=`);
                const marcaList = response.data;
                setMarcaList(marcaList);
                
                if (marca) {
                    const selectedMarca = marcaList.find(item => item._id === marca);
                    const modelos = selectedMarca ? selectedMarca.modelo : [];
                    setModeloList(modelos);
    
                    if (modelos && !modelos.find(item => item._id === modelo)) {
                        setModelo(null); // reset modelo if it's not found in the list
                    }
                }
            } catch (error) {
                console.error("Failed to fetch marcas:", error);
                // Handle the error, e.g., set an error message in the state
            }
        }
    
        if (categoria) {
            getMarcas();
        }
    }, [categoria, marca]);

    useEffect(() => {
        function parceiroGet() {
            api.get(`/parceiro/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
            ).then(response => {setParceiroList(response.data)})
        }

        parceiroGet()
    }, [])

    useEffect(() => {
        function nivel1Get() {
            api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
            ).then(
                response => {
                    setNivel1List(response.data)
                }
            )
        }

        nivel1Get()
    }, [])

    useEffect(() => {
        function nivel2Get(nivel1) {
            if (nivel1 && nivel1 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel1Path = nivel1 || '*';
        
                api.get(`/nivel_loc2/${idConta}/${nivel1Path}/*/*/*/*`)
                .then(
                    response => {
                        setNivel2List(response.data)
                        if(!response?.data.find(item => item._id === nivel2)){
                            setNivel2()
                            setNivel3()
                            setNivel4()
                        }
                    }
                )
            } else {
                setNivel2List([]);
            }
        } 

        nivel2Get(nivel1)
    }, [nivel1])

    useEffect(() => {
        function nivel3Get(nivel2) {
            if (nivel2 && nivel2 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel2Path = nivel2 || '*';
        
                api.get(`/nivel_loc3/${idConta}/${nivel2Path}/*/*/*/*`)
                    .then(response => {
                        setNivel3List(response.data);
                        if(!response?.data.find(item => item._id === nivel3)){
                            setNivel3()
                            setNivel4()
                        }
                    })
                    .catch(error => {
                        console.error("Error fetching data:", error);
                        // Optional: handle error state, show user feedback, etc.
                    });
            } else {
                setNivel3List([]);
            }
        }

        nivel3Get(nivel2)
    }, [nivel2])
    
    useEffect(() => {
        function nivel4Get(nivel3) {
            if (nivel3 && nivel3 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel3Path = nivel3 || '*';
        
                api.get(`/nivel_loc4/${idConta}/${nivel3Path}/*/*/*/*`)
                    .then(response => {
                        setNivel4List(response.data);
                    })
            } else {
                setNivel4List([]);
            }
        } 

        nivel4Get(nivel3)
    }, [nivel3])

    useEffect(() => {
        function getInfoCompl(){
            api.get(`/select_box/*/${sessionStorage.getItem('idConta')}`).then(
                response => {
                    let lista = []
                    for(let i = 0; i < 10; i++){
                        let aux = response.data.filter((item) => {return item.destino == `inf_compl_${i+1}`})
                        lista[i] = aux ? aux[0] : null 
                    }
                    //setinfoComplList(lista)
                }
            )
        }

        getInfoCompl()
    }, [])

    useEffect(()=>{
        setInfoComplList([
            {
                label: infoComplLabels[0] ? infoComplLabels[0] : `${t('common.info_label')} 1`,
                modo: infoComplList[0] && infoComplList[0]?.valores?.length ? 'lista' : 'texto',
                show: true
            },
            {
                label: infoComplLabels[1] ? infoComplLabels[1] : `${t('common.info_label')} 2`,
                modo: infoComplList[1] && infoComplList[1]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[1] || infoCompl2 ? true : false,
            },
            {
                label: infoComplLabels[2] ? infoComplLabels[2] : `${t('common.info_label')} 3`,
                modo: infoComplList[2] && infoComplList[2]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[2] || infoCompl2 ? true : false,
            },
            {
                label: infoComplLabels[3] ? infoComplLabels[3] : `${t('common.info_label')} 4`,
                modo: infoComplList[3] && infoComplList[3]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[3] || infoCompl2 ? true : false,
            },
            {
                label: infoComplLabels[4] ? infoComplLabels[4] : `${t('common.info_label')} 5`,
                modo: infoComplList[4] && infoComplList[4]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[4] || infoCompl2 ? true : false,
            },
            {
                label: infoComplLabels[5] ? infoComplLabels[5] : `${t('common.info_label')} 6`,
                modo: infoComplList[5] && infoComplList[5]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[5] || infoCompl2 ? true : false,
            },
            {
                label: infoComplLabels[6] ? infoComplLabels[6] : `${t('common.info_label')} 7`,
                modo: infoComplList[6] && infoComplList[6]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[6] || infoCompl2 ? true : false,
            },
            {
                label: infoComplLabels[7] ? infoComplLabels[7] : `${t('common.info_label')} 8`,
                modo: infoComplList[7] && infoComplList[7]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[7] || infoCompl2 ? true : false,
            },
            {
                label: infoComplLabels[8] ? infoComplLabels[8] : `${t('common.info_label')} 9`,
                modo: infoComplList[8] && infoComplList[8]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[8] || infoCompl2 ? true : false,
            },
            {
                label: infoComplLabels[9] ? infoComplLabels[9] : `${t('common.info_label')} 10`,
                modo: infoComplList[9] && infoComplList[9]?.valores?.length ? 'lista' : 'texto',
                show: expandInfos || infoComplLabels[9] || infoCompl2 ? true : false,
            }
        ])
    }, [expandInfos])

    function handleImageInput(event, type){
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            if(type === 'principal'){
                setFotoPrincipal(imageUrl);
            }else{
                setFotosComplementares([...fotosComplementares, imageUrl])
            }
        }
    };

    function handleRemoveFoto(value, type) {
        let image
        if(type === 'complementar'){
            image = fotosComplementares[value].replace('https://api.inovacode.app.br/', '').replace('.jpeg', '')                      

            setFotosComplementares(prevFotos => {
                const newFotos = [...prevFotos];
                newFotos.splice(value, 1);
                return newFotos;
            });
        }else{
            image = value.replace('https://api.inovacode.app.br/', '').replace('.jpeg', '')
            setFotoPrincipal("")
        }
        if(!image.includes('blob')){
            setFotosRemovalList([...fotosRemovalList], image)
        }
    }

    async function removeFotos() {
        const header = {
            headers: { 'id_conta': sessionStorage.idConta, 'Authorization': `Bearer ${token}` }
        };

        const uploadImagesPromises = fotosComplementares.map(async (foto) => {
            return await api.delete(`/imgDel/${foto}`, header);
        });
    
        const uploadImagesList = await Promise.all(uploadImagesPromises);

        onChange(0);
    }

    async function uploadImages() {
        const uploadImagesPromises = fotosComplementares.map(async (foto) => {
            if (foto.includes('blob')) {
                return 'https://api.inovacode.app.br/' + await saveImage(foto);
            } else {
                return foto;
            }
        });
    
        const uploadImagesList = await Promise.all(uploadImagesPromises);

        return uploadImagesList;
    }    
    
    async function saveImage(image) {
        const option = {
            headers: { 'Content-Type': 'application/json' }
        };
    
        if (image && image.includes('blob')) {
            const b64 = await b64Resize(image, 500, 500);
            const response = await api.post(`/save_jpeg/${sessionStorage.getItem('idConta')}`, [{ foto: b64 }], option);
            return response.data[0].id_foto
        }
    }  

    async function save(){
        const uploadImageList = await uploadImages()
        setFotosComplementares(uploadImageList)

        const reg = [{
            ativo: ativo || 1,
            foto: await saveImage(fotoPrincipal),
            foto_compl: uploadImageList,
            id_categoria: categoria,
            id_conta: sessionStorage.getItem('idConta'),
            id_usuario: sessionStorage.getItem('idUser'),
            id_externo: IDExterno,
            id_item_vinculado: null,
            id_nivel_loc_1: nivel1,
            id_nivel_loc_2: nivel2,
            id_nivel_loc_3: nivel3,
            id_nivel_loc_4: nivel4,
            id_parceiro: parceiro,
            id_registro: item?.id_registro?._id,
            inf_compl_1: infoCompl1,
            inf_compl_2: infoCompl2,
            inf_compl_3: infoCompl3,
            inf_compl_4: infoCompl4,
            inf_compl_5: infoCompl5,
            inf_compl_6: infoCompl6,
            inf_compl_7: infoCompl7,
            inf_compl_8: infoCompl8,
            inf_compl_9: infoCompl9,
            inf_compl_10: infoCompl10,
            status_estoque: item?.status_estoque,
            latitude: latitude || "",
            longitude: longitude || "",
            raio: radius || "",
            lido_registro: 1,
            observacao: observacao,
            tag: tag,
            valor: Number(valor),
            _id: item._id || 0, 
            id_marca: marca,
            id_modelo: modelo
        }]

        const option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        console.log(uploadImageList)
        api.post('/itemBO', reg, option
        ).then(
            response=> { 
                if(fotosRemovalList.length){
                    removeFotos(uploadImageList)
                }else{
                    onChange(0)
                }
            }
        )
    }

    return (
        <>
            <Container>
                <Box sx={{py: 5}}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h1">{t('items.manage_itens')}</Typography>
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" color="dark" onClick={e=>{onChange(0)}}>{t('actions.exit')}</Button>
                            <Button variant="contained" onClick={e=>{save()}}>{t('actions.save')}</Button>
                        </Stack>
                    </Stack>
                </Box>

                <Stack sx={{p: 3}} as={Paper} spacing={3} useFlexGap>
                    <Typography variant="h3">Informações</Typography>
                    <Grid item container spacing={3}>
                        <Grid item xs={12} md={9}>
                            <FormGroup>
                                <Typography>Tag</Typography>
                                <TextField 
                                    size="small" 
                                    value={tag} 
                                    onChange={e=>{setTag(e.target.value)}} 
                                    onBlur={e=>{setTag(e.target.value.padStart(24, '0'))}} 
                                    inputProps={{ maxLength: 24 }}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormGroup>
                                <Typography>Status</Typography>
                                <Select size="small" value={ativo} onChange={e=>{setAtivo(e.target.value)}}>
                                    <MenuItem value={'0'}>{t('common.inactive')}</MenuItem>
                                    <MenuItem value={'1'}>{t('common.active')}</MenuItem>
                                    <MenuItem value={'2'}>{t('common.disposal')}</MenuItem>
                                    <MenuItem value={'3'}>{t('common.deprecated')}</MenuItem>
                                </Select>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormGroup>
                                <Typography>Categoria</Typography>
                                <Autocomplete
                                    size="small"
                                    options={categoriaList}
                                    getOptionLabel={(option) => option.descricao}
                                    value={categoriaList.find(cat => cat._id === categoria) || null}
                                    onChange={(event, newValue) => {
                                        setCategoria(newValue ? newValue._id : null);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    noOptionsText={'Não encontrado'}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormGroup>
                                <Typography>Marca</Typography>
                                <Autocomplete
                                    size="small"
                                    options={marcaList}
                                    getOptionLabel={(option) => option.marca}
                                    value={marcaList.find(cat => cat._id === marca) || null}
                                    onChange={(event, newValue) => {
                                        setMarca(newValue ? newValue._id : null);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    noOptionsText={'Não encontrado'}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormGroup>
                                <Typography>Modelo</Typography>
                                <Autocomplete
                                    size="small"
                                    options={modeloList}
                                    getOptionLabel={(option) => option.descricao}
                                    value={modeloList.find(cat => cat._id === modelo) || null}
                                    onChange={(event, newValue) => {
                                        setModelo(newValue ? newValue._id : null);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    noOptionsText={'Não encontrado'}
                                />
                            </FormGroup>
                        </Grid>                    
                        <Grid item xs={12} md={6}>
                            <FormGroup>
                                <Typography>{t('common.partners')}</Typography>
                                <Select size="small" value={parceiro} onChange={e=>{setParceiro(e.target.value)}}>
                                    {parceiroList.map((parceiros)=>(
                                        <MenuItem value={parceiros._id}>{parceiros.nome}</MenuItem>
                                    ))}
                                </Select>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormGroup>
                                <Typography>{t('common.value')}</Typography>
                                <OutlinedInput
                                    type="number" 
                                    size="small" 
                                    value={valor} 
                                    onChange={e=>{setValor(e.target.value)}}
                                    endAdornment={
                                        <InputAdornment position="end" sx={{color: "grey.600"}}>
                                            <Icon icon="material-symbols:attach-money-rounded"/>
                                        </InputAdornment>
                                    }
                                    inputProps={{
                                        'aria-label': 'weight',
                                        min: 0
                                    }}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormGroup>
                                <Typography>{t('common.observation')}</Typography>
                                <TextField size="small" value={observacao} onChange={e=>{setObservacao(e.target.value)}} rows={1} multiline/>
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h3">Informações Complementares</Typography>
                        <FormControlLabel
                            required 
                            control={
                                <Switch
                                    checked={expandInfos}
                                    onChange={e=>{setExpandInfos(e.target.checked)}}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } 
                            label="Required" 
                        />
                    </Stack>

                    <Grid item container spacing={3}>
                        <Grid item xs={12} md>
                            <FormGroup>
                                <Typography>{ infoComplLabels[0] &&  infoComplLabels[0] != '' ?  infoComplLabels[0] : 'Inf. Complementar 1'}</Typography>
                                <TextField size="small" value={infoCompl1} onChange={e=>{setInfoCompl1(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md>
                            <FormGroup>
                                <Typography>{ infoComplLabels[1] &&  infoComplLabels[1] != '' ?  infoComplLabels[1] : 'Inf. Complementar 2'}</Typography>
                                <TextField size="small" value={infoCompl2} onChange={e=>{setInfoCompl2(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md>
                            <FormGroup>
                                <Typography>{ infoComplLabels[2] &&  infoComplLabels[2] != '' ?  infoComplLabels[2] : 'Inf. Complementar 3'}</Typography>
                                <TextField size="small" value={infoCompl3} onChange={e=>{setInfoCompl3(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md>
                            <FormGroup>
                                <Typography>{ infoComplLabels[3] &&  infoComplLabels[3] != '' ?  infoComplLabels[3] : 'Inf. Complementar 4'}</Typography>
                                <TextField size="small" value={infoCompl4} onChange={e=>{setInfoCompl4(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md>
                            <FormGroup>
                                <Typography>{ infoComplLabels[4] &&  infoComplLabels[4] != '' ?  infoComplLabels[4] : 'Inf. Complementar 5'}</Typography>
                                <TextField size="small" value={infoCompl5} onChange={e=>{setInfoCompl5(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={3}>
                        <Grid item xs={12} md>
                            <FormGroup>
                                <Typography>{ infoComplLabels[6] &&  infoComplLabels[6] != '' ?  infoComplLabels[6] : 'Inf. Complementar 6'}</Typography>
                                <TextField size="small" value={infoCompl6} onChange={e=>{setInfoCompl6(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md>
                            <FormGroup>
                                <Typography>{ infoComplLabels[6] &&  infoComplLabels[6] != '' ?  infoComplLabels[6] : 'Inf. Complementar 7'}</Typography>
                                <TextField size="small" value={infoCompl7} onChange={e=>{setInfoCompl7(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md>
                            <FormGroup>
                                <Typography>{ infoComplLabels[7] &&  infoComplLabels[7] != '' ?  infoComplLabels[7] : 'Inf. Complementar 8'}</Typography>
                                <TextField size="small" value={infoCompl8} onChange={e=>{setInfoCompl8(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md>
                            <FormGroup>
                                <Typography>{ infoComplLabels[8] &&  infoComplLabels[8] != '' ?  infoComplLabels[8] : 'Inf. Complementar 9'}</Typography>
                                <TextField size="small" value={infoCompl9} onChange={e=>{setInfoCompl9(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md>
                            <FormGroup>
                                <Typography>{ infoComplLabels[9] &&  infoComplLabels[9] != '' ?  infoComplLabels[9] : 'Inf. Complementar 10'}</Typography>
                                <TextField size="small" value={infoCompl10} onChange={e=>{setInfoCompl10(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Typography variant="h3">{t('common.locate')}</Typography>
                    <Grid item container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <FormGroup>
                                <Typography>{t('common.level')} 1</Typography>
                                <Autocomplete
                                    size="small"
                                    options={nivel1List}
                                    getOptionLabel={(option) => option.descricao}
                                    value={nivel1List.find(cat => cat._id === nivel1) || null}
                                    onChange={(event, newValue) => {
                                        setNivel1(newValue ? newValue._id : null);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    noOptionsText={'Não encontrado'}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormGroup>
                                <Typography>{t('common.level')} 2</Typography>
                                <Autocomplete
                                    size="small"
                                    options={nivel2List}
                                    getOptionLabel={(option) => option.descricao}
                                    value={nivel2List.find(cat => cat._id === nivel2) || null}
                                    onChange={(event, newValue) => {
                                        setNivel2(newValue ? newValue._id : null);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    noOptionsText={'Não encontrado'}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormGroup>
                                <Typography>{t('common.level')} 3</Typography>
                                <Autocomplete
                                    size="small"
                                    options={nivel3List}
                                    getOptionLabel={(option) => option.descricao}
                                    value={nivel3List.find(cat => cat._id === nivel3) || null}
                                    onChange={(event, newValue) => {
                                        setNivel3(newValue ? newValue._id : null);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    noOptionsText={'Não encontrado'}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormGroup>
                                <Typography>{t('common.level')} 4</Typography>
                                <Autocomplete
                                    size="small"
                                    options={nivel4List}
                                    getOptionLabel={(option) => option.descricao}
                                    value={nivel4List.find(cat => cat._id === nivel4) || null}
                                    onChange={(event, newValue) => {
                                        setNivel4(newValue ? newValue._id : null);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    noOptionsText={'Não encontrado'}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormGroup>
                                <Typography>{t('common.latitude')}</Typography>
                                <TextField size="small" value={latitude} onChange={e=>{setLatitude(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormGroup>
                                <Typography>{t('common.longitude')}</Typography>
                                <TextField size="small" value={longitude} onChange={e=>{setLongitude(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormGroup>
                                <Typography>{t('common.radius')}</Typography>
                                <TextField size="small" value={radius} onChange={e=>{setRadius(e.target.value)}}/>
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Typography variant="h3">{t('common.images')}</Typography>
                    <Stack direction="row" alignItems="center" spacing={5}>
                        <Box
                            component="label"
                            role={undefined}
                            variant="standard"
                            tabIndex={-1}
                            sx={{
                                width: '200px',
                                height: '250px',
                                border: 1,
                                borderColor: "grey.400",
                                background: fotoPrincipal ? `url(${fotoPrincipal})` : "",
                                backgroundColor: fotoPrincipal ? "" : "grey.300",
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                color: fotoPrincipal ? 'transparent' : "grey.600",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 3,
                                position: 'relative'
                            }}
                        >
                            {!fotoPrincipal && (
                                <>
                                    <Icon icon="fluent:camera-add-24-regular" width="32" />
                                    <Typography textAlign="center" textTransform="capitalize">Upload imagem</Typography>
                                </>
                            )}
                            {fotoPrincipal && (
                                <>
                                    <IconButton onClick={e=>{handleRemoveFoto(fotoPrincipal, 'principal')}} sx={{position: "absolute", top: -15, right: -15, backgroundColor: 'white', boxShadow: 2, color: "error.main", zIndex: 2}}>
                                        <Icon icon="mingcute:close-fill" width="16"/>
                                    </IconButton>
                                </>
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={e=>{handleImageInput(e, 'principal')}}
                            />
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem sx={{borderColor: "grey.600"}}/>
                        {fotosComplementares.map((foto, index)=>(
                            <>
                                <Box
                                    component="label"
                                    role={undefined}
                                    variant="standard"
                                    tabIndex={-1}
                                    sx={{
                                        width: '200px',
                                        height: '250px',
                                        border: 1,
                                        borderColor: "grey.200",
                                        background: foto ? `url(${foto})` : "",
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        borderRadius: 3,
                                        position: 'relative'
                                    }}
                                >
                                    {foto && (
                                        <>
                                            <IconButton onClick={e=>{handleRemoveFoto(index, 'complementar')}} sx={{position: "absolute", top: -15, right: -15, backgroundColor: 'white', boxShadow: 2, color: "error.main", zIndex: 2}}>
                                                <Icon icon="mingcute:close-fill" width="16"/>
                                            </IconButton>
                                        </>
                                    )}
                                </Box>
                            </>
                        ))}
                        <Stack alignItems="center" justifyContent="center">
                            <Box
                                component="label"
                                role={undefined}
                                variant="standard"
                                tabIndex={-1}
                                sx={{
                                    p: 1,
                                    backgroundColor: "white",
                                    boxShadow: 3,
                                    borderRadius: 100,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: 'relative'
                                }}
                            >
                                <Icon icon="iconamoon:sign-plus"/>
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={e=>{handleImageInput(e, 'complementar')}}
                                />
                            </Box>
                            <Typography variant="subtitle" textAlign="center">Imagem <br/> Complementar</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </>
    )
}