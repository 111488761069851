import { useEffect, useState } from "react";

import { Grid, IconButton, Stack, Typography, Paper } from "@mui/material";

import { common, cyan, green, grey, lightBlue, purple, red, yellow } from '@mui/material/colors';

import api from "../../../services/api"

import { Icon } from "@iconify/react/dist/iconify.js";

//------------------------------------------------------------------------------

const icons = [
    'hugeicons:delivery-truck-01', // Ícone de caminhão de entrega
    'fluent:box-multiple-search-24-regular', // Ícone de caixas com lupa
    'solar:delivery-line-duotone', // Ícone de linha de entrega 
    'streamline:dollar-coin-1', // Ícone de dinheiro
    'ion:business', // Ícone de edifício comercial 
    'cil:industry', // Ícone de fábrica
    'ph:call-bell' // Ícone de campainha
];

const color = [ yellow, cyan, green, purple, red, lightBlue]  


export default function WidgetItemsByLevel({ data }){
    const [locales, setLocales] = useState(data || [])

    useEffect(()=>{
        if(!data){    
            setLocales([
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "d9d1f965-a03c",
                        "descricao": "Recepção",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 3023
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "3b021df0-b72d",
                        "descricao": "Transito",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 2309
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "605b66c9-b333",
                        "descricao": "Estoque Almox",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1993
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "24329864-aa14",
                        "descricao": "Transito 2",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1892
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "3705b99d-bfd1",
                        "descricao": "Estoque SF",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1855
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "4ddd7495-a1ba",
                        "descricao": "Consumo",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1798
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "16faf2ab-9403",
                        "descricao": "BR-0460 Anual",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1633
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "676f7f47-995a",
                        "descricao": "BR-1202 Anual",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1476
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "f3a15af2-bfa9",
                        "descricao": "BR-1221 Anual",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1204
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "69aa9bb9-9f5f",
                        "descricao": "BR-1228 Anual",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1051
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "09c12d32-8706",
                        "descricao": "BR-8561 Anual",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1050
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "257ab933-9d8a",
                        "descricao": "BR-8234 Anual",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1049
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "4aa47a14-9cff",
                        "descricao": "BR-1215 Anual",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 643
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "971b9c77-b972",
                        "descricao": "BR-9655 Anual",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 565
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "e4941d8d-9f9d",
                        "descricao": "BR-9556 Anual",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 517
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "a0d94c0d-9d30",
                        "descricao": "BR-9108 Anual",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 192
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "2249c7bb-8462",
                        "descricao": "TRIBUNAL DE JUSTIÇA DE TOCANTINS",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 39
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "8009e2d8-8c24",
                        "descricao": "INOVACODE",
                        "id_conta": "_Y12sHQ61",
                        "observacao": "${prazo: 10} dias"
                    },
                    "count": 27
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "b9e081a6-a6c6",
                        "descricao": "316327650001-78",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 11
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "cda440c9-abc2",
                        "descricao": "475084111365-62",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 10
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "c890b280-993d",
                        "descricao": "Oeste",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 9
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "354e2589-a65d",
                        "descricao": "062402810001-36",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 2
                },
                {
                    "_id": null,
                    "count": 2
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "ce126e82-84b0",
                        "descricao": "244556770001-82",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "510ebd88-8647",
                        "descricao": "382544870001-01",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "77391831-a7e1",
                        "descricao": "046026240001-30",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "e4080c98-bed1",
                        "descricao": "134097750011-39",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1
                },
                {
                    "_id": {
                        "ativo": 1,
                        "_id": "0bb603ce-b7b8",
                        "descricao": "002795310003-27",
                        "id_conta": "_Y12sHQ61"
                    },
                    "count": 1
                }
            ])        
            //getDataLocale()
        }
    }, [])

    function getDataLocale(){
        api.get(`/qtdeItens_nivel/${sessionStorage.getItem('idConta')}`)
        .then(
            response => {
                console.log(response.data.sort((a, b) => b.count - a.count))

                setLocales(response.data.sort((a, b) => b.count - a.count))
            }
        )
    }

    function getIcon(name, index) {
        const levelName = name.toLowerCase()

        const findIcon = icons.find(icon => icon.includes(name)); // Procura em [icons] se existe um icone relacionado (por nome)

        if (findIcon) {
            return findIcon
        }

        if(levelName.includes('transporte') || levelName.includes('transport') || levelName.includes('transito') || levelName.includes('transito') || levelName.includes('entrega') || levelName.includes('delivery')){
            return 'hugeicons:delivery-truck-01'
        }

        if(levelName.includes('estoque sf')){
            return 'cil:industry'
        }

        if(levelName.includes('stock') || levelName.includes('estoque') || levelName.includes('almoxarifado')){
            return 'fluent:box-multiple-search-24-regular'
        }

        if(levelName.includes('recepção')){
            return 'ph:call-bell'
        }

        if(levelName.includes('consumo')){
            return 'streamline:dollar-coin-1'
        }

        return icons[index]; // Caso o ícone não seja encontrado, retorna null ou qualquer valor padrão desejado.
    }
    

    return (
        <>
            <Stack direction="row" spacing={2} padding={1}>
                {locales.length > 0 ? 
                    locales.slice(0, 6).map((locale, i)=>(
                        <Stack 
                            direction="row"
                            justifyContent="space-between"
                            as={Paper}
                            sx={{
                                padding: 2, 
                                borderRadius: 5, 
                                width: '250px'
                            }}
                        >
                            <Stack>
                                <Typography sx={{ color: grey[900],fontWeight: 900, fontSize: '2.3rem'}}>{locale?.count}</Typography>
                                <Typography variant="subtitle" sx={{fontFamily: 'Poppins, sans-serif', fontSize: '0.9rem'}}>{locale?._id?.descricao}</Typography>
                            </Stack>
                            <Stack>
                                <IconButton sx={{backgroundColor: color[i][50]}}>
                                    <Icon icon={getIcon(locale?._id?.descricao, i)} color={color[i][400]}/>
                                </IconButton>
                            </Stack>
                        </Stack>
                    ))
                : 
                    <>
                        {Array(6).fill(0).map((locale, i)=>(
                            <Stack spacing={3} sx={{boxShadow: 1, backgroundColor: common.white, padding: 2, borderRadius: 5, width: '100%'}}>
                                <Typography sx={{fontFamily: 'Poppins, sans-serif'}}>Nivel</Typography>
                                <Typography variant="h1" sx={{fontWeight: 'bold', fontSize: '2rem'}}>123</Typography>
                                <Stack direction="row">
                                    <Icon icon=""/>
                                    <Typography variant="subtitle" sx={{fontFamily: 'Poppins, sans-serif', fontSize: '0.9rem'}}>+12 últimas 24hrs.</Typography>
                                </Stack>
                            </Stack>
                        ))}
                    </>
                }


                
            </Stack>
        </>
    )
}