import { useEffect, useState } from "react";

import { t } from "i18next";

import Loading from "../../../components/ui/Loading";

import api from "../../../services/api";

import { Autocomplete, Box, Button, FormControl, Grid2, InputAdornment, InputLabel, Paper, Stack, styled, TextField, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js"

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { useNavigate, useParams } from "react-router-dom";

import { ptBR } from '@mui/x-data-grid/locales';

import CustomModal from "../../../components/ui/CustomModal";

import Alert from "../../../components/ui/Alert";

// ----------------------------------------------------------

export default function OrderDetails(){
    const { id_registro } = useParams()

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState()

    // Form criar pedido
    const [showModal, setShowModal] = useState(false)

    const [tag, setTag] = useState()

    const [categoria, setCategoria] = useState()

    const [ean, setEan] = useState()

    const [quantidade, setQuantidade] = useState(0)

    const [quantidadeLida, setQuantidadeLida] = useState(0)

    const [quantidadeAuditada, setQuantidadeAuditada] = useState(0)

    const [observacao, setObservacao] = useState()

    // Listas
    const [orders, setOrders] = useState([])

    const [deleteOrders, setDeleteOrders] = useState([])

    const [categorias, setCategorias] = useState([])


    const columns = [
        { 
            field: 'id', 
            headerName: 'ID', 
            width: 90
        },
        {
          field: 'categoria',
          headerName: 'Categoria',
          width: 200 
        },
        {
          field: 'ean',
          headerName: 'EAN',
          width: 200 
        },
        {
            field: 'quantidade',
            headerName: 'Quantidade', 
            editable: true,
            width: 200
        },
        {
            field: 'quantidade_lida',
            headerName: 'Quantidade lida', 
            editable: true,
            width: 200
        },
        {
            field: 'quantidade_auditada',
            headerName: 'Quantidade auditada', 
            editable: true,
            width: 200
        },
        {
            field: 'observacao',
            headerName: 'Observacao',
            width: 300, 
            editable: true,
            width: 200 
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<Icon icon="solar:trash-bin-minimalistic-linear" width="20"/>}
                        label="Cancel"
                        className="textPrimary"
                        onClick={e=>{handleDelete(id)}}
                        color="inherit"
                    />
                ]
            }
        }
    ];

    useEffect(()=>{
        function getcategorias() {
            setLoading(true)
            api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/1`, {})
            .then(
                response => {
                    setCategorias(response.data.slice(0, -1))
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        }

        getcategorias()
    }, [])

    useEffect(()=>{
        async function getOrders() {
            setLoading(true)
            await api.get(`/registro_pedido/*/${id_registro}`, {})
            .then(
                response => {
                    const res = response.data.map((item, index) => ({
                        ...item, 
                        id: index
                    }))
                    setOrders(res)
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        }

        getOrders()
    }, [])

    function handleSave(){
        setLoading(true)
        
        if(deleteOrders.length){
            let deleteError = false
            deleteOrders.map((_id)=>{
                api.delete(`/registro_pedido/${_id}`).then(
                    response=> {
                        
                    },
                    error=>{
                        deleteError = true
                    }
                )
            })
            if(deleteError){
                setMessage({
                    "type": "error",
                    "title": t('messages.errors.error'),
                    "message": t('messages.errors.500')
                })
                setLoading(false)
            }else{
                setLoading(false)
                setMessage({
                    "type": "success",
                    "title": t('messages.success'),
                    "message": t('settings.clean_base.success_message')
                })
            }
        }
    }

    function handleCreate(){
        setShowModal(false)
        setLoading(true)
        let option = {headers: { 'Content-Type': ['application/json']}}
        const order = {
            id_conta: sessionStorage.getItem('idConta'),
            id_registro: id_registro,
            tag: tag || "",
            id_categoria: categoria?._id,
            ean: categoria?.ean,
            quantidade: quantidade || 0,
            quantidade_lida: quantidadeLida || 0,
            quantidade_auditada: quantidadeAuditada || 0, 
            observacao: observacao,
            id: orders.length + 1
        }
        api.post(`/registro_pedido`, [order], option)
        .then(
            response => {
                setOrders(prev => [...prev, order])
                setTag()
                setCategoria()
                setQuantidade(0)
                setQuantidadeLida(0)
                setQuantidadeAuditada(0)
                setShowModal(false)
                setMessage({
                    "type": "success",
                    "title": t('messages.success'),
                    "message": t('settings.clean_base.success_message')
                })
            },
            error=>{
                setMessage({
                    "type": "error",
                    "title": t('messages.errors.error'),
                    "message": t('messages.errors.500')
                })
            }
        ).finally(
            () => {
                setShowModal(false)
                setLoading(false)
            }
        )
    }

    function handleDelete(id){
        const item = orders.find((row)=> row.id === id)
        setOrders(orders.filter((row) => row.id !== id))
        setDeleteOrders(prev => [...prev, item._id])
    }

    return (
        <>
            <Stack spacing={3}>
                
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h1">{t('common.demand')}</Typography>
                    <Stack direction="row" spacing={1}>
                        <Button variant="outlined" color="dark" onClick={e=> {navigate('/orders')}}>{t('actions.exit')}</Button>
                        <Button variant="contained" color="error" onClick={e=> {handleSave()}}>{t('actions.save')}</Button>
                    </Stack>
                </Stack>

                <Stack spacing={3} as={Paper} sx={{borderRadius: 3, p: 3}}>
                    
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h2">{t('common.items')}</Typography>
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" color="dark" onClick={e=> {setShowModal(true)}}>{t('actions.add')}</Button>
                        </Stack>
                    </Stack>
                    <DataGrid
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={orders}
                        columns={columns}
                        editMode="row"
                        hideFooterPagination
                        disableRowSelectionOnClick={true}
                        sx={{
                            border: 0
                        }}
                        processRowUpdate={(updatedRow, originalRow) => {
                            setOrders(prevRows => {
                                const newRows = [...prevRows];
                                newRows[originalRow.id] = updatedRow;
                                return newRows;
                            });
                        }}                        
                        getRowHeight={({ id, densityFactor }) => {
                            if (id % 2 === 0) {
                            return 50 * densityFactor;
                            }
                
                            return null;
                        }}
                        slotProps={{
                            columnsManagement: {
                                toggleAllMode: 'filteredOnly', 
                            },
                        }}
                    />
                </Stack>
            </Stack>

            {showModal && (
                <CustomModal
                    disableIcon
                    title={`${t('actions.create')}  ${t('common.demand')}`}
                    onSave={e=>{handleCreate()}}
                    onClose={e=>{setShowModal(false)}}
                    width= {'40vw'}
                >
                    <Stack spacing={3}>
                        <div>
                            <InputLabel>Tag</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                value={tag}
                                onChange={e=>{setTag(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel>{t('common.categories')}</InputLabel>
                            <Autocomplete
                                size="small"
                                options={categorias}
                                getOptionLabel={(option) => option.descricao}
                                value={categorias.find(cat => cat._id === categoria?._id) || null}
                                onChange={(event, newValue) => {
                                    setCategoria(newValue ? newValue : null);
                                    setEan(newValue?.ean ? newValue?.ean : null);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                noOptionsText={'Não encontrado'}
                            />
                        </div>
                        <div>
                            <InputLabel>EAN</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                value={ean}
                                onChange={e=>{setEan(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel>Quantidade</InputLabel>
                            <TextField
                                fullWidth
                                type="number"
                                size="small"
                                min={0}
                                value={quantidade}
                                onChange={e=>{setQuantidade(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel>Quantidade Lida</InputLabel>
                            <TextField
                                fullWidth
                                type="number"
                                size="small"
                                min={0}
                                value={quantidadeLida}
                                onChange={e=>{setQuantidadeLida(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel>Quantidade Auditada</InputLabel>
                            <TextField
                                fullWidth
                                type="number"
                                min={0}
                                size="small"
                                value={quantidadeAuditada}
                                onChange={e=>{setQuantidadeAuditada(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel>Observação</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                value={observacao}
                                onChange={e=>{setObservacao(e.target.value)}}
                            />
                        </div>
                    </Stack>
                    
                </CustomModal>
            )}

            <Loading show={loading}/>

            {message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{
                        setMessage(e)
                    }}
                />
            : ''}
        </>
    )
}